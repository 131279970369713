import { useRef, useState, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  TextField,
  Grid
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { RootState } from '../../../store/store';
import { NeedsAction } from '../../../models/needs-action.model';
import { getNeedsActionNotificationsAction } from '../../../store/action-creators/needs-action-actions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as permitActions from 'src/store/action-creators/permit-actions';
import './Notes.scss';
import { PermitRequest } from 'src/models/permit.model';

import { K2Notes } from 'src/models/k2notes.model';

import BootstrapDialogTitle from '../components/DialogTitle/DialogTitle';

import classNames from 'classnames';

import { handleEnterKeyDown } from 'src/utils/handlers-utils';

import moment from 'moment';
import { LoadingOverlay } from 'src/components/utils/LoadingOverlay/LoadingOverlay';
interface Props extends PropsFromRedux {
  permit: PermitRequest;
}

const Notes = (props: Props) => {
  const { permit, updateInsertNotes, currentUser } = props;

  const dispatch = useDispatch();
  // get notifications
  useEffect(() => {
    dispatch(getNeedsActionNotificationsAction());
  }, [permit.id]);

  const [notesForm, setNotesForm] = useState<K2Notes[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const updateNotes = permit.k2Notes.filter(
      (note) => note.modifiedBy === currentUser?.name
    );
    setNotesForm(updateNotes);
  }, [permit]);

  // only needed notifications - To Display Needs Action
  const notifications = useSelector<RootState, NeedsAction[]>(
    (state) => state.needsAction.needsActNotifications
  ).filter((n) => n.permitRequestId === permit.id);

  const notesTileRef = useRef(null);
  const needsActionTileRef = useRef(null);

  const [expanded, setExpanded] = useState(true);
  const [open, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleNotesChange = (event: any) => {
    const { name, value, id } = event.target;
    const newArray = [...notesForm];
    //newArray[id] is attribute of Textfield
    newArray[id].quickNote = value;
    newArray[id].id
      ? (newArray[id].action = 'UPDATE')
      : (newArray[id].action = 'INSERT');
    setNotesForm(newArray);
  };

  const handleAddNotes = () => {
    const newArray = [
      ...notesForm,
      {
        permitRequestId: permit.id,
        quickNote: '',
        customNote: '',
        action: 'INSERT'
      }
    ];
    setNotesForm(newArray);
  };

  const handleUpdateNotes = () => {
    const updateInsNotes = notesForm.filter(
      (note) => note.action === 'UPDATE' || note.action === 'INSERT'
    );

    if (updateInsNotes && updateInsNotes.length > 0) {
      setIsLoading(true);

      updateInsertNotes(String(permit.id), updateInsNotes)
        .then(
          () => {
            setIsLoading(false);
            setOpenDialog(false);
          },
          () => {
            setIsLoading(false);
            setOpenDialog(false);
          }
        )
        .catch(() => {
          setIsLoading(false);
          setOpenDialog(false);
        });
    } else {
      setOpenDialog(false);
    }
  };

  const handleOpenDialog = (event: React.MouseEvent<HTMLElement>) => {
    const id = event.currentTarget.id;
    if (id == 'k2notes') {
      return;
    }
    setOpenDialog(true);
  };

  return (
    <>
      <section
        className={classNames('permit-notes')}
        tabIndex={0}
        ref={notesTileRef}
        onKeyDown={(event) => handleEnterKeyDown(event, notesTileRef)}
      >
        <header>
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            Notes
          </Typography>
        </header>
        <div className="cell">
          <Button
            id="add-note"
            variant="contained"
            color="primary"
            onClick={handleOpenDialog}
          >
            Add Note
          </Button>
        </div>
        <section className="permit-notes-tile">
          <div
            className="title"
            id="k2notes"
            title={expanded ? 'Hide Notes' : 'Show Notes'}
            onClick={() => setExpanded(!expanded)}
          >
            <KeyboardArrowDownIcon
              style={{
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 200ms'
              }}
            />
            <div>Notes</div>
          </div>

          <Collapse in={expanded} unmountOnExit>
            <section
              className="notes-list-view"
              tabIndex={0}
              ref={notesTileRef}
              onKeyDown={(event) => handleEnterKeyDown(event, notesTileRef)}
            >
              <TableContainer sx={{ mb: '16px' }} component={Paper}>
                <Table>
                  {permit.k2Notes && permit.k2Notes.length > 0 && (
                    <TableHead>
                      <TableRow>
                        <TableCell className="notes-date-col">
                          RecordCreatedDate
                        </TableCell>
                        <TableCell className="notes-quicknote-col">
                          QuickNote
                        </TableCell>
                        <TableCell className="notes-modifyby-col">
                          Notes From K2
                        </TableCell>
                        <TableCell className="notes-modifyby-col">
                          ModifiedBy
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {permit.k2Notes.map((k2Notes: K2Notes) => (
                      <TableRow key={k2Notes.id}>
                        <TableCell className="notes-date-col">
                          {moment(k2Notes.recordCreatedDate).format(
                            'M/D/YYYY - HH:mm'
                          )}
                        </TableCell>
                        <TableCell className="notes-quicknote-col">
                          {k2Notes.quickNote}
                        </TableCell>
                        <TableCell className="notes-customnote-col">
                          {k2Notes.customNote}
                        </TableCell>
                        <TableCell className="notes-modifyby-col">
                          {k2Notes.modifiedBy}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </section>

            <section
              className="notes-list-view"
              tabIndex={0}
              ref={needsActionTileRef}
              onKeyDown={(event) =>
                handleEnterKeyDown(event, needsActionTileRef)
              }
            >
              {permit.needsAction && permit.needsAction.length > 0 && (
                <div className="title">
                  <strong>Request for info</strong>
                </div>
              )}
              <TableContainer sx={{ mb: '16px' }} component={Paper}>
                <Table>
                  {permit.needsAction && permit.needsAction.length > 0 && (
                    <TableHead>
                      <TableRow>
                        <TableCell className="needsaction-message-col">
                          Coordinator Message
                        </TableCell>
                        <TableCell className="needsaction-resolve-col">
                          Resolved
                        </TableCell>
                        <TableCell className="needsaction-created-col">
                          Created By
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {permit.needsAction &&
                      permit.needsAction.map((needsAction) => (
                        <TableRow key={needsAction.id}>
                          <TableCell className="notes-date-col">
                            {needsAction.text}
                          </TableCell>
                          <TableCell className="notes-quicknote-col">
                            {needsAction.resolved ? 'YES' : 'NO'}
                          </TableCell>
                          <TableCell className="notes-created-col">
                            {needsAction.authorName}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </section>
          </Collapse>
        </section>
      </section>

      <Dialog
        className="notes-dialog"
        fullWidth
        maxWidth="md"
        open={open}
        onBackdropClick={() => handleCloseDialog()}
        onClose={() => handleCloseDialog()}
      >
        <LoadingOverlay isVisible={isLoading} />

        <BootstrapDialogTitle onClose={handleCloseDialog}>
          <strong>Notes</strong>
        </BootstrapDialogTitle>

        <DialogContent>
          <form id="notes-form" className="notes-form">
            <div className="notes-list-dialog">
              <div className="attachment-item">
                <Grid container className="info-grid" spacing={2}>
                  <Grid item xs={10}>
                    QuickNote
                  </Grid>
                </Grid>
              </div>
              {notesForm.map((k2Note, index) => (
                <div className="attachment-item" key={index}>
                  <Grid container className="info-grid" spacing={2}>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        size="small"
                        name="quickNote"
                        inputProps={{ maxLength: 500 }}
                        value={k2Note.quickNote ? k2Note.quickNote : ''}
                        onChange={handleNotesChange}
                        id={index.toString()}
                      />
                    </Grid>
                  </Grid>
                </div>
              ))}
            </div>
          </form>
        </DialogContent>

        <DialogActions
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <IconButton
            className="add-button"
            title="Add Notes"
            onClick={handleAddNotes}
          >
            <AddCircleIcon color="primary">Add Another Note</AddCircleIcon>
          </IconButton>

          <div>
            <Button sx={{ marginRight: '10px' }} onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              form="notes-form"
              onClick={handleUpdateNotes}
              disabled={isLoading}
            >
              Update
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUser: state.auth.currentUser
});

const mapDispatchToProps = {
  updateInsertNotes: permitActions.updateInsertNotes
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Notes);
