import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import attachmentReducer from './reducers/attachment-reducer';
import authReducer from './reducers/auth-reducer';
import commentReducer from './reducers/comment-reducer';
import filterReducer from './reducers/filter-reducer';
import permitReducer from './reducers/permit-reducer';
import permitSidebarReducer from './reducers/permit-sidebar-reducer';
import needsActionReducer from './reducers/needs-action-reducer';
import alertReducer from './reducers/alert-reducer';

// Reducers
export const reducers = combineReducers({
  attachment: attachmentReducer,
  auth: authReducer,
  comment: commentReducer,
  filter: filterReducer,
  permit: permitReducer,
  permitSidebar: permitSidebarReducer,
  needsAction: needsActionReducer,
  alert: alertReducer
});

// State
const initialState = {};

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof Function;
  }
}

const composeMiddleware: any = window.__REDUX_DEVTOOLS_EXTENSION__
  ? compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__())
  : applyMiddleware(thunk);

// Store
export const store = createStore(reducers, initialState, composeMiddleware);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
