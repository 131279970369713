import { KeyboardEvent, MutableRefObject } from 'react';

/**
 * Handles keyboard down event ('Enter' key), and calls click event on ref element
 *
 * @param event - keyboard event
 * @param clickRef - ref to element which will be clicked
 */
export const handleEnterKeyDown = (
  event: KeyboardEvent,
  clickRef: MutableRefObject<HTMLElement | null>
) => {
  // prevent parent key down event handler overwritting the child's one
  if (event.currentTarget != event.target) {
    return;
  }

  if (event.key === 'Enter') {
    clickRef.current && clickRef.current.click();
  }
};

/**
 * Returns a handler for keyDownEvent. If the pressed key
 * is equal Enter or is equal Space (i.e. keys used for confirmation)
 * then it executes the provided callback.
 *
 * @param event - keyboard event
 */
export const onKeyboardConfirm = (callback: () => any) => {
  return (event: KeyboardEvent) => {
    // prevent parent key down event handler overwritting the child's one
    if (event.currentTarget != event.target) {
      return;
    }
    if (event.key === 'Enter' || event.key === ' ') {
      if (callback) {
        event.preventDefault();
        callback();
      }
    }
  };
};
