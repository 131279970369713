import React, { useEffect, useState } from 'react';
import './TitleAndSaveStatus.scss';
import { CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import moment from 'moment';

interface Props {
  titleName: string;
  isSavingPermitData: boolean;
  isPermitUploadSuccessful: boolean;
}

const TitleAndSaveStatus = (props: Props) => {
  const { titleName, isSavingPermitData, isPermitUploadSuccessful } = props;

  // keep track of last updated time
  const [lastUpdated, setLastUpdated] = useState(new Date());
  // state to keep track of first change in this component
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (isSavingPermitData) {
      setHasChanged(true);
      setLastUpdated(new Date());
    }

    const timer = setInterval(() => {
      // line below will force component update - trigger view changes
      setLastUpdated(new Date(lastUpdated));
    }, 10000);
    return () => {
      clearInterval(timer);
    };
  }, [isSavingPermitData]);

  return (
    <div className="title-and-save-status">
      <h2>{titleName}</h2>
      {hasChanged &&
        (isPermitUploadSuccessful ? (
          <>
            {isSavingPermitData ? (
              <div className="saving-status-icon">
                <i>Saving...</i>
                <CircularProgress size="15px" />
              </div>
            ) : (
              <div className="saving-status-icon">
                <strong>Saved</strong>
                <CheckCircleIcon color="success" fontSize="small" />
                {moment(lastUpdated).fromNow()}
              </div>
            )}
          </>
        ) : (
          <div className="saving-status-icon">
            <strong>Upload Failed</strong>
            <ErrorIcon color="error" fontSize="small" />
          </div>
        ))}
    </div>
  );
};

export default TitleAndSaveStatus;
