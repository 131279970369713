import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField
} from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingOverlay } from 'src/components/utils/LoadingOverlay/LoadingOverlay';
import {
  AssignedUsersSpec,
  Coordinator
} from 'src/models/assigned-users.model';
import { ReassignmentForm } from 'src/models/form.model';
import BootstrapDialogTitle from '../DialogTitle/DialogTitle';
import './Reassignment.scss';
import * as permitActions from 'src/store/action-creators/permit-actions';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/store/store';
import {
  ROLE_PSS,
  ROLE_QA_QC,
  ROLE_TCP,
  ROLE_TS,
  ROLE_TA
} from 'src/constants/coordinator-roles';

interface Props extends PropsFromRedux {
  isRequestingTechSpec?: boolean;
  isRequestingTechAdvisor?: boolean;
  permitId: number;
  assignedCoordinators: AssignedUsersSpec[];
  open: boolean;
  setOpen: (open: boolean) => void;
}

const Reassignment = (props: Props) => {
  const {
    isRequestingTechSpec,
    isRequestingTechAdvisor,
    permitId,
    assignedCoordinators,
    open,
    permittingTeam,
    setOpen,
    updateAssignedUsers
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [reassignmentForm, setReassignmentForm] = useState<ReassignmentForm>({
    qaqc: null,
    pss: null,
    tcp: null,
    ts: null,
    ta: null
  });

  useEffect(() => {
    const qaqc = assignedCoordinators.find((item) => item.role === ROLE_QA_QC);
    const pss = assignedCoordinators.find((item) => item.role === ROLE_PSS);
    const tcp = assignedCoordinators.find((item) => item.role === ROLE_TCP);
    const ts = assignedCoordinators.find((item) => item.role === ROLE_TS);
    const ta = assignedCoordinators.find((item) => item.role === ROLE_TA);

    setReassignmentForm({
      qaqc: getCoordinator(qaqc),
      pss: getCoordinator(pss),
      tcp: getCoordinator(tcp),
      ts: getCoordinator(ts),
      ta: getCoordinator(ta)
    });
  }, [open]);

  const getCoordinator = (
    assignedUser: AssignedUsersSpec | undefined
  ): Coordinator | null => {
    let result = null;
    if (assignedUser) {
      result = {
        id: assignedUser.id,
        name: assignedUser.name,
        email: assignedUser.email,
        role: assignedUser.role
      };
    }
    return result;
  };

  const handleCoordinatorChange = (
    role: 'qaqc' | 'pss' | 'tcp' | 'ts' | 'ta',
    value: Coordinator | null
  ) => {
    setReassignmentForm({
      ...reassignmentForm,
      [role]: value
    });
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    // filter only non-null values
    const coordinators = Object.values(reassignmentForm).filter(
      (item): item is Coordinator => Boolean(item)
    );

    // simulate saving
    updateAssignedUsers(String(permitId), coordinators).then(
      () => {
        setIsLoading(false);
        setOpen(false);
      },
      () => setIsLoading(false)
    );
  };

  return (
    <Dialog
      className="reassignment-dialog"
      maxWidth="lg"
      open={open}
      onBackdropClick={handleDialogClose}
    >
      <LoadingOverlay isVisible={isLoading} />

      <BootstrapDialogTitle onClose={handleDialogClose}>
        <strong>Permit Request Assignments</strong>
      </BootstrapDialogTitle>
      <DialogContent>
        <div className="dialog-content">
          <Autocomplete
            popupIcon={''}
            disabled={isRequestingTechSpec || isRequestingTechAdvisor}
            onChange={(event, value) => handleCoordinatorChange('qaqc', value)}
            size="small"
            disablePortal
            options={permittingTeam.qaqc}
            value={reassignmentForm.qaqc}
            isOptionEqualToValue={(option, value) =>
              option.email === value.email
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="QA/QC"
                InputLabelProps={{ required: false }}
              />
            )}
          />

          <Autocomplete
            popupIcon={''}
            disabled={isRequestingTechSpec || isRequestingTechAdvisor}
            onChange={(event, value) => handleCoordinatorChange('pss', value)}
            size="small"
            disablePortal
            options={permittingTeam.pss}
            value={reassignmentForm.pss}
            isOptionEqualToValue={(option, value) =>
              option.email === value.email
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="PSS"
                InputLabelProps={{ required: false }}
              />
            )}
          />

          <Autocomplete
            popupIcon={''}
            disabled={isRequestingTechSpec || isRequestingTechAdvisor}
            onChange={(event, value) => handleCoordinatorChange('tcp', value)}
            size="small"
            disablePortal
            options={permittingTeam.tcp}
            value={reassignmentForm.tcp}
            isOptionEqualToValue={(option, value) =>
              option.email === value.email
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="TCP"
                InputLabelProps={{ required: false }}
              />
            )}
          />

          <Autocomplete
            popupIcon={''}
            disabled={!isRequestingTechSpec}
            onChange={(event, value) => handleCoordinatorChange('ts', value)}
            size="small"
            disablePortal
            options={permittingTeam.ts}
            value={reassignmentForm.ts}
            isOptionEqualToValue={(option, value) =>
              option.email === value.email
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="TS"
                InputLabelProps={{ required: false }}
              />
            )}
          />

          <Autocomplete
            popupIcon={''}
            disabled={!isRequestingTechAdvisor}
            onChange={(event, value) => handleCoordinatorChange('ta', value)}
            size="small"
            disablePortal
            options={permittingTeam.ta}
            value={reassignmentForm.ta}
            isOptionEqualToValue={(option, value) =>
              option.email === value.email
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="TA"
                InputLabelProps={{ required: false }}
              />
            )}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: RootState) => ({
  permittingTeam: state.filter.dropdownOptions.permittingTeam
});

const mapDispatchToProps = {
  updateAssignedUsers: permitActions.updateAssignedUsersAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Reassignment);
