import { createSelector, Selector } from 'reselect';
import { RootState } from '../store';
import { User } from '../../models/user.model';
import { PermitDashboard, PermitRequest } from '../../models/permit.model';

type rbeType = [
  Selector<RootState, User[]>,
  Selector<RootState, User[]>,
  Selector<RootState, PermitDashboard[]>,
  Selector<RootState, PermitRequest | null>,
  Selector<RootState, string>
]

/**
 * Returns requestor data as User Object based on provided email.
 *
 * First, it searches the ADO users from state
 * if not found, it searches all users from state
 * if not found, the permits in state are searched, if there is match, createdByName is extracted
 *
 */
export const requestorByEmailSelector = createSelector<rbeType, User | null>(
  [
    (state) => state.filter.dropdownOptions.users,
    (state) => state.filter.dropdownOptions.allUsers,
    (state) => state.permit.permits,
    (state) => state.permit.permit,
    (state, requestorEmail) => requestorEmail
  ],
  (users, allUsers, permits, currentPermit, requestorEmail) => {

    if (!requestorEmail) {
      return null;
    }

    // utility function
    const findByCreatedByEmail = (item: User) => {
      if (!item || !item.email) return false;
      return item.email.toLowerCase() === requestorEmail.toLowerCase();
    };

    // get requestor by ADO users
    let requestor = users.find(findByCreatedByEmail);

    if (!requestor) {
      // get requestor by all users
      requestor = allUsers.find(findByCreatedByEmail);
    }

    if (!requestor) {
      // construct requestor from permit data
      const somePermitOfRequestor = [...permits, currentPermit].find(
        (p) => {
          if (!p?.createdByEmailID) return false;
          return p?.createdByEmailID.toLowerCase() === requestorEmail.toLowerCase();
        }
      );

      requestor = {
        name: somePermitOfRequestor?.createdByName || requestorEmail,
        email: requestorEmail,
        id: '',
        initials: '',
        firstName: '',
        lastName: ''
      };
    }
    return requestor;
  }
);