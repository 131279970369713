import ActionType from '../action-types';
import { AlertActions, AlertState } from '../../models/store-alert.model';

const initialState: AlertState = {};

const alertReducer = (
  state: AlertState = initialState,
  action: AlertActions
): AlertState => {
  switch (action.type) {
    case ActionType.SHOW_ALERT:
      // add item or replace existing
      return {
        ...state,
        ...{ [action.payload.id]: action.payload }
      };
    case ActionType.HIDE_ALERT:
      if (state[action.payload.id]) {
        // remove item
        const copy = { ...state };
        delete copy[action.payload.id];
        return copy;
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default alertReducer;
