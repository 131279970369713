const cities = [
  'AgencyName',
  'Aliso Viejo',
  'Caltrans north of 56',
  'Caltrans south of 56',
  'Carlsbad',
  'Chula Vista',
  'City of San Diego',
  'Coronado',
  'County of Orange',
  'County of San Diego north of 56',
  'County of San Diego south of 56',
  'Dana Point',
  'Del Mar',
  'El Cajon',
  'Encinitas',
  'Escondido',
  'Imperial Beach',
  'La Mesa',
  'Laguna Beach',
  'Laguna Hills',
  'Laguna Niguel',
  'Lemon Grove',
  'Mission Viejo',
  'National City',
  'Oceanside',
  'OCTA',
  'Orange County Caltrans',
  'Port of San Diego',
  'Poway',
  'Railroad/Trolly north of 56',
  'Railroad/Trolly south of 56',
  'San Clemente',
  'San Juan Capistrano',
  'San Marcos',
  'Santee',
  'SCRRA/Metrolink',
  'Solana Beach',
  'Vista'
];

const ocCities = [
  'Aliso Viejo',
  'County of Orange',
  'Dana Point',
  'Laguna Beach',
  'Laguna Hills',
  'Laguna Niguel',
  'Mission Viejo',
  'San Clemente',
  'San Juan Capistrano'
];

const notOcCities = cities.filter((city) => !ocCities.includes(city));

const southCities = [
  'El Cajon',
  'La Mesa',
  'Santee',
  'Lemon Grove',
  'City of San Diego',
  'County of San Diego north of 56',
  'County of San Diego south of 56',
  'Chula Vista',
  'National City',
  'Coronado',
  'Imperial Beach',
  'Port of San Diego',
  'SANDAG'
];

const assignmentLogic = {
  byProjectTypesList: {
    ['HFTD,Tier 2,Tier 3']: {
      byAgenciesList: {
        [ocCities.join(',')]: {
          ROLE_QA_QC: null, // when null, assigment by jurisdiction will be used
          ROLE_PSS: 'Mitch Bluman', // todo: replace by email for identity
          ROLE_TCP: null
        },
        [notOcCities.join(',')]: {
          ROLE_QA_QC: null, // when null, assigment by jurisdiction will be used
          ROLE_PSS: 'Monica Beltran', // todo: replace by email for identity
          ROLE_TCP: 'Juan Fierro' // todo: replace by email for identity
        }
      }
    },
    ['GTS']: {
      byAgenciesList: {
        [southCities.join(',')]: {
          ROLE_QA_QC: null, // when null, assigment by jurisdiction will be used
          ROLE_PSS: null,
          ROLE_TCP: 'Juan Fierro' // todo: replace by email for identity
        }
      }
    }
  }
};

/**
 * Returns the (first) object key, if provided string is found in any of the keys
 * of the object.
 * Keys of the object are comma separated strings. Exact match is required. i.e.
 * "a" will match "a,b"
 * but "a" should not match "a b, c"
 * @param object
 * @param s
 */
export const objectKeysIncludesString = (o: object, s: string) => {
  return Object.keys(o).find((k) => k.split(',').some((ks) => ks == s));
};

/**
 * Returns overrides for coordinator assigment by provided project type for provided agency
 * @param param0
 * @returns
 */
export const getAssignmentByProjectType = ({
  projectType,
  agency
}: {
  projectType: string;
  agency: string;
}) => {
  const byProjectTypesList = assignmentLogic.byProjectTypesList;
  const projectTypeKey = objectKeysIncludesString(
    byProjectTypesList,
    projectType
  );
  if (projectTypeKey) {
    const byAgenciesList = byProjectTypesList[projectTypeKey].byAgenciesList;
    const agencyKey = objectKeysIncludesString(byAgenciesList, agency);
    if (agencyKey) {
      return byAgenciesList[agencyKey];
    }
  }
  return {
    ROLE_QA_QC: null,
    ROLE_PSS: null,
    ROLE_TCP: null
  };
};
