import ActionType from '../action-types';
import { CommentActions, CommentState } from 'src/models/store-comment.model';

const initialState: CommentState = {
  comments: [],
  tagUser: null,
  unreadComments: {
    unreadCommentTotal: 0,
    unreadComments: []
  }
};

// should create separated function for delete comment considering the different type of payload
const commentReducer = (
  state: CommentState = initialState,
  action: CommentActions
): CommentState => {
  switch (action.type) {
    case ActionType.SET_COMMENTS:
      return {
        ...state,
        comments: [...action.payload]
      };
    case ActionType.ADD_NEW_COMMENT:
      return {
        ...state,
        comments: [...state.comments, action.payload]
      };
    case ActionType.ADD_COMMENT_REPLY:
      return {
        ...state,
        comments: [
          ...state.comments.slice(0, action.payload.replyPosition),
          action.payload.comment,
          ...state.comments.slice(action.payload.replyPosition)
        ]
      };
    case ActionType.SET_COMMENT:
      return {
        ...state,
        comments: [
          // update 1 comment in the array of comments
          ...state.comments.map((item) =>
            item.id === action.payload.id
              ? { ...item, ...action.payload }
              : item
          )
        ]
      };
    case ActionType.REMOVE_COMMENT:
      return {
        ...state,
        comments: [
          // set isRemoved flag for 1 comment in the array of comments
          ...state.comments.map((item) =>
            item.id === action.payload ? { ...item, isRemoved: true } : item
          )
        ]
      };
    case ActionType.SET_COMMENT_TAG_USER:
      return {
        ...state,
        tagUser: action.payload
      };
    case ActionType.SET_UNREAD_COMMENTS:
      return { ...state, unreadComments: action.payload };
    default:
      return state;
  }
};

export default commentReducer;
