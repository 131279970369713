import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Autocomplete,
  Button,
  debounce,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import { LoadingOverlay } from 'src/components/utils/LoadingOverlay/LoadingOverlay';
import {
  MAX_BUDGET_CODE_LENGTH,
  MAX_JOB_TITLE_LENGTH,
  MAX_WORKORDER_NUMBER_LENGTH
} from 'src/constants/permits';
import { Agency } from 'src/models/agency.model';
import { DetailsForm } from 'src/models/form.model';
import { PermitRequest } from 'src/models/permit.model';
import * as permitActions from 'src/store/action-creators/permit-actions';
import * as actions from 'src/store/action-creators/permit-sidebar-actions';
import { RootState } from 'src/store/store';
import { sortByName, transformAgencyObj } from 'src/utils/formatting-utils';
import { isMaxLengthOk } from 'src/utils/permit-utils';
import TitleAndSaveStatus from '../TitleAndSaveStatus/TitleAndSaveStatus';
import './Details.scss';

interface Props extends PropsFromRedux {
  permit: PermitRequest;
}

const Details = (props: Props) => {
  const {
    permit,
    agencies,
    projectTypes,
    outageReqOptions,
    setActiveStep,
    updatePermit,
    updateCoordinators
  } = props;

  const { id } = useParams<{ id: string }>();

  const [detailsForm, setDetailsForm] = useState<DetailsForm>({
    budgetCode: '',
    workOrderNo: '',
    projectNumber: '',
    projectType: '',
    outageRequired: '',
    jobTitle: '',
    agencies: { 0: null }
  });
  const [isSavingPermitData, setIsSavingPermitData] = useState(false);
  const [isPermitUploadSuccessful, setIsPermitUploadSuccessful] =
    useState(true);
  const [isLoadingAgencies, setIsLoadingAgencies] = useState(false);
  const [isPermitViewLoaded, setIsPermitViewLoaded] = useState(false);
  const detailsFormRef = useRef<DetailsForm>(detailsForm);

  detailsFormRef.current = detailsForm;

  useEffect(() => {
    if (
      !isPermitViewLoaded &&
      agencies?.length > 0 &&
      detailsForm?.agencies &&
      detailsForm.agencies[0] != null
    ) {
      updateCoordinators(id, {
        ...transformAgencyObj(detailsForm.agencies),
        tcpRequired: permit.tcpRequired
      });
      setIsPermitViewLoaded(true);
    }
  }, [agencies, detailsForm.agencies]);

  // whenever permit object changes - set default values to states
  useEffect(() => {
    setDetailsForm({
      budgetCode: permit.budgetCode || '',
      workOrderNo: permit.workOrderNo || '',
      projectNumber: permit.projectNumber || '',
      projectType: permit.projectType || '',
      outageRequired: permit.outageRequired || '',
      jobTitle: permit.jobTitle || '',
      agencies: {
        ...(permit.primarAgencyId ? { 0: permit.primarAgencyId } : { 0: null }),
        ...(permit.secondAgencyId ? { 1: permit.secondAgencyId } : {}),
        ...(permit.thirdAgencyId ? { 2: permit.thirdAgencyId } : {}),
        ...(permit.fourthAgencyId ? { 3: permit.fourthAgencyId } : {})
      }
    });
  }, []);

  const isFormValid = (o: Partial<PermitRequest>): boolean => {
    // if jobTitle is set, do not allow to submit form if length is exceeded
    if (o.jobTitle && !isMaxLengthOk(o.jobTitle, MAX_JOB_TITLE_LENGTH)) {
      return false;
    }
    // if budgetCode is set, do not allow to submit form if length is exceeded
    if (o.budgetCode && !isMaxLengthOk(o.budgetCode, MAX_BUDGET_CODE_LENGTH)) {
      return false;
    }
    // if workOrderNo is set, do not allow to submit form if length is exceeded
    if (
      o.workOrderNo &&
      !isMaxLengthOk(o.workOrderNo, MAX_WORKORDER_NUMBER_LENGTH)
    ) {
      return false;
    }
    return true;
  };

  //used to delay the updatePermit function so the input has time to solidify it's format
  const invokeUpdatePermit = (obj: Partial<PermitRequest>) => {
    if (!isFormValid(obj)) return;
    setIsPermitUploadSuccessful(true);
    setIsSavingPermitData(true);
    updatePermit(id, obj)
      .then(() => {
        setIsSavingPermitData(false);
      })
      .catch(() => {
        setIsSavingPermitData(false);
        setIsPermitUploadSuccessful(false);
      });
  };

  const handledProjectTypeChange = (obj: Partial<PermitRequest>) => {
    // do not update coordinators if no agency selected
    if (Object.values(detailsFormRef.current.agencies).some((item) => item)) {
      updateCoordinators(id, {
        ...transformAgencyObj(detailsFormRef.current.agencies),
        tcpRequired: permit.tcpRequired,
        projectType: detailsFormRef.current.projectType
      });
    }
    invokeUpdatePermit(obj);
  };

  // 1 second delay to submitting updatePermit lambda
  const debouncedHandlerBudgetCode = useCallback(
    debounce(invokeUpdatePermit, 1000),
    []
  );
  const debouncedHandlerWorkOrderNo = useCallback(
    debounce(invokeUpdatePermit, 1000),
    []
  );
  const debouncedHandlerProjectNumber = useCallback(
    debounce(invokeUpdatePermit, 1000),
    []
  );
  const debouncedHandlerJobTitle = useCallback(
    debounce(invokeUpdatePermit, 1000),
    []
  );
  const debouncedHandlerProjectType = useCallback(
    debounce(handledProjectTypeChange, 1000),
    []
  );
  const debouncedHandlerOutageRequired = useCallback(
    debounce(invokeUpdatePermit, 1000),
    []
  );

  const handleFormChange = (
    name: string,
    value: string,
    debouncedHandler: (obj: Partial<PermitRequest>) => void
  ) => {
    setIsPermitUploadSuccessful(true);
    setDetailsForm({ ...detailsForm, [name]: value });
    debouncedHandler({ [name]: value });
  };

  const handleAgencyChange = (
    elementKey: number,
    changedValue: Agency | null
  ) => {
    setIsPermitUploadSuccessful(true);
    setDetailsForm((prevState) => {
      const result = {
        ...prevState,
        agencies: {
          ...prevState.agencies,
          [elementKey]: changedValue?.id
        }
      };
      // debouncedAgencyHandler(result.agencies);
      // const result = transformAgencyObj(obj);
      setIsLoadingAgencies(true);
      setIsSavingPermitData(true);

      // do not update coordinators if no agency selected
      if (Object.values(result.agencies).some((item) => item)) {
        updateCoordinators(id, {
          ...transformAgencyObj(result.agencies),
          tcpRequired: permit.tcpRequired,
          projectType: permit.projectType
        });
      }
      updatePermit(id, transformAgencyObj(result.agencies))
        .then(
          () => {
            setIsLoadingAgencies(false);
            setIsSavingPermitData(false);
          },
          () => {
            setIsLoadingAgencies(false);
            setIsSavingPermitData(false);
          }
        )
        .catch(() => {
          setIsPermitUploadSuccessful(false);
        });
      return result;
    });
  };

  const addAgency = () => {
    const elementKeys = Object.keys(detailsForm.agencies).map(Number);
    const newElementKey = elementKeys[elementKeys.length - 1] + 1;
    setDetailsForm((prevState) => ({
      ...prevState,
      agencies: {
        ...prevState.agencies,
        [newElementKey]: null
      }
    }));
  };

  const removeAgency = (elementKey: string) => {
    setDetailsForm((prevState) => {
      delete prevState.agencies[elementKey];
      // debouncedAgencyHandler({ ...prevState.agencies });
      setIsPermitUploadSuccessful(true);
      setIsLoadingAgencies(true);
      setIsSavingPermitData(true);
      updatePermit(id, transformAgencyObj({ ...prevState.agencies }))
        .then(
          () => {
            setIsLoadingAgencies(false);
            setIsSavingPermitData(false);
          },
          () => {
            setIsLoadingAgencies(false);
            setIsSavingPermitData(false);
          }
        )
        .catch(() => {
          setIsPermitUploadSuccessful(false);
        });
      return {
        ...prevState
      };
    });
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setActiveStep('contacts');
  };

  return (
    <>
      <TitleAndSaveStatus
        titleName={'Permit Details'}
        isSavingPermitData={isSavingPermitData}
        isPermitUploadSuccessful={isPermitUploadSuccessful}
      />
      <form className="details-form" onSubmit={submitForm}>
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="job-title-input">Job Title</InputLabel>
          <OutlinedInput
            id="job-title-input"
            label="Job Title"
            name="jobTitle"
            value={detailsForm.jobTitle}
            error={
              !detailsForm.jobTitle ||
              !isMaxLengthOk(detailsForm.jobTitle, MAX_JOB_TITLE_LENGTH)
            }
            onChange={(event) =>
              handleFormChange(
                event.target.name,
                event.target.value,
                debouncedHandlerJobTitle
              )
            }
          />
        </FormControl>

        <div className="budget-code">
          <FormControl variant="outlined" size="small" required>
            <InputLabel htmlFor="budget-code-input">Budget Code</InputLabel>
            <OutlinedInput
              id="budget-code-input"
              label="Budget Code"
              name="budgetCode"
              value={detailsForm.budgetCode}
              error={
                !detailsForm.budgetCode ||
                !isMaxLengthOk(detailsForm.budgetCode, MAX_BUDGET_CODE_LENGTH)
              }
              onChange={(event) =>
                handleFormChange(
                  event.target.name,
                  event.target.value,
                  debouncedHandlerBudgetCode
                )
              }
            />
          </FormControl>
          <ArrowForwardIcon />
          <span>{permit.budgetCodeDescription}</span>
        </div>

        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="work-order-number-input">
            Work Order Number
          </InputLabel>
          <OutlinedInput
            id="work-order-number-input"
            label="Work Order Number"
            name="workOrderNo"
            value={detailsForm.workOrderNo}
            error={
              !isMaxLengthOk(
                detailsForm.workOrderNo,
                MAX_WORKORDER_NUMBER_LENGTH
              )
            }
            onChange={(event) =>
              handleFormChange(
                event.target.name,
                event.target.value,
                debouncedHandlerWorkOrderNo
              )
            }
          />
        </FormControl>

        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="project-number-input">Project Number</InputLabel>
          <OutlinedInput
            id="project-number-input"
            label="Project Number"
            name="projectNumber"
            value={detailsForm.projectNumber}
            error={
              !isMaxLengthOk(
                detailsForm.projectNumber,
                MAX_WORKORDER_NUMBER_LENGTH
              )
            }
            onChange={(event) =>
              handleFormChange(
                event.target.name,
                event.target.value,
                debouncedHandlerProjectNumber
              )
            }
          />
        </FormControl>

        <FormControl variant="outlined" size="small" required>
          <InputLabel id="project-type-select">Project Type</InputLabel>
          <Select
            labelId="project-type-select"
            label="Project Type"
            name="projectType"
            value={detailsForm.projectType}
            onChange={(event) =>
              handleFormChange(
                event.target.name,
                event.target.value,
                debouncedHandlerProjectType
              )
            }
          >
            {projectTypes.map((projectType) => (
              <MenuItem key={projectType.id} value={projectType.type}>
                {projectType.type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" size="small" required>
          <InputLabel id="outage-required-type-select">
            Outage Required for Job
          </InputLabel>
          <Select
            labelId="outage-required-option-select"
            label="Outage Required for Job"
            name="outageRequired"
            value={detailsForm.outageRequired}
            onChange={(event) =>
              handleFormChange(
                event.target.name,
                event.target.value,
                debouncedHandlerOutageRequired
              )
            }
          >
            {outageReqOptions.map((outageReq) => (
              <MenuItem key={outageReq.id} value={outageReq.id}>
                {outageReq.outageRequired}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className="agencies">
          <LoadingOverlay isVisible={isLoadingAgencies} />

          <div className="title">Agency</div>
          {Object.keys(detailsForm.agencies).map((key, index) => (
            <div className="agency" key={key}>
              <Autocomplete
                onChange={(event, value) => {
                  if (value) {
                    handleAgencyChange(Number(key), value);
                  } else {
                    removeAgency(key);
                  }
                }}
                size="small"
                disablePortal
                options={agencies.sort(sortByName)}
                value={
                  agencies.find(
                    (agency) => agency.id === detailsForm.agencies[key]
                  ) || null
                }
                filterOptions={(options, state) =>
                  options.filter(
                    (option) =>
                      !Object.values(detailsForm.agencies).some(
                        (agencyId) => agencyId === option.id
                      ) &&
                      option.name
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                  )
                }
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Agency" required />
                )}
              />
              {index !== 0 && (
                <IconButton
                  title="Remove Agency"
                  onClick={() => removeAgency(key)}
                >
                  <RemoveCircleIcon />
                </IconButton>
              )}
            </div>
          ))}
          {Object.keys(detailsForm.agencies).length < 4 && (
            <div>
              <IconButton
                className="add-button"
                title="Add Agency"
                onClick={addAgency}
              >
                <AddCircleIcon color="primary" />
              </IconButton>
            </div>
          )}
        </div>

        <Button
          className="submit-button"
          type="submit"
          variant="contained"
          disableElevation
          disabled={isSavingPermitData}
        >
          Next
        </Button>
      </form>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  projectTypes: state.filter.dropdownOptions?.projectTypes || [],
  agencies: state.filter.dropdownOptions?.agencies || [],
  outageReqOptions: state.filter.dropdownOptions?.outageReqOptions || []
});

const mapDispatchToProps = {
  setActiveStep: actions.setPermitActiveStepAction,
  updatePermit: permitActions.updatePermitAction,
  updateCoordinators: permitActions.updateCoordinatorsAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Details);
