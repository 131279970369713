import PermitHeader from '../PermitHeader/PermitHeader';
import Contacts from './Contacts/Contacts';
import Details from './Details/Details';
import Schedule from './Schedule/Schedule';
import Location from './Location/Location';
import TCP from './TCP/TCP';
import Scope from './Scope/Scope';
import './PermitForm.scss';
import FormSidebar from './FormSidebar/FormSidebar';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/store/store';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import * as attachmentActions from 'src/store/action-creators/attachment-actions';
import * as permitActions from 'src/store/action-creators/permit-actions';
import Comments from './Comments/Comments';
import Attachements from './Attachments/Attachments';
import { SapInfoMessage } from 'src/components/utils/SapInfoMessage/SapInfoMessage';

const PermitForm = (props: PropsFromRedux) => {
  const { permit, activeFormStep, clearPermit, setHasLoadedAttachments,clearAttachment } =
    props;

  const { id } = useParams<{ id: string }>();

  // on component mount, load all data for currently opened permit
  useEffect(() => {
    setHasLoadedAttachments(false);

    return () => {
      clearPermit();
      clearAttachment();
    };
  }, [id]);

  return (
    <div className="permit-form">
      {permit && (
        <>
          <div className="form-nav">
            <FormSidebar permit={permit}></FormSidebar>
          </div>

          <div className="permit-content">
            {!permit.hasSapAccess && (
              <SapInfoMessage className="sap-offline-message" />
            )}

            <PermitHeader
              notificationsCount={0}
              needsActionNotifications={[]}
              unreadComments={{
                unreadCommentTotal: 0,
                unreadComments: []
              }}
            ></PermitHeader>

            <div className="body">
              <div className="form">
                {
                  {
                    details: <Details permit={permit} />,
                    contacts: (
                      <Contacts
                        permitId={id}
                        permitWatchers={permit.watchers || []}
                        requestorEmail={permit.createdByEmailID || ''}
                      />
                    ),
                    schedule: <Schedule permit={permit} />,
                    location: <Location permit={permit} />,
                    scope: <Scope permit={permit} />,
                    TCP: <TCP permit={permit} />,
                    attachments: <Attachements permit={permit} />
                  }[activeFormStep]
                }
              </div>
              <div className="comments">
                <Comments permitId={id} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  activeFormStep: state.permitSidebar.activeStep,
  permit: state.permit.permit
});

const mapDispatchToProps = {
  clearPermit: permitActions.clearPermitAction,
  clearAttachment: attachmentActions.clearAttachementAction,
  setHasLoadedAttachments: attachmentActions.setHasLoadedAttachmentsAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PermitForm);
