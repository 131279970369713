import ActionType from '../action-types';
import { Dispatch } from 'redux';
import {
  AddCommentAction,
  AddCommentReplyAction,
  RemoveCommentAction,
  SetCommentAction,
  SetCommentsAction,
  SetCommentTagUserAction,
  SetUnreadComments
} from 'src/models/store-comment.model';
import { Comment, USER_TAG_REGEXP } from '../../models/comment.model';
import axiosClient from 'src/utils/axios-client';
import envConfig from 'src/env-config';
import { User } from 'src/models/user.model';
import {
  schNewCommentsNotif,
  schTaggedCommentNotif
} from './notification-actions';
import { makeASCISafe } from '../../utils/formatting-utils';
import { processError } from './alert-actions';

// Get Comments Action
export const getCommentsAction =
  (permitId: string) => async (dispatch: Dispatch<SetCommentsAction>) => {
    // try to execute backend request
    try {
      const response = await axiosClient.get(
        `${envConfig.api.permits}/${permitId}/getComments`
      );
      const comments: Comment[] = response.data.afpermitsResponse.body.comments;
      dispatch({
        type: ActionType.SET_COMMENTS,
        payload: comments
      });
      return Promise.resolve('Sucessfully received!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

//Get Unread Comments Action
export const getUnreadCommentsAction =
  () => async (dispatch: Dispatch<SetUnreadComments>) => {
    try {
      const response = await axiosClient.post(
        `${envConfig.api.permits}/getUnreadComments`
      );

      const unreadComments = response.data.afpermitsResponse.body;

      dispatch({
        type: ActionType.SET_UNREAD_COMMENTS,
        payload: unreadComments
      });
      return Promise.resolve('Successfully received!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

// Add Comment Action
export const addCommentAction =
  (permitId: string, comment: Partial<Comment>) =>
  async (dispatch: Dispatch<AddCommentAction>) => {
    // sanitize text, until db is upgraded
    const commentData = { ...comment, ...{ text: makeASCISafe(comment.text) } };
    // try to execute backend request
    try {
      const response = await axiosClient.post(
        `${envConfig.api.permits}/${permitId}/addComment`,
        commentData
      );

      const addedComment: Comment =
        response.data.afpermitsResponse.body.comment;

      // schedule the TAGGED_COMMENT notification - only if there is a tag present in the comment
      if (new RegExp(USER_TAG_REGEXP).test(addedComment.text)) {
        schTaggedCommentNotif(permitId, addedComment.id);
      } else {
        // schedule the NEW_COMMENTS notification
        schNewCommentsNotif(permitId);
      }

      dispatch({
        type: ActionType.ADD_NEW_COMMENT,
        payload: addedComment
      });
      return Promise.resolve('Successfully added!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

// Add Comment Reply Action
export const addCommentReplyAction =
  (permitId: string, reply: Partial<Comment>, position: number) =>
  async (dispatch: Dispatch<AddCommentReplyAction>) => {
    // sanitize text, until db is upgraded
    const commentData = { ...reply, ...{ text: makeASCISafe(reply.text) } };

    // try to execute backend request
    try {
      const response = await axiosClient.post(
        `${envConfig.api.permits}/${permitId}/addComment`,
        commentData
      );

      const added: Comment = response.data.afpermitsResponse.body.comment;

      // schedule the TAGGED_COMMENT notification - only if there is a tag present in the comment
      if (new RegExp(USER_TAG_REGEXP).test(added.text)) {
        schTaggedCommentNotif(permitId, added.id);
      } else {
        // schedule the NEW_COMMENTS notification
        schNewCommentsNotif(permitId);
      }

      dispatch({
        type: ActionType.ADD_COMMENT_REPLY,
        payload: { comment: added, replyPosition: position }
      });
      return Promise.resolve('Successfully replied!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

// Update Comment Action
export const updateCommentAction =
  (permitId: string, commentId: number, updated: Comment) =>
  async (dispatch: Dispatch<SetCommentAction>) => {
    // sanitize text, until db is upgraded
    const commentData = { ...updated, ...{ text: makeASCISafe(updated.text) } };

    // try to execute backend request
    try {
      await axiosClient.patch(
        `${envConfig.api.permits}/${permitId}/updateComment/${commentId}`,
        commentData
      );

      // schedule the TAGGED_COMMENT notification - only if there is a tag present in the comment
      if (new RegExp(USER_TAG_REGEXP).test(updated.text)) {
        schTaggedCommentNotif(permitId, commentId);
      }

      dispatch({
        type: ActionType.SET_COMMENT,
        payload: updated
      });
      return Promise.resolve('Successfully updated!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

// Remove Comment Action
export const removeCommentAction =
  (permitId: string, commentId: string) =>
  async (dispatch: Dispatch<RemoveCommentAction>) => {
    // try to execute backend request
    try {
      const response = await axiosClient.delete(
        `${envConfig.api.permits}/${permitId}/removeComment/${commentId}`
      );

      const removedId: number = response.data.afpermitsResponse.body.removedId;

      dispatch({
        type: ActionType.REMOVE_COMMENT,
        payload: removedId
      });
      return Promise.resolve('Sucessfully removed!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

// Set Comment Tag User Action
export const setCommentTagUserAction =
  (user: User | null) => (dispatch: Dispatch<SetCommentTagUserAction>) => {
    dispatch({
      type: ActionType.SET_COMMENT_TAG_USER,
      payload: user
    });
  };
