import {
  Button,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { sapAttachment } from 'src/models/permit.model';

interface Props {
  SapAttachment: (sapAttachment | undefined)[];
}

const ViewSapAttachments = (props: Props) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell> File Name</TableCell>
            <TableCell> Document Type</TableCell>
            <TableCell> Link</TableCell>
          </TableRow>
        </TableHead>
        {props.SapAttachment.map((attachment, index) => (
          <tbody key={index}>
            <TableRow>
              <TableCell> {attachment?.fileName}</TableCell>
              <TableCell> {attachment?.docType}</TableCell>
              <TableCell>
                <Button variant="contained" href={attachment?.link}>
                  {' '}
                  View Attachment
                </Button>
              </TableCell>
            </TableRow>
          </tbody>
        ))}
      </Table>
    </TableContainer>
  );
};

export default ViewSapAttachments;
