import { Switch, Route, Redirect } from 'react-router-dom';
import './App.scss';
import NewPermit from './components/NewPermit/NewPermit';
import Login from './components/Login/Login';
import { ThemeProvider } from '@mui/material';
import theme from './theme/theme';
import Dashboard from './components/Dashboard/Dashboard';
import AppNav from './components/AppNav/AppNav';
import { RootState } from './store/store';
import { connect, ConnectedProps } from 'react-redux';
import { useEffect, useState } from 'react';
import * as filterActions from 'src/store/action-creators/filter-actions';
import * as authActions from 'src/store/action-creators/auth-actions';
import envConfig from 'src/env-config';
import { useLoadScript } from '@react-google-maps/api';
import Permit from './components/Permit/Permit';
import { AppAlert } from './components/AppAlert/AppAlert';
import { UserRole } from './models/user-role.model';
import { DashboardFilter } from './models/filter.model';
import { User } from './models/user.model';
import Assignment from './components/Assignment/Assignment';

const App = (props: PropsFromRedux) => {
  const {
    authenticate,
    currentUser,
    dashboardFilter,
    users,
    getDropdownOptions,
    setIsLoadingOptions,
    setDashboardFilter
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [libraries, setLibraries] = useState<any>(['places', 'geometry']);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: envConfig.googleMaps.apiKey,
    libraries
  });

  // trigger on each currentUser change (currently logged in user)
  useEffect(() => {
    if (!currentUser) {
      // try to authenticate only if user is not logged in
      authenticate();
    } else {
      // when user logged in, then load all other data
      setIsLoadingOptions(true);
      getDropdownOptions().then(() => {
        setIsLoadingOptions(false);
      });
    }
  }, [currentUser]);

  // whenever props.currentUser or props.users objects are changed
  useEffect(() => {
    if (currentUser && users.length > 0) {
      const applyToFilter = getFilterValuesToApply(currentUser, users);
      setDashboardFilter({
        ...dashboardFilter,
        ...applyToFilter
      });
    }
  }, [currentUser, users]);

  const getFilterValuesToApply = (user: User, allUsers: User[]) => {
    let result: Partial<DashboardFilter>;
    // set default value to requestor filter
    const filteredUsers = allUsers.filter((item) => item.id === user.id);
    // pick which filers should be applied based of user role
    switch (user.role) {
      case UserRole.REQUESTOR:
        result = {
          requestors: filteredUsers,
          team: []
        };
        break;
      case UserRole.PSS1:
      case UserRole.QAQC:
      case UserRole.TCP:
      case UserRole.TS:
        result = {
          requestors: [],
          team: filteredUsers
        };
        break;
      default:
        result = {
          requestors: [],
          team: []
        };
        break;
    }

    return result;
  };

  return (
    <ThemeProvider theme={theme}>
      <AppAlert />
      <AppNav>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/new-permit" component={NewPermit} />
          <Route exact path="/permit/:id" component={Permit} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/assignment" component={Assignment} />
          <Redirect from="*" to="/" />
        </Switch>
      </AppNav>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUser: state.auth.currentUser,
  dashboardFilter: state.filter.dashboardFilter,
  users: state.filter.dropdownOptions.users
});

const mapDispatchToProps = {
  authenticate: authActions.authenticateAction,
  getDropdownOptions: filterActions.getDropdownOptionsAction,
  setDashboardFilter: filterActions.setDashboardFilterAction,
  setIsLoadingOptions: filterActions.setIsLoadingOptionsAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(App);
