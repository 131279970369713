import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { useCallback, useRef, useState } from 'react';
import GeoCode from 'react-geocode';

import { MarkerMap } from 'src/models/marker-map.model';
import envConfig from 'src/env-config';
import { SUPPORTING_ADDRESS } from 'src/constants/addresses';

interface Props {
  primaryAddress: MarkerMap | null;
  supportingAddress: MarkerMap[];
  permitId?: number | string;
  addSupportingAddress?(marker: MarkerMap): void;
  options: {
    mapContainerStyle: any;
    disableDefaultUI: boolean;
    zoomControl: boolean;
  };
}

const Map = (props: Props) => {
  const {
    primaryAddress,
    supportingAddress,
    permitId,
    addSupportingAddress,
    options,
    options: { mapContainerStyle }
  } = props;
  const mapRef = useRef<GoogleMap>();
  const [selected, setSelected] = useState<MarkerMap>();
  const [center, setCenter] = useState({
    lat: 32.715736,
    lng: -117.161087
  });
  GeoCode.setApiKey(envConfig.googleMaps.apiKey);
  GeoCode.setLanguage('en');

  const onMapClick = useCallback((event): void => {
    if (!permitId || !addSupportingAddress) {
      return;
    }
    const markerMap: MarkerMap = {
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
      time: Date.now(),
      addressType: SUPPORTING_ADDRESS,
      permitId: permitId
    };

    GeoCode.fromLatLng(event.latLng.lat(), event.latLng.lng()).then(
      (response) => {
        const address = response.results[0].formatted_address;
        markerMap.address = address;
        addSupportingAddress(markerMap);
      },
      (error) => {
        markerMap.address = '';
        console.error(error);
      }
    );
  }, []);

  const panAndCenter = (lat: number, lng: number) => {
    try {
      mapRef.current?.panTo({
        lat: lat,
        lng: lng
      });
      setCenter({
        lat: lat,
        lng: lng
      });
    } catch (error) {
      console.error('Error creating marker: ', error);
    }
  };

  const onMapLoad = useCallback((map: any) => {
    mapRef.current = map;
    if (primaryAddress && primaryAddress.latitude && primaryAddress.longitude) {
      panAndCenter(
        Number(primaryAddress.latitude),
        Number(primaryAddress.longitude)
      );
    } else if (supportingAddress && supportingAddress.length > 0) {
      panAndCenter(
        Number(supportingAddress[0].latitude),
        Number(supportingAddress[0].longitude)
      );
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          panAndCenter(position.coords.latitude, position.coords.longitude);
        },
        () => null
      );
    }
  }, []);

  return (
    <GoogleMap
      zoom={8}
      center={center}
      options={options}
      onClick={onMapClick}
      onLoad={onMapLoad}
      mapContainerStyle={mapContainerStyle}
    >
      {supportingAddress?.map(
        (marker: any) =>
          marker.latitude &&
          marker.longitude && (
            <Marker
              key={marker.time}
              position={{
                lat: parseFloat(marker.latitude),
                lng: parseFloat(marker.longitude)
              }}
              onClick={() => {
                setSelected(marker);
              }}
            />
          )
      )}

      {primaryAddress && primaryAddress.latitude && primaryAddress.longitude && (
        <Marker
          key={primaryAddress.time}
          position={{
            lat: parseFloat(primaryAddress.latitude),
            lng: parseFloat(primaryAddress.longitude)
          }}
          onClick={() => {
            setSelected(primaryAddress);
          }}
        />
      )}

      {selected && selected.latitude && selected.longitude && (
        <InfoWindow
          position={{
            lat: parseFloat(selected.latitude),
            lng: parseFloat(selected.longitude)
          }}
          onCloseClick={() => {
            setSelected(undefined);
          }}
        >
          <div>
            <h2>Adress</h2>
            <p>{selected.address}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

Map.defaultProps = {
  options: {
    disableDefaultUI: true,
    zoomControl: false,
    mapContainerStyle: {
      width: '100%',
      height: '100%',
      borderRadius: '10px'
    },
    mapTypeId: 'hybrid'
  }
};

export default Map;
