import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useThemeProps,
  CircularProgress
} from '@mui/material';

import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import EventIcon from '@mui/icons-material/Event';
import CheckIcon from '@mui/icons-material/Check';
import LinkIcon from '@mui/icons-material/Link';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import './Details.scss';
import BootstrapDialogTitle from '../components/DialogTitle/DialogTitle';
import { ReactComponent as UndrawFollowing } from 'src/assets/images/logos/undraw-following.svg';
import { ReactComponent as UndrawMailSent } from 'src/assets/images/logos/undraw-mail-sent.svg';
import * as permitActions from 'src/store/action-creators/permit-actions';
import {
  getPermitAction,
  updatePermitDetailsAction
} from 'src/store/action-creators/permit-actions';
import { RootState } from 'src/store/store';
import { PermitRequest } from 'src/models/permit.model';
import { Agency } from 'src/models/agency.model';
import { PermitDetailsForm, Text } from 'src/models/form.model';
import {
  SetPermitAction,
  SetPermitPropertiesAction
} from 'src/models/store-permit.model';
import {
  formatCurrency,
  formatDateTime,
  makeASCISafe,
  sortByName,
  transformAgencyObj
} from '../../../utils/formatting-utils';
import { StatusType } from '../../../models/status.model';
import { PERMIT_COMPLETE_IDS } from 'src/constants/statuses';
import {
  checkPermissions,
  notifyNotAllowed
} from '../../../utils/authorize-utils';
import { permissionsList } from '../../../constants/authorize-permissions-list';
import { handleEnterKeyDown } from 'src/utils/handlers-utils';
import {
  MAX_BUDGET_CODE_LENGTH,
  MAX_COST_LENGTH,
  MAX_PERMIT_NUMBER_LENGTH
} from 'src/constants/permits';
import { json } from 'stream/consumers';
import { useHistory } from 'react-router';
import { UserRole } from 'src/models/user-role.model';

interface Props extends PropsFromRedux {
  permit: PermitRequest;
  permitId: string;
}

const initialPermitDetailsForm: PermitDetailsForm = {
  customersPermitNo: '',
  cost: '',
  approved: null,
  expires: null,
  agencies: {},
  projectType: '',
  budgetCodeDescription: '',
  budgetCode: '',
  outageRequired: '',
  isAnnualPermit: undefined,
  constructionChangeNumber: ''
};

/**
 * Utility function to return status value(name) by id
 *
 * @param statusId
 * @param allStatusesList
 */
const getHighLevelStatusById = (
  statusId: number,
  allStatusesList: StatusType[]
) => {
  const item = allStatusesList.find(
    (item) => String(item.id) == String(statusId)
  );
  return item && item['category'];
};

const Details = (props: Props): JSX.Element => {
  const dispatchGetPermitAction =
    useDispatch<
      (a: ReturnType<typeof getPermitAction>) => Promise<SetPermitAction>
    >();

  const dispatchUpdatePermitDetailAction =
    useDispatch<
      (
        a: ReturnType<typeof updatePermitDetailsAction>
      ) => Promise<SetPermitPropertiesAction>
    >();

  const {
    permit: {
      budgetCode,
      budgetCodeDescription,
      projectType,
      workOrderNo,
      projectNumber,
      outageRequired,
      priorityText,
      permitNumber,
      cost,
      expirationDate,
      approvalDate,
      isAnnualPermit,
      primarAgencyId,
      id
    },
    agencies,
    currentUser,
    outageReqOptions,
    permitId,
    spinoffNewPermit
  } = props;

  const startInputRef = useRef<HTMLButtonElement>();
  const endInputRef = useRef<HTMLButtonElement>();
  const history = useHistory();
  const detailsTileRef = useRef(null);
  const [open, setOpenDialog] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [formData, setFormData] = useState(initialPermitDetailsForm);
  const [isComplete, setIsComplete] = useState(false);
  const [isSpinOff, setIsSpinoff] = useState(false);
  const [marginNeeded, setMarginNeeded] = useState(false);
  const [requestExtDialog, setRequestExtDialog] = useState(false);
  const [rscExtDialog, setRscExtDialog] = useState(false);
  const [openExtDialog, setOpenExtDialog] = useState(false);
  const [isRequestingScopeChange, setIsRequestingScopeChange] = useState(false);
  const [isRequestingExtension, setIsRequestingExtension] = useState(false);

  /**
   * On permit prop change, refresh form data state
   */
  useEffect((): void => {
    const { permit } = props;
    const formData = formatFormData(permit);
    setFormData(
      (prevState: PermitDetailsForm): PermitDetailsForm => ({
        ...prevState,
        ...formData
      })
    );

    // compute if Permit is of complete status
    setIsComplete(getIsPermitComplete(permit));
  }, [props.permit]);

  /**
   * Compute if user is allowed to access/edit tile
   */
  useEffect((): void => {
    // user must be logged in
    if (!currentUser) {
      setCanEdit(false);
      return;
    }

    const isAllowed = checkPermissions(
      permissionsList.MODIFY_DETAILS,
      currentUser
    );
    if (isAllowed) {
      setCanEdit(true);
      return;
    }

    // Other roles can not edit
    setCanEdit(false);
  }, [currentUser, props.permit]);

  if (props.permit.spunParentPermitID != undefined) {
    if (parseInt(props.permit.spunParentPermitID, 10) != props.permit.id) {
      useEffect(() => {
        setIsSpinoff(true);
      });
    }
  }

  useEffect(() => {
    if (
      props.permit.requestStatusId == 12 ||
      props.permit.requestStatusId == 40 ||
      props.permit.requestStatusId == 4
    ) {
      setMarginNeeded(true);
    }
  });

  const openParentPermit = () => {
    window.open(`/permit/${props.permit.spunParentPermitID}`);
  };

  // refresh permit data when tile is opened
  useEffect((): void => {
    if (open && props.permitId) {
      setIsUpdating(true);
      dispatchGetPermitAction(getPermitAction(props.permitId))
        .then(() => {
          setIsUpdating(false);
        })
        .catch(() => {
          alert('Failed refreshing permit data!');
          setIsUpdating(false);
        });
    }
  }, [open]);

  useEffect(() => {
    const isAgencyListEmpty =
      !formData?.agencies ||
      Object.keys(formData.agencies).length === 0 ||
      Object.entries(formData.agencies)[0][1] === null; // agency slot is active, but is not filled out
    setIsSubmitDisabled(isAgencyListEmpty);
  }, [formData]);

  const isValLenValid = (value: string | null, length: number) => {
    return value && value.length > length ? false : true;
  };

  /**
   * Returns true, if the request is of Complete status.
   * @param permit
   */
  const getIsPermitComplete = (permit: PermitRequest): boolean => {
    return PERMIT_COMPLETE_IDS.includes(Number(permit.requestStatusId));
  };

  /**
   * Formats the received permit prop to object consumed by the form
   *
   * @param permit
   */
  const formatFormData = (permit: PermitRequest) => ({
    outageRequired: permit.outageRequired || '',
    expires: permit.expirationDate,
    approved: permit.approvalDate,
    customersPermitNo: permit.permitNumber || '',
    cost: permit.cost,
    budgetCode: permit.budgetCode || '',
    isAnnualPermit: permit.isAnnualPermit,
    agencies: {
      ...(permit.primarAgencyId ? { 0: permit.primarAgencyId } : { 0: null }),
      ...(permit.secondAgencyId ? { 1: permit.secondAgencyId } : {}),
      ...(permit.thirdAgencyId ? { 2: permit.thirdAgencyId } : {}),
      ...(permit.fourthAgencyId ? { 3: permit.fourthAgencyId } : {})
    },
    constructionChangeNumber: permit.constructionChangeNumber || ''
  });

  /**
   * Resets form state to the permit values that are set in application state.
   * (modifying the form does not alter the app state)
   * used on e.g. "Cancel" press
   */
  const resetForm = (): void => {
    const { permit } = props;
    const formData = formatFormData(permit);
    setFormData({ ...initialPermitDetailsForm, ...formData });
  };

  const handleCloseDialog = (): void => {
    resetForm();
    setOpenDialog(false);
  };

  const handleCloseExtDialog = (): void => {
    resetForm();
    setOpenExtDialog(false);
  };

  const handleOpenDialog = (): void => {
    canEdit && setOpenDialog(true);
    if (!canEdit) {
      notifyNotAllowed(permissionsList.MODIFY_DETAILS, currentUser);
      return;
    }
  };

  const handleRangeChange = (range: DateRange<Date>): void => {
    const [approved, expires]: DateRange<Date> = range;
    setFormData(
      (prevState: PermitDetailsForm): PermitDetailsForm => ({
        ...prevState,
        approved,
        expires
      })
    );
  };

  const formatPayload = (permitDetailsForm: PermitDetailsForm) => {
    const {
      cost,
      agencies,
      customersPermitNo,
      outageRequired,
      approved: approvalDate,
      expires: expirationDate,
      budgetCode,
      isAnnualPermit,
      constructionChangeNumber
    } = permitDetailsForm;

    const payload = {
      cost, // Cost field
      approvalDate, //  = Issued Date
      expirationDate, // Expires Date
      outageRequired, // Outage Required
      permitNumber: customersPermitNo, // Permit Number
      budgetCode,
      isAnnualPermit,
      ...transformAgencyObj(agencies),
      constructionChangeNumber
    };
    return payload;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const payload = formatPayload(formData);
    setIsUpdating(true);
    dispatchUpdatePermitDetailAction(
      updatePermitDetailsAction(permitId, payload)
    )
      .then(() => {
        setIsUpdating(false);
        setOpenDialog(false); // close dialog
      })
      .catch((message) => {
        alert(message || 'Error updating permit!');
        setIsUpdating(false);
      });
  };

  // Handle OnClick submit for Extension or Request Scope Change
  const handleExtRSC = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let extOption = '';

    if (requestExtDialog) {
      extOption = 'EXTENSION';
      setIsRequestingExtension(true);
    } else if (rscExtDialog) {
      extOption = 'RSC';
      setIsRequestingScopeChange(true);
    } else {
      setIsRequestingExtension(false);
      setIsRequestingScopeChange(false);
      alert('Error: Please contact the administrator.');
      return;
    }

    spinoffNewPermit(String(id), extOption)
      .then((permitId) => {
        history.push(`/permit/${permitId}`);
      })
      .catch(() => alert('Error: Please contact the administrator.'));

    return;
  };

  const handleValueChange = <T,>(name: string, value: T): void => {
    setFormData(
      (prevState: PermitDetailsForm): PermitDetailsForm => ({
        ...prevState,
        [name]: makeASCISafe(String(value))
      })
    );
  };

  // Handle request extension or RSC dialog
  const handleExtensionsDialog = (event: React.MouseEvent<HTMLElement>) => {
    const id = event.currentTarget.id;
    if (id == 'rsc') {
      setRscExtDialog(true);
      setRequestExtDialog(false);
    } else if (id == 'request-extension') {
      setRscExtDialog(false);
      setRequestExtDialog(true);
    }
    setOpenExtDialog(true);
  };

  /**
   * Change handler for switch component,
   * its name property name must match the state property name
   *
   * @param e
   * @param checked
   */
  const handleSwitchChange = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setFormData((p) => ({ ...p, [e.target.name]: checked }));
  };

  const handleAgencyChange = (
    elementKey: number,
    changedValue: Agency | null
  ): void => {
    setFormData((prevState: PermitDetailsForm): PermitDetailsForm => {
      const result = {
        ...prevState,
        agencies: {
          ...prevState.agencies,
          [elementKey]: changedValue?.id
        }
      };

      return result;
    });
  };

  const addAgency = (): void => {
    const elementKeys = Object.keys(formData.agencies).map(Number);
    const newElementKey = elementKeys[elementKeys.length - 1] + 1;
    setFormData(
      (prevState: PermitDetailsForm): PermitDetailsForm => ({
        ...prevState,
        agencies: {
          ...prevState.agencies,
          [newElementKey]: null
        }
      })
    );
  };

  const removeAgency = (elementKey: string): void => {
    setFormData((prevState: PermitDetailsForm): PermitDetailsForm => {
      delete prevState.agencies[elementKey];
      return {
        ...prevState
      };
    });
  };

  const getBudgetCodeDescription = (budgetCode: string | null): string => {
    return budgetCodeDescription || budgetCode || Text.NA;
  };

  const submittedPermitRequestForm = (
    <>
      <form
        id="details-form"
        className="submitted-permit-form"
        onSubmit={handleSubmit}
      >
        <section className="submitted-permit-form-inputs">
          <TextField
            variant="outlined"
            size="small"
            disabled={isUpdating}
            type="text"
            value={formData.customersPermitNo || ''}
            label="Permit number"
            name="customersPermitNo"
            onChange={(event): void =>
              handleValueChange<string>(event.target.name, event.target.value)
            }
            error={
              !isValLenValid(
                formData.customersPermitNo,
                MAX_PERMIT_NUMBER_LENGTH
              )
            }
            helperText={
              !isValLenValid(
                formData.customersPermitNo,
                MAX_PERMIT_NUMBER_LENGTH
              ) && `Max. ${MAX_PERMIT_NUMBER_LENGTH} chars!`
            }
          />

          <TextField
            variant="outlined"
            size="small"
            disabled={isUpdating}
            type="number"
            value={formData.cost || ''}
            onChange={(event): void =>
              handleValueChange(event.target.name, event.target.value)
            }
            label="Cost"
            name="cost"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
            error={!isValLenValid(formData.cost, MAX_COST_LENGTH)}
            helperText={
              !isValLenValid(formData.cost, MAX_COST_LENGTH) &&
              `Max. ${MAX_COST_LENGTH} chars!`
            }
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              disabled={isUpdating}
              startText="Approved"
              endText="Expires"
              shouldDisableYear={undefined}
              value={[
                formatDateTime(formData.approved) || null,
                formatDateTime(formData.expires) || null
              ]}
              onChange={(newValue: DateRange<Date>): void => {
                handleRangeChange(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField
                    size="small"
                    {...startProps}
                    inputRef={startInputRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              startInputRef.current?.focus();
                            }}
                          >
                            <EventIcon style={{ color: '#0098da' }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Box sx={{ mx: 2 }}> - </Box>
                  <TextField
                    className="end-date"
                    size="small"
                    {...endProps}
                    inputRef={endInputRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              endInputRef.current?.focus();
                            }}
                          >
                            <EventIcon style={{ color: '#0098da' }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </>
              )}
            />
          </LocalizationProvider>
        </section>

        <div className="break"></div>

        <FormControl>
          <FormControlLabel
            control={
              <Switch
                disabled={isUpdating}
                name="isAnnualPermit"
                checked={!!formData.isAnnualPermit}
                onChange={handleSwitchChange}
              />
            }
            label="Annual Permit"
          />
        </FormControl>

        <div className="break"></div>

        <section className="details">
          <div>
            <div className="data">
              <TextField
                sx={{ width: '16vw' }}
                variant="outlined"
                size="small"
                required
                disabled={isUpdating}
                type="text"
                value={formData.budgetCode || ''}
                label="Budget Code"
                name="budgetCode"
                onChange={(event): void =>
                  handleValueChange<string>(
                    event.target.name,
                    event.target.value
                  )
                }
                error={
                  !isValLenValid(formData.budgetCode, MAX_BUDGET_CODE_LENGTH)
                }
                helperText={
                  !isValLenValid(formData.budgetCode, MAX_BUDGET_CODE_LENGTH) &&
                  `Max. ${MAX_BUDGET_CODE_LENGTH} chars!`
                }
              />
            </div>
            <div className="data">
              <span>Work Order Number</span>
              <span>{workOrderNo || Text.NA}</span>
            </div>
          </div>

          <ArrowForwardIcon className="forward-icon" />
          <div>
            <div className="data">
              <span>Budget Code Description</span>
              <span>{getBudgetCodeDescription(budgetCode)}</span>
            </div>

            <div className="data">
              <span>Project Type</span>
              <span>{projectType || Text.NA}</span>
            </div>
          </div>
        </section>

        <div className="break"></div>

        <FormControl
          sx={{ width: '16vw' }}
          variant="outlined"
          size="small"
          required
        >
          <InputLabel id="outage-required-type-select">
            Outage Required for Job
          </InputLabel>
          <Select
            disabled={isUpdating}
            labelId="outage-required-option-select"
            label="Outage Required for Job"
            name="outageRequired"
            value={formData.outageRequired}
            onChange={(event) =>
              handleValueChange<string>(event.target.name, event.target.value)
            }
          >
            {outageReqOptions.length &&
              outageReqOptions.map((outageReq) => (
                <MenuItem key={outageReq.id} value={outageReq.id}>
                  {outageReq.outageRequired}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <div className="break"></div>

        <Typography variant="subtitle2">AGENCY</Typography>

        <div className="break"></div>

        <section className="agencies">
          {formData.agencies &&
            Object.keys(formData.agencies).map((key) => (
              <div className="agency" key={key}>
                <Autocomplete
                  disabled={isUpdating}
                  onChange={(event, value) => {
                    if (value) {
                      handleAgencyChange(Number(key), value);
                    } else {
                      removeAgency(key);
                    }
                  }}
                  size="small"
                  disablePortal
                  fullWidth
                  options={agencies.sort(sortByName)}
                  value={
                    agencies.find(
                      (agency): boolean => agency.id == formData.agencies[key]
                    ) || null
                  }
                  filterOptions={(options, state) =>
                    options.filter(
                      (option) =>
                        !Object.values(formData.agencies).some(
                          (agencyId) => agencyId === option.id
                        ) &&
                        option.name
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                    )
                  }
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Agency" />
                  )}
                />
                <IconButton
                  title="Remove Agency"
                  onClick={(): void => removeAgency(key)}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </div>
            ))}
          {formData.agencies && Object.keys(formData.agencies).length < 4 && (
            <div>
              <IconButton
                className="add-button"
                title="Add Agency"
                onClick={addAgency}
              >
                <AddCircleIcon color="primary" />
              </IconButton>
            </div>
          )}
        </section>
      </form>
      <div className="break"></div>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <div className="data">
            <span>Parent Permit</span>
          </div>
          <div>
            {isSpinOff ? (
              <span className="parentPermitSpan" onClick={openParentPermit}>
                {props.permit.spunParentPermitID}
              </span>
            ) : (
              <span>N/A</span>
            )}
          </div>
        </Grid>
        <Grid item md={4}>
          <div className="align-icon-w-item">
            <TextField
              id="constructionChangeNumber"
              variant="outlined"
              size="small"
              disabled={isUpdating}
              type="text"
              value={formData.constructionChangeNumber || ''}
              label="Construction Change Number"
              name="constructionChangeNumber"
              onChange={(event): void =>
                handleValueChange<string>(event.target.name, event.target.value)
              }
            />
            <Tooltip
              title="This is only used for the City of San Diego."
              sx={{ ml: 2 }}
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <section
        id={(isComplete && 'completed') || undefined}
        className="permit-details"
        tabIndex={0}
        ref={detailsTileRef}
        onKeyDown={(event) => handleEnterKeyDown(event, detailsTileRef)}
      >
        <header>
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            Permit Details
          </Typography>
          {isComplete && props.permit.requestStatusId != 21 && (
            <>
              <Button
                id="request-extension"
                variant="contained"
                color="primary"
                onClick={handleExtensionsDialog}
                className="mr-15px"
              >
                Request Extension
              </Button>
              <Button
                id="rsc"
                variant="contained"
                color="primary"
                onClick={handleExtensionsDialog}
                className="mr-15px"
              >
                Request Scope Change
              </Button>
            </>
          )}
        </header>
        <section className="body pt-25">
          <div className="content">
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 6, md: 8 }}
              sx={{ height: '100%' }}
              direction="row"
              alignItems="center"
            >
              {isAnnualPermit && (
                <Grid hidden={!isComplete} item xs={2} sm={8} md={8}>
                  <div className="completed">
                    <div className="cell">
                      Annual Permit <br />
                      <CheckIcon color="primary" />
                    </div>
                  </div>
                </Grid>
              )}
              <Grid hidden={!isComplete} item xs={2} sm={4} md={4}>
                <div className="completed">
                  <div className="cell">
                    <span>Permit Number</span>
                    <span>{permitNumber || Text.NA}</span>
                  </div>
                  <div className="cell">
                    <span>Permit Cost</span>
                    <span>{formatCurrency(cost) || Text.NA}</span>
                  </div>
                </div>
              </Grid>
              <Grid hidden={!isComplete} item xs={2} sm={4} md={4}>
                <div className="completed">
                  <div className="cell">
                    <span>Approved</span>
                    <span>
                      {(approvalDate && formatDateTime(approvalDate)) ||
                        Text.NA}
                    </span>
                  </div>
                  <div className="cell">
                    <span>Expires</span>
                    <span>
                      {(expirationDate && formatDateTime(expirationDate)) ||
                        Text.NA}
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <div className="cell">
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    Budget Code
                    <LinkIcon
                      className="link-icon"
                      sx={{
                        width: '30px',
                        height: '30px',
                        marginLeft: '30%'
                      }}
                    />
                  </span>
                  <span>{budgetCode || Text.NA}</span>
                </div>
              </Grid>

              <Grid item xs={2} sm={4} md={4}>
                <div className="cell">
                  <span>Budget Code Description</span>
                  <span>{getBudgetCodeDescription(budgetCode)}</span>
                </div>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <div className="cell">
                  <span>Project Type</span>
                  <span>{projectType || Text.NA}</span>
                </div>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <div className="cell">
                  <span>Priority</span>
                  <span>{priorityText || Text.NA}</span>
                </div>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <div className="cell">
                  <span>Outage Required</span>
                  <span>
                    {outageReqOptions[+outageRequired - 1]?.outageRequired ||
                      Text.NA}
                  </span>
                </div>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <div className="cell">
                  <span>Work Order #</span>
                  <span>{workOrderNo || Text.NA}</span>
                </div>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <div className="cell">
                  <span>Project #</span>
                  <span>{projectNumber || Text.NA}</span>
                </div>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <div className="cell">
                  <span>Agency</span>
                  <span>
                    {agencies.find((item) => item.id === primarAgencyId)
                      ?.name || (
                      <Box
                        component="span"
                        sx={{ color: (theme) => theme.palette.error.main }}
                      >
                        Agency is missing!
                      </Box>
                    )}
                  </span>
                </div>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <div className="cell">
                  <span>Parent Permit</span>
                  {isSpinOff ? (
                    <span
                      className="parentPermitSpan"
                      onClick={openParentPermit}
                    >
                      {props.permit.spunParentPermitID}
                    </span>
                  ) : (
                    <span>N/A</span>
                  )}
                </div>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <div className="cell">
                  {props.permit.requestStatusId != 21 && (
                    <Button
                      id="edit-details"
                      variant="contained"
                      color="primary"
                      sx={isComplete || marginNeeded ? { mb: 5 } : { mb: 0 }}
                      onClick={() => handleOpenDialog()}
                    >
                      Edit Details
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <div className="cell">
                  <div className="align-icon-w-item">
                    <span>Construction Change # </span>{' '}
                    <Tooltip title="This is only used for the City of San Diego.">
                      <InfoIcon fontSize="small" />
                    </Tooltip>
                  </div>
                  {props.permit.constructionChangeNumber ? (
                    props.permit.constructionChangeNumber
                  ) : (
                    <span> N/A </span>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
      </section>
      <Dialog
        maxWidth="lg"
        open={open}
        onBackdropClick={handleCloseDialog}
        onClose={handleCloseDialog}
      >
        <BootstrapDialogTitle onClose={handleCloseDialog}>
          <strong>Permit Details</strong>
        </BootstrapDialogTitle>
        <DialogContent>{submittedPermitRequestForm}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            disabled={
              !isValLenValid(
                formData.customersPermitNo,
                MAX_PERMIT_NUMBER_LENGTH
              ) ||
              !isValLenValid(formData.cost, MAX_COST_LENGTH) ||
              !isValLenValid(formData.budgetCode, MAX_BUDGET_CODE_LENGTH) ||
              isUpdating ||
              isSubmitDisabled
            }
            form="details-form"
            type="submit"
            variant="contained"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* This is the modal for extension and rsc */}
      <Dialog
        maxWidth="lg"
        open={openExtDialog}
        onBackdropClick={handleCloseExtDialog}
        onClose={handleCloseExtDialog}
      >
        <BootstrapDialogTitle onClose={handleCloseExtDialog}>
          <strong>
            {(requestExtDialog && 'Request Extension') ||
              (rscExtDialog && 'Request Scope Change')}
          </strong>
        </BootstrapDialogTitle>
        <DialogContent>
          {requestExtDialog ? (
            <p>
              Requesting a permit extension will spin off a new permit request.
              You will need to review the information and submit the request to
              permitting.
              <br /> <br />
              Are you sure you want to create an extension for this permit?
            </p>
          ) : (
            <p>
              Requesting a scope change will spin off a new permit request. You
              will need to review the information and submit the request to
              permitting.
              <br /> <br />
              Are you sure you want to create a scope change for this permit?
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <form id="extension-rsc-form" onSubmit={handleExtRSC}>
            <Button onClick={handleCloseExtDialog}>Cancel</Button>
            {isRequestingExtension ? (
              <Button disabled variant="contained">
                Creating Extension{' '}
                <CircularProgress size="15px" sx={{ marginLeft: '10px' }} />
              </Button>
            ) : isRequestingScopeChange ? (
              <Button disabled variant="contained">
                Creating Scope Change{' '}
                <CircularProgress size="15px" sx={{ marginLeft: '10px' }} />
              </Button>
            ) : (
              <Button
                form="extension-rsc-form"
                type="submit"
                variant="contained"
              >
                {(requestExtDialog && 'Request Extension') ||
                  (rscExtDialog && 'Request Scope Change')}
              </Button>
            )}
          </form>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  agencies: state.filter.dropdownOptions?.agencies || [],
  outageReqOptions: state.filter.dropdownOptions?.outageReqOptions || [],
  currentUser: state.auth.currentUser
});

const mapDispatchToProps = {
  updatePermit: permitActions.updatePermitDetailsAction,
  spinoffNewPermit: permitActions.spinoffNewPermitAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Details);
