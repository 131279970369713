import React, { useEffect, useState, useCallback } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  debounce,
  Alert
} from '@mui/material';
import { connect, ConnectedProps } from 'react-redux';
import PlaceIcon from '@mui/icons-material/Place';
import * as actions from '../../../store/action-creators/permit-sidebar-actions';
import * as permitActions from 'src/store/action-creators/permit-actions';
import './Location.scss';
import envConfig from 'src/env-config';
import Search from './Search';
import GeoCode from 'react-geocode';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { MarkerMap } from '../../../models/marker-map.model';
import { PermitRequest } from 'src/models/permit.model';
import { LoadingOverlay } from 'src/components/utils/LoadingOverlay/LoadingOverlay';
import TitleAndSaveStatus from '../TitleAndSaveStatus/TitleAndSaveStatus';
import { SUPPORTING_ADDRESS } from 'src/constants/addresses';
import { setPrimaryAddressLatLon } from 'src/utils/permit-utils';

interface Props extends PropsFromRedux {
  permit: PermitRequest;
}

const mapContainerStyle = {
  height: '50vh',
  width: '100%'
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeId: 'hybrid'
};

const Location = (props: Props) => {
  GeoCode.setApiKey(envConfig.googleMaps.apiKey);
  GeoCode.setLanguage('en');

  const {
    setActiveStep,
    permit,
    addSupportingAddress,
    removeSupportingAddress,
    addEmptySupportingAddress,
    addUpdateSupportingAddress,
    updateSupportingAddress,
    updatePrimaryAddress
  } = props;

  const [selected, setSelected] = useState<MarkerMap>();
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [thomasBR, setThomasBR] = useState('');
  const [center, setCenter] = useState({
    lat: 32.715736,
    lng: -117.161087
  });
  const [isSavingPermitData, setIsSavingPermitData] = useState(false);
  const [isPermitUploadSuccessful, setIsPermitUploadSuccessful] =
    useState(true);

  // panTo called on each primaryAddress change - this way map will be refreshed and pin displayed on map
  useEffect(() => {
    if (
      permit.primaryAddress &&
      permit.primaryAddress.latitude &&
      permit.primaryAddress.longitude
    ) {
      panTo({
        lat: Number(permit.primaryAddress.latitude),
        lng: Number(permit.primaryAddress.longitude)
      });
    }
  }, [permit.primaryAddress]);

  useEffect(() => {
    if (permit.primaryAddress && permit.primaryAddress.thomsBrosTB) {
      setThomasBR(permit.primaryAddress.thomsBrosTB);
    }

    setIsLoadingAddress(true);
    setPrimaryAddressLatLon(permit).then(
      () => setIsLoadingAddress(false),
      () => setIsLoadingAddress(false)
    );
  }, []);

  const handleThomasBR = async (thomasBR: string) => {
    setThomasBR(thomasBR.trim());

    const markerMap: MarkerMap = {
      ...permit.primaryAddress,
      thomsBrosTB: thomasBR.trim()
    };
    debouncedHandlerThomasBR(markerMap);
  };

  const handleUpdatePrimaryAddress = async (markerMap: MarkerMap) => {
    markerMap.permitId = permit.id;
    setIsLoadingAddress(true);
    updatePrimaryAddress(markerMap)
      .then(
        () => setIsLoadingAddress(false),
        () => setIsLoadingAddress(false)
      )
      .then(() => setIsSavingPermitData(false));
  };

  const debouncedHandlerThomasBR = useCallback(
    debounce(handleUpdatePrimaryAddress, 1000),
    []
  );

  /**
   * Invoke when user clicks on map,always new address.
   */
  const handleAddSupportingAddress = async (markerMap: MarkerMap) => {
    setIsPermitUploadSuccessful(true);
    setIsLoadingAddress(true);
    setIsSavingPermitData(true);
    addSupportingAddress(markerMap)
      .then(
        () => setIsLoadingAddress(false),
        () => setIsLoadingAddress(false)
      )
      .then(() => setIsSavingPermitData(false))
      .catch(() => {
        setIsPermitUploadSuccessful(false);
      });
  };

  const panAndCenter = (lat: number, lng: number) => {
    try {
      mapRef.current?.panTo({
        lat: lat,
        lng: lng
      });
      setCenter({
        lat: lat,
        lng: lng
      });
    } catch (error) {
      console.error('Error creating marker: ', error);
    }
  };

  const onMapClick = React.useCallback((e) => {
    // call google api to get address based on lat/log.
    // Add address
    const markerMap: MarkerMap = {
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
      time: new Date().getTime(),
      addressType: SUPPORTING_ADDRESS,
      permitId: permit.id
    };

    GeoCode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      async (response) => {
        const address = response.results[0].formatted_address;
        markerMap.address = address;
        await handleAddSupportingAddress(markerMap);
      }, // end of geocode
      async (error) => {
        markerMap.address = '';
        console.error(error);
        await handleAddSupportingAddress(markerMap);
      }
    );
  }, []);

  const mapRef = React.useRef<any>();
  const onMapLoad = React.useCallback((map: any) => {
    mapRef.current = map;
    if (
      permit &&
      permit.primaryAddress &&
      permit.primaryAddress.latitude &&
      permit.primaryAddress.longitude
    ) {
      panAndCenter(
        Number(permit.primaryAddress.latitude),
        Number(permit.primaryAddress.longitude)
      );
    } else if (
      permit &&
      permit.supportingAddress &&
      permit.supportingAddress.length > 0
    ) {
      panAndCenter(
        Number(permit.supportingAddress[0].latitude),
        Number(permit.supportingAddress[0].longitude)
      );
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          panAndCenter(position.coords.latitude, position.coords.longitude);
        },
        () => null
      );
    }
  }, []);

  const panTo = (latlng: any) => {
    try {
      mapRef.current?.panTo(latlng);
    } catch (error) {
      console.error('Error creating marker: ', error);
    }
    mapRef.current?.setZoom(6);
  };

  /**
   * Triggered when clicked on plus icon.
   * creates an empty object in permit.supportingaddress
   * does not make any db calls.
   */
  const addAddress = () => {
    const markerMap = { time: new Date().getTime() } as MarkerMap;
    setIsPermitUploadSuccessful(true);
    setIsLoadingAddress(true);
    addEmptySupportingAddress(markerMap)
      .then(
        () => {
          setIsLoadingAddress(false);
        },
        () => {
          setIsLoadingAddress(false);
        }
      )
      .catch(() => {
        setIsPermitUploadSuccessful(false);
      });
  };

  /**
   * @param markerMap clicked when address entered from search.
   */
  const addupdateMarker = (marker: MarkerMap) => {
    setIsPermitUploadSuccessful(true);
    setIsSavingPermitData(true);
    if (marker.id) {
      // update database
      setIsLoadingAddress(true);
      updateSupportingAddress(marker)
        .then(
          () => setIsLoadingAddress(false),
          () => setIsLoadingAddress(false)
        )
        .then(() => setIsSavingPermitData(false))
        .catch(() => {
          setIsPermitUploadSuccessful(false);
        });
    } else {
      // insert address and update state..
      marker.permitId = permit.id;
      marker.addressType = SUPPORTING_ADDRESS;
      setIsLoadingAddress(true);
      addUpdateSupportingAddress(marker)
        .then(
          () => setIsLoadingAddress(false),
          () => setIsLoadingAddress(false)
        )
        .then(() => setIsSavingPermitData(false))
        .catch(() => {
          setIsPermitUploadSuccessful(false);
        });
    }
  };

  /**
   * Triggered when clicked on remove icon.
   * @param marker
   */
  const removeAddress = (marker: MarkerMap) => {
    setIsPermitUploadSuccessful(true);
    setIsSavingPermitData(true);
    setIsLoadingAddress(true);
    removeSupportingAddress(
      String(marker.permitId),
      String(marker.id),
      String(marker.time)
    )
      .then(
        () => setIsLoadingAddress(false),
        () => setIsLoadingAddress(false)
      )
      .then(() => setIsSavingPermitData(false))
      .catch(() => {
        setIsPermitUploadSuccessful(false);
      });
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setActiveStep('schedule');
  };

  const handleKeyDown = (keyEvent: React.KeyboardEvent<HTMLFormElement>) => {
    if (keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  };

  return (
    <>
      <TitleAndSaveStatus
        titleName={'Location'}
        isSavingPermitData={isSavingPermitData}
        isPermitUploadSuccessful={isPermitUploadSuccessful}
      />
      <form
        className="location-form"
        onSubmit={submitForm}
        onKeyDown={handleKeyDown}
      >
        <div className="map-container">
          <LoadingOverlay isVisible={isLoadingAddress} />
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={8}
            center={center}
            options={options}
            onClick={onMapClick}
            onLoad={onMapLoad}
          >
            {permit.supportingAddress?.map(
              (marker) =>
                marker.latitude &&
                marker.longitude && (
                  <Marker
                    key={marker.time}
                    position={{
                      lat: parseFloat(marker.latitude),
                      lng: parseFloat(marker.longitude)
                    }}
                    onClick={() => {
                      setSelected(marker);
                    }}
                  />
                )
            )}

            {permit.primaryAddress &&
              permit.primaryAddress.latitude &&
              permit.primaryAddress.longitude && (
                <Marker
                  key={permit.primaryAddress.time}
                  position={{
                    lat: parseFloat(permit.primaryAddress.latitude),
                    lng: parseFloat(permit.primaryAddress.longitude)
                  }}
                  onClick={() => {
                    setSelected(permit.primaryAddress);
                  }}
                />
              )}

            {selected && selected.latitude && selected.longitude && (
              <InfoWindow
                position={{
                  lat: parseFloat(selected.latitude),
                  lng: parseFloat(selected.longitude)
                }}
                onCloseClick={() => {
                  setSelected(undefined);
                }}
              >
                <div>
                  <h2>Adress</h2>
                  <p>{selected.address}</p>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </div>
        <div>
          <Alert severity="info" className="mt-15px">
            Please enter the <strong>Primary Address</strong> field and select
            an option from the pre-populated dropdown. This will generate
            coordinates for the lat/long.
          </Alert>
        </div>
        <div className="addresses-container">
          <div className="primary-address">
            <Search
              label="Primary Address"
              panTo={panTo}
              addupdateMarker={handleUpdatePrimaryAddress}
              marker={permit.primaryAddress}
              startAdornment={
                <InputAdornment position="end">
                  <PlaceIcon className="icon-lat-long" />
                </InputAdornment>
              }
            />

            <FormControl variant="outlined" size="small">
              <InputLabel htmlFor="lat-long-input">Lat/Long</InputLabel>
              <OutlinedInput
                id="lat-long-input"
                label="Lat/Long"
                name="latLong"
                disabled={true}
                value={
                  permit.primaryAddress?.latitude &&
                  permit.primaryAddress.latitude +
                    ',' +
                    permit.primaryAddress.longitude
                }
                startAdornment={
                  <InputAdornment position="start">
                    <PlaceIcon className="icon-lat-long" />
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl variant="outlined" size="small">
              <InputLabel htmlFor="thomas-bros-input">
                Thomas Brothers
              </InputLabel>
              <OutlinedInput
                id="thomas-bros-input"
                label="Thomas Brothers"
                name="thomasBros"
                value={thomasBR}
                onChange={(event) => handleThomasBR(event.target.value)}
              />
            </FormControl>
          </div>

          <div className="supporting-addresses">
            <div className="title">Supporting Addresses</div>

            {permit.supportingAddress?.map((marker) => (
              <div className="address" key={marker.time}>
                <div>
                  <Search
                    label="Address"
                    panTo={panTo}
                    addupdateMarker={addupdateMarker}
                    marker={marker}
                  />
                  {marker && marker.latitude && (
                    <div className="supporting-addr-latlng">
                      <PlaceIcon className="icon-supporting-addresses" />
                      <span>
                        {marker?.latitude}, {marker?.longitude}
                      </span>
                    </div>
                  )}
                </div>
                <IconButton
                  title="Remove Address"
                  onClick={() => removeAddress(marker)}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </div>
            ))}

            <div>
              <IconButton
                className="add-button"
                title="Add Address"
                onClick={addAddress}
              >
                <AddCircleIcon color="primary" />
              </IconButton>
            </div>
          </div>
        </div>
        <Button
          className="submit-button"
          type="submit"
          variant="contained"
          disableElevation
          disabled={isSavingPermitData || isLoadingAddress}
        >
          Next
        </Button>
      </form>
    </>
  );
};

const mapDispatchToProps = {
  setActiveStep: actions.setPermitActiveStepAction,
  addSupportingAddress: permitActions.addSupportingAddress,
  removeSupportingAddress: permitActions.removeSupportingAddress,
  addEmptySupportingAddress: permitActions.addEmptySupportingAddress,
  addUpdateSupportingAddress: permitActions.addUpdateSupportingAddress,
  updateSupportingAddress: permitActions.updateSupportingAddress,
  updatePrimaryAddress: permitActions.updatePrimaryAddress
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Location);
