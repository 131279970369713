import ActionType from '../action-types';
import { AuthActions, AuthState } from '../../models/store-auth.model';

const initialState: AuthState = {
  currentUser: null
};

const authReducer = (
  state: AuthState = initialState,
  action: AuthActions
): AuthState => {
  switch (action.type) {
    case ActionType.SET_CURRENT_USER:
      return { ...state, currentUser: action.payload };
    default:
      return state;
  }
};

export default authReducer;
