export interface Attachment {
  id: number;
  title: string | null;
  type: AttachmentType;
  authorName: string;
  authorEmail: string;
  createdAt: Date | null;
  fileName: string;
  fromSap: boolean;
  contentType: string;
  attached: boolean;
  preSignedURL?: string;
}

export enum AttachmentType {
  PHOTO = 'Photo Markup',
  SKETCH = 'Site Sketch',
  FACILITY = 'Facility Map',
  OTHER = 'Other'
}

export interface FileNamePayload {
  fileName: string;
  fileType: string;
  imageType: AttachmentType;
}

export interface GetSAPAttachments {
  fileName: string;
  link: string;
  docType: string;
}
