import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  TextFieldProps
} from '@mui/material';
import DialogTitle from './DialogTitle/DialogTitle';
import { LoadingOverlay } from '../utils/LoadingOverlay/LoadingOverlay';
import { ChangeEvent, useEffect, useState } from 'react';
import ActionType from '../../store/action-types';
import { useDispatch } from 'react-redux';

export interface IDialogFormProps {
  formId: string; 
  open: boolean;  
  dialogTitle: string;
  dialogFormFields: TextFieldProps[];
  handleClose: () => void;
  onSubmit: (d: any) => Promise<any>;
}

// Is the minimum time we want to show the "saving" spinner
const MIN_UPDATING_DURATION_MS = 500;

/**
 * Shows a modal dialog window, with TextField(s) component(s) and Cancel,Update Buttons
 * Useful when one needs to modify a single (or more) TextField values.
 *
 * @param props
 * @constructor
 */
const DialogForm = (props: IDialogFormProps) => {
  /**
   * Default error alert, when form submit/update error occurred
   */
  const dialogFormError = {
    type: ActionType.SHOW_ALERT,
    payload: {
      id: 'FORM_ERROR_' + props.formId,
      text: 'Error saving form data!',
      autoHideDuration: 3000
    }
  };

  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();
  const [formFieldsData, setFormFieldsData] = useState({});

  /**
   * Populates the internal state of form fields values with provided data.
   * This is needed so we can format the output object for on submit.
   * The state format is simple object map, field names are object keys.
   */
  const populateFormFieldsData = () => {
    const arrayToObjectReducer = (
      result: any,
      textFieldProperty: TextFieldProps
    ) => {
      result[String(textFieldProperty.name)] = textFieldProperty.value || '';
      return result;
    };
    const data = props.dialogFormFields.reduce(arrayToObjectReducer, {});
    setFormFieldsData(data);
  };
  useEffect(populateFormFieldsData, [props.dialogFormFields]);

  /**
   * Utility function. Resolves a promise after a specified time.
   */
  const someDelayPromise = (delayMs: number) =>
    new Promise((resolve) => {
      const callback = () => {
        resolve(true);
      };
      setTimeout(callback, delayMs);
    });

  /**
   * Triggered when form submitted
   * @param e
   */
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsUpdating(true);
    try {
      await Promise.all([
        someDelayPromise(MIN_UPDATING_DURATION_MS),
        props.onSubmit(formFieldsData)
      ]);
      setIsUpdating(false);
      props.handleClose();
    } catch (er: any) {
      setIsUpdating(false);
      // notify error
      dispatch(dialogFormError);
    }
  };

  /**
   * Upates internal state component when a field is modified 
   * 
   * @param e 
   */
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormFieldsData({ ...formFieldsData, ...{ [name]: value } });
  };

  /**
   * Constructs the TextField componentsbased on provided properties.
   * It is needed to inject onChange handler and key property.
   * 
   * @param tp 
   * @returns 
   */
  const renderTextField = (tp: TextFieldProps) => {
    if (!tp.name) throw new Error('Name property is required!');
    const props = {
      ...tp,
      ...{
        key: tp.name,
        value: formFieldsData[tp.name] || '',
        onChange: handleChange
      }
    };
    return <TextField {...props} />;
  };

  /**
   * Main component wrapper
   */
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle onClose={props.handleClose}>
        {' '}
        {props.dialogTitle}{' '}
      </DialogTitle>
      <DialogContent>
        <LoadingOverlay isVisible={isUpdating} />
        <form id={props.formId} onSubmit={handleSubmit}>
          {props.dialogFormFields.map(renderTextField)}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button
          disabled={false}
          form={props.formId}
          type="submit"
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogForm;
