import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle
} from '@mui/material';
import axiosClient from 'src/utils/axios-client';
import { PermitRequest } from 'src/models/permit.model';
import { useHistory, useParams } from 'react-router';
import { deleteAllNotifEvents } from 'src/store/action-creators/notification-actions';
import envConfig from 'src/env-config';

interface Props {
  permit: PermitRequest;
}

const DeletePermitButton = (props: Props) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [isDeletingPermit, setIsDeletingPermit] = React.useState(false);

  const handleClickAlertOpen = () => {
    setAlertOpen(true);
  };

  const handleClickAlertClose = () => {
    setAlertOpen(false);
  };

  const onPermitRequestDelete = () => {
    removeRequest(id);
    setIsDeletingPermit(true);
  };

  const removeRequest = async (id: string) => {
    const isRemovedObj = { isRemoved: 1 };
    let isRequestRemoved = false;
    try {
      const response = await axiosClient.patch(
        `${envConfig.api.updatePermit}/${id}`,
        {
          ...isRemovedObj
        }
      );

      if (response.config.data === '{"isRemoved":1}') {
        deleteAllNotifEvents(id);
        isRequestRemoved = true;
        console.log('isRemoved');
        removeRequest(id).then((isRequestRemoved) => {
          if (isRequestRemoved) {
            history.push('/');
          } else {
            alert('There was an issue deleting this request!');
          }
        });
      }

      return Promise.resolve(isRequestRemoved);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickAlertOpen}>
        Delete Permit Request
      </Button>
      <Dialog
        open={alertOpen}
        onClose={handleClickAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this Permit Request?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClickAlertClose}>No</Button>
          <Button
            onClick={onPermitRequestDelete}
            autoFocus
            type="submit"
            variant="contained"
            disableElevation
            disabled={isDeletingPermit}
            endIcon={
              isDeletingPermit && <CircularProgress size={24} color="inherit" />
            }
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeletePermitButton;
