import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Typography,
  Box,
  Alert,
  Stack
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';

import './Scope.scss';
import * as permitActions from 'src/store/action-creators/permit-actions';
import { PermitRequest } from 'src/models/permit.model';
import { Digging } from 'src/models/digging.model';
import { TrenchSpec } from 'src/models/trench.model';
import TrenchSpecView from './TrenchSpecView/TrenchSpecView';
import BootstrapDialogTitle from '../components/DialogTitle/DialogTitle';
import TrenchSpecification from 'src/components/PermitForm/TrenchSpecification/TrenchSpecification';
import { ScopeForm, ScopeInterface, Text } from 'src/models/form.model';
import { LoadingOverlay } from 'src/components/utils/LoadingOverlay/LoadingOverlay';
import classNames from 'classnames';
import { isMinLengthOk } from '../../../utils/permit-utils';
import { handleEnterKeyDown } from 'src/utils/handlers-utils';

interface Props extends PropsFromRedux {
  isEditable: boolean;
  permit: PermitRequest;
}

// OMMENTED OUT, BUT NOT DELETED - see https://dev.azure.com/SempraUtilities/SempraUtilities/_sprints/taskboard/SDGE%20Permits/SempraUtilities/Sprint%20084?workitem=245097
// TODO: jucf object below will be fetched from SAP, structure may change
// const jucf = {
//   isNeeded: true,
//   cip: ['CA Public Utilities Commission', 'Cox Communications San Diego']
// };

const REQUIRED_LENGTH_DESCRIPTION = 1; //
const minCharsHelper = `Minimum ${REQUIRED_LENGTH_DESCRIPTION} chars are required!`;

const Scope = (props: Props) => {
  const { isEditable, permit, updatePermitScope } = props;

  const scopeTileRef = useRef(null);
  const trenchSpecsRef = useRef(null);
  const [expanded, setExpanded] = useState(true);
  const [open, setOpenDialog] = useState(false);
  const [openTrenchDialog, setOpenTrenchDialog] = useState(false);
  const [scopeForm, setScopeForm] = useState<ScopeInterface>({
    customersPermitNo: '',
    jobDescription: '',
    scopeDescription: '',
    customerJobDescription: '',
    isNoChecked: undefined,
    isCustomerChecked: undefined,
    isSdgeChecked: undefined
  });
  const [trenchSpecsForm, setTrenchSpecsForm] = useState<TrenchSpec[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [checkTrenchIsEmpty, setCheckTrenchIsEmpty] = useState(true);

  if (permit.trenchSpecifications.length >= 1) {
    useEffect(() => {
      setCheckTrenchIsEmpty(false);
    });
  } else {
    useEffect(() => {
      setCheckTrenchIsEmpty(true);
    });
  }

  useEffect(() => {
    resetForm(permit);
  }, [permit]);

  useEffect(() => {
    if (open) {
      validateFormFields();
    }
  }, [
    open,
    scopeForm.isCustomerChecked,
    scopeForm.isNoChecked,
    scopeForm.isSdgeChecked
  ]);

  const handleCloseDialog = (reset: boolean) => {
    setOpenDialog(false);
    // timeout, so that dialog hides after the animation is done
    setTimeout(() => {
      setOpenTrenchDialog(false);
    }, 500);

    if (reset) {
      resetForm(permit);
    }
  };

  const resetForm = (permit: PermitRequest) => {
    setScopeForm({
      customersPermitNo: permit.customersPermitNo || '',
      jobDescription: permit.jobDescription || '',
      scopeDescription: permit.scopeDescription || '',
      customerJobDescription: permit.customerJobDescription || '',
      isNoChecked: permit.diggingDoneBy.includes(Digging.NO) || false,
      isCustomerChecked:
        permit.diggingDoneBy.includes(Digging.CUSTOMER) || false,
      isSdgeChecked: permit.diggingDoneBy.includes(Digging.SDGE) || false
    });
    setTrenchSpecsForm(permit.trenchSpecifications);
  };

  const handleOpenDialog = (event: React.MouseEvent<HTMLElement>) => {
    const id = event.currentTarget.id;
    if (id == 'edit-trench') {
      setOpenTrenchDialog(true);
    }
    setOpenDialog(true);
  };

  const handleTrenchSpecAdd = () => {
    const trenchSpec = { id: Date.now() } as TrenchSpec;
    setTrenchSpecsForm((prevState) => [...prevState, trenchSpec]);
  };

  const handleTrenchSpecRemove = (trenchSpecId: number) => {
    const filteredForm = trenchSpecsForm.filter(
      (trechSpec: TrenchSpec) => trechSpec && trechSpec.id != trenchSpecId
    );
    setTrenchSpecsForm(filteredForm);
  };

  const handleTrenchSpecChange = (
    trenchSpecId: number,
    update: Partial<TrenchSpec>
  ) => {
    const trenchSpecsUpdated = trenchSpecsForm.map((item) =>
      item.id === trenchSpecId ? { ...item, ...update } : item
    );
    setTrenchSpecsForm(trenchSpecsUpdated);
  };

  const handleFormChange = (name: string, value: string | boolean) => {
    let newState: ScopeInterface = {
      ...scopeForm,
      [name]: value
    };
    if (name === 'isNoChecked') {
      newState = {
        ...newState,
        isCustomerChecked: false,
        isSdgeChecked: false
      };
    } else if (name === 'isCustomerChecked' || name === 'isSdgeChecked') {
      newState = {
        ...newState,
        isNoChecked: false
      };
    }
    setScopeForm(newState);
  };

  /**
   * Checks, if scope/job description pass validation rules
   *
   * @param data
   */
  const validateDescriptions = (
    data: ScopeForm
  ): { [key: string]: boolean | string } => {
    const errorMessage = `${minCharsHelper}`;

    const fields = [
      'jobDescription',
      'scopeDescription',
      'customerJobDescription'
    ];

    const fieldsEnabled = [
      scopeForm.isSdgeChecked ? 'jobDescription' : '',
      scopeForm.isNoChecked ? 'jobDescription' : '',
      scopeForm.isCustomerChecked ? 'jobDescription' : ''
    ].filter((i) => !!i); // remove empty values

    const validationResult = fields.map((key) => {
      const isEnabled = fieldsEnabled.includes(key);
      const isError =
        isEnabled && !isMinLengthOk(data[key], REQUIRED_LENGTH_DESCRIPTION);
      return {
        key,
        error: isError ? errorMessage : false
      };
    });

    const asObject = {};
    validationResult.forEach((item) => {
      asObject[item.key] = item.error;
    });
    return asObject;
  };

  const validateFormFields = () => {
    const valDescResults = validateDescriptions(scopeForm);
    setErrors((errors) => {
      return { ...errors, ...valDescResults };
    });
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setScopeForm((prevState) => {
      const newScopeData = { ...prevState, [name]: value };
      return newScopeData;
    });

    validateFormFields();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    let requestBody = {};
    const {
      customersPermitNo,
      customerJobDescription,
      scopeDescription,
      isCustomerChecked,
      isNoChecked,
      isSdgeChecked,
      jobDescription
    } = scopeForm;

    if (openTrenchDialog) {
      requestBody = {
        trenchSpecifications: trenchSpecsForm
      };
    } else {
      requestBody = {
        diggingDoneBy: [
          ...(isNoChecked ? [Digging.NO] : []),
          ...(isCustomerChecked ? [Digging.CUSTOMER] : []),
          ...(isSdgeChecked ? [Digging.SDGE] : [])
        ],
        ...(isSdgeChecked ? { jobDescription } : { jobDescription: '' }),
        ...(isNoChecked ? { jobDescription } : { jobDescription: '' }),
        ...(isCustomerChecked
          ? { customersPermitNo, jobDescription }
          : { customersPermitNo: '', jobDescription })
      };
    }

    updatePermitScope(String(permit.id), requestBody).then(
      () => {
        setIsLoading(false);
        handleCloseDialog(false);
      },
      () => setIsLoading(false)
    );
  };

  const isValidationError = Object.keys(errors).some((key) => !!errors[key]);

  return (
    <>
      <section
        className={classNames('permit-scope', { editable: isEditable })}
        tabIndex={0}
        ref={scopeTileRef}
        onKeyDown={(event) => handleEnterKeyDown(event, scopeTileRef)}
      >
        <header>
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            Scope
          </Typography>
        </header>
        {props.permit.requestStatusId != 21 && (
          <Button
            id="edit-scope"
            variant="contained"
            color="primary"
            onClick={handleOpenDialog}
          >
            Edit Scope
          </Button>
        )}

        <section className="permit-scope-info">
          <table>
            <tbody>
              {/* No Digging */}
              {permit.diggingDoneBy.includes(Digging.NO) && (
                <>
                  {permit.scopeDescription ? (
                    <>
                      <tr>
                        <td>
                          <div className="table-cell">
                            <CheckIcon />
                            <strong>No Soil Disturbance</strong>
                          </div>
                        </td>
                        <td>Scope Description</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <div className="table-cell">
                            {permit.scopeDescription || Text.NA}
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td>
                          <div className="table-cell">
                            <CheckIcon />
                            <strong>No Soil Disturbance</strong>
                          </div>
                        </td>
                        <td>
                          <em>SDG&E Job Description</em>
                          <div className="table-cell">
                            {permit.jobDescription || Text.NA}
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              )}
              {/* Digging By Customer */}
              {permit.diggingDoneBy.includes(Digging.CUSTOMER) && (
                <>
                  {permit.customerJobDescription ? (
                    <>
                      <tr>
                        <td>
                          <div className="table-cell">
                            <CheckIcon />
                            <strong>Customer</strong>
                          </div>
                        </td>
                        <td>
                          <div className="table-cell">
                            <span>
                              Permit :
                              <strong>
                                &nbsp; {permit.customersPermitNo || Text.NA}
                              </strong>
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <em>Customer Job Description</em>
                          <div className="table-cell">
                            {permit.customerJobDescription || Text.NA}
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : !permit.diggingDoneBy.includes(Digging.SDGE) ? (
                    <>
                      <tr>
                        <td>
                          <div className="table-cell">
                            <CheckIcon />
                            <strong>Customer</strong>
                          </div>
                        </td>
                        <td>
                          <span>
                            Permit :
                            <strong>
                              &nbsp; {permit.customersPermitNo || Text.NA}
                            </strong>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <em>SDG&E Job Description</em>
                          <div className="table-cell">
                            {permit.jobDescription || Text.NA}
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td>
                          <div className="table-cell">
                            <CheckIcon />
                            <strong>Customer</strong>
                          </div>
                        </td>
                        <td>
                          <div className="table-cell">
                            <span>
                              Permit :
                              <strong>
                                &nbsp; {permit.customersPermitNo || Text.NA}
                              </strong>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              )}
              {/* Digging BY SDGE */}
              {permit.diggingDoneBy.includes(Digging.SDGE) && (
                <>
                  <tr>
                    <td>
                      <div className="table-cell">
                        <CheckIcon />
                        <strong>SDG&E</strong>
                      </div>
                    </td>
                    <td>
                      <em>SDG&E Job Description</em>
                      <div className="table-cell">
                        {permit.jobDescription || Text.NA}
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </section>
        <section className="permit-scope-trench">
          <div
            className="title"
            id="trench-spec"
            title={
              expanded
                ? 'Hide SDG&E Trench Specifications'
                : 'Show SDG&E Trench Specifications'
            }
            onClick={() => setExpanded(!expanded)}
          >
            <KeyboardArrowDownIcon
              style={{
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 200ms'
              }}
            />
            <div>SDG&E Trench Specifications</div>
          </div>
          <Collapse in={expanded} unmountOnExit>
            <section className="trench-specification-list-view">
              {permit.trenchSpecifications.map((trenchSpec: TrenchSpec) => (
                <TrenchSpecView trenchSpec={trenchSpec} key={trenchSpec.id} />
              ))}
            </section>
            <Box sx={{ mb: 8, ml: 4 }}>
              {!checkTrenchIsEmpty && (
                <Button
                  id="edit-trench"
                  variant="contained"
                  color="primary"
                  ref={trenchSpecsRef}
                  onClick={handleOpenDialog}
                >
                  Edit Trench
                </Button>
              )}
            </Box>
          </Collapse>
        </section>
        {/* COMMENTED OUT, BUT NOT DELETED - see https://dev.azure.com/SempraUtilities/SempraUtilities/_sprints/taskboard/SDGE%20Permits/SempraUtilities/Sprint%20084?workitem=245097
        <section className="permit-scope-jucf">
          <div>
            <CheckIcon />
            <strong>JUCF</strong>
          </div>
          <div>
            <span>CIP</span>
            {jucf.cip.map((item, index) => (
              <div className="jucf" key={index}>
                {item}
              </div>
            ))}
          </div>
        </section> */}
      </section>

      <Dialog
        className="scope-dialog"
        fullWidth
        maxWidth="md"
        open={isEditable && open}
        onBackdropClick={() => handleCloseDialog(true)}
        onClose={() => handleCloseDialog(true)}
      >
        <LoadingOverlay isVisible={isLoading} />

        <BootstrapDialogTitle onClose={() => handleCloseDialog(true)}>
          <strong>
            {!openTrenchDialog ? 'Scope' : 'SDG&E Trench Specifications'}
          </strong>
        </BootstrapDialogTitle>

        <DialogContent>
          <form
            id="scope-form"
            className="scope-form"
            onSubmit={(event) => handleSubmit(event)}
          >
            {(openTrenchDialog && (
              <div className="trench-specification-list-dialog">
                {trenchSpecsForm.map(
                  (trenchSpec) =>
                    trenchSpec && (
                      <TrenchSpecification
                        styles={{ backgroundColor: '#F1F4F9' }}
                        trenchSpec={trenchSpec}
                        key={trenchSpec.id}
                        onRemove={() => handleTrenchSpecRemove(trenchSpec.id)}
                        onChange={(update) =>
                          handleTrenchSpecChange(trenchSpec.id, update)
                        }
                      />
                    )
                )}
              </div>
            )) || (
              <div className="digging">
                <FormGroup className="options">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isNoChecked"
                        checked={scopeForm.isNoChecked}
                        onChange={(event) =>
                          handleFormChange(
                            event.target.name,
                            event.target.checked
                          )
                        }
                        required={
                          !scopeForm.isNoChecked &&
                          !scopeForm.isCustomerChecked &&
                          !scopeForm.isSdgeChecked
                        }
                      />
                    }
                    label="No Soil Disturbance"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isCustomerChecked"
                        checked={scopeForm.isCustomerChecked}
                        onChange={(event) =>
                          handleFormChange(
                            event.target.name,
                            event.target.checked
                          )
                        }
                        required={
                          !scopeForm.isNoChecked &&
                          !scopeForm.isCustomerChecked &&
                          !scopeForm.isSdgeChecked
                        }
                      />
                    }
                    label="Customer Digging Responsibility"
                  />
                  {scopeForm.isCustomerChecked && (
                    <>
                      <TextField
                        label="Customer's Permit Number"
                        name="customersPermitNo"
                        size="small"
                        value={scopeForm.customersPermitNo}
                        onChange={(event) =>
                          handleFormChange(
                            event.target.name,
                            event.target.value
                          )
                        }
                      />
                    </>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isSdgeChecked"
                        checked={scopeForm.isSdgeChecked}
                        onChange={(event) =>
                          handleFormChange(
                            event.target.name,
                            event.target.checked
                          )
                        }
                        required={
                          !scopeForm.isNoChecked &&
                          !scopeForm.isCustomerChecked &&
                          !scopeForm.isSdgeChecked
                        }
                      />
                    }
                    label="SDG&E Digging Responsibility"
                  />
                </FormGroup>
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity="info">
                    While describing SDG&E-specific job scope of work, avoid
                    acronyms or technical jargon which the SDG&E permit
                    coordinator or agency permit counter may not understand. If
                    the customer is doing any excavation, then clarify SDG&E
                    scope within the excavation limits. If any numbered SDG&E
                    facilities are directly involved, provide the facility
                    number in the written scope, and make sure the same
                    facilities are noted and labeled on any supporting exhibit
                    documents (sketch, photo markups, etc.).
                  </Alert>
                  <TextField
                    label="SDG&E Job Description"
                    error={!!errors['jobDescription']}
                    helperText={errors['jobDescription']}
                    name="jobDescription"
                    multiline
                    rows={6}
                    size="small"
                    value={scopeForm.jobDescription}
                    onChange={handleValueChange}
                  />
                </Stack>
              </div>
            )}
          </form>
        </DialogContent>

        <DialogActions
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {(openTrenchDialog && (
            <IconButton
              className="add-button"
              title="Add Trench Specification"
              onClick={handleTrenchSpecAdd}
            >
              <AddCircleIcon color="primary" />
            </IconButton>
          )) || <div />}
          <div>
            <Button
              onClick={() => handleCloseDialog(true)}
              sx={{ marginRight: '10px' }}
            >
              Cancel
            </Button>
            <Button
              disabled={isValidationError}
              variant="contained"
              form="scope-form"
              type="submit"
            >
              Update
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapDispatchToProps = {
  updatePermitScope: permitActions.updatePermitScopeAction
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Scope);
