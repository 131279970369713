import { useDispatch, useSelector } from 'react-redux';
import './NeedsActionItems.scss';
import { RootState } from '../../store/store';
import { NeedsAction } from '../../models/needs-action.model';
import { useEffect } from 'react';
import { getNeedsActionNotificationsAction } from '../../store/action-creators/needs-action-actions';
import { NeedsActionItem } from './NeedsActionItem';

interface Props {
  permitId: number;
}

const NeedsActionItems = (props: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  // get notifications
  useEffect(() => {
    dispatch(getNeedsActionNotificationsAction());
  }, [props.permitId]);

  // only needed notifications
  const notifications = useSelector<RootState, NeedsAction[]>((state) => state.needsAction.needsActNotifications)
    .filter(n => n.permitRequestId === props.permitId)
    .filter(n => n.resolved === false);

  // no content if no notifications
  if (notifications.length === 0) {
    return null;
  }

  return (
    <section className='notifications'>
      {notifications
        .map((notification) =>
          <NeedsActionItem
            key={notification.id}
            needsActionItem={notification}
            permitId={props.permitId}
          />)}
    </section>
  );
};


export default NeedsActionItems;
