import './Scope.scss';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  debounce,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Alert,
  Stack
} from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import TrenchSpecification from '../TrenchSpecification/TrenchSpecification';
import { TrenchSpec } from 'src/models/trench.model';
import { ScopeForm } from 'src/models/form.model';
import { PermitRequest } from 'src/models/permit.model';
import { Digging } from 'src/models/digging.model';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import * as permitActions from 'src/store/action-creators/permit-actions';
import * as permitSidebarActions from 'src/store/action-creators/permit-sidebar-actions';
import { useParams } from 'react-router';
import TitleAndSaveStatus from '../TitleAndSaveStatus/TitleAndSaveStatus';
import { isMinLengthOk, removeKeys } from 'src/utils/permit-utils';
import { LENGTH_CHARS_REQUIRED_SCOPE_DESCRIPTION } from '../../../constants/permits';
import { RootState } from '../../../store/store';

interface Props extends PropsFromRedux {
  permit: PermitRequest;
}

const DEBOUNCE_SAVING_TIME = 1000; //ms
const minCharsHelper = `Minimum ${LENGTH_CHARS_REQUIRED_SCOPE_DESCRIPTION} characters are required.`;

const Scope = (props: Props) => {
  const {
    permit,
    setActiveStep,
    updatePermit,
    addTrenchSpec,
    removeTrenchSpec,
    updateTrenchSpec
  } = props;

  // COMMENTED OUT, BUT NOT DELETED - see https://dev.azure.com/SempraUtilities/SempraUtilities/_sprints/taskboard/SDGE%20Permits/SempraUtilities/Sprint%20084?workitem=245097
  // TODO: jucf object below will be fetched from SAP, structure may change
  // const jucf = {
  //   isNeeded: true,
  //   cip: ['CA Public Utilities Commission', 'Cox Communications San Diego']
  // };

  const [isNoChecked, setIsNoChecked] = useState(true);
  const [isCustomerChecked, setIsCustomerChecked] = useState(false);
  const [isSdgeChecked, setIsSdgeChecked] = useState(false);
  const [scopeForm, setScopeForm] = useState<ScopeForm>({
    customersPermitNo: '',
    jobDescription: '',
    scopeDescription: '',
    customerJobDescription: ''
  });
  const [expanded, setExpanded] = useState(true);
  const [trenchSpecs, setTrenchSpecs] = useState<TrenchSpec[]>([]);
  const [isLoadingTrenchSpec, setIsLoadingTrenchSpec] = useState(false);
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);
  const [isSavingPermitData, setIsSavingPermitData] = useState(false);
  const [isPermitUploadSuccessful, setIsPermitUploadSuccessful] =
    useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setIsNoChecked(permit.diggingDoneBy?.includes(Digging.NO) || false);
    setIsCustomerChecked(
      permit.diggingDoneBy?.includes(Digging.CUSTOMER) || false
    );
    setIsSdgeChecked(permit.diggingDoneBy?.includes(Digging.SDGE) || false);
    setScopeForm({
      customersPermitNo: permit.customersPermitNo || '',
      jobDescription: permit.jobDescription || '',
      scopeDescription: permit.scopeDescription || '',
      customerJobDescription: permit.customerJobDescription || ''
    });
  }, []);

  useEffect(() => {
    setTrenchSpecs(permit.trenchSpecifications || []);
    const valResults = validateDescriptions(scopeForm);
    setErrors((errors) => {
      return { ...errors, ...valResults };
    });
  }, [permit, scopeForm]);

  const { id } = useParams<{ id: string }>();

  const invokeUpdateTrenchSpec = (
    permitId: string,
    trenchSpecId: string,
    update: Partial<TrenchSpec>
  ) => {
    updateTrenchSpec(permitId, trenchSpecId, update);
    setIsSavingPermitData(false);
  };

  const debouncedTrenchHandler = useCallback(
    debounce(invokeUpdateTrenchSpec, DEBOUNCE_SAVING_TIME),
    []
  );

  /**
   * Clear text of other descriptions, based on active checkbox choices
   *
   * @param isNoChecked
   * @param isCustomerChecked
   * @param isSdgeChecked
   */
  const getOtherDescriptions = (
    isNoChecked: boolean,
    isCustomerChecked: boolean,
    isSdgeChecked: boolean
  ) => {
    if (isNoChecked) {
      // NO DIGGING
      return {
        jobDescription: '',
        customerJobDescription: ''
      };
    } else {
      // CUSTOMER OR SDGE DIGGING
      const result = { scopeDescription: '' }; // always cleared
      if (!isSdgeChecked) {
        result['jobDescription'] = '';
      }
      if (!isCustomerChecked) {
        result['customerJobDescription'] = '';
      }
      return result;
    }
  };

  const handleNoChange = (isChecked: boolean) => {
    setIsPermitUploadSuccessful(true);
    setIsSavingPermitData(true);
    setIsNoChecked(isChecked);
    if (isChecked) {
      setIsCustomerChecked(false);
      setIsSdgeChecked(false);
      setScopeForm(
        // clear job descriptions
        {
          ...scopeForm,
          ...getOtherDescriptions(isChecked, false, false)
        }
      );
    }
    const diggingDoneBy = [...(isChecked ? [Digging.NO] : [])];
    updatePermit(String(permit.id), {
      diggingDoneBy: diggingDoneBy,
      ...getOtherDescriptions(isChecked, false, false)
    })
      .then(() => setIsSavingPermitData(false))
      .catch(() => {
        setIsPermitUploadSuccessful(false);
      });
  };

  const handleCustomerChange = (isChecked: boolean) => {
    setIsPermitUploadSuccessful(true);
    setIsSavingPermitData(true);
    setIsCustomerChecked(isChecked);
    if (isChecked) {
      setIsNoChecked(false);
      setScopeForm(
        // clear scope description
        {
          ...scopeForm,
          ...getOtherDescriptions(false, isChecked, isSdgeChecked)
        }
      );
    }
    const diggingDoneBy = [
      ...(isChecked ? [Digging.CUSTOMER] : []),
      ...(isSdgeChecked ? [Digging.SDGE] : [])
    ];
    updatePermit(String(permit.id), {
      diggingDoneBy: diggingDoneBy,
      ...getOtherDescriptions(false, isChecked, isSdgeChecked)
    })
      .then(() => setIsSavingPermitData(false))
      .catch(() => {
        setIsPermitUploadSuccessful(false);
      });
  };

  const handleSdgeChange = (isChecked: boolean) => {
    setIsPermitUploadSuccessful(true);
    setIsSavingPermitData(true);
    setIsSdgeChecked(isChecked);
    if (isChecked) {
      setIsNoChecked(false);
      setScopeForm(
        // clear scope description
        {
          ...scopeForm,
          ...getOtherDescriptions(false, isCustomerChecked, isChecked)
        }
      );
    }
    const diggingDoneBy = [
      ...(isCustomerChecked ? [Digging.CUSTOMER] : []),
      ...(isChecked ? [Digging.SDGE] : [])
    ];
    updatePermit(String(permit.id), {
      diggingDoneBy: diggingDoneBy,
      ...getOtherDescriptions(false, isCustomerChecked, isChecked)
    })
      .then(() => setIsSavingPermitData(false))
      .catch(() => {
        setIsPermitUploadSuccessful(false);
      });
  };

  /**
   * Checks, if scope/job description pass validation rules
   *
   * @param data
   */
  const validateDescriptions = (
    data: ScopeForm
  ): { [key: string]: boolean | string } => {
    const errorMessage = `${minCharsHelper}`;

    const fields = [
      'jobDescription',
      'scopeDescription',
      'customerJobDescription'
    ];

    const fieldsEnabled = [
      isSdgeChecked ? 'jobDescription' : '',
      isNoChecked ? 'jobDescription' : '',
      isCustomerChecked ? 'jobDescription' : ''
    ].filter((i) => !!i); // remove empty values

    const validationResult = fields.map((key) => {
      const isEnabled = fieldsEnabled.includes(key);
      const isError =
        isEnabled &&
        !isMinLengthOk(data[key], LENGTH_CHARS_REQUIRED_SCOPE_DESCRIPTION);
      return {
        key,
        error: isError ? errorMessage : false
      };
    });

    const asObject = {};
    validationResult.forEach((item) => {
      asObject[item.key] = item.error;
    });
    return asObject;
  };

  /**
   * Saves form change to BE. Intended to be wrapped in debounce function.
   *
   * @param name
   * @param value
   */
  const updatePermitHandler = (payload: any) => {
    const valResults = validateDescriptions(payload);
    // set/re-set errors
    setErrors((errors) => {
      return { ...errors, ...valResults };
    });

    // send valid fields to BE
    setIsSavingPermitData(true);
    updatePermit(String(permit.id), payload)
      .then(() => setIsSavingPermitData(false))
      .catch(() => {
        setIsPermitUploadSuccessful(false);
        setIsSavingPermitData(false);
      });
  };

  /**
   * Debounced reference to updatePermitHandler
   */
  const updatePermitDebounced = debounce(
    updatePermitHandler,
    DEBOUNCE_SAVING_TIME
  );

  /**
   * Updates form state and triggers the debounced permitUpdate function.
   * Wrapping in useCallback is needed, otherwise debounced fn is not the same fn
   * and called multiple times (again),  see https://stackoverflow.com/a/64859849
   *
   * @param e
   */
  const handleValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setScopeForm((prevState) => {
        const newScopeData = { ...prevState, [name]: value };
        updatePermitDebounced(newScopeData);
        return newScopeData;
      });
    },
    // deps needed/referenced in updatePermitHandler
    [isNoChecked, isSdgeChecked, isCustomerChecked]
  );

  const handleTrenchSpecAdd = () => {
    setIsPermitUploadSuccessful(true);
    setIsSavingPermitData(true);
    setIsLoadingTrenchSpec(true);
    addTrenchSpec(String(permit.id))
      .then(
        () => {
          setIsLoadingTrenchSpec(false);
        },
        () => {
          setIsLoadingTrenchSpec(false);
        }
      )
      .then(() => setIsSavingPermitData(false))
      .catch(() => {
        setIsPermitUploadSuccessful(false);
      });
  };

  const handleTrenchSpecRemove = (permitId: string, trenchSpecId: string) => {
    setIsPermitUploadSuccessful(true);
    setIsSavingPermitData(true);
    removeTrenchSpec(permitId, trenchSpecId)
      .then(() => {
        setIsSavingPermitData(false);
      })
      .catch(() => {
        setIsPermitUploadSuccessful(false);
      });
  };

  const handleTrenchSpecChange = (
    permitId: string,
    trenchSpecId: string,
    update: Partial<TrenchSpec>
  ) => {
    setIsSavingPermitData(true);
    debouncedTrenchHandler(permitId, trenchSpecId, update);
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setHasTriedToSubmit(true);
    if (
      isNoChecked ||
      (isCustomerChecked && !isSdgeChecked) ||
      (isSdgeChecked && trenchSpecs.length > 0)
    ) {
      setActiveStep('TCP');
    }
  };

  return (
    <div className="scope">
      <TitleAndSaveStatus
        titleName={'Scope'}
        isSavingPermitData={isSavingPermitData}
        isPermitUploadSuccessful={isPermitUploadSuccessful}
      />
      <form className="scope-form" onSubmit={submitForm}>
        <div className="digging">
          <div className="title">
            What is SDGE&apos;s specific scope of work? Who is responsible for{' '}
            <strong>digging</strong>?*
          </div>
          <div>
            <Alert severity="info">
              While describing SDG&E-specific job scope of work, avoid acronyms
              or technical jargon which the SDG&E permit coordinator or agency
              permit counter may not understand. If the customer is doing any
              excavation, then clarify SDG&E scope within the excavation limits.
              If any numbered SDG&E facilities are directly involved, provide
              the facility number in the written scope, and make sure the same
              facilities are noted and labeled on any supporting exhibit
              documents (sketch, photo markups, etc.).
            </Alert>
          </div>
          <FormGroup className="options">
            <FormControlLabel
              control={
                <Checkbox
                  name="isNoChecked"
                  checked={isNoChecked}
                  onChange={(event) => handleNoChange(event.target.checked)}
                  required={
                    !isNoChecked && !isCustomerChecked && !isSdgeChecked
                  }
                />
              }
              label="No Soil Disturbance"
            />
            {/* {isNoChecked && (
              <>
                { <TextField
                  label={`Scope Description${isNoChecked && '*'}`}
                  name="scopeDescription"
                  error={!!errors['scopeDescription']}
                  helperText={errors['scopeDescription']}
                  multiline
                  rows={6}
                  size="small"
                  value={scopeForm.scopeDescription}
                  onChange={handleValueChange}
                /> }
              </>
            )} */}
            <FormControlLabel
              control={
                <Checkbox
                  name="isCustomerChecked"
                  checked={isCustomerChecked}
                  onChange={(event) =>
                    handleCustomerChange(event.target.checked)
                  }
                  required={
                    !isNoChecked && !isCustomerChecked && !isSdgeChecked
                  }
                />
              }
              label="Customer Digging Responsibility"
            />
            {isCustomerChecked && (
              <>
                <TextField
                  label="Customer's Permit Number"
                  name="customersPermitNo"
                  size="small"
                  value={scopeForm.customersPermitNo}
                  onChange={handleValueChange}
                />
                {/* <TextField
                  label={`Customer's Job Description${(isCustomerChecked && '*')}`}
                  name='customerJobDescription'
                  error={!!errors['customerJobDescription']}
                  helperText={errors['customerJobDescription']}
                  multiline
                  rows={6}
                  size='small'
                  value={scopeForm.customerJobDescription}
                  onChange={handleValueChange}
                /> */}
              </>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  name="isSdgeChecked"
                  checked={isSdgeChecked}
                  onChange={(event) => handleSdgeChange(event.target.checked)}
                  required={
                    !isNoChecked && !isCustomerChecked && !isSdgeChecked
                  }
                />
              }
              label="SDG&E Digging Responsibility"
            />
            {isSdgeChecked && (
              <>
                <Stack sx={{ width: '100%' }} spacing={1}>
                  <div className="trench-specifications-collapse">
                    <div
                      className="title"
                      title={
                        expanded
                          ? 'Hide SDG&E Trench Specifications'
                          : 'Show SDG&E Trench Specifications'
                      }
                      onClick={() => setExpanded(!expanded)}
                    >
                      <KeyboardArrowDownIcon
                        style={{
                          transform: expanded
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                          transition: 'transform 200ms'
                        }}
                      />
                      <div>SDG&E Trench Specifications</div>
                    </div>

                    <Collapse in={expanded} unmountOnExit>
                      <div className="items">
                        {/* Trench specification with values */}
                        {trenchSpecs.map((trenchSpec) => (
                          <TrenchSpecification
                            key={trenchSpec.id}
                            trenchSpec={trenchSpec}
                            onRemove={
                              trenchSpecs.length > 1
                                ? () =>
                                    handleTrenchSpecRemove(
                                      String(permit.id),
                                      String(trenchSpec.id)
                                    )
                                : undefined
                            }
                            onChange={(update) =>
                              handleTrenchSpecChange(
                                String(permit.id),
                                String(trenchSpec.id),
                                update
                              )
                            }
                          />
                        ))}
                        {/* Loading placeholder for newly added Trench specification */}
                        {isLoadingTrenchSpec && (
                          <TrenchSpecification isLoading={true} />
                        )}
                        {/* No Trench Specifications message */}
                        {!isLoadingTrenchSpec && trenchSpecs.length === 0 && (
                          <div className="no-items-message">
                            {hasTriedToSubmit ? (
                              <Box
                                sx={{
                                  color: (theme) => theme.palette.error.main
                                }}
                              >
                                Please add at least one Trench Specification!
                              </Box>
                            ) : (
                              <div>No Trench Specifications present.</div>
                            )}
                          </div>
                        )}
                        <IconButton
                          className="add-button"
                          title="Add Trench Specification"
                          disabled={isLoadingTrenchSpec}
                          onClick={handleTrenchSpecAdd}
                        >
                          {isLoadingTrenchSpec ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            <AddCircleIcon color="primary" />
                          )}
                        </IconButton>
                      </div>
                    </Collapse>
                  </div>
                </Stack>
              </>
            )}
            <TextField
              className="mt-25"
              error={!!errors['jobDescription']}
              helperText={errors['jobDescription']}
              label="SDGE Job Description*"
              name="jobDescription"
              multiline
              rows={6}
              size="small"
              value={scopeForm.jobDescription}
              onChange={handleValueChange}
            />
          </FormGroup>
        </div>

        <Divider />

        {/* COMMENTED OUT, BUT NOT DELETED - see https://dev.azure.com/SempraUtilities/SempraUtilities/_sprints/taskboard/SDGE%20Permits/SempraUtilities/Sprint%20084?workitem=245097 for more details
        <table className="jcuf-table">
          <tbody>
            <tr className="table-header">
              <td>Joint Utility Communitation Form</td>
              <td>CIP</td>
            </tr>
            <tr>
              <td>
                <div className="jucf-value">
                  {jucf.isNeeded ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )}
                  <div>JUCF</div>
                </div>
              </td>
              <td>
                <div className="cip-value">
                  {jucf.cip.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))}
                </div>
              </td>
            </tr>
          </tbody>
        </table> */}

        <Button
          className="submit-button"
          type="submit"
          variant="contained"
          disableElevation
          disabled={isSavingPermitData || isLoadingTrenchSpec}
        >
          Next
        </Button>
      </form>
    </div>
  );
};

const mapDispatchToProps = {
  setActiveStep: permitSidebarActions.setPermitActiveStepAction,
  updatePermit: permitActions.updatePermitAction,
  addTrenchSpec: permitActions.addTrenchSpecAction,
  removeTrenchSpec: permitActions.removeTrenchSpecAction,
  updateTrenchSpec: permitActions.updateTrenchSpecAction
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Scope);
