import ActionType from '../action-types';
import {
  API404_ERROR,
  DBTIMEOUT_ERROR,
  GWTIMEOUT_ERROR
} from '../../utils/axios-client';
import { Dispatch } from 'redux';

/**
 * Default action, when DB timeout error occurred.
 *
 */
export const dbTimeoutAction = {
  type: ActionType.SHOW_ALERT,
  payload: {
    id: DBTIMEOUT_ERROR,
    text: 'Database connection timeout. Please try to reload the page.',
    autoHideDuration: 10000
  }
};

/**
 * Default action, when GW timeout error occurred.
 */
export const gwTimeoutAction = {
  type: ActionType.SHOW_ALERT,
  payload: {
    id: GWTIMEOUT_ERROR,
    text: 'API connection timeout. Please try to reload the page.',
    autoHideDuration: 10000
  }
};

/**
 * Check the provided error object shape,
 * if specific match is found, according action is dispatched
 *
 * @param error
 * @param dispatch
 */
export const processError = (error: any, dispatch: Dispatch<any>) => {
  // logging
  const logErrorsToConsole = false;
  if (process.env.NODE_ENV === 'development' && logErrorsToConsole) {
    console.error(error);
  }

  const errorMessage = error?.message || '';
  let errorCode = errorMessage;

  // check, if custom message is also provided within the error
  let customMessage = '';
  if (errorMessage.indexOf('|') > 0) {
    [errorCode, customMessage] = errorMessage.split('|');
  }

  // source:  axios-client.ts => axiosClient.interceptors.response
  switch (errorCode) {
    case DBTIMEOUT_ERROR:
      dispatch(dbTimeoutAction);
      break;
    case GWTIMEOUT_ERROR:
      dispatch(gwTimeoutAction);
      break;
    case API404_ERROR:
      dispatch({
        type: ActionType.SHOW_ALERT,
        payload: {
          id: API404_ERROR,
          text: customMessage,
          autoHideDuration: 10000
        }
      });
      break;
    default:
    // no action
  }
};
