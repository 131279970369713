import './TCP.scss';
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { PermitRequest } from 'src/models/permit.model';
import { useParams } from 'react-router';
import * as actions from 'src/store/action-creators/permit-sidebar-actions';
import * as permitActions from 'src/store/action-creators/permit-actions';
import TitleAndSaveStatus from '../TitleAndSaveStatus/TitleAndSaveStatus';

interface Props extends PropsFromRedux {
  permit: PermitRequest;
}

const TCP = (props: Props) => {
  const { permit, setActiveStep, updatePermit } = props;

  const { id } = useParams<{ id: string }>();

  const [isYesChecked, setIsYesChecked] = useState(false);
  const [isNoChecked, setIsNoChecked] = useState(false);
  const [isSavingPermitData, setIsSavingPermitData] = useState(false);
  const [isPermitUploadSuccessful, setIsPermitUploadSuccessful] =
    useState(true);

  useEffect(() => {
    if (permit.tcpRequired !== null) {
      setIsTcpRequired(Boolean(permit.tcpRequired));
    } else {
      setIsTcpRequired(false, true);
    }
  }, [permit.tcpRequired]);

  const setIsTcpRequired = (isTcpRequired: boolean, setBoth = false) => {
    if (setBoth) {
      setIsNoChecked(isTcpRequired);
      setIsYesChecked(isTcpRequired);
    }
    setIsNoChecked(!isTcpRequired);
    setIsYesChecked(isTcpRequired);
  };

  const handleFormChange = (name: string, checked: boolean) => {
    setIsPermitUploadSuccessful(true);
    setIsSavingPermitData(true);

    // If selection is null, make sure to make it false.
    if (checked == null) {
      checked = false;
      setIsTcpRequired(checked);
    } else {
      setIsTcpRequired(checked);
    }

    updatePermit(id, { [name]: Number(checked) })
      .then(() => setIsSavingPermitData(false))
      .catch(() => {
        setIsPermitUploadSuccessful(false);
      });
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setActiveStep('attachments');
  };

  return (
    <div className="tcp">
      <TitleAndSaveStatus
        titleName={'TCP'}
        isSavingPermitData={isSavingPermitData}
        isPermitUploadSuccessful={isPermitUploadSuccessful}
      />
      <form className="tcp-form" onSubmit={submitForm}>
        <div className="title">Will pedestrian traffic be blocked?</div>
        <FormGroup className="options" row={true}>
          <FormControlLabel
            className="yes-checkbox"
            control={
              <Checkbox
                name="tcpRequired"
                checked={isYesChecked}
                onChange={(event) =>
                  handleFormChange(event.target.name, event.target.checked)
                }
              />
            }
            label="Yes"
          />
          <FormControlLabel
            className="no-checkbox"
            control={
              <Checkbox
                name="tcpRequired"
                checked={isNoChecked}
                onChange={(event) =>
                  handleFormChange(event.target.name, !event.target.checked)
                }
              />
            }
            label="No"
          />
        </FormGroup>
        <br></br>
        <Button
          className="submit-button"
          type="submit"
          variant="contained"
          disableElevation
          disabled={isSavingPermitData}
        >
          Next
        </Button>
      </form>
    </div>
  );
};

const mapDispatchToProps = {
  setActiveStep: actions.setPermitActiveStepAction,
  updatePermit: permitActions.updatePermitAction
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TCP);
