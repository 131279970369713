import ActionType from '../action-types';
import { FilterState, FilterActions } from '../../models/store-filter.model';

const initialState: FilterState = {
  dashboardFilter: {
    search: '',
    requestStatuses: [],
    subStatuses: [],
    team: [],
    requestors: [],
    agencies: [],
    jobNumber: '',
    jobTitle: ''
  },
  dropdownOptions: {
    agencies: [],
    outageReqOptions: [],
    projectTypes: [],
    rejectionReasons: [],
    requestStatuses: [],
    requestTypes: [],
    reviewStatuses: [],
    trenchRepairTypes: [],
    userRoles: [],
    users: [], // only users from AD
    allUsers: [], // all users from AD + tblPermittingTeam + tblAgencies
    timeOfWorkProposed: [],
    permittingTeam: {
      ma: [],
      pss: [],
      qaqc: [],
      supervisor: [],
      tcp: [],
      ts: [],
      ta: []
    }
  },
  isLoadingOptions: false
};

const filterReducer = (
  state: FilterState = initialState,
  action: FilterActions
): FilterState => {
  switch (action.type) {
    case ActionType.SET_DASHBOARD_FILTER:
      return { ...state, dashboardFilter: action.payload };
    case ActionType.SET_DROPDOWN_OPTIONS:
      return { ...state, dropdownOptions: action.payload };
    case ActionType.SET_IS_LOADING_OPTIONS:
      return { ...state, isLoadingOptions: action.payload };
    default:
      return state;
  }
};

export default filterReducer;
