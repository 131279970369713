import {
  AttachmentActions,
  AttachmentState
} from 'src/models/store-attachment.model';
import ActionType from '../action-types';

const initialState: AttachmentState = {
  attachments: [],
  sapDocuments: [],
  hasLoaded: false,
  sapAttachments: []
};

const authReducer = (
  state: AttachmentState = initialState,
  action: AttachmentActions
): AttachmentState => {
  switch (action.type) {
    case ActionType.ADD_ATTACHMENTS:
      return {
        ...state,
        attachments: [...state.attachments, ...action.payload]
      };
    case ActionType.REMOVE_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.filter(
          (item) => item.id !== action.payload
        )
      };
    case ActionType.SET_ATTACHMENTS:
      return {
        ...state,
        attachments: action.payload
      };
    case ActionType.SET_HAS_LOADED_ATTACHMENTS:
      return {
        ...state,
        hasLoaded: action.payload
      };
    case ActionType.SET_SAP_DOCUMENTS:
      return {
        ...state,
        sapDocuments: action.payload
      };
    case ActionType.UPDATE_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.map((item) =>
          item.id === action.payload.attachmentId
            ? { ...item, ...action.payload.update }
            : item
        )
      };
    case ActionType.SET_SAP_ATTACHMENTS:
      return {
        ...state,
        sapAttachments: action.payload
      };
    default:
      return state;
  }
};

export default authReducer;
