import { Alert, AlertColor, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { AlertState } from '../../models/store-alert.model';
import { AlertItemModel } from '../../models/alert.model';
import ActionType from '../../store/action-types';

export const AppAlert = () => {
  const alerts = useSelector<RootState, AlertState>((state) => state.alert);
  const dispatch = useDispatch();
  const remove = (id: string) => () => {
    dispatch({
      type: ActionType.HIDE_ALERT,
      payload: { id }
    });
  };

  const severity = 'warning'; // 'success' | 'info' | 'warning' | 'error';
  const horizontal = 'center';

  const items = Object.values(alerts);

  return (
    <>
      {items.map((item: AlertItemModel) => (
        <Snackbar
          onClose={remove(item.id)}
          key={item.id}
          open={true}
          autoHideDuration={item.autoHideDuration}
          anchorOrigin={{
            vertical: item.vertical === 'bottom' ? 'bottom' : 'top',
            horizontal
          }}
        >
          <Alert
            onClose={remove(item.id)}
            severity={(item.severity as AlertColor) || severity}
            sx={{ width: '100%' }}
            variant={'filled'}
          >
            {item.text}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};
