export enum SapStatuses {
  IN = 'Initiated',
  IP = 'In Planning',
  PC = 'Pre-Construction',
  CO = 'Construction',
  RE = 'Reconciliation'
}

export interface SapData {
  CarriedOutBy: string;
  CarriedOutDate: string;
  CarriedOutName: string;
  CarriedOutTime: string;
  CreatedBy: string;
  CreatedDate: string;
  ItemKey: string;
  NotifNo: string;
  PartnRole: string;
  Partner: string;
  PartnerName: string;
  Permit: boolean;
  PlndEndDate: string;
  PlndEndTime: string;
  PlndStartDate: string;
  PlndStartTime: string;
  Status: string;
  TaskCatTyp: string;
  TaskCode: string;
  TaskCodegrp: string;
  TaskKey: string;
  TaskSortNo: string;
  TaskText: string;
  TxtTaskcd: string;
  TxtTaskgrp: string;
  Userstatus: string;
}
