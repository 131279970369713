import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import SendIcon from '@mui/icons-material/Send';
import { Avatar, IconButton } from '@mui/material';
import './CommentTextbox.scss';
import classNames from 'classnames';
import { Mention, MentionsInput, OnChangeHandlerFunc } from 'react-mentions';
import { User } from 'src/models/user.model';
import { LoadingOverlay } from 'src/components/utils/LoadingOverlay/LoadingOverlay';
import { RootState } from 'src/store/store';
import { connect, ConnectedProps } from 'react-redux';

/**
 * New or Edit comment textbox
 * - with Create button for New Comment
 * - with Save and Cancel buttons for Edit Comment
 */

interface Props extends PropsFromRedux {
  className?: string;
  editbox?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  value?: string;
  onCancel?: () => void;
  onChange?: OnChangeHandlerFunc;
  onSubmit: (value: string) => void;
}

const CommentTextbox = (props: Props) => {
  const {
    className = '',
    editbox,
    isLoading = false,
    placeholder = 'Type your message...',
    value = '',
    users,
    onCancel,
    onChange,
    onSubmit
  } = props;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(value);
  };

  const mentionableUsers = users.map((item) => ({
    ...item,
    id: item.email,
    display: item.name
  }));

  return (
    <form
      className={classNames('comment-textbox', [className], {
        editbox: editbox
      })}
      onSubmit={handleSubmit}
    >
      <LoadingOverlay isVisible={isLoading} />

      <div className="mention-textbox-wrapper">
        <MentionsInput
          className="mention-textbox"
          name="commentText"
          placeholder={placeholder}
          ignoreAccents
          disabled={isLoading}
          value={value}
          maxLength={1500}
          required
          onChange={onChange}
        >
          <Mention
            markup="@[name:__display__](email:__id__)"
            displayTransform={(id, display) => `@${display}`}
            trigger="@"
            data={mentionableUsers}
            renderSuggestion={(
              suggestion,
              search,
              highlightedDisplay,
              index,
              focused
            ) => (
              <div className={classNames('mention-item', { focused: focused })}>
                <Avatar>{(suggestion as User).initials}</Avatar>{' '}
                {highlightedDisplay}
              </div>
            )}
            style={{ backgroundColor: '#f1f4f9' }}
          />
        </MentionsInput>

        <div
          className={classNames('text-counter', { error: value.length > 1500 })}
        >
          {value.length}/1500
        </div>
      </div>

      <div className="actions">
        <IconButton
          title={editbox ? 'Save Changes' : 'Create New Comment'}
          color="primary"
          type="submit"
          disabled={isLoading || value.length > 1500}
        >
          {editbox ? <DoneIcon /> : <SendIcon />}
        </IconButton>

        {onCancel && (
          <IconButton
            className="cancel"
            title="Cancel and Discard Changes"
            color="primary"
            type="button"
            disabled={isLoading}
            onClick={onCancel}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.filter.dropdownOptions?.users || []
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CommentTextbox);
