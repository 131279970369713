import { useState, useRef } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { renderToString } from 'react-dom/server';
import { RootState } from 'src/store/store';
import { PermitRequest } from 'src/models/permit.model';
import * as permitActions from 'src/store/action-creators/permit-actions';
import moment from 'moment';
import './ExportPermitPackage.scss';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ExportPermitPDF from './ExportPermitPDF/ExportPermitPDF';
import { lightBlue } from '@mui/material/colors';
import {
  checkPermissions,
  notifyNotAllowed
} from '../../../utils/authorize-utils';
import { User } from '../../../models/user.model';
import { permissionsList } from '../../../constants/authorize-permissions-list';
import { handleEnterKeyDown } from 'src/utils/handlers-utils';

const downloadCircularIconColor = lightBlue[50];

interface Props extends PropsFromRedux {
  permit: PermitRequest;
}

const ExportPermitPackage = (props: Props) => {
  const { attachments, permit, exportPermitPDF, exportPermitZIP } = props;
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
  const [isDownloadingZIP, setIsDownloadingZIP] = useState(false);
  const user = useSelector<RootState, User | null>(
    (state) => state.auth.currentUser
  );
  const exportsTileRef = useRef(null);
  const invokeExportPermitPDF = () => {
    const action = permissionsList.EXPORT_PERMIT;
    const isAllowed = checkPermissions(action, user, permit);
    if (!isAllowed) {
      notifyNotAllowed(action, user);
      return;
    }
    setIsDownloadingPDF(true);
    const htmlString = renderToString(
      <ExportPermitPDF attachments={attachments} permit={permit} />
    );
    const prefixTime = moment().format('MMDDYYYY');
    exportPermitPDF(permit.id.toString(), htmlString, prefixTime)
      .then((response) => {
        window.open(response);
        setIsDownloadingPDF(false);
      })
      .catch(() => setIsDownloadingPDF(false));
  };

  const invokeExportPermitZIP = () => {
    const action = permissionsList.EXPORT_PERMIT;
    const isAllowed = checkPermissions(action, user, permit);
    if (!isAllowed) {
      notifyNotAllowed(action, user);
      return;
    }
    setIsDownloadingZIP(true);
    const htmlString = renderToString(
      <ExportPermitPDF attachments={attachments} permit={permit} />
    );
    const fileNameArr: (string | undefined)[] = [];
    attachments.map((attachment) => {
      fileNameArr.push(attachment.fileName);
    });
    const prefixTime = moment().format('MMDDYYYY');
    exportPermitPDF(permit.id.toString(), htmlString, prefixTime)
      .then((response) => {
        fileNameArr.push(response);
        exportPermitZIP(permit.id.toString(), fileNameArr, prefixTime)
          .then((zipResponse) => {
            setIsDownloadingZIP(false);
            if (zipResponse.signedUrl) {
              window.open(zipResponse.signedUrl);
            }
          })
          .catch((e) => {
            console.error(e);
            setIsDownloadingZIP(false);
          });
      })
      .catch(() => setIsDownloadingZIP(false));
  };

  return (
    <>
      <section
        className="title-permit-permit-package"
        tabIndex={0}
        ref={exportsTileRef}
        onKeyDown={(event) => handleEnterKeyDown(event, exportsTileRef)}
      >
        <header>
          <Typography
            sx={{ fontWeight: 'bold', color: '#001C71' }}
            variant="h5"
          >
            Export Permit Package
          </Typography>
        </header>

        <Grid container mt={2}>
          <Grid item xs={12}>
            <Card sx={{ boxShadow: 'none', backgroundColor: 'red' }}>
              <CardContent className="card-content">
                <FolderOpenIcon fontSize="large" />
                <div className="card-content-text">
                  <span>
                    <strong>Separate Files</strong>
                  </span>
                  <div>Permit Form + Attachments</div>
                  <div>(.zip format)</div>
                </div>
                {isDownloadingZIP ? (
                  <Button
                    className="export-button"
                    title="Export results"
                    disabled
                  >
                    Downloading{' '}
                    <CircularProgress
                      size="20px"
                      sx={{
                        marginLeft: '15px',
                        color: downloadCircularIconColor
                      }}
                    />
                  </Button>
                ) : (
                  <Button
                    className="export-button"
                    title="Export results"
                    onClick={invokeExportPermitZIP}
                  >
                    Export{' '}
                    <ExitToAppIcon
                      fontSize="medium"
                      sx={{ marginLeft: '15px' }}
                    />
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  attachments: state.attachment.attachments,
  currentUser: state.auth.currentUser
});

const mapDispatchToProps = {
  exportPermitPDF: permitActions.exportPermitPDFAction,
  exportPermitZIP: permitActions.exportPermitZIPAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ExportPermitPackage);
