import ActionType from '../action-types';
import { Dispatch } from 'redux';
import {
  AddNeedsActAction,
  SetNeedsActAction,
  UpdateNeedsActAction
} from '../../models/store-needs-action.model';
import { NeedsAction } from '../../models/needs-action.model';
import axiosClient from '../../../src/utils/axios-client';
import envConfig from '../../../src/env-config';
import { schRequestorResolvedActionsNotif } from './notification-actions';
import { makeASCISafe } from '../../utils/formatting-utils';
import { processError } from './alert-actions';

export const getNeedsActionNotificationsAction =
  () => async (dispatch: Dispatch<SetNeedsActAction>) => {
    // try to execute backend request
    try {
      const response = await axiosClient.get(`${envConfig.api.getNeedsAct}`);
      const needsActions: NeedsAction[] =
        response.data.afpermitsResponse.body.needsActionData;

      dispatch({
        type: ActionType.SET_NEEDS_ACTION_NOTIFICATION,
        payload: needsActions
      });
      return Promise.resolve('Successfully received!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const addNeedsActionNotificationsAction =
  (permitId: string, needsAction: Partial<NeedsAction>) =>
  async (dispatch: Dispatch<AddNeedsActAction>) => {
    // try to execute backend request
    try {
      const response = await axiosClient.post(
        `${envConfig.api.permits}/${permitId}/addNeedsAct`,
        needsAction
      );

      const added: NeedsAction =
        response.data.afpermitsResponse.body.needsAction;

      dispatch({
        type: ActionType.ADD_NEEDS_ACTION_NOTIFICATION,
        payload: added
      });
      return Promise.resolve('Successfully added!');
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

export const updateNeedsActionNotificationsAction =
  (permitId: number, needsActId: number, updated: NeedsAction) =>
  async (dispatch: Dispatch<UpdateNeedsActAction>) => {
    const needsActionData = {
      ...updated,
      ...{ text: makeASCISafe(updated.text) }
    };

    // try to execute backend request
    try {
      await axiosClient.patch(
        `${envConfig.api.permits}/${permitId}/updateNeedsAct/${needsActId}`,
        needsActionData
      );

      // if updating to resolved, then schedule REQUESTOR_RESOLVED_ACTIONS notification
      if (needsActionData.resolved) {
        schRequestorResolvedActionsNotif(permitId, needsActId);
      }

      dispatch({
        type: ActionType.UPDATE_NEEDS_ACTION_NOTIFICATION,
        payload: needsActionData
      });

      return Promise.resolve('Successfully updated!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject('Failed to update!');
    }
  };
