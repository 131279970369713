enum ActionType {
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  SET_JWT_REFRESH_TOKEN = 'SET_JWT_REFRESH_TOKEN',
  SET_JWT_TOKEN = 'SET_JWT_TOKEN',
  SET_DASHBOARD_FILTER = 'SET_DASHBOARD_FILTER',
  SET_DROPDOWN_OPTIONS = 'SET_DROPDOWN_OPTIONS',
  SET_IS_LOADING_OPTIONS = 'SET_IS_LOADING_OPTIONS',
  SET_PERMITS = 'SET_PERMITS',
  LOAD_ADDITIONAL_PERMITS = 'LOAD_ADDITIONAL_PERMITS',
  SET_PERMIT = 'SET_PERMIT',
  SET_IS_LOADING_PERMITS = 'SET_IS_LOADING_PERMITS',
  SET_PERMIT_ACTIVE_STEP = 'SET_PERMIT_ACTIVE_STEP',
  SET_PERMIT_ASSIGNED_USERS = 'SET_PERMIT_ASSIGNED_USERS',
  SET_PERMIT_PROPERTIES = 'SET_PERMIT_PROPERTIES',
  ADD_TRENCH_SPEC = 'ADD_TRENCH_SPEC',
  REMOVE_TRENCH_SPEC = 'REMOVE_TRENCH_SPEC',
  SET_TRENCH_SPEC_PROPERTY = 'SET_TRENCH_SPEC_PROPERTY',
  ADD_NEW_COMMENT = 'ADD_NEW_COMMENT',
  ADD_COMMENT_REPLY = 'ADD_COMMENT_REPLY',
  REMOVE_COMMENT = 'REMOVE_COMMENT',
  SET_COMMENT = 'SET_COMMENT',
  SET_COMMENTS = 'SET_COMMENTS',
  SET_COMMENT_TAG_USER = 'SET_COMMENT_TAG_USER',
  ADD_ATTACHMENTS = 'ADD_ATTACHMENTS',
  REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT',
  SET_ATTACHMENTS = 'SET_ATTACHMENTS',
  SET_SAP_ATTACHMENTS = 'SET_SAP_ATTACHMENTS',
  SET_HAS_LOADED_ATTACHMENTS = 'SET_HAS_LOADED_ATTACHMENTS',
  SET_SAP_DOCUMENTS = 'SET_SAP_DOCUMENTS',
  UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT',
  ADD_SUPPORTING_ADDRESS = 'ADD_SUPPORTING_ADDRESS',
  REMOVE_SUPPORTING_ADDRESS = 'REMOVE_SUPPORTING_ADDRESS',
  SET_SUPPORTING_ADDRESS = 'SET_SUPPORTING_ADDRESS',
  SET_NEEDS_ACTION_NOTIFICATION = 'SET_NEEDS_ACTION_NOTIFICATION',
  ADD_NEEDS_ACTION_NOTIFICATION = 'ADD_NEEDS_ACTION_NOTIFICATION',
  UPDATE_NEEDS_ACTION_NOTIFICATION = 'UPDATE_NEEDS_ACTION_NOTIFICATION',
  SET_NEEDS_ACTION_DIALOG = 'SET_NEEDS_ACTION_DIALOG',
  SET_UNREAD_COMMENTS = 'SET_UNREAD_COMMENTS',
  RESET_PERMIT_UNREAD_COMMENTS = 'RESET_PERMIT_UNREAD_COMMENTS',
  SET_PRIMARY_ADDRESS = 'SET_PRIMARY_ADDRESS',
  ADD_PERMIT_STATUS_SPEC = 'ADD_PERMIT_STATUS_SPEC',
  REMOVE_PERMIT_STATUS = 'REMOVE_PERMIT_STATUS',
  SET_ALL_STATUSES = 'SET_ALL_STATUSES',
  SET_ASSIGNED_USERS = 'SET_ASSIGNED_USERS',
  REMOVE_PERMIT_REQUEST = 'REMOVE_PERMIT_REQUEST',
  SHOW_ALERT = 'SHOW_ALERT',
  HIDE_ALERT = 'HIDE_ALERT',
  SET_PERMIT_NOTES = 'SET_PERMIT_NOTES'
}

export default ActionType;
