import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select, TextField
} from '@mui/material';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { LoadingOverlay } from 'src/components/utils/LoadingOverlay/LoadingOverlay';
import { MAX_FILE_TITLE_LENGTH } from 'src/constants/permits';
import { Attachment, AttachmentType } from 'src/models/attachment.model';
import './AttachmentItem.scss';

interface Props {
  attachment: Attachment;
  className?: string;
  isEditable?: boolean;
  onChange: (update: Partial<Attachment>) => void;
  onRemove: () => void;
}

type FormState = {
  title: string;
  type: string | undefined;
};

const AttachmentItem = (props: Props) => {
  const {
    attachment,
    className,
    isEditable = true,
    onChange,
    onRemove
  } = props;

  const [form, setForm] = useState<FormState>({
    title: '',
    type: ''
  });
  const [showLoading, setShowLoading] = useState(false);

  const link = attachment['preSignedGetURL'] || attachment['presignedUrl'];

  useEffect(() => {
    setForm({
      title: attachment.title || '',
      type: attachment.type
    });
  }, [attachment]);

  const isTitleValid = (s: string) => {
    if (!s) return false;
    return s.length <= MAX_FILE_TITLE_LENGTH;
  };

  const isFormValid = (form: FormState) => {
    if (!isTitleValid(form.title)) {
      return false;
    }
    return true;
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    const change = { [name]: value };
    const newFormData = { ...form, ...change };
    setForm(newFormData);
    if (isFormValid(newFormData)) {
      onChange(change);
    }
  };

  const downloadItem = () => {
    if (link) {
      window.open(link);
    }
  };

  return (
    <div className={classNames('attachment-item', className)}>
      <LoadingOverlay isVisible={showLoading} />

      <Grid container className="info-grid" spacing={2}>
        <Grid item xs={isEditable ? 2 : 3}>
          {isEditable ? (
            <TextField
              fullWidth
              label="File Title"
              name="title"
              size="small"
              value={form.title}
              onChange={handleChange}
              error={!isTitleValid(form.title)}
              helperText={
                !isTitleValid(form.title) && form.title && 'Max.100 chars!'
              }
              required
            />
          ) : (
            form.title
          )}
        </Grid>

        <Grid item xs={2}>
          {isEditable ? (
            <FormControl fullWidth size="small" error={!form.type} required>
              <InputLabel>File Type</InputLabel>
              <Select
                value={form.type}
                label="File Type"
                name="type"
                size="small"
                onChange={handleChange}
              >
                {Object.values(AttachmentType).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            form.type
          )}
        </Grid>

        <Grid item xs={3}>
          <span>
            <strong>
              <span>{attachment.authorName}</span>
              {attachment.createdAt && (
                <span>
                  {' '}
                  - {moment(attachment.createdAt).format('MM/DD/YYYY')}
                </span>
              )}
            </strong>
          </span>
        </Grid>

        <Grid item xs={3}>
          <span>{attachment.fileName}</span>
        </Grid>

        <Grid item xs={1}>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton
              disabled={!link}
              title="Download Attachment"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                downloadItem();
              }}
            >
              <FileDownloadIcon />
            </IconButton>
          </Box>
        </Grid>

        {isEditable ? (
          <Grid item xs={1}>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton
                title="Remove Attachment"
                onClick={() => {
                  setShowLoading(true);
                  onRemove();
                }}
              >
                <RemoveCircleIcon />
              </IconButton>
            </Box>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </div>
  );
};

export default AttachmentItem;
