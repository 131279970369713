import { UserRole } from '../models/user-role.model';

const roleStringToUserRole = (s: string): UserRole => {
  switch (s.toUpperCase()) {
    case 'ADMIN':
      return UserRole.ADMIN;
    case 'PSS':
      return UserRole.PSS1;
    case 'QAQC':
      return UserRole.QAQC;
    case 'TECH-SPECIALIST':
      return UserRole.TS;
    case 'TCP-SPECIALIST':
      return UserRole.TCP;
    case 'REQUESTOR':
      return UserRole.REQUESTOR;
    default:
      return UserRole.REQUESTOR; // TODO how to handle...
  }
};


/**
 * Converts string of role/roles to array of UserRole items
 * @param r String with roles
 * @return UserRole[]
 */
export const parseRoleString = (r = ''): UserRole[] => {
  const asArray = r
    .replace('[', '')
    .replace(']', '')
    .split(',')
    .map((item) => String(item).trim())
    .map((item) => (roleStringToUserRole(item)));

  return asArray;
};