import { Backdrop, CircularProgress } from '@mui/material';
import './LoadingOverlay.scss';

/**
 * Loading overlay which will display over the whole parent element's area
 * NOTE: parent must be be - "postion: relative" - otherwise the overlay will display over the whole screen
 */

interface Props {
  isVisible: boolean;
}

export const LoadingOverlay = (props: Props) => {
  const { isVisible } = props;

  return (
    <Backdrop
      className="loading-overlay"
      color="primary"
      sx={{
        color: (theme) => theme.palette.primary.main,
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={isVisible}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
