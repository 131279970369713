import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Autocomplete,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import * as commentActions from 'src/store/action-creators/comment-actions';
import * as permitActions from 'src/store/action-creators/permit-actions';
import * as permitSidebarActions from 'src/store/action-creators/permit-sidebar-actions';
import { User, Watcher } from 'src/models/user.model';
import { RootState } from 'src/store/store';
import './Contacts.scss';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LoadingOverlay } from 'src/components/utils/LoadingOverlay/LoadingOverlay';
import TitleAndSaveStatus from '../TitleAndSaveStatus/TitleAndSaveStatus';
import { requestorByEmailSelector } from '../../../store/selectors/users-selectors';

interface Props extends PropsFromRedux {
  permitId: string;
  permitWatchers: (Watcher | undefined)[];
  requestorEmail: string;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    minWidth: 180,
    maxWidth: 400
  }
}));

const Contacts = (props: Props) => {
  const {
    permitId,
    permitWatchers,
    requestorEmail,
    users,
    setActiveStep,
    setCommentTagUser,
    updatePermit
  } = props;

  const requestor = useSelector<RootState, User | null>((state) =>
    requestorByEmailSelector(state, requestorEmail)
  );
  const [watchers, setWatchers] = useState<(Watcher | undefined)[]>([]);
  const [mainContactId, setMainContactId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingPermitData, setIsSavingPermitData] = useState(false);
  const [isPermitUploadSuccessful, setIsPermitUploadSuccessful] =
    useState(true);

  useEffect(() => {
    const mainContact = permitWatchers.find(
      (item) => item && item.isMainContact
    );
    setWatchers(permitWatchers);
    setMainContactId(mainContact?.id || '');
  }, [permitWatchers]);

  const invokeUpdateWatchers = (watchers: (Watcher | undefined)[]) => {
    setIsPermitUploadSuccessful(true);
    setIsLoading(true);
    setIsSavingPermitData(true);
    updatePermit(permitId, { watchers: watchers })
      .then(
        () => setIsLoading(false),
        () => setIsLoading(false)
      )
      .then(() => setIsSavingPermitData(false))
      .catch(() => {
        setIsPermitUploadSuccessful(false);
      });
  };

  const handleWatcherChange = (position: number, value: Watcher) => {
    const changedWatchers = [...watchers];
    changedWatchers[position] = { ...value, isMainContact: false };
    setWatchers(changedWatchers);
    invokeUpdateWatchers(changedWatchers);
  };

  const handleWatcherAdd = () => {
    setWatchers([...watchers, undefined]);
  };

  const handleWatcherRemove = (position: number) => {
    const changedWatchers = [...watchers];
    const isUndefined = !changedWatchers[position];
    // unset main contact, if was removed
    if (changedWatchers[position]?.id === mainContactId) {
      setMainContactId('');
    }
    changedWatchers.splice(position, 1);
    setWatchers(changedWatchers);
    // only send request to BE if removing actual watcher (not just empty input)
    if (!isUndefined) {
      invokeUpdateWatchers(changedWatchers);
    }
  };

  const handleMainContactChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const watcherId = event.target.value;
    setMainContactId(watcherId);
    let changedWatchers = [...watchers];
    changedWatchers = changedWatchers.map((item) => {
      if (!item) {
        return item;
      }
      if (item.id === watcherId) {
        return { ...item, isMainContact: true };
      } else {
        return { ...item, isMainContact: false };
      }
    });
    setWatchers(changedWatchers);
    invokeUpdateWatchers(changedWatchers);
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setActiveStep('location');
  };

  return (
    <>
      <TitleAndSaveStatus
        titleName={'Contacts'}
        isSavingPermitData={isSavingPermitData}
        isPermitUploadSuccessful={isPermitUploadSuccessful}
      />
      <form className="contacts-form" onSubmit={submitForm}>
        <div className="requestor">
          <div>
            <div className="label">Requestor</div>
            <div>{requestor?.name}</div>
          </div>
          <Divider className="divider" />
          <div className="info">
            <div>{requestor?.email}</div>
            <div>{requestor?.phone || 'w/o phone number'}</div>
            <div>
              <IconButton
                color="primary"
                title="Tag user in a new comment"
                onClick={() => requestor && setCommentTagUser(requestor)}
              >
                <ChatBubbleOutlineIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <div>
          <div>Add anyone who needs to view this request.</div>
          <div className="watchers">
            <LoadingOverlay isVisible={isLoading} />

            <div className="title">Watchers</div>
            <RadioGroup
              value={mainContactId}
              name="mainProjectContact"
              onChange={handleMainContactChange}
            >
              <table>
                <tbody>
                  {watchers.map((watcher, index) => (
                    <tr className="watcher" key={watcher?.id || index}>
                      <td>
                        <Autocomplete
                          popupIcon={''}
                          disableClearable
                          onChange={(event, value) =>
                            handleWatcherChange(index, value)
                          }
                          size="small"
                          disablePortal
                          options={users as Watcher[]}
                          value={watcher}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          filterOptions={(options, state) =>
                            options.filter(
                              (option) =>
                                !watchers.some(
                                  (watcher) => watcher?.id === option.id
                                ) &&
                                option.name
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                            )
                          }
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Name"
                              required
                              InputLabelProps={{ required: false }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {watcher && (
                                      <InputAdornment position="end">
                                        <LightTooltip
                                          title={
                                            <div className="contacts-tooltip-content">
                                              <span className="tooltip-text">
                                                {watcher.phone ||
                                                  'w/o phone number'}
                                              </span>
                                              <div>
                                                <IconButton
                                                  color="primary"
                                                  onClick={() => {
                                                    navigator.clipboard.writeText(
                                                      watcher.phone || ''
                                                    );
                                                  }}
                                                >
                                                  <ContentCopyIcon className="tooltip-copy-icon" />
                                                </IconButton>
                                              </div>
                                            </div>
                                          }
                                        >
                                          <IconButton color="primary">
                                            <PhoneIphoneIcon />
                                          </IconButton>
                                        </LightTooltip>
                                        <LightTooltip
                                          title={
                                            <div className="contacts-tooltip-content">
                                              <span className="tooltip-text">
                                                {watcher.email}
                                              </span>
                                              <div>
                                                <IconButton
                                                  color="primary"
                                                  onClick={() => {
                                                    navigator.clipboard.writeText(
                                                      watcher.email
                                                    );
                                                  }}
                                                >
                                                  <ContentCopyIcon className="tooltip-copy-icon" />
                                                </IconButton>
                                                <IconButton
                                                  color="primary"
                                                  onClick={(e) => {
                                                    const mailto =
                                                      'mailto:' + watcher.email;
                                                    window.location.href =
                                                      mailto;
                                                    e.preventDefault();
                                                  }}
                                                >
                                                  <svg
                                                    className="tooltip-mail-icon"
                                                    width="25"
                                                    height="16"
                                                    viewBox="0 0 25 25"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M0 2.81689V21.9483L14.6975 25V0L0 2.81689ZM7.40468 16.8319C2.7324 16.5335 3.21361 8.19394 7.51376 8.12769C12.1204 8.4292 11.6685 16.7654 7.40468 16.8319ZM7.47204 9.76055C5.01055 9.92978 5.12076 15.1515 7.43407 15.1934C9.88496 15.037 9.74103 9.80112 7.47204 9.76055ZM17.2018 13.0864C17.4237 13.2479 17.6911 13.0864 17.6911 13.0864C17.4246 13.2479 24.9707 8.28452 24.9707 8.28452V17.2723C24.9707 18.2507 24.3382 18.661 23.627 18.661H15.6258L15.6263 12.015L17.2018 13.0864ZM15.6267 5.30049V10.1906L17.3525 11.2667C17.398 11.2798 17.4966 11.2808 17.5422 11.2667L24.9696 6.30796C24.9696 5.72114 24.4168 5.30049 24.1048 5.30049H15.6267Z"
                                                      fill="#0193D5"
                                                    />
                                                  </svg>
                                                </IconButton>
                                              </div>
                                            </div>
                                          }
                                        >
                                          <IconButton color="primary">
                                            <MailOutlineIcon />
                                          </IconButton>
                                        </LightTooltip>
                                        <IconButton
                                          color="primary"
                                          title="Tag user in a new comment"
                                          onClick={() =>
                                            setCommentTagUser(watcher)
                                          }
                                        >
                                          <ChatBubbleOutlineIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    )}
                                  </>
                                )
                              }}
                            />
                          )}
                        />
                      </td>

                      <td>
                        {watcher && (
                          <div className="title-cell">
                            <div className="title">Title</div>
                            <div className="value">{watcher.role}</div>
                          </div>
                        )}
                      </td>

                      <td>
                        {watcher && (
                          <div className="main-contact-cell">
                            <div className="title" hidden={index !== 0}>
                              Main Project Contact
                            </div>
                            <div className="value">
                              <FormControlLabel
                                value={watcher.id}
                                control={<Radio />}
                                label={''}
                              />
                            </div>
                          </div>
                        )}
                      </td>

                      <td>
                        {
                          <IconButton
                            title="Remove Watcher"
                            onClick={() => handleWatcherRemove(index)}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </RadioGroup>

            <div>
              <IconButton
                className="add-button"
                title="Add Watcher"
                onClick={handleWatcherAdd}
              >
                <AddCircleIcon color="primary" />
              </IconButton>
            </div>
          </div>
        </div>

        <Button
          className="submit-button"
          type="submit"
          variant="contained"
          disableElevation
          disabled={isLoading || isSavingPermitData}
        >
          Next
        </Button>
      </form>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.filter.dropdownOptions ? state.filter.dropdownOptions.users : []
});

const mapDispatchToProps = {
  setActiveStep: permitSidebarActions.setPermitActiveStepAction,
  setCommentTagUser: commentActions.setCommentTagUserAction,
  updatePermit: permitActions.updatePermitAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Contacts);
