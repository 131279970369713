import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputBase,
  Radio,
  RadioGroup
} from '@mui/material';
import { Box } from '@mui/system';
import classNames from 'classnames';
import React, { useState } from 'react';
import './NewPermit.scss';
import * as permitActions from 'src/store/action-creators/permit-actions';
import PermitCard from './PermitCard/PermitCard';
import { NewPermitForm } from 'src/models/form.model';
import { PermitRequest, PermitCardInfo } from 'src/models/permit.model';
import theme from 'src/theme/theme';
import { useHistory } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/store/store';
import { SapInfoMessage } from 'src/components/utils/SapInfoMessage/SapInfoMessage';
import { JobType } from 'src/models/job-type.model';

const NewPermit = (props: PropsFromRedux) => {
  const { currentUser, createPermit, getPermitsByJobId } = props;
  const history = useHistory();

  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [formValues, setFormValues] = useState<NewPermitForm>({
    jobType: '',
    jobNumber: '',
    withoutJobNumber: false
  });

  const [resultsVisible, setResultsVisible] = useState(false);
  const [sapAccess, setSapAccess] = useState(true);
  const [jobIdValid, setJobIdValid] = useState(true);
  const [results, setResults] = useState<PermitCardInfo[]>([]);
  const [cpdNextButtonAccess, setCpdNextButtonAccess] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResultsVisible(false);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // if job type is CPD, then uncheck the checkbox (it will be also automatically disabled and job number required if CPD is selected)
    if (name === 'jobType' && value === JobType.CPD) {
      setFormValues({ ...formValues, [name]: value, withoutJobNumber: false });
      setFormValues({
        ...formValues,
        [name]: value,
        withoutJobNumber: false
      });
      setCpdNextButtonAccess(formValues.jobNumber.length === 12 ? false : true);
    } else if (name === 'jobNumber' && formValues.jobType === JobType.CPD) {
      setFormValues({ ...formValues, [name]: target.value });
      setCpdNextButtonAccess(target.value.length === 12 ? false : true);
    } else {
      setFormValues({ ...formValues, [name]: value });
      setCpdNextButtonAccess(false);
    }
  };

  const showResults = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // do not make getPermitsJob request if no jobNumber provided, or is disabled
    if (formValues.withoutJobNumber || !formValues.jobNumber) {
      setResultsVisible(true);
    } else {
      setIsLoadingResults(true);
      setSapAccess(true);
      setJobIdValid(true);
      getPermitsByJobId(formValues.jobNumber, formValues.jobType).then(
        (results) => {
          setIsLoadingResults(false);
          setResultsVisible(true);
          setResults(results.permitsbyjobid);

          setSapAccess(results.permitJobSapValid.sapOnline);
          setJobIdValid(results.permitJobSapValid.jobIdValid);
        },
        () => {
          setIsLoadingResults(false);
        }
      );
    }
  };

  const handleCreateNewPermit = () => {
    const jobNumber = formValues.withoutJobNumber ? null : formValues.jobNumber;
    const jobType = formValues.jobType;

    if (currentUser) {
      // show loading
      setIsLoadingCreate(true);
      // get data
      createPermit(jobNumber, jobType).then(
        (res: PermitRequest) => {
          // navigate to newly created permit
          history.push(`/permit/${res.id}`);
          // hide loading
          setIsLoadingCreate(false);
        },
        () => {
          // hide loading
          setIsLoadingCreate(false);
        }
      );
    }
  };

  const showJobSearchResults =
    formValues.jobNumber && !formValues.withoutJobNumber;

  return (
    <div className="new-permit">
      <h2>New Permit Request</h2>

      <form className="new-permit-form" onSubmit={showResults}>
        {!sapAccess && <SapInfoMessage />}
        <FormControl className="job-type">
          <label>
            What <strong>type of job</strong> is this for?*
          </label>
          <RadioGroup row name="jobType" onChange={handleChange}>
            <FormControlLabel
              value={JobType.CPD}
              control={<Radio required />}
              label="CPD"
            />
            <FormControlLabel
              value={JobType.DPSS}
              control={<Radio required />}
              label="DPSS"
            />
            <FormControlLabel
              value={JobType.OTHER}
              control={<Radio required />}
              label="Other"
            />
          </RadioGroup>
        </FormControl>

        <div className="job-number">
          {formValues.jobType === JobType.CPD && (
            <label>
              Enter the <strong>Job Notification Number</strong>
            </label>
          )}
          {formValues.jobType != JobType.CPD && (
            <label>
              Enter the <strong>job number(s)</strong>
            </label>
          )}

          <div className="controls">
            <FormControl
              disabled={formValues.withoutJobNumber}
              required={formValues.jobType === JobType.CPD}
            >
              <Box
                className={classNames('input', {
                  'input-disabled': formValues.withoutJobNumber
                })}
                sx={{
                  border: '1px solid #c4c4c4',
                  ':hover': {
                    borderColor: 'black'
                  },
                  '&:focus-within': {
                    border: `2px solid ${theme.palette.primary.main}`
                  }
                }}
              >
                {formValues.jobType === JobType.CPD && (
                  <InputBase
                    sx={{ flex: 1 }}
                    placeholder="Job number(s)"
                    name="jobNumber"
                    value={formValues.jobNumber}
                    onChange={handleChange}
                    inputProps={{ maxLength: 12 }} // sets the maximum length to 12
                  />
                )}
                {formValues.jobType != JobType.CPD && (
                  <InputBase
                    sx={{ flex: 1 }}
                    placeholder="Job number(s)"
                    name="jobNumber"
                    value={formValues.jobNumber}
                    onChange={handleChange}
                  />
                )}
              </Box>
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.withoutJobNumber}
                    name="withoutJobNumber"
                    onChange={handleChange}
                  />
                }
                disabled={formValues.jobType === JobType.CPD}
                label="I don't have one"
                labelPlacement="end"
              />
            </FormControl>
          </div>
          {!jobIdValid && (
            <span className="title-message">
              No prereqs were found with this job number in SAP system.
            </span>
          )}
        </div>
        <Button
          type="submit"
          variant="contained"
          disableElevation
          disabled={isLoadingCreate || isLoadingResults || cpdNextButtonAccess}
          endIcon={
            isLoadingResults && <CircularProgress size={24} color="inherit" />
          }
        >
          Next
        </Button>
      </form>

      {resultsVisible && (
        <div className="results">
          {showJobSearchResults && (
            <div className="grouped">
              {results.length > 0 ? (
                <>
                  <div>We found current permit request(s) for this Job:</div>
                  <div className="permits">
                    {results.map((permit) => (
                      <PermitCard key={permit.id} permit={permit}></PermitCard>
                    ))}
                  </div>
                </>
              ) : (
                <div className="no-results">
                  No permit request(s) found for this Job, please create a new
                  one.
                </div>
              )}
            </div>
          )}

          <div className="grouped">
            {results.length > 0 && (
              <div>
                If these permits are not what you need please create a new
                request.
              </div>
            )}
            <Button
              variant="contained"
              size="large"
              endIcon={
                isLoadingCreate ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <OpenInBrowserIcon />
                )
              }
              disableElevation
              disabled={isLoadingCreate}
              onClick={handleCreateNewPermit}
            >
              Create a New Permit Request
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUser: state.auth.currentUser
});

const mapDispatchToProps = {
  createPermit: permitActions.createPermitAction,
  getPermitsByJobId: permitActions.getPermitsByJobIdAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NewPermit);
