import { Chip } from '@mui/material';
import { PermitCardInfo } from 'src/models/permit.model';
import './PermitCard.scss';
import { Fragment } from 'react';
import Stack from '@mui/material/Stack';
import CoordinatorAvatar from '../../PermitForm/CoordinatorAvatar/CoordinatorAvatar';
import { useHistory } from 'react-router';
import { RootState } from 'src/store/store';
import { connect, ConnectedProps } from 'react-redux';

interface PermitCardProps extends PropsFromRedux {
  permit: PermitCardInfo;
}
const PermitCard = (props: PermitCardProps) => {
  const history = useHistory();
  const { permit, requestStatuses } = props;
  const higherLevelCategoryStatus = requestStatuses?.find(
    (status) => Number(status.id) === Number(permit.requestStatusId)
  )?.higherLevelCategory;
  const generateInitials = (name: string | undefined) => {
    let res;
    if (name) {
      const nameArr = name
        .replace(/ *\([^)]*\) */g, '')
        //.replace(/\s/g, '')
        .split(/[,\s]/);
      res = nameArr[0].charAt(0) + nameArr[1].charAt(0);
    } else {
      res = null;
    }
    return res;
  };

  //Box Color Determination - Conditional Color Rendering
  const determineBoxColor = (higherLevelCategory: string | undefined) => {
    if (higherLevelCategory === 'In-progress with Permitting') {
      return 'default'; //gray
    } else if (higherLevelCategory === 'In-progress with Agency') {
      return 'primary'; //blue
    } else if (higherLevelCategory === 'Needs Action') {
      return 'error'; //red
    } else if (higherLevelCategory === 'Complete') {
      return 'success'; //green
    } else {
      return 'default'; //gray
    }
  };

  const boxColor = determineBoxColor(higherLevelCategoryStatus);

  return (
    <div
      className="permit-card"
      onClick={() => history.push(`permit/${permit.id}`)}
    >
      <div className="header">
        <span>
          Job# <strong>{permit.jobNumber}</strong>
        </span>
        <span>{permit.id}</span>
      </div>
      {higherLevelCategoryStatus && (
        <Chip color={boxColor} label={higherLevelCategoryStatus} />
      )}
      <div>
        <div className="card-job-title">{permit.jobTitle}</div>
        <div className="card-agency-name">
          <strong>{permit.agencyName}</strong>
        </div>
      </div>
      <div className="team">
        <Stack
          className="assigned"
          direction="row"
          spacing={2}
          style={{ width: 'initial' }}
        >
          {(permit.requestTypeId === '1' ||
            permit.requestTypeId === '3' ||
            permit.requestTypeId === '4') &&
            permit.agencyTCPCoordinatorName && (
              <Fragment>
                <CoordinatorAvatar
                  initials={
                    generateInitials(permit.agencyTCPCoordinatorName) || ''
                  }
                  name={
                    'TCP Coordinator: ' + permit.agencyTCPCoordinatorName || ''
                  }
                />
              </Fragment>
            )}
          {(permit.requestTypeId === '2' ||
            permit.requestTypeId === '3' ||
            permit.requestTypeId === '4') &&
            permit.agencyPermitCoordinatorName && (
              <Fragment>
                <CoordinatorAvatar
                  initials={
                    generateInitials(permit.agencyPermitCoordinatorName) || ''
                  }
                  name={
                    'Permit Coordinator: ' +
                      permit.agencyPermitCoordinatorName || ''
                  }
                />
              </Fragment>
            )}
        </Stack>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  requestStatuses: state.filter.dropdownOptions?.requestStatuses || []
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PermitCard);
