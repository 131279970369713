import './Attachments.scss';
import { connect, ConnectedProps } from 'react-redux';
import * as attachmentActions from 'src/store/action-creators/attachment-actions';
import DragDropInput from 'src/components/common/DragDropInput/DragDropInput';
import TitleAndSaveStatus from '../TitleAndSaveStatus/TitleAndSaveStatus';
import { useCallback, useEffect, useState } from 'react';
import AttachmentItem from 'src/components/common/AttachmentItem/AttachmentItem';
import { Attachment, GetSAPAttachments } from 'src/models/attachment.model';
import { CircularProgress, debounce, Button, Alert } from '@mui/material';
import { RootState } from 'src/store/store';
import { PermitRequest } from 'src/models/permit.model';
import { Box } from '@mui/system';
import envConfig from 'src/env-config';
import axiosClient from 'src/utils/axios-client';
import ViewSapAttachments from 'src/components/common/ViewSapAttachment/ViewSapAttachment';
interface Props extends PropsFromRedux {
  permit: PermitRequest;
  isEditable?: boolean;
  attachmentDialog?: boolean;
  onPendingUploadsChange?: (n: number) => void;
}

const Attachements = (props: Props): JSX.Element => {
  const {
    agencies,
    attachments,
    hasLoadedAttachments,
    permit,
    isEditable = true,
    attachmentDialog = false,
    addAttachments,
    getAttachments,
    removeAttachment,
    setHasLoadedAttachments,
    updateAttachment
  } = props;

  const [isSavingPermitData, setIsSavingPermitData] = useState(false);
  const [isAutosaveSuccessful, setIsAutosaveSuccessful] = useState(true);
  const [loadingAddCount, setLoadingAddCount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [getSAPAttachment, setGetSAPAttachment] =
    useState<GetSAPAttachments[]>();

  const retrieveSAPAttachments = async (permit: PermitRequest) => {
    try {
      const response = await axiosClient.get(
        `${envConfig.api.getSAPAttachments}/${permit.jobNumber}`
      );

      const data = response.data.afpermitsResponse.body;

      return Promise.resolve(data?.sapAttachments || []);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    //Retrieve SAP Attachments and then set state
    retrieveSAPAttachments(permit).then((sapAttachments) => {
      setGetSAPAttachment(sapAttachments);
    });

    const hasAgency = agencies.some(
      (item) => item.id === permit.primarAgencyId
    );
    if (!hasLoadedAttachments && hasAgency) {
      setErrorMessage('');
      getAttachments(permit).then(
        () => setHasLoadedAttachments(true),
        () => setHasLoadedAttachments(false)
      );
    } else if (!hasAgency) {
      setErrorMessage(
        'There was an error loading all attachments. Make sure that at least 1 Agency is specified for this permit, then try again.'
      );
    }
  }, [hasLoadedAttachments, permit.primarAgencyId, agencies]);

  const updatePendingUploadCount = (count: number) => {
    setLoadingAddCount((prevState) => {
      const newCount = prevState + count;
      // inform the parent, if callback was provided
      if (props.onPendingUploadsChange) {
        props.onPendingUploadsChange(newCount);
      }
      return newCount;
    });
  };

  const handleAttachmentAdd = (files: File[]) => {
    updatePendingUploadCount(files.length);
    addAttachments(permit, files).then((res) => {
      updatePendingUploadCount(res.length * -1);
    });
  };

  const invokeUpdateAttachment = (
    attachmentId: number,
    update: Partial<Attachment>
  ) => {
    // update only if title or type are defined (otherwise no update will be triggered)
    setIsSavingPermitData(true);
    updateAttachment(permit.id, attachmentId, update).then(
      () => setIsSavingPermitData(false),
      () => setIsAutosaveSuccessful(false)
    );
  };

  const debouncedTitleHandler = useCallback(
    debounce(invokeUpdateAttachment, 1000),
    []
  );

  const handleAttachmentChange = (
    attachmentId: number,
    update: Partial<Attachment>
  ) => {
    // lets use debounce when updating file title
    if ('title' in update) {
      debouncedTitleHandler(attachmentId, update);
    } else {
      invokeUpdateAttachment(attachmentId, update);
    }
  };

  const handleAttachmentRemove = (attachment: Attachment) => {
    removeAttachment(permit.id, attachment);
  };

  return (
    <div className="attachments-section">
      {isEditable && (
        <TitleAndSaveStatus
          titleName="Attachments"
          isSavingPermitData={isSavingPermitData}
          isPermitUploadSuccessful={isAutosaveSuccessful}
        />
      )}
      <Alert severity="info" className="mt-15px">
        *Make sure the file name cannot be no longer than 80 characters.
      </Alert>
      {!errorMessage ? (
        <div className="attachments-content">
          {hasLoadedAttachments ? (
            <div className="attachment-list">
              {attachments.map((attachment) => (
                <AttachmentItem
                  className="request-form-attachment-item"
                  key={attachment.id}
                  attachment={attachment}
                  isEditable={isEditable}
                  onChange={(update) =>
                    handleAttachmentChange(attachment.id, update)
                  }
                  onRemove={() => handleAttachmentRemove(attachment)}
                />
              ))}

              {/* Loading of newly added files */}
              {Array.from(Array(loadingAddCount).keys()).map((item, index) => (
                <div className="request-form-attachment-item" key={index}>
                  <div>
                    <CircularProgress className="mr-15px" />
                  </div>
                  <div>
                    <p>Uploading.... Please do not refresh or close window.</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="loading-circle">
              <CircularProgress />
            </div>
          )}
          {permit.id < envConfig.k2Attachments.permitNumber &&
            attachmentDialog && (
              <Button
                variant="contained"
                href={envConfig.k2Attachments.url + permit.id}
                disableElevation
                target="_blank"
              >
                K2 Attachments
              </Button>
            )}
          {permit.id >= envConfig.k2Attachments.permitNumber &&
            permit.id <= envConfig.k2Attachments.spunMaxParentPermitID &&
            attachmentDialog && (
              <Button
                variant="contained"
                href={envConfig.k2Attachments.url + permit.spunParentPermitID}
                disableElevation
                target="_blank"
              >
                K2 Attachments
              </Button>
            )}
          {isEditable && (
            <>
              <DragDropInput onFilesAdd={handleAttachmentAdd} />
            </>
          )}
          <section>
            <h2>
              <strong>SAP Attachments</strong>
            </h2>
            {getSAPAttachment !== undefined && getSAPAttachment.length > 0 ? (
              <ViewSapAttachments
                SapAttachment={getSAPAttachment}
              ></ViewSapAttachments>
            ) : (
              <div> No SAP Attachments Found</div>
            )}
          </section>
        </div>
      ) : (
        <Box sx={{ color: (theme) => theme.palette.error.main }}>
          {errorMessage}
        </Box>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  agencies: state.filter.dropdownOptions.agencies,
  attachments: state.attachment.attachments,
  hasLoadedAttachments: state.attachment.hasLoaded,
  sapDocuments: state.attachment.sapDocuments
});

const mapDispatchToProps = {
  addAttachments: attachmentActions.addAttachmentsAction,
  attachSapDocument: attachmentActions.attachSapDocumentAction,
  getAttachments: attachmentActions.getAttachmentsAction,
  removeAttachment: attachmentActions.removeAttachmentAction,
  setHasLoadedAttachments: attachmentActions.setHasLoadedAttachmentsAction,
  updateAttachment: attachmentActions.updateAttachmentAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Attachements);
