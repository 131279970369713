import ActionType from '../action-types';
import {
  NeedsActActions,
  NeedsActState
} from '../../../src/models/store-needs-action.model';

const initialState: NeedsActState = {
  needsActNotifications: [],
  needsActionDialog: {
    isOpen: false,
    permitId: null,
    statusId: null
  }
};

const needsActionReducer = (
  state: NeedsActState = initialState,
  action: NeedsActActions
): NeedsActState => {
  switch (action.type) {
    case ActionType.SET_NEEDS_ACTION_NOTIFICATION:
      return {
        ...state,
        needsActNotifications: [...action.payload]
      };
    case ActionType.ADD_NEEDS_ACTION_NOTIFICATION:
      return {
        ...state,
        needsActNotifications: [...state.needsActNotifications, action.payload]
      };
    case ActionType.UPDATE_NEEDS_ACTION_NOTIFICATION:
      return {
        ...state,
        needsActNotifications: [
          ...state.needsActNotifications.filter(n => n.id !== action.payload.id),
          action.payload
        ]
      };
    case ActionType.SET_NEEDS_ACTION_DIALOG:
      return {
        ...state,
        needsActionDialog: action.payload
      };
    default:
      return state;
  }
};

export default needsActionReducer;
