import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { RootState } from 'src/store/store';
import * as permitActions from 'src/store/action-creators/permit-actions';
import { connect, ConnectedProps } from 'react-redux';
import PermitForm from '../PermitForm/PermitForm';
import { Backdrop, CircularProgress } from '@mui/material';
import { PermitRequest } from 'src/models/permit.model';
import SubmittedPermit from '../SubmittedPermit/SubmittedPermit';
import NotFound from '../NotFound/NotFound';
import { PERMIT_NOT_SUBMITTED_STATUS_ID } from 'src/constants/statuses';

export const Permit = (props: PropsFromRedux) => {
  const { permit, getPermit } = props;

  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  useEffect(() => {
    // condition below is required when permit is being submitted - so that new submitted status (and all permit data) will load from DB
    const isPermitNotLoaded = Number(id) !== permit?.id;
    if (isPermitNotLoaded) {
      setIsLoading(true);
      getPermit(id).then(
        () => setIsLoading(false),
        () => {
          setIsLoading(false);
          setIsNotFound(true);
        }
      );
    }
  }, [getPermit, id, permit?.id]);

  const isNotSubmittedPermit = (toCheck: PermitRequest) => {
    return Number(toCheck.requestStatusId) === PERMIT_NOT_SUBMITTED_STATUS_ID;
  };

  return (
    <>
      <Backdrop
        sx={{
          color: 'white',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* If permit is of not submitted status, then display permit form, otherwise display submitted permit view */}
      {!isLoading &&
        permit &&
        (isNotSubmittedPermit(permit) ? <PermitForm /> : <SubmittedPermit />)}

      {!permit && isNotFound && <NotFound />}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  permit: state.permit.permit
});

const mapDispatchToProps = {
  getPermit: permitActions.getPermitAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Permit);
