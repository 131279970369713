import { useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material';

import './Attachments.scss';
import BootstrapDialogTitle from '../components/DialogTitle/DialogTitle';
import { PermitRequest } from 'src/models/permit.model';
import * as permitActions from 'src/store/action-creators/permit-actions';
import { RootState } from 'src/store/store';
import AttachmentsForm from '../../PermitForm/Attachments/Attachments';
import { handleEnterKeyDown } from 'src/utils/handlers-utils';

interface Props extends PropsFromRedux {
  permit: PermitRequest;
}

const Attachments = (props: Props) => {
  const { permit } = props;

  const attachmentsTileRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [pendingUploadsCount, setPendingUploadsCount] = useState(0);

  const handleOpenDialog = () => setOpen(true);

  const handleOnClose = (): void => {
    if (pendingUploadsCount > 0) {
      if (
        window.confirm(
          'Closing the window now might interrupt the current upload. Continue?'
        )
      ) {
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <section
        className="permit-attachments-tile"
        tabIndex={0}
        ref={attachmentsTileRef}
        onKeyDown={(event) => handleEnterKeyDown(event, attachmentsTileRef)}
      >
        <header>
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            Attachments
          </Typography>
        </header>
        <div className="cell">
          <Button
            id="edit-details"
            variant="contained"
            color="primary"
            onClick={() => handleOpenDialog()}
            sx={{ mt: 2 }}
          >
            Edit Attachments
          </Button>
        </div>
        <article>
          <AttachmentsForm permit={permit} isEditable={false} />
        </article>
      </section>
      <Dialog
        className="submitted-attachments-dialog"
        open={open}
        fullWidth
        maxWidth="md"
        onBackdropClick={handleOnClose}
        onClose={handleOnClose}
      >
        <BootstrapDialogTitle onClose={handleOnClose}>
          <strong>Edit Attachments</strong>
        </BootstrapDialogTitle>

        <DialogContent>
          <AttachmentsForm
            permit={permit}
            attachmentDialog={true}
            onPendingUploadsChange={setPendingUploadsCount}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUser: state.auth.currentUser
});

const mapDispatchToProps = {
  updatePermit: permitActions.updateSubmittedPermitTCPAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Attachments);
