import { JobType } from 'src/models/job-type.model';
import { MarkerMap } from 'src/models/marker-map.model';
import {
  PermitDashboard,
  PermitGroupedMap,
  PermitRequest
} from '../models/permit.model';
import { StatusType } from '../models/status.model';
import GeoCode from 'react-geocode';
import { updatePrimaryAddress } from 'src/store/action-creators/permit-actions';
import envConfig from 'src/env-config';
import { store } from 'src/store/store';
import { RequestStatus } from '../models/request-status.model';
import {
  PERMIT_PERMIT_INBOX_ID,
  STATUSES_DISABLED_IDS
} from '../constants/statuses';

/**
 * Takes array of permits and groups them:
 * - if jobNumber is defined then groups them by it
 * - if jobNumber is NOT defined then groups them by spunParentPermitId
 * - if none of above is defined, then creates unique group
 *
 * @param permits
 * @returns grouped permits (this structure is used to display permits on dashboard)
 */
export const getGroupedPermits = (
  permits: PermitDashboard[]
): PermitGroupedMap => {
  const unsorted: PermitGroupedMap = {};
  const sorted: PermitGroupedMap = {};

  permits.forEach((item, index) => {
    // get group name
    let group = item.jobNumber;
    if (!group) {
      group = item.spunParentPermitId
        ? String(item.spunParentPermitId)
        : `N/A - ${index}`;
    }
    // if group not yet present, then init empty group
    if (!unsorted[group]) {
      unsorted[group] = [];
    }
    // add permit to group
    unsorted[group].push(item);
  });

  // sort each group by ID
  Object.keys(unsorted).forEach((group) => {
    sorted[group] = unsorted[group].sort((a, b) => a.id - b.id);
  });

  return sorted;
};

/**
 * Returns job type for provided permit
 * @param permit permit request object, for which we are getting the job type
 * @returns string value representing job type
 */
export const getPermitJobType = (permit: PermitRequest) => {
  if (permit.sapNumber2) {
    return JobType.CPD;
  } else if (permit.dpssNumberA1) {
    return JobType.DPSS;
  } else {
    return JobType.OTHER;
  }
};

/**
 * Utility function returns true, if provided status id belongs to a provided category
 *
 * @param statusId
 * @param statusCategory
 * @param allStatuses
 */
export const isStatusOfCategoryById = (
  statusId: number,
  statusCategory: string,
  allStatuses: StatusType[]
) => {
  if (!allStatuses || !allStatuses.length)
    throw new Error('Statuses list must not be empty');
  if (!statusCategory) throw new Error('Status category must be provided');
  const higherCategory = allStatuses.find(
    (status) => status.id === statusId
  ) || { category: null };
  return higherCategory.category === statusCategory;
};

/**
 * Checks if the primary address has lat and lon
 * if not, then it will be fetched from GoeCode and updated on BE as well
 * @param permit
 * @returns
 */
export const setPrimaryAddressLatLon = (permit: PermitRequest) => {
  const { primaryAddress } = permit;

  return new Promise((resolve, reject) => {
    if (
      primaryAddress &&
      primaryAddress.address &&
      !primaryAddress.latitude &&
      !primaryAddress.longitude
    ) {
      GeoCode.fromAddress(
        primaryAddress.address,
        envConfig.googleMaps.apiKey
      ).then(
        (response) => {
          if (response && response.results.length > 0) {
            const markerMap: MarkerMap = {
              latitude: response.results[0].geometry.location.lat,
              longitude: response.results[0].geometry.location.lng,
              time: new Date().getTime(),
              address: permit.jobAddress,
              permitId: permit.id,
              thomsBrosTB: permit.thomsBrosTB
            };

            store.dispatch<any>(updatePrimaryAddress(markerMap)).then(
              (res: any) => resolve(res),
              (err: any) => reject(err)
            );
          }
        }, // end of geocode
        (error) => {
          console.error('Error parsing primary address: ', error);
          reject(error);
        }
      );
    } else {
      resolve('Data already updated');
    }
  });
};

/**
 * Returns a copy of an object, with specified keys removed
 *
 * @param obj
 * @param toRemoveKeys
 */
export const removeKeys = <T, >(obj: T, toRemoveKeys: string[]) => {
  const result = {} as T;
  for (const key in obj) {
    if (toRemoveKeys.includes(key) === false) {
      result[key] = obj[key];
    }
  }
  return result;
};

/**
 * Returns true, if string has minimum length
 * @param value
 */
export const isMinLengthOk = (value: string, minLength: number) => {
  // if key is not set, or empty, do not validate
  if (!value) return true;
  if (String(value).length >= minLength) {
    return true;
  }
  return false;
};

/**
 * Returns true, if string is eqal or shorter than given length
 * @param value
 */
export const isMaxLengthOk = (value: string, maxLength: number) => {
  // if key is not set, or empty, do not validate
  if (!value) return true;
  if (String(value).length <= maxLength) {
    return true;
  }
  return false;
};

/**
 * Returns false, if a status is not enabled
 * Context: The DB tracks more statuses than we want to show in this application.
 *
 * @param status
 */
export const getIsStatusEnabled = (status: RequestStatus): boolean => {
  // specifically always allow this status (has usedBy = null)
  if (status.id == PERMIT_PERMIT_INBOX_ID) return true;

  // do not show statuses with usedBy = null
  if (!status.usedBy) return false;

  // do not show these specific status
  if (STATUSES_DISABLED_IDS.includes(status.id)) return false;

  return true;
};

/**
 * Performs correction/formatting actions on received Permit object
 * to match expected shape for storing in state
 *
 * @param p
 */
export const formatPermitData = (p: PermitRequest | null) => {
  if (!p) return p;
  // list of functions to apply
  const list: any[] =
    [
      addSapAccess,
      sanitizeLatitudeLongitude,
      addPrimaryAddress
    ];
  if (list.length === 0) return p;
  return list.reduce((p, fn) => fn(p), p);
};

/**
 * todo: document why needed
 * @param p
 */
const addSapAccess = (p: PermitRequest): PermitRequest => {
  p.hasSapAccess = true;
  return p;
};

/**
 * Construct primaryAddress object and add it to permit
 *
 * @param permit
 */
const addPrimaryAddress = (permit: PermitRequest): PermitRequest => {
  const markerMap: MarkerMap = {
    latitude: permit.latitude ? permit.latitude : '',
    longitude: permit.longitude ? permit.longitude : '',
    time: new Date().getTime(),
    address: permit.jobAddress ? permit.jobAddress : '',
    addressType: 'PRIMARY_ADDRESS',
    permitId: permit.id,
    thomsBrosTB: permit.thomsBrosTB ? permit.thomsBrosTB : ''
  };
  permit.primaryAddress = markerMap;
  return permit;
};

/**
 * If primary address lat/long values are incorrect, remove them.
 * (map shows than the current location as fallback rather than blank map)
 *
 * @param p
 */
const sanitizeLatitudeLongitude = (p: PermitRequest): PermitRequest => {
  if (!p) return p;

  // check valid values
  const isValidLatitude = isBetween([25, 50])(p.latitude);
  const isValidLongitude = isBetween([-125, -70])(p.longitude);

  // reset values, if invalid
  if (!isValidLatitude || !isValidLongitude) {
    p.longitude = '';
    p.latitude = '';
  }

  return p;
};


/**
 * Returns a function, that accepts a value and returns true if it is between
 * the provided min,max parameters
 *
 * @param array range [min, max]
 * @return boolean
 */
const isBetween = (range: number[]) => (value: any) => {
  const [min, max] = range;
  if (max < min) throw new Error('Wrong parameters, first min, then max!');
  const asNumber = Number(value);
  if (asNumber !== 0 && !asNumber) return false;
  return (value > min && value < max);
};


