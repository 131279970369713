import Dialog from '@mui/material/Dialog';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField
} from '@mui/material';
import DialogTitle from '../common/DialogTitle/DialogTitle';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { NeedsActionDialog as NeedsActionDialogModel } from '../../models/needs-action.model';
import ActionType from '../../store/action-types';
import { addPermitStatusAction } from '../../store/action-creators/permit-actions';
import { getNeedsActionNotificationsAction } from '../../store/action-creators/needs-action-actions';
import { makeASCISafe } from '../../utils/formatting-utils';

const NeedsActionDialog = () => {
  const { isOpen, permitId, statusId } = useSelector<
    RootState,
    NeedsActionDialogModel
  >((state) => state.needsAction.needsActionDialog);
  const email = useSelector<RootState, string>(
    (state) => state.auth.currentUser?.email || ''
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [text, setText] = useState('');
  const dispatch = useDispatch();

  // make submit button disabled, if missing required values
  useEffect(() => {
    if (!permitId || !statusId) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [permitId, statusId, isOpen]);

  const handleSubmit = async () => {
    if (text.trim().length === 0) return; // no action on empty text
    setIsUpdating(true);

    try {
      await dispatch(
        addPermitStatusAction(
          permitId as number,
          statusId as number,
          email,
          text
        )
      );

      // refresh items - gets stored notification (with it's assigned id)
      await dispatch(getNeedsActionNotificationsAction());

      setIsUpdating(false);

      // close dialog
      handleCloseDialog();
    } catch (e) {
      alert('Error storing information!');
      setIsUpdating(false);
    }
  };

  const handleCloseDialog = () => {
    setText('');
    dispatch({
      type: ActionType.SET_NEEDS_ACTION_DIALOG,
      payload: {
        isOpen: false,
        permitId: null,
        statusId: null
      }
    });
  };

  const handleCancelClick = () => {
    handleCloseDialog();
  };

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setText(makeASCISafe(e.target.value));
  };

  return (
    <Dialog maxWidth="lg" open={isOpen} onBackdropClick={handleCloseDialog}>
      <DialogTitle onClose={handleCloseDialog}>
        Additional Information Request
      </DialogTitle>
      <DialogContent>
        <p>
          The requestor will be alerted that they need to provide more
          information for this request.
          <br />
          Please provide specifics regarding this request.
        </p>
        <div>
          <TextField
            id="needs-action-textfield"
            multiline
            rows={5}
            value={text}
            onChange={handleChange}
            fullWidth={true}
            placeholder={
              'Please provide details regarding your request for more information...'
            }
          />
        </div>
        <Box sx={{ mt: 2, mb: -2 }}>
          Alternatively, you can create a discussion in the &quot;Comments&quot;
          section.
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>Cancel</Button>
        <Button
          disabled={isUpdating || isDisabled}
          variant="contained"
          onClick={handleSubmit}
        >
          Update
        </Button>
      </DialogActions>
      ;
    </Dialog>
  );
};

export default NeedsActionDialog;
