const baseUrl = `https://${process.env.REACT_APP_GATEWAY_API_ENV}.execute-api.${process.env.REACT_APP_COGNITO_REGION}.amazonaws.com/${process.env.REACT_APP_ENV}`;
const isProduction = process.env.REACT_APP_ENV === 'prd';
const envConfig = {
  headers: {
    contentType: 'application/json',
    apiKey: `${process.env.REACT_APP_GATEWAY_API_KEY}`
  },
  isProduction,
  baseUrl: baseUrl,
  api: {
    permits: `${baseUrl}/permits`,
    getAllFilters: `${baseUrl}/getallfilters`,
    getPermits: `${baseUrl}/permits/getPermits`,
    getPermit: `${baseUrl}/permits/getPermit`,
    getUsers: `${baseUrl}/getUsers`,
    createPermit: `${baseUrl}/permits/createPermit`,
    updatePermit: `${baseUrl}/permits/updatePermit`,
    getPermitsByJobId: `${baseUrl}/permits/getPermitsJobId`,
    getNeedsAct: `${baseUrl}/getNeedsAct`,
    getPermitsExport: `${baseUrl}/permits/getPermitsExport`,
    scheduleEmail: `${baseUrl}/scheduleEmail`,
    multipleAgencies: `${baseUrl}/permits/multipleAgencies`,
    spinoffNewPermit: `${baseUrl}/permits/spinoffNewPermit`,
    getSAPAttachments: `${baseUrl}/permits/getSapAttachments`
  },
  googleMaps: {
    apiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`
  },
  // cognito: {
  //   domain: process.env.REACT_APP_COGNITO_DOMAIN,
  //   poolData: {
  //     userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  //     clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  //     region: process.env.REACT_APP_COGNITO_REGION
  //   }
  // },
  port: process.env.NODE_ENV === 'production' ? '3000' : 3001,
  k2Attachments: {
    url: isProduction
      ? 'https://sempra.sharepoint.com/teams/constructionservices/PERMITS%20Requests/'
      : 'https://sempra.com/tstattachment/',
    permitNumber: 63206,
    spunMaxParentPermitID: 65617
  },

  auth: {
    region: process.env.REACT_APP_COGNITO_REGION
      ? process.env.REACT_APP_COGNITO_REGION
      : 'us-west-2',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID
      ? process.env.REACT_APP_COGNITO_USER_POOL_ID
      : 'us-west-2_Kk4hCaMF3',
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
      ? process.env.REACT_APP_COGNITO_CLIENT_ID
      : '1aegl84o5dvm9v899lobivvac4',
    oauth: {
      domain:
        `${process.env.REACT_APP_COGNITO_DOMAIN}.auth.${process.env.REACT_APP_COGNITO_REGION}.amazoncognito.com` ||
        'dev-af-gperm-socalgas.auth.us-west-2.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'openid',
        'aws.cognito.signin.user.admin',
        'profile'
      ],
      redirectSignIn: isProduction
        ? 'https://af-gperm.socalgas.com/'
        : `https://${process.env.REACT_APP_ENV}.af-gperm.socalgas.com/`,
      redirectSignOut: isProduction
        ? 'https://af-gperm.socalgas.com/'
        : `https://${process.env.REACT_APP_ENV}.af-gperm.socalgas.com/`,
      responseType: 'code'
    }
  }
};

export default envConfig;
