import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaceIcon from '@mui/icons-material/Place';

import './Location.scss';
import { PermitRequest } from 'src/models/permit.model';
import Map from './Map/Map';
import Search from 'src/components/PermitForm/Location/Search';
import * as permitActions from 'src/store/action-creators/permit-actions';
import { MarkerMap } from 'src/models/marker-map.model';
import { LocationForm, Text } from 'src/models/form.model';
import { LoadingOverlay } from 'src/components/utils/LoadingOverlay/LoadingOverlay';
import { connect, ConnectedProps } from 'react-redux';
import { setPrimaryAddressLatLon } from 'src/utils/permit-utils';
import { SUPPORTING_ADDRESS } from 'src/constants/addresses';
import classNames from 'classnames';
import { handleEnterKeyDown } from 'src/utils/handlers-utils';

interface Props extends PropsFromRedux {
  isEditable?: boolean;
  permit: PermitRequest;
}

const dialogMapOptions = {
  disableDefaultUI: true,
  zoomControl: false,
  mapContainerStyle: {
    width: '100%',
    height: '100%',
    borderRadius: '0px'
  },
  mapTypeId: 'hybrid'
};

const Location = (props: Props) => {
  const {
    isEditable,
    permit,
    permit: { primaryAddress, supportingAddress },
    updatePermitLocation
  } = props;

  const showInMapClicked = () => {
    //window.open('https://maps.google.com?q='+locationForm.primaryAddress?.latitude+','+locationForm.primaryAddress?.longitude);
    window.open(
      'https://maps.google.com/maps?q=&layer=c&cbll=' +
        locationForm.primaryAddress?.latitude +
        ',' +
        locationForm.primaryAddress?.longitude
    );
  };

  const locationTileRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [locationForm, setLocationForm] = useState<LocationForm>({
    primaryAddress: null,
    thomsBros: '',
    supportingAddresses: []
  });
  const mapRef = useRef<any>();

  useEffect(() => {
    resetForm(permit);
    setPrimaryAddressLatLon(permit).catch(console.error);
  }, [permit]);

  const resetForm = (permit: PermitRequest) => {
    setLocationForm({
      primaryAddress: permit.primaryAddress,
      thomsBros: primaryAddress.thomsBrosTB || '',
      supportingAddresses: permit.supportingAddress
    });
  };

  const handleCloseDialog = (reset: boolean) => {
    setOpen(false);

    if (reset) {
      resetForm(permit);
    }
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const panTo = (latlng: any) => {
    try {
      mapRef.current?.panTo(latlng);
    } catch (error) {
      console.error('Error creating marker: ', error);
    }

    mapRef.current?.setZoom(6);
  };

  const handleThomsBrosChange = (value: string) => {
    setLocationForm({ ...locationForm, thomsBros: value });
  };

  const handlePrimaryAddrChange = (marker: MarkerMap) => {
    setLocationForm({ ...locationForm, primaryAddress: marker });
  };

  const handleSuppAddrAddEmpty = () => {
    const markerMap = {
      time: Date.now(),
      addressType: SUPPORTING_ADDRESS
    } as MarkerMap;
    setLocationForm((prevState) => ({
      ...prevState,
      supportingAddresses: [...prevState.supportingAddresses, markerMap]
    }));
  };

  const handleSuppAddrChange = (marker: MarkerMap) => {
    const suppAddrsUpdated = locationForm.supportingAddresses.map((item) =>
      item.time === marker.time ? { ...item, ...marker } : item
    );
    setLocationForm({ ...locationForm, supportingAddresses: suppAddrsUpdated });
  };

  const handleSuppAddrRemove = (marker: MarkerMap) => {
    const supportingAddresses = locationForm.supportingAddresses.filter(
      (address) => address.time !== marker.time
    );
    setLocationForm((prevState) => ({
      ...prevState,
      supportingAddresses
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (locationForm.primaryAddress) {
      // get rid of empty supporting addresses
      const definedSuppAddrs = locationForm.supportingAddresses.filter(
        (item) => item.address
      );

      setIsLoadingSubmit(true);
      updatePermitLocation(
        String(permit.id),
        locationForm.primaryAddress,
        locationForm.thomsBros,
        definedSuppAddrs
      ).then(
        () => {
          setIsLoadingSubmit(false);
          handleCloseDialog(false);
        },
        () => {
          setIsLoadingSubmit(false);
        }
      );
    }
  };

  // add supporting address by clicking on map
  const handleSuppAddrAdd = (markerMap: MarkerMap) => {
    setLocationForm((prevState) => ({
      ...prevState,
      supportingAddresses: [...prevState.supportingAddresses, markerMap]
    }));
  };

  return (
    <>
      <section
        className={classNames('permit-location')}
        //className={classNames('permit-location', { editable: isEditable })}
        tabIndex={0}
        onKeyDown={(event) => handleEnterKeyDown(event, locationTileRef)}
      >
        <Grid container sx={{ height: '100%' }}>
          <Grid
            item
            xs={6}
            md={5}
            ref={locationTileRef}
            //onClick={() => handleOpenDialog()}
          >
            <Typography sx={{ fontWeight: 'bold' }} variant="h5">
              Location
            </Typography>
            <article className="permit-location-data">
              {/* Primary address */}
              <div className="address-list">
                <div className="list-title">Primary Address</div>
                <div className="list-item">
                  <PlaceIcon htmlColor="#CB4539" />
                  <span title={primaryAddress.address}>
                    {primaryAddress.address || Text.NA}
                  </span>
                </div>
              </div>

              {/* Supportin address */}
              <div className="address-list">
                <div className="list-title">Additional Addresses</div>
                {supportingAddress.length == 0 ? (
                  <div className="list-item">{Text.NA}</div>
                ) : (
                  supportingAddress.map((marker) => (
                    <div className="list-item" key={marker.id}>
                      <PlaceIcon htmlColor="rgba(255, 107, 0, 0.54)" />
                      <span>{marker.address}</span>
                    </div>
                  ))
                )}
              </div>

              {/* Thomas bros */}
              <div className="address-list">
                <div className="list-title">Thomas Bros</div>
                <div className="list-item">
                  <span>{permit.primaryAddress.thomsBrosTB}</span>
                </div>
              </div>
            </article>
            <div className="location-btn-box">
              <hr />
              {/* Edit Map Btn */}
              {props.permit.requestStatusId != 21 && (
                <Button
                  id="edit-map"
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenDialog()}
                >
                  Edit Location
                </Button>
              )}

              <br />
              <br />
              {/* Stree View Btn */}
              <Button
                id="street-view-btn"
                variant="contained"
                color="primary"
                onClick={() => showInMapClicked()}
              >
                Street View
              </Button>
            </div>
          </Grid>
          <Grid item xs={6} md={7}>
            <section className="permit-location-map">
              <Map
                primaryAddress={primaryAddress}
                supportingAddress={supportingAddress}
              ></Map>
            </section>
          </Grid>
        </Grid>
      </section>

      <Dialog
        className="location-dialog"
        fullWidth
        maxWidth="md"
        open={Boolean(isEditable) && open}
        onBackdropClick={() => handleCloseDialog(true)}
        onClose={() => handleCloseDialog(true)}
      >
        <LoadingOverlay isVisible={isLoadingSubmit} />

        <DialogContent>
          <h2 className="location-header">
            <strong>Location</strong>
          </h2>
          <Grid container sx={{ minHeight: '60vh', height: '100%' }}>
            <Grid item xs={7}>
              <Map
                permitId={permit.id}
                primaryAddress={locationForm.primaryAddress}
                supportingAddress={locationForm.supportingAddresses}
                options={dialogMapOptions}
                addSupportingAddress={handleSuppAddrAdd}
              ></Map>
            </Grid>
            <Grid item xs={5}>
              <div className="location-dialog-content-right">
                <form
                  id="location-adresses-form"
                  className="addresses-form"
                  onSubmit={handleSubmit}
                >
                  <div className="primary-address">
                    <Search
                      label="Primary Address"
                      panTo={panTo}
                      addupdateMarker={handlePrimaryAddrChange}
                      marker={locationForm.primaryAddress}
                      required
                      startAdornment={
                        <InputAdornment position="end">
                          <PlaceIcon className="icon-lat-long" />
                        </InputAdornment>
                      }
                    />

                    <FormControl variant="outlined" size="small">
                      <InputLabel htmlFor="lat-long-input">Lat/Long</InputLabel>
                      <OutlinedInput
                        id="lat-long-input"
                        label="Lat/Long"
                        name="latLong"
                        disabled={true}
                        value={
                          locationForm.primaryAddress &&
                          locationForm.primaryAddress.latitude &&
                          locationForm.primaryAddress.longitude &&
                          `${locationForm.primaryAddress.latitude}, ${locationForm.primaryAddress.longitude}`
                        }
                        startAdornment={
                          <InputAdornment position="start">
                            <PlaceIcon className="icon-lat-long" />
                          </InputAdornment>
                        }
                      />
                    </FormControl>

                    <FormControl variant="outlined" size="small">
                      <InputLabel htmlFor="thomas-bros-input">
                        Thomas Brothers
                      </InputLabel>
                      <OutlinedInput
                        id="thomas-bros-input"
                        label="Thomas Brothers"
                        name="thomsBros"
                        value={locationForm.thomsBros}
                        onChange={(event) =>
                          handleThomsBrosChange(event.target.value)
                        }
                      />
                    </FormControl>
                  </div>

                  <div className="supporting-addresses">
                    <div className="title">Supporting Addresses</div>

                    {locationForm.supportingAddresses.map(
                      (marker: MarkerMap) => (
                        <div className="address" key={marker.time}>
                          <div>
                            <Search
                              label="Address"
                              panTo={panTo}
                              addupdateMarker={handleSuppAddrChange}
                              marker={marker}
                            />
                            {marker && marker.latitude && marker.longitude && (
                              <div className="supporting-addr-latlng">
                                <PlaceIcon className="icon-supporting-addresses" />
                                <span>
                                  {marker.latitude}, {marker.longitude}
                                </span>
                              </div>
                            )}
                          </div>
                          <IconButton
                            title="Remove Address"
                            onClick={() => handleSuppAddrRemove(marker)}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        </div>
                      )
                    )}

                    <div>
                      <IconButton
                        className="add-button"
                        title="Add Address"
                        onClick={handleSuppAddrAddEmpty}
                      >
                        <AddCircleIcon color="primary" />
                      </IconButton>
                    </div>
                  </div>
                </form>
                <div className="location-dialog-actions">
                  <DialogActions>
                    <Button onClick={() => handleCloseDialog(true)}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      form="location-adresses-form"
                      type="submit"
                    >
                      Update
                    </Button>
                  </DialogActions>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapDispatchToProps = {
  updatePermitLocation: permitActions.updatePermitLocationAction
};
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Location);
