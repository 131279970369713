import { Autocomplete, TextField } from '@mui/material';

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from 'use-places-autocomplete';
import './Location.scss';
import { MarkerMap } from '../../../models/marker-map.model';
import { useState } from 'react';

interface SearchProps {
  disabled?: boolean;
  label: string;
  marker?: MarkerMap | null;
  required?: boolean;
  startAdornment?: JSX.Element;
  addupdateMarker: (updateMarker: MarkerMap) => void;
  panTo: (latlng: any) => void;
}

const Search = (props: SearchProps) => {
  const {
    disabled,
    label,
    marker,
    required,
    startAdornment,
    addupdateMarker,
    panTo
  } = props;
  const {
    suggestions: { data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete();

  const [searchAddress, setSearchAddress] = useState(marker?.address || '');

  const handleInput = (value: string) => {
    setValue(value);
    setSearchAddress(value);
  };

  const handleSelect = async (address: string | null) => {
    if (address) {
      setSearchAddress(address);
      setValue(address, false);
      clearSuggestions();

      try {
        const results = await getGeocode({ address });
        const latlng = await getLatLng(results[0]);
        //console.log(panTo);
        panTo(latlng);
        addupdateMarker({
          id: marker?.id,
          permitId: marker?.permitId,
          latitude: String(latlng.lat),
          longitude: String(latlng.lng),
          time: marker?.time,
          address: address
        });
      } catch (error) {
        console.error('😱 Error: ', error);
      }
    }
  };

  return (
    <Autocomplete
      size="small"
      freeSolo
      disabled={disabled}
      value={searchAddress}
      onChange={(event, value) => handleSelect(value)}
      options={data.map((option) => option.description)}
      popupIcon={''}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          onChange={(event) => handleInput(event.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: startAdornment
          }}
        />
      )}
    />
  );
};

export default Search;
