import './CommentItem.scss';
import { Avatar, Button } from '@mui/material';
import { Box } from '@mui/system';
import classNames from 'classnames';
import { Comment, USER_TAG_REGEXP } from 'src/models/comment.model';
import * as actions from 'src/store/action-creators/comment-actions';
import moment from 'moment';
import { useEffect, useState } from 'react';
import CommentTextbox from '../CommentTextbox/CommentTextbox';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/store/store';
import { makeASCISafe } from '../../../../utils/formatting-utils';

/**
 * Comment item with all actions and Edit and Reply functionality
 */

interface Props extends PropsFromRedux {
  comment: Comment;
  isReply: boolean;
  permitId: string;
  position: number;
}

const CommentItem = (props: Props) => {
  const { comment, currentUser, isReply, permitId, position, addCommentReply } =
    props;

  const [commentText, setCommentText] = useState<(JSX.Element | string)[]>([]);
  const [isReplyboxVisible, setIsReplyboxVisible] = useState(false);
  const [isLoadingReplyCreate, setIsLoadingReplyCreate] = useState(false);
  const [replyText, setReplyText] = useState('');

  useEffect(() => {
    setCommentText(processTagText(comment.text));
  }, [comment.text]);

  useEffect(() => {
    setReplyText('');
  }, [isReplyboxVisible]);

  // Comment reply create
  const handleCommentReplyCreate = (text: string) => {
    if (text === '') {
      setIsReplyboxVisible(false);
    }

    if (currentUser) {
      setIsLoadingReplyCreate(true);
      const reply = {
        text: text,
        replyTo: comment.id
      };

      addCommentReply(permitId, reply, position + 1).then(
        () => {
          setIsLoadingReplyCreate(false);
          setIsReplyboxVisible(false);
        },
        () => setIsLoadingReplyCreate(false)
      );
    }
  };

  // will add some styling to tagged users
  const processTagText = (text: string) => {
    const matches = Array.from(text.matchAll(USER_TAG_REGEXP));
    const taggedUserNames = matches.map((item) => item[1]);
    const splitted = text.split(USER_TAG_REGEXP);
    const result: (JSX.Element | string)[] = [];

    splitted.forEach((item, index) => {
      if (taggedUserNames.includes(item)) {
        result.push(
          <Box
            key={index}
            component="span"
            sx={{
              color: (theme) =>
                isReply ? 'white' : theme.palette.primary.main,
              fontWeight: 'bold'
            }}
          >
            @{item}
          </Box>
        );
      } else {
        result.push(item);
      }
    });

    return result;
  };

  return (
    <div className="comment-item">
      {/* When comment is removed, then display this message over that item */}
      {comment.isRemoved && (
        <div className="is-removed-message">
          This Comment was removed by its author.
        </div>
      )}

      {/* Comment header with User information */}
      <div className={classNames('header', { reply: isReply })}>
        <Avatar className="avatar">{comment.author.initials}</Avatar>
        <div className="details">
          <div className="name">{comment.author.name}</div>
          <div className="date">
            {moment(comment.createdAt).format('M/D/YYYY - HH:mm')}
          </div>
        </div>

        {isReply && (
          <Box
            className="reply-bar"
            sx={{ backgroundColor: (theme) => theme.palette.primary.main }}
          ></Box>
        )}
      </div>

      {/* Comment Displayed text */}
      <Box
        className="content"
        sx={{
          backgroundColor: (theme) =>
            isReply ? theme.palette.primary.main : '#f1f4f9',
          color: isReply ? 'white' : 'inherit'
        }}
      >
        {commentText}
      </Box>

      {/* Display actions below only if comment is NOT removed */}
      {!comment.isRemoved && (
        <>
          {/* Comment Reply textbox */}
          {isReplyboxVisible ? (
            <CommentTextbox
              className="reply-textbox"
              editbox
              isLoading={isLoadingReplyCreate}
              placeholder="Type your reply..."
              value={replyText}
              onCancel={() => setIsReplyboxVisible(false)}
              onChange={(event) =>
                setReplyText(makeASCISafe(event.target.value))
              }
              onSubmit={(text) => handleCommentReplyCreate(text)}
            />
          ) : (
            //  Action Buttons for each comment
            <div className="actions">
              <Button
                className="reply-button"
                size="small"
                variant="text"
                onClick={() => setIsReplyboxVisible(true)}
              >
                Reply
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUser: state.auth.currentUser
});

const mapDispatchToProps = {
  addCommentReply: actions.addCommentReplyAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CommentItem);
