import moment from 'moment-timezone';
import {
  ROLE_MUNI_ADVISOR,
  ROLE_PSS,
  ROLE_QA_QC,
  ROLE_SUPERVISOR,
  ROLE_TCP,
  ROLE_TS
} from 'src/constants/coordinator-roles';
import { UserRole } from 'src/models/user-role.model';
import { replaceDiacritics } from './normalize-diactritics';

// us date default format
export const DATEFORMAT_USDATE = 'MM/DD/YYYY';
export const DATEFORMAT_DBDATE = 'YYYY-MM-DD HH:mm:ss';
export const TIMEFORMAT_USDATE = 'hh:mm:ss A';

/**
 * Returns formatted date.
 *
 * If a nullish value is provided, a nullish value is returned (needed
 * for e.g. unsetting a date)
 *
 * @param s
 * @param dateFormat
 */
export const formatDateTime = (
  s: string | Date | null | undefined,
  dateFormat = DATEFORMAT_USDATE
) => {
  if (!s) {
    return s;
  }
  const dateUTC = moment(s).tz('Etc/UTC');
  const dateUTCFormat = dateUTC.format(dateFormat);
  return dateUTCFormat;
};

/**
 * Returns EN-US Date
 * @param s
 */
export const formatUsDate = (s: string | Date) => {
  return new Intl.DateTimeFormat('en-US').format(new Date(s));
};

/**
 * Returns UTC formatted date.
 *
 * If a nullish value is provided, a nullish value is returned (needed
 * for e.g. unsetting a date)
 *
 * @param s
 * @param dateFormat
 */
export const formatUTCDateTime = (
  s: string | Date | null | undefined,
  dateFormat = DATEFORMAT_USDATE
) => {
  if (!s) {
    return s;
  }
  return moment.utc(s).format(dateFormat);
};

/**
 * Returns formatted time part from date string/object.
 *
 * If a nullish value is provided, a nullish value is returned (needed
 * for e.g. unsetting a date)
 *
 * @param s
 * @param excludeTimezone
 * @param timeFormat
 */
export const formatTime = (
  s: string | Date | null | undefined,
  excludeTimezone = false,
  timeFormat = TIMEFORMAT_USDATE
) => {
  if (!s) {
    return s;
  }
  let result = moment(s);
  if (excludeTimezone) {
    result = result.parseZone();
  }
  return result.format(timeFormat);
};

/**
 * Formats provided string or number like USD currency. Removes cents.
 *
 * @param s
 */
export const formatCurrency = (s: string | number) => {
  // do not process undefined or nullish values
  if (!s && s !== 0) {
    return '';
  }

  // do not process empty values
  if (String(s).trim().length === 0) {
    return '';
  }

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
  });
  return currencyFormatter.format(typeof s == 'number' ? s : parseFloat(s));
};

/**
 * formats the agency information to be in a format compatiable with updatepermits lambda function
 *
 * @param obj
 */
export const transformAgencyObj = (obj: object) => {
  const result = {
    primarAgencyId: null,
    secondAgencyId: null,
    thirdAgencyId: null,
    fourthAgencyId: null
  };
  const arrOfValues = Object.values(obj);
  for (let i = 0; i < arrOfValues.length; i++) {
    if (i === 0 && arrOfValues[i]) {
      result['primarAgencyId'] = arrOfValues[i];
    } else if (i === 1 && arrOfValues[i]) {
      result['secondAgencyId'] = arrOfValues[i];
    } else if (i === 2 && arrOfValues[i]) {
      result['thirdAgencyId'] = arrOfValues[i];
    } else if (i === 3 && arrOfValues[i]) {
      result['fourthAgencyId'] = arrOfValues[i];
    }
  }
  return result;
};

/**
 * Asci String sanitization
 * - Replaces diacritics with asci chars, if known (é => e)
 * - Removes emojis and other non-asci chars
 *
 * @param s
 */
export const makeASCISafe = (s = '') => {
  return replaceDiacritics(s).replace(/[^(\r\n|\r|\n),^\x20-\x7E]/g, '');
};

/**
 * Special characters sanitization for S3 key
 * - Replaces diacritics with asci chars, if known (é => e)
 * - Removes emojis and other non-asci chars
 * - Replaces all S3 key unsafe characters with "-"
 *
 * @param s
 */
export const makeS3KeySafe = (s = '') => {
  const TO_REPLACE = [
    '&',
    '$',
    '@',
    '=',
    ';',
    '/',
    ':',
    '+',
    ',',
    ' ',
    '?',
    '\\',
    '{',
    '^',
    '}',
    '%',
    '`',
    ']',
    '>',
    '[',
    '~',
    '<',
    '#',
    '|',
    '"',
    "'"
  ];
  const regexFromArray = new RegExp(TO_REPLACE.join('|\\'), 'gi');
  return makeASCISafe(s).replace(regexFromArray, '-');
};

/**
 * Sorts objects in provided array by the "name" property Ascending (a->z)
 *
 * @param a
 * @param b
 */
export const sortByName = (a: any, b: any) => (a.name > b.name ? 1 : -1);

/**
 * Creates name initials for provided name
 * - splits string by spaces
 * - returns first letter of each splitted word
 *
 * @param name
 */
export const getUserNameInitials = (name: string) => {
  const nameArray = (name || '').split(' ');
  const initialsArray = nameArray.map((word: string) => word.substring(0, 1));
  return initialsArray.join('');
};

/**
 * Create role initials for provided role string
 *
 * @param role
 */
export const getUserRoleInitials = (role: UserRole | string) => {
  let result = '';
  switch (role) {
    case ROLE_SUPERVISOR:
    case UserRole.ADMIN:
      result = 'ADMIN';
      break;
    case ROLE_QA_QC:
    case UserRole.QAQC:
      result = 'QA/QC';
      break;
    case ROLE_PSS:
    case UserRole.PSS1:
      result = 'PSS';
      break;
    case ROLE_TCP:
    case UserRole.TCP:
      result = 'TCP';
      break;
    case ROLE_TS:
    case UserRole.TS:
      result = 'TS';
      break;
    case ROLE_MUNI_ADVISOR:
      result = 'MA';
      break;
    case UserRole.REQUESTOR:
      result = 'REQUESTOR';
      break;
    default:
      break;
  }
  return result;
};
