import { Box, Button, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import { NeedsAction } from '../../models/needs-action.model';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { updateNeedsActionNotificationsAction } from '../../store/action-creators/needs-action-actions';
import { removePermitStatusAction } from '../../store/action-creators/permit-actions';
import { RootState } from 'src/store/store';
import { User } from 'src/models/user.model';
import { PermitDashboard, PermitRequest } from 'src/models/permit.model';
import {
  checkIsAuthorOfNeedsAct,
  checkIsRequestorOfPermit
} from 'src/utils/authorize-utils';
import { UserRole } from 'src/models/user-role.model';

interface NeedsActionItemProps {
  needsActionItem: NeedsAction;
  permitId: number;
}

export const NeedsActionItem = (props: NeedsActionItemProps) => {
  const { needsActionItem } = props;

  const dispatchP = useDispatch<(a: any) => Promise<any>>();
  const currentUser = useSelector<RootState, User | null>(
    (state) => state.auth.currentUser
  );
  const currentPermit = useSelector<
    RootState,
    PermitDashboard | PermitRequest | null
  >(
    (state) =>
      state.permit.permits.find(
        (item) => item.id === needsActionItem.permitRequestId
      ) ||
      (state.permit.permit?.id === needsActionItem.permitRequestId
        ? state.permit.permit
        : null)
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const canEdit = false;
  const canResolve =
    currentUser?.role === UserRole.ADMIN ||
    currentUser?.role === UserRole.PSS1 ||
    currentUser?.role === UserRole.QAQC ||
    currentUser?.role === UserRole.TS ||
    currentUser?.role === UserRole.TCP ||
    currentUser?.role === UserRole.REQUESTOR ||
    checkIsRequestorOfPermit(currentUser, currentPermit) ||
    checkIsAuthorOfNeedsAct(currentUser, needsActionItem);

  const updateItem = (updatedItem: NeedsAction) => {
    setIsUpdating(true);
    dispatchP(
      updateNeedsActionNotificationsAction(
        props.permitId,
        needsActionItem.id,
        updatedItem
      )
    ).then(
      () => setIsUpdating(false),
      () => setIsUpdating(false)
    );
    dispatchP(removePermitStatusAction(updatedItem.relatedStatusId));
  };

  useEffect(() => {
    setIsUpdating(false);
  }, []);

  const resolveHandler = () => {
    const updatedItem: NeedsAction = {
      ...needsActionItem,
      ...{ resolved: true }
    };
    updateItem(updatedItem);
  };

  return (
    <Box key={needsActionItem.id} mx={5} mt={2}>
      <Button className="notification-label">Needs Action</Button>
      <Grid
        container
        className="notification-content"
        px={4}
        pb={2}
        pt={3}
        mt={-2}
        borderRadius={3}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item xs>
          <Grid container direction={'column'} pr={1}>
            <Grid item xs={'auto'} className={'notification-author'}>
              Additional information Requested by {needsActionItem.authorName}
            </Grid>
            <Grid item className={'notification-text'}>
              {needsActionItem.text}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'flex-end'}
            direction={'row'}
          >
            {canEdit && (
              <IconButton>
                <EditIcon />
              </IconButton>
            )}
            <div
              title={
                canResolve
                  ? ''
                  : 'This item can be only resolved by its Author or Requestor'
              }
            >
              <Button
                disabled={isUpdating || !canResolve}
                variant="contained"
                color="primary"
                onClick={resolveHandler}
              >
                Resolve
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
