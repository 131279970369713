import { useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import List from '@mui/material/List';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import './Contacts.scss';
import { User, Watcher } from 'src/models/user.model';
import { Text } from 'src/models/form.model';
import BootstrapDialogTitle from '../components/DialogTitle/DialogTitle';
import * as commentActions from 'src/store/action-creators/comment-actions';
import * as permitActions from 'src/store/action-creators/permit-actions';
import { RootState } from 'src/store/store';
import { PermitRequest } from 'src/models/permit.model';
import { LoadingOverlay } from 'src/components/utils/LoadingOverlay/LoadingOverlay';
import { LightTooltip } from '../components/ToolTip/ToolTip';
import { requestorByEmailSelector } from '../../../store/selectors/users-selectors';
import ContactItem from './Contact/ContactItem';
import { handleEnterKeyDown } from 'src/utils/handlers-utils';

interface Props extends PropsFromRedux {
  permit: PermitRequest;
  permitId: string;
}

const Contacts = (props: Props) => {
  const {
    permit: { createdByEmailID },
    permitId,
    users,
    setCommentTagUser,
    updatePermit
  } = props;

  const contactsTileRef = useRef(null);
  const requestor = useSelector<RootState, User | null>((state) =>
    requestorByEmailSelector(state, createdByEmailID)
  );
  const [watchers, setWatchers] = useState<(Watcher | undefined)[]>([]);
  const [modalWatchers, setModalWatchers] = useState<(Watcher | undefined)[]>(
    []
  );
  const [mainContact, setMainContact] = useState<Watcher | null>(null);
  const [mainContactId, setMainContactId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect((): void => {
    const mainContact = watchers.find(
      (watcher: Watcher | undefined) => watcher && watcher.isMainContact
    );
    setWatchers(props.permit.watchers);
    setModalWatchers(props.permit.watchers);
    mainContact &&
      setMainContact(mainContact) &&
      setMainContactId(mainContact.id);
  }, []);

  const invokeUpdateWatchers = (watchers: (Watcher | undefined)[]): void => {
    setIsLoading(true);
    updatePermit(permitId, { watchers: watchers })
      .then(
        () => (setIsLoading(false), setOpen(false)),
        () => (setIsLoading(false), setOpen(false))
      )
      .then(() => (setWatchers(watchers), setModalWatchers(watchers)));
  };

  const handleOnUpdate = (): void => {
    invokeUpdateWatchers(modalWatchers);
  };

  const handleOnClose = (): void => {
    setWatchers(props.permit.watchers);
    setModalWatchers(props.permit.watchers);
    setOpen(false);
  };

  // **KANE COMMENT OUT
  // const handleOpenDialog = (
  //   event: React.MouseEvent<HTMLElement> & { target: Element }
  // ): void => {
  //   if (event.target.id !== 'stop-open') {
  //     setOpen(true);
  //   }
  // };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleMainContactChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const watcherId = event.target.value;
    setMainContactId(watcherId);
    let changedWatchers = [...modalWatchers];
    changedWatchers = changedWatchers.map((item) => {
      if (!item) {
        return item;
      }
      if (item.id === watcherId) {
        return { ...item, isMainContact: true };
      } else {
        return { ...item, isMainContact: false };
      }
    });
    setModalWatchers(changedWatchers);
  };

  const handleWatcherRemove = (position: number): void => {
    const changedWatchers = [...modalWatchers];
    if (changedWatchers[position]?.id === mainContactId) {
      setMainContactId('');
    }
    changedWatchers.splice(position, 1);
    setModalWatchers(changedWatchers);
  };

  const handleWatcherAdd = (): void => {
    setModalWatchers([...modalWatchers, undefined]);
  };

  const handleModalWatcherChange = (position: number, value: Watcher): void => {
    const changedModalWatchers = [...modalWatchers];
    changedModalWatchers[position] = { ...value, isMainContact: false };
    setModalWatchers(changedModalWatchers);
  };

  return (
    <>
      <section
        className="permit-contacts"
        tabIndex={0}
        ref={contactsTileRef}
        onKeyDown={(event) => handleEnterKeyDown(event, contactsTileRef)}
      >
        <header>
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            Contacts
          </Typography>
        </header>
        <article>
          <section className="permitTeam">
            <List subheader={<span>Permit Team</span>}>
              {props.permit.assignedUsers.map((index) => (
                <li key={index.email}>
                  <strong>{index.name}</strong>
                </li>
              ))}
            </List>
          </section>
          <section className="manager">
            <List subheader={<span>Requestor</span>}>
              {requestor && (
                <ContactItem
                  user={requestor}
                  onTagUserClick={() => setCommentTagUser(requestor)}
                />
              )}
            </List>
          </section>
          <section className="watchers">
            {watchers.length == 0 && <p>{Text.NA}</p>}
            <List
              sx={{ bgcolor: 'background.paper' }}
              subheader={<span>Watchers</span>}
            >
              {watchers &&
                watchers.map(
                  (watcher: Watcher | undefined) =>
                    watcher && (
                      <ContactItem
                        key={watcher.id}
                        user={watcher}
                        mainContact={watcher.isMainContact}
                        onTagUserClick={() => setCommentTagUser(watcher)}
                      />
                    )
                )}
            </List>
          </section>
        </article>

        {/* <section className="manager">
            <List subheader={<span>TCP</span>}>
              {requestor}
            </List>
          </section> */}

        <div className="location-btn-box">
          {/* Edit Contact Btn */}
          <Button
            id="edit-contact"
            variant="contained"
            color="primary"
            onClick={() => handleOpenDialog()}
          >
            Edit Contact
          </Button>
        </div>
      </section>

      <Dialog
        className="contacts-edit-dialog"
        fullWidth
        maxWidth="lg"
        open={open}
        onBackdropClick={handleOnClose}
        onClose={handleOnClose}
      >
        <BootstrapDialogTitle onClose={handleOnClose}>
          <strong>Contacts</strong>
        </BootstrapDialogTitle>
        <DialogContent>
          <section className="requestor">
            <div>
              <mark>Requestor</mark>
              <span>{requestor?.name || Text.NA}</span>
            </div>
            <div className="requestor-graphic">
              <Divider className="divider" />
              <div className="info">
                <span>{requestor?.email || Text.NA}</span>
                <span>{requestor?.phone || Text.NA}</span>
                <div>
                  <IconButton
                    color="primary"
                    title="Tag user in a new comment"
                    onClick={() => requestor && setCommentTagUser(requestor)}
                  >
                    <ChatBubbleOutlineIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          </section>
          <section className="watchers">
            <span>Add anyone who needs to view this request</span>
            <div className="watchers">
              <LoadingOverlay isVisible={isLoading} />

              <div className="title">Watchers</div>
              <RadioGroup
                value={mainContactId}
                name="mainProjectContact"
                onChange={handleMainContactChange}
              >
                <table>
                  <tbody>
                    {modalWatchers.map((watcher, index) => (
                      <tr className="watcher" key={watcher?.id || index}>
                        <td>
                          <Autocomplete
                            popupIcon={''}
                            disableClearable
                            onChange={(event, value) =>
                              handleModalWatcherChange(index, value)
                            }
                            size="small"
                            disablePortal
                            options={users as Watcher[]}
                            value={watcher}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            filterOptions={(options, state) =>
                              options.filter(
                                (option) =>
                                  !modalWatchers.some(
                                    (watcher) => watcher?.id === option.id
                                  ) &&
                                  option.name
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Name"
                                required
                                InputLabelProps={{ required: false }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {watcher && (
                                        <InputAdornment position="end">
                                          <LightTooltip
                                            title={
                                              <div className="contacts-tooltip-content">
                                                <span className="tooltip-text">
                                                  {watcher.phone ||
                                                    'w/o phone number'}
                                                </span>
                                                <div>
                                                  <IconButton
                                                    color="primary"
                                                    onClick={() => {
                                                      navigator.clipboard.writeText(
                                                        watcher.phone || ''
                                                      );
                                                    }}
                                                  >
                                                    <ContentCopyIcon className="tooltip-copy-icon" />
                                                  </IconButton>
                                                </div>
                                              </div>
                                            }
                                          >
                                            <IconButton color="primary">
                                              <PhoneIphoneIcon />
                                            </IconButton>
                                          </LightTooltip>
                                          <LightTooltip
                                            title={
                                              <div className="contacts-tooltip-content">
                                                <span className="tooltip-text">
                                                  {watcher.email}
                                                </span>
                                                <div>
                                                  <IconButton
                                                    color="primary"
                                                    onClick={() => {
                                                      navigator.clipboard.writeText(
                                                        watcher.email
                                                      );
                                                    }}
                                                  >
                                                    <ContentCopyIcon className="tooltip-copy-icon" />
                                                  </IconButton>
                                                  <IconButton
                                                    color="primary"
                                                    onClick={(e) => {
                                                      const mailto =
                                                        'mailto:' +
                                                        watcher.email;
                                                      window.location.href =
                                                        mailto;
                                                      e.preventDefault();
                                                    }}
                                                  >
                                                    <svg
                                                      className="tooltip-mail-icon"
                                                      width="25"
                                                      height="16"
                                                      viewBox="0 0 25 25"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M0 2.81689V21.9483L14.6975 25V0L0 2.81689ZM7.40468 16.8319C2.7324 16.5335 3.21361 8.19394 7.51376 8.12769C12.1204 8.4292 11.6685 16.7654 7.40468 16.8319ZM7.47204 9.76055C5.01055 9.92978 5.12076 15.1515 7.43407 15.1934C9.88496 15.037 9.74103 9.80112 7.47204 9.76055ZM17.2018 13.0864C17.4237 13.2479 17.6911 13.0864 17.6911 13.0864C17.4246 13.2479 24.9707 8.28452 24.9707 8.28452V17.2723C24.9707 18.2507 24.3382 18.661 23.627 18.661H15.6258L15.6263 12.015L17.2018 13.0864ZM15.6267 5.30049V10.1906L17.3525 11.2667C17.398 11.2798 17.4966 11.2808 17.5422 11.2667L24.9696 6.30796C24.9696 5.72114 24.4168 5.30049 24.1048 5.30049H15.6267Z"
                                                        fill="#0193D5"
                                                      />
                                                    </svg>
                                                  </IconButton>
                                                </div>
                                              </div>
                                            }
                                          >
                                            <IconButton color="primary">
                                              <MailOutlineIcon />
                                            </IconButton>
                                          </LightTooltip>
                                          <IconButton
                                            color="primary"
                                            title="Tag user in a new comment"
                                            onClick={() =>
                                              setCommentTagUser(watcher)
                                            }
                                          >
                                            <ChatBubbleOutlineIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      )}
                                    </>
                                  )
                                }}
                              />
                            )}
                          />
                        </td>

                        <td>
                          {watcher && (
                            <div className="title-cell">
                              <div className="title">Title</div>
                              <div className="value">{watcher.role}</div>
                            </div>
                          )}
                        </td>

                        <td>
                          {watcher && (
                            <div className="main-contact-cell">
                              <div className="title" hidden={index !== 0}>
                                Main Project Contact
                              </div>
                              <div className="value">
                                <FormControlLabel
                                  value={watcher.id}
                                  control={
                                    <Radio
                                      checked={watcher.isMainContact}
                                      required={true}
                                    />
                                  }
                                  label={''}
                                />
                              </div>
                            </div>
                          )}
                        </td>

                        <td>
                          {
                            <IconButton
                              title="Remove Watcher"
                              onClick={() => handleWatcherRemove(index)}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </RadioGroup>

              <div>
                <IconButton
                  className="add-button"
                  title="Add Watcher"
                  onClick={handleWatcherAdd}
                >
                  <AddCircleIcon color="primary" />
                </IconButton>
              </div>
            </div>
          </section>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button variant="contained" onClick={handleOnUpdate}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.filter.dropdownOptions ? state.filter.dropdownOptions.users : []
});

const mapDispatchToProps = {
  updatePermit: permitActions.updatePermitContactsAction,
  setCommentTagUser: commentActions.setCommentTagUserAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Contacts);
