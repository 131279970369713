import { useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import {
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlaceIcon from '@mui/icons-material/Place';
import PermitHeader from '../PermitHeader/PermitHeader';
import './SubmittedPermit.scss';
import { RootState } from 'src/store/store';
import Details from './Details/Details';
import * as attachmentActions from 'src/store/action-creators/attachment-actions';
import * as permitActions from 'src/store/action-creators/permit-actions';
import Comments from '../PermitForm/Comments/Comments';
import Schedule from './Schedule/Schedule';
import Contacts from './Contacts/Contacts';
import Scope from './Scope/Scope';
import Location from './Location/Location';
import Tcp from './Tcp/Tcp';
import Attachments from './Attachments/Attachments';
import ExportPermitPackage from './ExportPermitPackage/ExportPermitPackage';
import { Text } from 'src/models/form.model';
import { HtmlTooltip } from './components/ToolTip/ToolTip';
import StatusTimeline from './StatusTimeline/StatusTimeline';
import StatusTimelineHeader from './StatusTimelineHeader/StatusTimelineHeader';

import Reassignment from './components/Reassignment/Reassignment';
import { UserRole } from 'src/models/user-role.model';
import NeedsActionItems from '../NeedsActionItems/NeedsActionItems';
import NeedsActionDialog from '../NeedsActionDialog/NeedsActionDialog';
import { needsActionBadgeNotificationsSelector } from '../../store/selectors/needs-action-selectors';
import Notes from './Notes/Notes';
import envConfig from 'src/env-config';
import DialogForm from '../common/DialogForm';
import { PermitRequest } from 'src/models/permit.model';
import { onKeyboardConfirm } from 'src/utils/handlers-utils';
import axiosClient from 'src/utils/axios-client';
import { deleteAllNotifEvents } from 'src/store/action-creators/notification-actions';
import DeletePermitButton from '../PermitForm/DeletePermitButton/DeletePermitButton';
import { SapData, SapStatuses } from 'src/models/sap-statuses.model';

const Item = styled(Paper)(() => ({
  boxShadow: 'none',
  borderRadius: '10px'
}));

const SubmittedPermit = (props: PropsFromRedux): JSX.Element => {
  const {
    permit,
    needsActionNotifications,
    unreadComments,
    currentUser,
    addPermitStatusAction,
    clearPermit,
    getStatuses,
    allStatuses,
    setHasLoadedAttachments
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isStatusBoxOpen, setIsStatusBoxOpen] = useState(false);
  const [isJobTitleDialogOpen, setIsJobTitleDialogOpen] = useState(false);
  const [anchorReassignMenu, setAnchorReassignMenu] =
    useState<null | HTMLElement>(null);
  const [isReassignmentOpen, setIsReassignmentOpen] = useState(false);
  const [isRequestingTechSpec, setIsRequestingTechSpec] = useState(false);
  const [isRequestingTechAdvisor, setIsRequestingTechAdvisor] = useState(false);
  // permission checking state
  const [canReassign, setCanReassign] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [SAPStatuses, setSAPStatuses] = useState<SapData>();

  const { id } = useParams<{ id: string }>();

  // permission checking
  useEffect(() => {
    if (currentUser) {
      const { role } = currentUser;
      if (
        role === UserRole.PSS1 ||
        role === UserRole.QAQC ||
        role === UserRole.TCP ||
        role === UserRole.TS ||
        role === UserRole.TA ||
        role === UserRole.ADMIN
      ) {
        setCanReassign(true);
      }
    }

    if (currentUser) {
      const { role } = currentUser;
      if (role === UserRole.ADMIN) {
        setIsAdmin(true);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    setHasLoadedAttachments(false);
    setIsLoading(true);

    getStatuses(id).then(
      () => setIsLoading(false),
      () => setIsLoading(false)
    );

    return () => {
      clearPermit();
    };
  }, [getStatuses, id, permit?.id]);

  // retrieve SAP data by passing in job number
  useEffect(() => {
    const getSAPDataByJobID = async (jobNumber: string | undefined) => {
      try {
        if (jobNumber === null) {
          return Promise.resolve(null);
        }

        const reqBody = {
          jobnumber: jobNumber,
          jobtype: 'cpd'
        };
        const response = await axiosClient.post(
          envConfig.api.getPermitsByJobId,
          reqBody
        );

        const returnSAPData = response.data.afpermitsResponse.permitJobSapValid;

        return Promise.resolve(returnSAPData?.sapData || []);
      } catch (error) {
        return Promise.reject(error);
      }
    };

    getSAPDataByJobID(props.permit?.jobNumber).then((results) => {
      let mapData: SapData;
      if (results === null || results.length === 0) {
        mapData = {
          Userstatus: '',
          CarriedOutBy: '',
          CarriedOutDate: '',
          CarriedOutName: '',
          CarriedOutTime: '',
          CreatedBy: '',
          CreatedDate: '',
          ItemKey: '',
          NotifNo: '',
          PartnRole: '',
          Partner: '',
          PartnerName: '',
          Permit: false,
          PlndEndDate: '',
          PlndEndTime: '',
          PlndStartDate: '',
          PlndStartTime: '',
          Status: '',
          TaskCatTyp: '',
          TaskCode: '',
          TaskCodegrp: '',
          TaskKey: '',
          TaskSortNo: '',
          TaskText: '',
          TxtTaskcd: '',
          TxtTaskgrp: ''
        };
        setSAPStatuses(mapData);
      } else {
        const userStatus = results.Userstatus.split(/\s+/);
        mapData = {
          Userstatus: userStatus[0],
          CarriedOutBy: '',
          CarriedOutDate: '',
          CarriedOutName: '',
          CarriedOutTime: '',
          CreatedBy: '',
          CreatedDate: '',
          ItemKey: '',
          NotifNo: '',
          PartnRole: '',
          Partner: '',
          PartnerName: '',
          Permit: false,
          PlndEndDate: '',
          PlndEndTime: '',
          PlndStartDate: '',
          PlndStartTime: '',
          Status: '',
          TaskCatTyp: '',
          TaskCode: '',
          TaskCodegrp: '',
          TaskKey: '',
          TaskSortNo: '',
          TaskText: '',
          TxtTaskcd: '',
          TxtTaskgrp: ''
        };
        setSAPStatuses(mapData);
      }
    });
  }, []);

  const handleReassignMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorReassignMenu(event.currentTarget);
  };

  const handleReassignMenuClose = () => {
    setAnchorReassignMenu(null);
  };

  const handleRequestTechSpec = () => {
    setAnchorReassignMenu(null);
    setIsRequestingTechSpec(true);
    setIsRequestingTechAdvisor(false);
    setIsReassignmentOpen(true);
  };

  const handleRequestTechAdvisor = () => {
    setAnchorReassignMenu(null);
    setIsRequestingTechAdvisor(true);
    setIsRequestingTechSpec(false);
    setIsReassignmentOpen(true);
  };

  const handleReassignPermit = () => {
    setAnchorReassignMenu(null);
    setIsRequestingTechSpec(false);
    setIsRequestingTechAdvisor(false);
    setIsReassignmentOpen(true);
  };

  return (
    <>
      <Backdrop
        sx={{
          color: 'white',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {permit && (
        <main>
          <NeedsActionDialog />
          <PermitHeader
            commentCount={0}
            notificationsCount={needsActionNotifications.length}
            needsActionNotifications={needsActionNotifications}
            unreadComments={unreadComments}
          >
            <div className="submitted-permit-header-content">
              {currentUser && (
                <StatusTimelineHeader
                  permitId={permit.id}
                  permitStatuses={permit.statuses}
                  allStatuses={allStatuses}
                  setIsStatusBoxOpen={setIsStatusBoxOpen}
                  currentUser={currentUser}
                  addPermitStatusAction={addPermitStatusAction}
                />
              )}

              {canReassign && (
                <div>
                  <IconButton
                    aria-label="Reassignment options"
                    title="Reassignment options"
                    onClick={handleReassignMenuOpen}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorReassignMenu}
                    open={Boolean(anchorReassignMenu)}
                    onClose={handleReassignMenuClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                  >
                    <MenuItem onClick={handleRequestTechSpec}>
                      Request Technical Specialist
                    </MenuItem>
                    <MenuItem onClick={handleRequestTechAdvisor}>
                      Request Technical Advisor
                    </MenuItem>
                    <MenuItem onClick={handleReassignPermit}>
                      Reassign Permit Request
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </PermitHeader>

          <section className="body">
            <StatusTimeline
              statuses={permit.statuses}
              allStatuses={allStatuses}
              isStatusBoxOpen={isStatusBoxOpen}
              setIsStatusBoxOpen={setIsStatusBoxOpen}
              permit={permit}
            />

            <Reassignment
              isRequestingTechSpec={isRequestingTechSpec}
              isRequestingTechAdvisor={isRequestingTechAdvisor}
              permitId={permit.id}
              assignedCoordinators={permit.assignedUsers}
              open={isReassignmentOpen}
              setOpen={setIsReassignmentOpen}
            />

            <section className="submitted-permit">
              <header>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item sm={true}>
                    <div className="header-title">
                      <div className="request-number">
                        {permit.id || Text.NA}
                      </div>
                      <Typography
                        tabIndex={0}
                        onClick={() => {
                          setIsJobTitleDialogOpen(true);
                        }}
                        onKeyDown={onKeyboardConfirm(() => {
                          setIsJobTitleDialogOpen(true);
                        })}
                        style={{ cursor: 'pointer' }}
                        variant="h4"
                        noWrap
                      >
                        {permit.jobTitle || Text.NA}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="header-info">
                      <Typography variant="inherit" noWrap>
                        <PlaceIcon sx={{ verticalAlign: 'middle' }} />
                        <strong>{permit.jobAddress || Text.NA}</strong>
                      </Typography>
                      <div className="job-number">
                        Job#
                        <strong> {permit.jobNumber || Text.NA}</strong>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </header>
              <DialogForm
                formId={'JobTitleForm'}
                open={isJobTitleDialogOpen}
                dialogTitle={'Update the Job Title'}
                dialogFormFields={[
                  {
                    name: 'jobTitle', // the name of PermitRequest property
                    label: 'Job tile',
                    fullWidth: true,
                    placeholder: '',
                    margin: 'normal',
                    type: 'text',
                    required: true,
                    value: permit.jobTitle,
                    autoFocus: true // focus this field on open
                  }
                ]}
                handleClose={() => {
                  setIsJobTitleDialogOpen(false);
                }}
                onSubmit={async (formFieldsData: Partial<PermitRequest>) => {
                  return props.updatePermit(String(permit.id), formFieldsData);
                }}
              />
              <NeedsActionItems permitId={permit.id}></NeedsActionItems>
              <section className="content">
                <Grid container spacing={4}>
                  <Grid item xs={12} lg={7}>
                    {(() => {
                      const getSAPStatus = SAPStatuses?.Userstatus;
                      const status =
                        SapStatuses[getSAPStatus as keyof typeof SapStatuses];
                      if (getSAPStatus !== '' && getSAPStatus !== undefined) {
                        switch (getSAPStatus) {
                          case 'IN':
                            return (
                              <div className="sap-status-in">
                                <p>
                                  <strong>SAP Status: {status}</strong>
                                </p>
                              </div>
                            );
                          case 'IP':
                            return (
                              <div className="sap-status-ip">
                                <p>
                                  <strong>SAP Status: {status}</strong>
                                </p>
                              </div>
                            );
                          case 'PC':
                            return (
                              <div className="sap-status-pc">
                                <p>
                                  <strong>SAP Status: {status}</strong>
                                </p>
                              </div>
                            );
                          case 'CO':
                            return (
                              <div className="sap-status-co">
                                <p>
                                  <strong>SAP Status: {status}</strong>
                                </p>
                              </div>
                            );
                          case 'RE':
                            return (
                              <div className="sap-status-re">
                                <p>
                                  <strong>SAP Status: {status}</strong>
                                </p>
                              </div>
                            );
                          default:
                            return (
                              <div className="sap-status">
                                <p>
                                  <strong>SAP Status: N/A</strong>
                                </p>
                              </div>
                            );
                        }
                      } else if (getSAPStatus === '') {
                        return (
                          <div className="sap-status">
                            <p>
                              <strong>SAP Status: N/A</strong>
                            </p>
                          </div>
                        );
                      } else {
                        return (
                          <div className="sap-status">
                            <p>
                              <strong>Loading...</strong>
                            </p>
                          </div>
                        );
                      }
                    })()}
                  </Grid>
                  {isAdmin && (
                    <Grid item xs={12} lg={7}>
                      <DeletePermitButton permit={permit}></DeletePermitButton>
                    </Grid>
                  )}
                  <Grid item xs={12} lg={7}>
                    <Item sx={{ height: '43vh' }}>
                      <Details permit={permit} permitId={id} />
                    </Item>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <Item sx={{ height: '43vh' }}>
                      <Schedule permit={permit} />
                    </Item>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <Item sx={{ height: '42vh' }}>
                      <Contacts permit={permit} permitId={id} />
                    </Item>
                  </Grid>
                  <Grid item xs={12} lg={7}>
                    <Item sx={{ height: '42vh' }}>
                      <Location
                        permit={permit}
                        isEditable={
                          currentUser?.role === UserRole.ADMIN ||
                          currentUser?.role === UserRole.QAQC ||
                          currentUser?.role === UserRole.PSS1
                        }
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item sx={{ height: '66vh' }}>
                      <Scope
                        permit={permit}
                        isEditable={
                          currentUser?.role === UserRole.ADMIN ||
                          currentUser?.role === UserRole.QAQC ||
                          currentUser?.role === UserRole.PSS1 ||
                          currentUser?.role === UserRole.TS ||
                          currentUser?.role === UserRole.TA
                        }
                      />
                    </Item>
                  </Grid>

                  <Grid item xs={12}>
                    <Item sx={{ height: 'auto' }}>
                      <Notes permit={permit} />
                    </Item>
                  </Grid>

                  <Grid item xs={12}>
                    <Item sx={{ height: 'auto' }}>
                      <Tcp permit={permit} />
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item sx={{ height: 'auto' }}>
                      <Attachments permit={permit} />
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item>
                      <ExportPermitPackage permit={permit} />
                    </Item>
                  </Grid>
                </Grid>
              </section>
            </section>
            <section className="comments">
              <Comments permitId={id} />
            </section>
          </section>
        </main>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUser: state.auth.currentUser,
  permit: state.permit.permit,
  needsActionNotifications: needsActionBadgeNotificationsSelector(state),
  unreadComments: state.comment.unreadComments,
  allStatuses: state.permit.allStatuses
});

const mapDispatchToProps = {
  updatePermit: permitActions.updatePermitAction,
  getStatuses: permitActions.getStatusesAction,
  addPermitStatusAction: permitActions.addPermitStatusAction,
  clearPermit: permitActions.clearPermitAction,
  setHasLoadedAttachments: attachmentActions.setHasLoadedAttachmentsAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SubmittedPermit);
