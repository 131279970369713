import { Dispatch } from 'redux';
import envConfig from 'src/env-config';
import {
  Attachment,
  AttachmentType,
  FileNamePayload,
  GetSAPAttachments
} from 'src/models/attachment.model';
import { PermitRequest } from 'src/models/permit.model';
import {
  AddAttachmentsAction,
  RemoveAttachmentAction,
  SetAttachmentsAction,
  SetHasLoadedAttachmentsAction,
  SetSapDocumentsAction,
  UpdateAttachmentAction,
  SetSapAttachmentsAction
} from 'src/models/store-attachment.model';
import axiosClient from 'src/utils/axios-client';
import { getBinaryFromFile } from 'src/utils/file-utils';
import { makeS3KeySafe } from 'src/utils/formatting-utils';
import { getPermitJobType, removeKeys } from 'src/utils/permit-utils';
import ActionType from '../action-types';
import { RootState } from '../store';
import { processError } from './alert-actions';

export const addAttachmentsAction =
  (permit: PermitRequest, files: File[]) =>
  async (dispatch: Dispatch<AddAttachmentsAction>) => {
    try {
      const fileNamesPayload: FileNamePayload[] = [];
      const fileMap = new Map<string, File>();

      if (!files || !files.length) {
        return Promise.resolve([]);
      }

      files.forEach((item) => {
        const fileName = makeS3KeySafe(`${Date.now()}-${item.name}`);
        // file names array - for first request
        fileNamesPayload.push({
          fileName: fileName, // generate unique filename with the timestamp
          fileType: item.type,
          imageType: AttachmentType.OTHER
        });

        // map with all files - will be needed for each file upload request
        fileMap.set(fileName, item);
      });

      const requestBody = {
        jobNumber: permit.jobNumber,
        id: permit.id,
        primarAgencyId: permit.primarAgencyId,
        fileNames: fileNamesPayload
      };

      const addedAttachments = await axiosClient
        .post(`${envConfig.api.permits}/${permit.id}/upload`, requestBody)
        .then(async (res) => {
          const attachments: Attachment[] =
            res.data.afpermitsResponse.body.attachments;

          const uploadPromises: Promise<any>[] = [];

          // now loop over all new attachments and create array of upload promises
          for (const attachment of attachments) {
            const file = fileMap.get(attachment.fileName);

            if (attachment.preSignedURL && file) {
              uploadPromises.push(
                axiosClient.put(attachment.preSignedURL, file, {
                  headers: {
                    skipAuthorization: 'skip',
                    'Content-Type': file.type
                  }
                })
              );
            }
          }

          // now execute oploading for all promises
          await Promise.all(uploadPromises);

          // return all newly added attachemnts
          return attachments;
        });

      // the returned pre-signed URLS are PUT format. We used them for
      // uploading. We need to delete them, so they do not get confused
      // with the attachments GET signed url from getAttachmentsAction
      // although their key is presignedUrl and not preSignedURL...
      const attachments = addedAttachments.map((fileInfo) => {
        return removeKeys(fileInfo, ['preSignedURL']);
      });

      dispatch({
        type: ActionType.ADD_ATTACHMENTS,
        payload: attachments
      });

      return Promise.resolve(addedAttachments);
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const attachSapDocumentAction =
  (permitId: number | string, document: Attachment) =>
  async (
    dispatch: Dispatch<AddAttachmentsAction | SetSapDocumentsAction>,
    getState: () => RootState
  ) => {
    try {
      const update = { attached: true };

      await axiosClient.patch(
        `${envConfig.api.permits}/${permitId}/attachments/${document.id}`,
        update
      );

      dispatch({
        type: ActionType.ADD_ATTACHMENTS,
        payload: [document]
      });

      // hide this document from SAP Documents section
      const sapCopy = [...getState().attachment.sapDocuments];
      dispatch({
        type: ActionType.SET_SAP_DOCUMENTS,
        payload: [...sapCopy.filter((item) => item.id !== document.id)]
      });
      return Promise.resolve(document);
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const getAttachmentsAction =
  (permit: PermitRequest) =>
  async (dispatch: Dispatch<SetAttachmentsAction | SetSapDocumentsAction>) => {
    try {
      const response = await axiosClient.post(
        `${envConfig.api.permits}/${permit.id}/download`,
        {
          jobNumber: permit.jobNumber,
          id: permit.id,
          primarAgencyId: permit.primarAgencyId,
          jobType: getPermitJobType(permit)
        }
      );

      const {
        attachments,
        sapDocuments
      }: { attachments: Attachment[]; sapDocuments: Attachment[] } =
        response.data.afpermitsResponse.body;

      dispatch({
        type: ActionType.SET_ATTACHMENTS,
        payload: attachments
      });
      dispatch({
        type: ActionType.SET_SAP_DOCUMENTS,
        payload: sapDocuments
      });
      return Promise.resolve('Successfully loaded data!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const getSAPAttachmentsAction =
  (permit: PermitRequest) =>
  async (dispatch: Dispatch<SetSapAttachmentsAction>) => {
    try {
      const response = await axiosClient.get(
        `${envConfig.api.getSAPAttachments}/${permit.jobNumber}`
      );

      const sapAttachments: GetSAPAttachments[] =
        response.data.afpermitsResponse.body;

      dispatch({
        type: ActionType.SET_SAP_ATTACHMENTS,
        payload: sapAttachments
      });
      return Promise.resolve(sapAttachments);
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const removeAttachmentAction =
  (permitId: string | number, attachment: Attachment) =>
  async (
    dispatch: Dispatch<RemoveAttachmentAction | SetSapDocumentsAction>,
    getState: () => RootState
  ) => {
    try {
      // if the attachment is from SAP, then we dont really want to remove it
      if (attachment.fromSap) {
        // update attached flag in db
        const update = { attached: false };
        await axiosClient.patch(
          `${envConfig.api.permits}/${permitId}/attachments/${attachment.id}`,
          update
        );

        // add this document back to SAP Documents section
        const sapCopy = [...getState().attachment.sapDocuments];
        dispatch({
          type: ActionType.SET_SAP_DOCUMENTS,
          payload: [...sapCopy, { ...attachment, ...update }]
        });
      } else {
        // if not from SAP, then get rid of it from db
        await axiosClient.delete(
          `${envConfig.api.permits}/${permitId}/attachments/${attachment.id}`
        );
      }

      dispatch({
        type: ActionType.REMOVE_ATTACHMENT,
        payload: attachment.id
      });

      return Promise.resolve('Successfully removed!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const setHasLoadedAttachmentsAction =
  (hasLoaded: boolean) =>
  (dispatch: Dispatch<SetHasLoadedAttachmentsAction>) => {
    dispatch({
      type: ActionType.SET_HAS_LOADED_ATTACHMENTS,
      payload: hasLoaded
    });
  };

export const updateAttachmentAction =
  (
    permitId: number | string,
    attachmentId: number,
    update: Partial<Attachment>
  ) =>
  async (dispatch: Dispatch<UpdateAttachmentAction>) => {
    try {
      await axiosClient.patch(
        `${envConfig.api.permits}/${permitId}/attachments/${attachmentId}`,
        update
      );

      dispatch({
        type: ActionType.UPDATE_ATTACHMENT,
        payload: { attachmentId, update }
      });
      return Promise.resolve('Successfully updated!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const clearAttachementAction =
  () => async (dispatch: Dispatch<SetAttachmentsAction>) => {
    dispatch({
      type: ActionType.SET_ATTACHMENTS,
      payload: []
    });
  };
