import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  IconButton,
  Select,
  MenuItem,
  SelectChangeEvent,
  ListSubheader
} from '@mui/material';
import { Status } from 'src/models/permit.model';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { STATUS_CATEGORY_NEEDS_ACTION } from '../../../constants/statuses';
import {
  getIsStatusEnabled,
  isStatusOfCategoryById
} from '../../../utils/permit-utils';
import { StatusType } from '../../../models/status.model';
import { useDispatch } from 'react-redux';
import ActionType from '../../../store/action-types';
import { User } from 'src/models/user.model';
import { AD_COORD_ROLE_MAPPING } from 'src/constants/coordinator-roles';
import { checkPermissions } from 'src/utils/authorize-utils';
import { permissionsList } from 'src/constants/authorize-permissions-list';
import { UserRole } from 'src/models/user-role.model';
import { getUserRoleInitials } from 'src/utils/formatting-utils';

type Props = {
  permitId: number;
  className?: string;
  isStatusBoxOpen?: boolean;
  setIsStatusBoxOpen?: Dispatch<SetStateAction<boolean>>;
  permitStatuses?: Status[];
  allStatuses?: any[];
  currentUser: User;
  addPermitStatusAction: (
    permitId: number,
    value: number,
    email: string
  ) => any;
};

const StatusTimelineHeader = (props: Props) => {
  const {
    permitId,
    permitStatuses,
    allStatuses,
    isStatusBoxOpen,
    setIsStatusBoxOpen,
    currentUser,
    addPermitStatusAction
  } = props;

  const dispatch = useDispatch();

  const lastStatusOfThisUser = permitStatuses
    ?.filter((status) => status.email === currentUser.email)
    .at(-1)?.statusId;

  const [selectedStatusId, setSelectedStatusId] = useState<number>(
    lastStatusOfThisUser ? lastStatusOfThisUser : 0
  );
  const [canChangeStatus, setCanChangeStatus] = useState<boolean>(false);
  const [statusSelectOptions, setStatusSelectOptions] = useState<JSX.Element[]>(
    []
  );

  const handleStatusChange = (e: SelectChangeEvent<string>) => {
    setSelectedStatusId(parseInt(e.target.value));
  };

  const handleAddStatus = () => {
    // check, if user chose a "Needs Action" status
    const isNeedActionStatus = isStatusOfCategoryById(
      selectedStatusId,
      STATUS_CATEGORY_NEEDS_ACTION,
      allStatuses as StatusType[]
    );
    if (isNeedActionStatus) {
      dispatch({
        type: ActionType.SET_NEEDS_ACTION_DIALOG,
        payload: {
          isOpen: true,
          statusId: selectedStatusId,
          permitId
        }
      });
      return;
    }

    addPermitStatusAction(permitId, selectedStatusId, currentUser.email).then(
      (res: { errorMessage: string; successMessage: string }) => {
        if (res && res.errorMessage) {
          alert(res.errorMessage);
        }
      }
    );
  };

  const getStatusSelectOptions = (userRole: UserRole, allStatuses: any[]) => {
    let result: JSX.Element[] = [];
    const coordRoles: string[] = [];
    const isAdmin = currentUser.role === UserRole.ADMIN;

    // check user role - to identify which statuses we want to populate in select
    if (isAdmin) {
      coordRoles.push(...Object.values(AD_COORD_ROLE_MAPPING));
    } else {
      coordRoles.push(AD_COORD_ROLE_MAPPING[userRole]);
    }

    // fitler all statuses based on user role
    const filtered = allStatuses
      .filter(getIsStatusEnabled)
      .filter(
        (status) =>
          status.usedBy &&
          status.usedBy
            .split(',')
            .some((r: string) => coordRoles.indexOf(r) >= 0)
      );

    if (isAdmin) {
      // if admin, then we will display all statuses - GROUPED
      // sort them first
      const sortedByRole = filtered.sort((a, b) =>
        a.usedBy.localeCompare(b.usedBy)
      );
      // create map of statuses for each role
      const roleStatusMap: { [key: string]: any[] } = sortedByRole.reduce(
        (map, current) => {
          if (!map[current.usedBy]) {
            map[current.usedBy] = [];
          }
          map[current.usedBy].push(current);
          return map;
        },
        {}
      );

      // finally create grouped select options (with headers/titles)
      result = Object.keys(roleStatusMap)
        .map((key) => {
          const statuses = roleStatusMap[key].map((status) => (
            <MenuItem key={status.name} value={status.id}>
              {status.name}
            </MenuItem>
          ));
          const category = (
            <ListSubheader key={key}>{getUserRoleInitials(key)}</ListSubheader>
          );
          return [category, ...statuses];
        })
        .flat();
    } else {
      // if not admin, then just create classic options without grouping
      result = filtered.map((status) => (
        <MenuItem key={status.name} value={status.id}>
          {status.name}
        </MenuItem>
      ));
    }

    return result;
  };

  useEffect(() => {
    if (!(currentUser && permitStatuses)) return;

    // check if current user is allowed to change status
    setCanChangeStatus(
      checkPermissions(permissionsList.CHANGE_STATUS, currentUser)
    );

    // if status is Permit Issue, status cannot be changed regardless of your role, unless your ADMIN or PSS.
    if (
      permitStatuses[permitStatuses.length - 1]?.statusName ==
        'Permit Issued' &&
      currentUser.role != 'ADMIN' &&
      currentUser.role != 'PSS'
    ) {
      setCanChangeStatus(false);
    }

    currentUser.role &&
      allStatuses &&
      setStatusSelectOptions(
        getStatusSelectOptions(currentUser.role, allStatuses)
      );
  }, [currentUser, allStatuses]);

  return (
    <>
      {permitStatuses && setIsStatusBoxOpen && currentUser && (
        <div className="status">
          <IconButton
            onClick={() => {
              setIsStatusBoxOpen(!isStatusBoxOpen);
            }}
          >
            <AltRouteIcon className="rotate" />
          </IconButton>
          {permitStatuses && permitStatuses.length === 1 && 'Permit Inbox'}

          {permitStatuses &&
            permitStatuses.length > 1 &&
            permitStatuses[permitStatuses.length - 1]?.statusName}

          {canChangeStatus && (
            <>
              <FormControl
                variant="standard"
                id="status-select"
                sx={{ m: 1, minWidth: 120 }}
              >
                <Select
                  value={selectedStatusId.toString()}
                  onChange={handleStatusChange}
                  label="Status"
                >
                  {statusSelectOptions}
                </Select>
              </FormControl>

              {!!selectedStatusId &&
                lastStatusOfThisUser !== selectedStatusId &&
                selectedStatusId > 0 && (
                  <Button onClick={handleAddStatus}>Update</Button>
                )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default StatusTimelineHeader;
