import { useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import {
  Button,
  Checkbox,
  FormGroup,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Typography
} from '@mui/material';

import './Tcp.scss';
import { PermitRequest } from 'src/models/permit.model';
import { ReactComponent as UndrawWarning } from 'src/assets/images/logos/undraw-warning-cyit.svg';
import BootstrapDialogTitle from '../components/DialogTitle/DialogTitle';
import * as permitActions from 'src/store/action-creators/permit-actions';
import { RootState } from 'src/store/store';
import {
  checkPermissions,
  notifyNotAllowed
} from '../../../utils/authorize-utils';
import { permissionsList } from '../../../constants/authorize-permissions-list';
import { handleEnterKeyDown } from 'src/utils/handlers-utils';

interface Props extends PropsFromRedux {
  permit: PermitRequest;
}

const Tcp = (props: Props) => {
  const {
    updatePermit,
    currentUser,
    permit: { tcpRequired }
  } = props;

  const tcpTileRef = useRef(null);
  const [isTcpRequired, setIsTcpRequired] = useState(false);
  const [open, setOpen] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [tcpFormIsRequired, setTcpFormIsRequired] = useState({
    tcpRequiredYes: false,
    tcpRequiredNo: false
  });
  const [limitAnnualStandard, setLimitAnnualStandard] = useState(false);
  const [limitAnnualSiteSpecific, setLimitAnnualSiteSpecific] = useState(false);
  const { id } = useParams<{ id: string }>();

  useEffect((): void => {
    if (currentUser) {
      const { role } = currentUser;
      if (checkPermissions(permissionsList.MODIFY_TCP, currentUser)) {
        setCanEdit(true);
      }
    }
  }, [currentUser]);

  useEffect((): void => {
    setIsTcpRequired(Boolean(tcpRequired));
  }, [props.permit]);

  const getLimitAnnual = props.permit.limitAnnual;

  useEffect((): void => {
    if (getLimitAnnual == 'Standard') {
      setLimitAnnualStandard(true);
    } else if (getLimitAnnual == 'Site Specific') {
      setLimitAnnualSiteSpecific(true);
    }
  }, [props.permit]);

  useEffect((): void => {
    setTcpForm();
  }, [isTcpRequired]);

  const handlePedestrianChange = (name: string, checked: boolean): void => {
    setTcpFormIsRequired((prevState) => {
      for (const [key, value] of Object.entries(prevState)) {
        prevState[key] = !value;
      }
      return { ...prevState, [name]: checked };
    });
  };

  const handleAnnualLimitCheck = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.name === 'siteSpecific') {
      if (event.target.checked) {
        setLimitAnnualSiteSpecific(true);
        setLimitAnnualStandard(false);
      } else {
        setLimitAnnualSiteSpecific(false);
      }
    }

    if (event.target.name === 'standard') {
      if (event.target.checked) {
        setLimitAnnualStandard(true);
        setLimitAnnualSiteSpecific(false);
      } else {
        setLimitAnnualStandard(false);
      }
    }
  };

  const setTcpForm = (): void => {
    if (isTcpRequired) {
      setTcpFormIsRequired({
        tcpRequiredNo: false,
        tcpRequiredYes: true
      });
    } else {
      setTcpFormIsRequired({
        tcpRequiredYes: false,
        tcpRequiredNo: true
      });
    }
  };

  const handleOpenDialog = () => {
    if (canEdit) {
      setOpen(true);
    } else {
      notifyNotAllowed(permissionsList.MODIFY_TCP, currentUser);
    }
  };

  const handleOnClose = (): void => {
    setOpen(false);
    setTcpForm();
  };

  const handleOnUpdate = (): void => {
    const checked = tcpFormIsRequired.tcpRequiredNo ? false : true;

    let limitAnnual = '';
    if (limitAnnualStandard) {
      limitAnnual = 'Standard';
    } else if (limitAnnualSiteSpecific) {
      limitAnnual = 'Site Specific';
    }

    // TODO: update redux and call BE endpoint
    updatePermit(id, { tcpRequired: Number(checked), limitAnnual }).then(
      (): void => {
        setOpen(false);
      }
    );
  };

  return (
    <>
      <section
        className="permit-tcp"
        tabIndex={0}
        ref={tcpTileRef}
        onKeyDown={(event) => handleEnterKeyDown(event, tcpTileRef)}
      >
        <header>
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            TCP
          </Typography>
        </header>
        <div className="cell">
          {props.permit.requestStatusId != 21 && canEdit && (
            <Button
              id="edit-details"
              variant="contained"
              color="primary"
              onClick={() => handleOpenDialog()}
              sx={{ mt: 2 }}
            >
              Edit TCP
            </Button>
          )}
        </div>
        <article>
          <section>
            <p>
              <strong>Will pedestrian traffic be blocked?</strong>
              <br />
              {isTcpRequired ? 'Yes' : 'No'}
            </p>
            <p>
              <strong>Limit Annual</strong>
              <br />
              {limitAnnualSiteSpecific && 'Site Specific'}
              {limitAnnualStandard && 'Standard'}
              {!limitAnnualSiteSpecific && !limitAnnualStandard && 'N/A'}
            </p>
          </section>
          <section>
            <UndrawWarning />
          </section>
        </article>
      </section>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onBackdropClick={handleOnClose}
        onClose={handleOnClose}
      >
        <BootstrapDialogTitle onClose={handleOnClose}>
          <strong>TCP</strong>
        </BootstrapDialogTitle>
        <DialogContent>
          <p>Will pedestrian traffic be blocked?</p>
          <FormGroup row={true}>
            <FormControlLabel
              label="Yes"
              control={
                <Checkbox
                  checked={tcpFormIsRequired.tcpRequiredYes}
                  name="tcpRequiredYes"
                  onChange={(event) =>
                    handlePedestrianChange(
                      event.target.name,
                      event.target.checked
                    )
                  }
                />
              }
            />
            <FormControlLabel
              label="No"
              control={
                <Checkbox
                  checked={tcpFormIsRequired.tcpRequiredNo}
                  name="tcpRequiredNo"
                  onChange={(event) =>
                    handlePedestrianChange(
                      event.target.name,
                      event.target.checked
                    )
                  }
                />
              }
            />
          </FormGroup>
          <p>Limit Annual</p>
          <FormGroup row={true}>
            <FormControlLabel
              label="Standard"
              control={
                <Checkbox
                  checked={limitAnnualStandard}
                  name="standard"
                  onChange={handleAnnualLimitCheck}
                />
              }
            />
            <FormControlLabel
              label="Site Specific"
              control={
                <Checkbox
                  checked={limitAnnualSiteSpecific}
                  name="siteSpecific"
                  onChange={handleAnnualLimitCheck}
                />
              }
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button variant="contained" onClick={handleOnUpdate}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUser: state.auth.currentUser
});

const mapDispatchToProps = {
  updatePermit: permitActions.updateSubmittedPermitTCPAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Tcp);
