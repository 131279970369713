import { UserRole } from 'src/models/user-role.model';

export const ROLE_SUPERVISOR = 'ROLE_SUPERVISOR';
export const ROLE_QA_QC = 'ROLE_QA_QC';
export const ROLE_TS = 'ROLE_TS';
export const ROLE_TA = 'ROLE_TA';
export const ROLE_PSS = 'ROLE_PSS';
export const ROLE_TCP = 'ROLE_TCP';
export const ROLE_MUNI_ADVISOR = 'ROLE_MA';

export const AD_COORD_ROLE_MAPPING = {
  [UserRole.QAQC]: ROLE_QA_QC,
  [UserRole.TS]: ROLE_TS,
  [UserRole.TA]: ROLE_TA,
  [UserRole.PSS1]: ROLE_PSS,
  [UserRole.TCP]: ROLE_TCP
};
