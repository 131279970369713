import { UserRole } from '../models/user-role.model';
import { User } from '../models/user.model';
import { store } from '../store/store';
import ActionType from '../store/action-types';
import { PermitDashboard, PermitRequest } from 'src/models/permit.model';
import { NeedsAction } from 'src/models/needs-action.model';
import { parseRoleString } from './parse-utils';

const NOT_ALLOWED = 'NOT_ALLOWED'; // snack-bar notification ID
const dispatch = store.dispatch;

type PermissionModel = {
  name?: string;
  byRole: {
    [key in UserRole]: {
      allowed: boolean;
      onlyOwnItem?: boolean;
    };
  };
};

/**
 * Returns true, if user is owner of provided item
 *
 * @param user {Object}
 * @param item {Object}
 */
const isUserOwner = (user: User, item: any) => {
  throw new Error('Function isUserOwner not implemented');
};

/**
 * Checks if user is allowed to perform action on item
 *
 * @param requiredActionPermissions {Object} Required permissions of desired action
 * @param user {Object}  User performing the action
 * @param item {Object}  Object on which the action is requested
 * @return {boolean}
 */
export const checkPermissions = (
  requiredActionPermissions: PermissionModel,
  user: User | null,
  item: any = {}
) => {
  if (!requiredActionPermissions) {
    throw new Error('Required action permissions are not provided');
  }

  // check valid user
  if (!user || !user?.role) return false;

  const roles = parseRoleString(user.role as string);

  // check valid permission present
  const isRolePermissionSet = roles.some(
    (r) => (requiredActionPermissions.byRole[r])
  );
  if (!isRolePermissionSet) return false;

  // check role allowed
  const isRoleAllowed = roles.some(
    (r) => (requiredActionPermissions.byRole[r].allowed)
  );

  // check item level ownership
  const isOwnershipCheckRequired =
    roles.some(
      (r) => (requiredActionPermissions.byRole[r].onlyOwnItem === true)
    );
  const isItemAccessAllowed = isOwnershipCheckRequired
    ? isUserOwner(user, item)
    : true;
  return isRoleAllowed && isItemAccessAllowed;
};

/**
 * Logic for communicating not allowed message to app user
 * If called without parameters, the defaultMessage is used
 */
export const notifyNotAllowed = (
  action?: PermissionModel,
  user?: User | null,
  customMessage?: string
) => {
  // get alert text
  const defaultMessage = 'User is not allowed to perform this action!';
  const constructedMessage =
    action &&
    action.name &&
    user &&
    `User of role ${user.role} is not allowed to perform the action "${action.name}"!`;
  const text = customMessage || constructedMessage || defaultMessage;

  // default not allowed action
  const alertAction = {
    type: ActionType.SHOW_ALERT,
    payload: {
      id: NOT_ALLOWED,
      text,
      autoHideDuration: 5000,
      severity: 'warning', // 'success' | 'info' | 'warning' | 'error';
      vertical: 'top' // 'bottom' | 'top'
    }
  };

  // shows "snack bar" message
  dispatch(alertAction);
};

/**
 * Checks if user is requestor of permit
 * @param user
 * @param permit
 * @returns boolean value determining if user is requestor or not
 */
export const checkIsRequestorOfPermit = (
  user: User | null,
  permit: PermitDashboard | PermitRequest | null
) => {
  if (!user || !permit) {
    return false;
  }
  return user.email === permit.createdByEmailID;
};

/**
 * Checks if user is author of needs action
 * @param user
 * @param needsAction
 * @returns boolean value determining if user is author or not
 */
export const checkIsAuthorOfNeedsAct = (
  user: User | null,
  needsAction: NeedsAction | null
) => {
  if (!user || !needsAction) {
    return false;
  }
  return user.email === needsAction.authorEmail;
};
