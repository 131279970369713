import ActionType from '../action-types';
import { Dispatch } from 'redux';
import {
  SetPermitActiveStepAction,
  SidebarStep
} from '../../models/store-permit-sidebar.model';

export const setPermitActiveStepAction = (step: SidebarStep) => {
  return (dispatch: Dispatch<SetPermitActiveStepAction>) => {
    dispatch({
      type: ActionType.SET_PERMIT_ACTIVE_STEP,
      payload: step
    });
  };
};
