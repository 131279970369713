import moment from 'moment';
import { Attachment } from 'src/models/attachment.model';
import { MarkerMap } from 'src/models/marker-map.model';
import { PermitRequest } from 'src/models/permit.model';
import { Text } from 'src/models/form.model';
import { TrenchSpec } from 'src/models/trench.model';
import { getExtensionByMimeType } from '../../../../utils/mime-types';

const verifyTruthyFormat = (
  data: string | number | Date | undefined | null
) => {
  if (!data) {
    return 'N/A';
  }
  return data;
};

const verifyTruthyBooleanFormat = (
  data: string | boolean | number | null | undefined
) => {
  if (data === null || data === undefined) {
    return 'N/A';
  }

  if (data === '1' || data === true || data > 1) {
    return 'Yes';
  }
  return 'No';
};

const verifyOutageRequiredFormat = (
  outageReqString: string | null | undefined
) => {
  if (!outageReqString) {
    return 'N/A';
  }
  if (outageReqString === '1') {
    return 'Yes';
  } else if (outageReqString === '2') {
    return 'No';
  } else if (outageReqString === '3') {
    return 'Unknown at this time';
  }
  return 'N/A';
};

const setDateFormat = (time: Date | string | null | undefined): string => {
  if (!time) {
    return 'N/A';
  }
  return (time && moment(time).format('MMM Do YYYY')) || Text.NA;
};

const setTimeFormat = (time: Date | string | null | undefined): string => {
  if (!time) {
    return 'N/A';
  }
  return (time && moment(time).format('h:mm a')) || Text.NA;
};

//Style
const pageStyle = {
  margin: '20px 20px'
};
const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between'
};

const tableStyle = {
  borderSpacing: '0px',
  width: '100%'
};

const firstRowStyle = {
  backgroundColor: '#f2f2f2'
};

const divAndTableDataStyle = {
  padding: '15px'
};

const tableDataWithBorderBottomStyle = {
  padding: '15px',
  borderBottom: '1px solid #f2f2f2'
};

interface Props {
  attachments: Attachment[];
  permit: PermitRequest;
}

const ExportPermitPDF = (props: Props) => {
  const { attachments, permit } = props;
  return (
    <div style={pageStyle}>
      <div style={headerStyle}>
        <div>Permit Request: {verifyTruthyFormat(permit.jobTitle)}</div>
        <div>Job # {verifyTruthyFormat(permit.jobNumber)}</div>
      </div>
      <div style={headerStyle}>
        <div>
          <strong>
            Location Address {verifyTruthyFormat(permit.jobAddress)}
          </strong>
        </div>
      </div>
      <hr></hr>
      <h2>Permit Details</h2>
      <table style={tableStyle}>
        <tbody>
          <tr style={firstRowStyle}>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Permit Number</strong>
              </div>
              <div>{verifyTruthyFormat(permit.permitNumber)}</div>
            </td>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Permit Cost</strong>
              </div>
              <div>{verifyTruthyFormat(permit.cost)}</div>
            </td>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Approved</strong>
              </div>
              <div>{setDateFormat(permit.approvalDate)}</div>
            </td>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Expires</strong>
              </div>
              <div>{setDateFormat(permit.expirationDate)}</div>
            </td>
          </tr>
          <tr>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Budget Code Description</strong>
              </div>
              <div>{verifyTruthyFormat(permit.budgetCodeDescription)}</div>
            </td>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Budget Code</strong>
              </div>
              <div>{verifyTruthyFormat(permit.budgetCode)}</div>
            </td>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Project Type</strong>
              </div>
              <div>{verifyTruthyFormat(permit.projectType)}</div>
            </td>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Priority</strong>
              </div>
              <div>{verifyTruthyFormat(permit.priorityText)}</div>
            </td>
          </tr>
          <tr>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Work Order #</strong>
              </div>
              <div>{verifyTruthyFormat(permit.workOrderNo)}</div>
            </td>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Outage Required</strong>
              </div>
              <div>{verifyOutageRequiredFormat(permit.outageRequired)}</div>
            </td>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Annual Permit</strong>
              </div>
              <div>{verifyTruthyBooleanFormat(permit.isAnnualPermit)}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Estimated Schedule</h2>
      <table style={tableStyle}>
        <tbody>
          <tr style={firstRowStyle}>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Construction Start & End</strong>
              </div>
              <div>
                {verifyTruthyFormat(
                  setDateFormat(permit.constructionStartDate)
                )}{' '}
                -{' '}
                {verifyTruthyFormat(setDateFormat(permit.constructionEndDate))}
              </div>
            </td>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Time of Day</strong>
              </div>
              <div>{verifyTruthyFormat(permit.timeOfWorkProposed)}</div>
            </td>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>CMP Compliance</strong>
              </div>
              <div>{verifyTruthyFormat(setDateFormat(permit.cmpDueDate))}</div>
            </td>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Work Performed Daily</strong>
              </div>
              <div>
                {verifyTruthyFormat(setTimeFormat(permit.workStartTime))} -{' '}
                {verifyTruthyFormat(setTimeFormat(permit.workEndTime))}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Contacts</h2>
      <table style={tableStyle}>
        <tbody>
          <tr style={firstRowStyle}>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Project Manager</strong>
              </div>
              <div>{verifyTruthyFormat(permit.projectManager)}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <table style={tableStyle}>
        <tbody>
          <tr>
            <td style={divAndTableDataStyle}>Watchers</td>
            <td style={divAndTableDataStyle}></td>
          </tr>
          {permit.watchers.length > 0 ? (
            permit.watchers.map((watcher: any) => (
              <tr key={watcher.id}>
                <td style={tableDataWithBorderBottomStyle}>
                  {verifyTruthyFormat(watcher.lastName)},{' '}
                  {verifyTruthyFormat(watcher.firstName)}
                </td>
                {watcher.phone ? (
                  <td style={tableDataWithBorderBottomStyle}>
                    {verifyTruthyFormat(watcher.firstName)}{' '}
                    {verifyTruthyFormat(watcher.lastName)}
                  </td>
                ) : (
                  <td style={tableDataWithBorderBottomStyle}>
                    {watcher.email} | No phone number provided
                  </td>
                )}
              </tr>
            ))
          ) : (
            <div>No watchers for this permit</div>
          )}
        </tbody>
      </table>
      <h2>Location</h2>
      <table style={tableStyle}>
        <tbody>
          <tr style={firstRowStyle}>
            <td style={divAndTableDataStyle}>
              <div>
                <strong>Primary Address</strong>
              </div>
              <div>{verifyTruthyFormat(permit.primaryAddress.address)}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <h4 style={divAndTableDataStyle}>Additional Addresses</h4>
      {permit.supportingAddress.length > 0 ? (
        permit.supportingAddress.map((address: MarkerMap, index: number) => (
          <div
            key={address.address + index.toString()}
            style={divAndTableDataStyle}
          >
            {verifyTruthyFormat(address.address)}
          </div>
        ))
      ) : (
        <div style={divAndTableDataStyle}>No additional addresses listed.</div>
      )}

      <h2>Scope</h2>
      <table style={tableStyle}>
        <tbody>
          <tr>
            <td style={divAndTableDataStyle}>
              <strong>Customer</strong>
            </td>
            <td style={divAndTableDataStyle}>
              Permit <strong>{verifyTruthyFormat(permit.jobNumber)}</strong>
            </td>
          </tr>
          <tr>
            <td style={divAndTableDataStyle}>
              <strong>SDG&E</strong>
            </td>
            <td style={divAndTableDataStyle}>
              <div>Job Description</div>
              <div>{verifyTruthyFormat(permit.jobDescription)}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <h4 style={divAndTableDataStyle}>SDG&E Trench Specifications</h4>
      {permit.trenchSpecifications.length > 0 ? (
        <table>
          <tbody>
            {permit.trenchSpecifications.map((trench: TrenchSpec) => (
              <tr key={trench.id}>
                <td style={divAndTableDataStyle}>
                  <div>Repair Type</div>
                  <div>{verifyTruthyFormat(trench.trenchRepairTypeId)}</div>
                </td>
              </tr>
            ))}
            {permit.trenchSpecifications.map(
              (trench: TrenchSpec, index: number) => (
                <tr key={trench.id + index}>
                  <td style={divAndTableDataStyle}>
                    <table>
                      <tbody>
                        <tr>
                          <td style={tableDataWithBorderBottomStyle}>Length</td>
                          <td style={tableDataWithBorderBottomStyle}>Width</td>
                          <td style={tableDataWithBorderBottomStyle}>Depth</td>
                        </tr>
                        <tr>
                          <td style={tableDataWithBorderBottomStyle}>
                            {verifyTruthyFormat(trench.length)}
                          </td>
                          <td style={tableDataWithBorderBottomStyle}>
                            {verifyTruthyFormat(trench.width)}
                          </td>
                          <td style={tableDataWithBorderBottomStyle}>
                            {verifyTruthyFormat(trench.depth)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <div style={tableDataWithBorderBottomStyle}>
                      Intersecting Street
                    </div>
                    <div style={tableDataWithBorderBottomStyle}>
                      {verifyTruthyFormat(trench.intersectingStreet)}
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      ) : (
        <div style={divAndTableDataStyle}>No trench specifications listed.</div>
      )}
      <h2>TCP</h2>
      <table style={tableStyle}>
        <tbody>
          <tr>
            <td style={divAndTableDataStyle}>
              <div>Will pedestrian traffic be blocked?</div>
              <div>{verifyTruthyBooleanFormat(permit.tcpRequired)}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Attachments</h2>
      {attachments.length > 0 ? (
        <table style={tableStyle}>
          <tbody>
          {attachments.map((attachment: Attachment) => (
            <tr key={attachment.fileName}>
              <td style={divAndTableDataStyle}>
                {
                  (attachment.title == attachment.fileName) ?
                    (attachment.fileName) : (
                      <>
                        <b>
                          {attachment.title}
                        </b>
                        <br />
                        {attachment.fileName}
                      </>
                    )
                }
              </td>
              <td style={divAndTableDataStyle}>
                {verifyTruthyFormat(getExtensionByMimeType(attachment.contentType))}
              </td>
              <td style={divAndTableDataStyle}>
                {verifyTruthyFormat(attachment.authorName)}{' '}
                {setDateFormat(attachment.createdAt)}
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      ) : (
        <div style={divAndTableDataStyle}>
          No associated attachments listed.
        </div>
      )}
    </div>
  );
};

export default ExportPermitPDF;
