import './Login.scss';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { ReactComponent as SdgeLogo } from 'src/assets/images/logos/sdge-logo-white.svg';
import { connect, ConnectedProps } from 'react-redux';
import * as actions from '../../store/action-creators/auth-actions';
// import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import React from 'react';
import envConfig from 'src/env-config';
const Login = (props: PropsFromRedux) => {
  const { submitLogin, ssoAction } = props;

  const [formValues, setFormValues] = React.useState({
    username: '',
    password: ''
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitLogin(formValues.username, formValues.password).then(
      () => undefined,
      (error) => alert(error)
    );
  };

  const handleSSO = async () => {
    ssoAction().then(
      () => undefined,
      (error) => alert(error)
    );
  };

  return (
    <div className="login-container">
      <div className="login-page">
        <div>
          <div className="title">
            <h2>
              Permit <span className="slim-text">Requests &nbsp;</span>{' '}
            </h2>
          </div>
          <h2 className="login-label">Login</h2>
          <Button variant="contained" color="primary" onClick={handleSSO}>
            Login with Microsoft
          </Button>
          <br />
          <br />
          <br />

          {!envConfig.isProduction && (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <span className="button-label">Username</span>
              <Box>
                <FormControl sx={{ width: '37ch' }} required>
                  <OutlinedInput
                    placeholder="ie. firstlast@semprautilities.com"
                    name="username"
                    onChange={handleChange}
                  />
                </FormControl>
              </Box>
              <br />
              <span className="button-label">Password</span>
              <Box>
                <FormControl sx={{ width: '37ch' }} required>
                  <OutlinedInput
                    type="password"
                    placeholder="Password"
                    autoComplete="on"
                    name="password"
                    onChange={handleChange}
                  />
                </FormControl>
              </Box>
              <br />
              <div className="login-button-container">
                <Button
                  className="login"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Login
                </Button>
              </div>
            </form>
          )}
          <div className="logo-container">
            <SdgeLogo />
          </div>
        </div>
      </div>
      <div className="background-login img-container"></div>
    </div>
  );
};

const mapDispatchToProps = {
  submitLogin: actions.submitLoginAction,
  ssoAction: actions.ssoAction
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Login);
