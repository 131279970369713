import './ContactItem.scss';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import {
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { LightTooltip } from '../../components/ToolTip/ToolTip';
import { User } from 'src/models/user.model';

interface Props {
  mainContact?: boolean;
  user: User;
  onTagUserClick: () => void;
}

const ContactItem = (props: Props) => {
  const { mainContact = false, user, onTagUserClick } = props;

  return (
    <ListItem className="contact-item">
      <ListItemText>
        <strong>{user.name}</strong>
      </ListItemText>
      <div className="icons-col">
        <ListItemIcon>
          <LightTooltip
            title={
              <div className="contacts-tooltip-content">
                <span className="tooltip-text">
                  {user?.phone || 'w/o phone number'}
                </span>
                <div>
                  <IconButton
                    id="stop-open"
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(user?.phone || '');
                    }}
                  >
                    <ContentCopyIcon
                      id="stop-open"
                      className="tooltip-copy-icon"
                    />
                  </IconButton>
                </div>
              </div>
            }
          >
            <IconButton color="primary">
              <PhoneIphoneIcon />
            </IconButton>
          </LightTooltip>
        </ListItemIcon>
        <ListItemIcon>
          <LightTooltip
            title={
              <div className="contacts-tooltip-content">
                <span className="tooltip-text">{user?.email}</span>
                <div>
                  <IconButton
                    id="stop-open"
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(user?.email || '');
                    }}
                  >
                    <ContentCopyIcon
                      id="stop-open"
                      className="tooltip-copy-icon"
                    />
                  </IconButton>
                  <IconButton
                    id="stop-open"
                    color="primary"
                    onClick={(e) => {
                      const mailto = 'mailto:' + user?.email;
                      window.location.href = mailto;
                      e.preventDefault();
                    }}
                  >
                    <svg
                      id="stop-open"
                      className="tooltip-mail-icon"
                      width="25"
                      height="16"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 2.81689V21.9483L14.6975 25V0L0 2.81689ZM7.40468 16.8319C2.7324 16.5335 3.21361 8.19394 7.51376 8.12769C12.1204 8.4292 11.6685 16.7654 7.40468 16.8319ZM7.47204 9.76055C5.01055 9.92978 5.12076 15.1515 7.43407 15.1934C9.88496 15.037 9.74103 9.80112 7.47204 9.76055ZM17.2018 13.0864C17.4237 13.2479 17.6911 13.0864 17.6911 13.0864C17.4246 13.2479 24.9707 8.28452 24.9707 8.28452V17.2723C24.9707 18.2507 24.3382 18.661 23.627 18.661H15.6258L15.6263 12.015L17.2018 13.0864ZM15.6267 5.30049V10.1906L17.3525 11.2667C17.398 11.2798 17.4966 11.2808 17.5422 11.2667L24.9696 6.30796C24.9696 5.72114 24.4168 5.30049 24.1048 5.30049H15.6267Z"
                        fill="#0193D5"
                      />
                    </svg>
                  </IconButton>
                </div>
              </div>
            }
          >
            <IconButton color="primary">
              <MailOutlineIcon />
            </IconButton>
          </LightTooltip>
        </ListItemIcon>
        <ListItemIcon>
          <IconButton
            id="stop-open"
            color="primary"
            title="Tag user in a new comment"
            onClick={onTagUserClick}
          >
            <ChatBubbleOutlineIcon id="stop-open" />
          </IconButton>
        </ListItemIcon>
        <ListItemIcon>
          {mainContact && (
            <Box
              sx={{
                backgroundColor: '#F1F4F9',
                padding: '5px',
                borderRadius: '5px'
              }}
            >
              MC
            </Box>
          )}
        </ListItemIcon>
      </div>
    </ListItem>
  );
};

export default ContactItem;
