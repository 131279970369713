import { useHistory, useLocation } from 'react-router-dom';
import './AppNav.scss';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import React from 'react';
import { ReactComponent as SdgeLogo } from 'src/assets/images/logos/sdge-logo-white.svg';
import * as authActions from 'src/store/action-creators/auth-actions';
import * as permitActions from 'src/store/action-creators/permit-actions';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/store/store';

// value used as width of sidebar and content offset
const drawerWidth = 255;

const openedMixin = (theme: any) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: any) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop: any) => prop !== 'open'
})(({ theme, open }: any) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

interface Props extends PropsFromRedux {
  children: JSX.Element[] | JSX.Element;
}

const AppNav = (props: Props) => {
  const { children, currentUser, clearPermits, logout } = props;

  const history = useHistory();
  const currentUrl = useLocation().pathname;
  let currentPath = '';

  // save url to localstorage, so user can be redirected after login
  if (currentUrl !== '/login') {
    localStorage.setItem('redirectTo', currentUrl);
  }

  switch (currentUrl) {
    case '/':
      currentPath = 'dashboard';
      break;
    case '/new-permit':
      currentPath = 'new-permit';
      break;
    case '/login':
      currentPath = 'login';
      break;
    default:
      currentPath = '';
  }

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const dashboardNav = () => {
    history.push('/');
  };

  const handleLogout = () => {
    clearPermits();
    logout();
  };

  return (
    <div className="app-nav">
      <div className="content">
        <CssBaseline />
        {currentPath !== 'login' ? (
          <Drawer variant="permanent" open={open} className="sidebar-nav">
            <DrawerHeader className="nav-header">
              {open ? (
                <div className="logo-container">
                  <SdgeLogo className="sdge-logo" width="43" height="25" />
                </div>
              ) : (
                ''
              )}

              <span className="logo-text">
                P
                <span
                  className="hide-text"
                  style={{ display: open ? 'initial' : 'none' }}
                >
                  ermit{' '}
                </span>
                R
                <span
                  className="hide-text"
                  style={{ display: open ? 'initial' : 'none' }}
                >
                  equests
                </span>
              </span>
              <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List className="nav-list">
              {['All Requests'].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  className="nav-button"
                  onClick={dashboardNav}
                  style={{
                    backgroundColor:
                      currentPath === 'dashboard' ? '#0C75DF' : 'inherit'
                  }}
                >
                  <ListItemIcon>{<ListAltIcon />}</ListItemIcon>
                  <ListItemText
                    primary={text}
                    style={{ display: open ? 'initial' : 'none' }}
                  />
                </ListItem>
              ))}
            </List>
            {/* <Divider />
            <span className="sidebar-label">ADMIN</span>
            <List className="nav-list">
              {['Agencies', 'Users'].map((text, index) => (
                <ListItem button key={text} className="nav-button">
                  <ListItemIcon>
                    {index % 2 === 0 ? (
                      <LocationCityIcon />
                    ) : (
                      <PersonOutlineIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    style={{ display: open ? 'initial' : 'none' }}
                  />
                </ListItem>
              ))}
            </List> */}

            <div className="empty-space"></div>
            <div
              style={{
                width: '100%',
                textAlign: 'right',
                padding: '25px',
                color: '#eee',
                fontSize: '0.8em',
                display: open ? 'initial' : 'none'
              }}
            >
            {process.env.REACT_APP_VERSION as string || 'version not defined'}
            </div>
            <Stack className="avatar-row" direction="row" spacing={2}>
              <Avatar
                alt="First Last"
                src="/static/images/avatar/1.jpg"
                onClick={handleLogout}
              >
                {currentUser?.initials}
              </Avatar>
              <span
                className="avatar-name"
                style={{ display: open ? 'initial' : 'none' }}
              >
                {currentUser?.name}
              </span>
            </Stack>
          </Drawer>
        ) : (
          ''
        )}
        <div
          className="content-container"
          style={{
            paddingLeft:
              open && currentPath !== 'login'
                ? 0 + 'px'
                : currentPath !== 'login'
                ? '0em'
                : 0
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  currentUser: state.auth.currentUser
});

const mapDispatch = {
  clearPermits: permitActions.clearPermitsAction,
  logout: authActions.logoutAction
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AppNav);
