import './CoordinatorAvatar.scss';
import { Avatar, Tooltip } from '@mui/material';

interface Props {
  initials: string;
  name: string;
  role?: string;
}

const CoordinatorAvatar = (props: Props) => {
  const { initials, name, role } = props;

  return (
    <div className="coordinator-avatar">
      <Tooltip placement="right" title={name} followCursor={true}>
        <Avatar alt={name} src="/static/images/avatar/1.jpg">
          {initials}
        </Avatar>
      </Tooltip>
      {role && <span className="avatar-name">{role}</span>}
    </div>
  );
};

export default CoordinatorAvatar;
