import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EventIcon from '@mui/icons-material/Event';
import moment from 'moment';

import './Schedule.scss';
import BootstrapDialogTitle from '../components/DialogTitle/DialogTitle';
import * as permitActions from 'src/store/action-creators/permit-actions';
import { PermitRequest } from 'src/models/permit.model';
import { ScheduleForm, Text } from 'src/models/form.model';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { LoadingOverlay } from 'src/components/utils/LoadingOverlay/LoadingOverlay';
import { formatDateTime, formatTime } from 'src/utils/formatting-utils';
import {
  checkPermissions,
  notifyNotAllowed
} from '../../../utils/authorize-utils';
import { RootState } from '../../../store/store';
import { User } from '../../../models/user.model';
import { permissionsList } from '../../../constants/authorize-permissions-list';
import { handleEnterKeyDown } from 'src/utils/handlers-utils';

interface Props extends PropsFromRedux {
  permit: PermitRequest;
}

const Schedule = (props: Props) => {
  // 2 following constants to be changed
  const daysForCloserNotification = 365;
  const daysForExpireNotification = 365;
  const {
    permit: {
      constructionStartDate,
      constructionEndDate,
      cmpDueDate: cmpDueDateFromProps,
      timeOfWorkProposed,
      workStartTime,
      workEndTime,
      id
    },
    updatePermit,
    spinoffNewPermit
  } = props;

  const startInputRef = useRef<HTMLButtonElement>();
  const endInputRef = useRef<HTMLButtonElement>();
  const reqExtRef = useRef<HTMLButtonElement>(null!);
  const scheduleTileRef = useRef(null);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestingPermitExtension, setIsRequestingPermitExtension] =
    useState(false);
  const [scheduleDialog, setScheduleDialog] = useState(false);
  const [requestExtDialog, setRequestExtDialog] = useState(false);
  const [nightExtDialog, setNightExtDialog] = useState(false);
  const [scheduleForm, setScheduleForm] = useState<ScheduleForm>({
    constructionStartDate: null,
    constructionEndDate: null,
    cmpDueDate: null,
    timeOfWork: null,
    workStartTime: null,
    workEndTime: null
  });
  const user = useSelector<RootState, User | null>(
    (state) => state.auth.currentUser
  );

  useEffect(() => {
    resetForm();
  }, [props.permit]);

  // Handle request extension or night option dialog
  const handleExtensionsDialog = (event: React.MouseEvent<HTMLElement>) => {
    const id = event.currentTarget.id;
    if (id == 'night-option') {
      setNightExtDialog(true);
      setRequestExtDialog(false);
    } else if (id == 'request-extension') {
      setNightExtDialog(false);
      setRequestExtDialog(true);
    }
    setOpen(true);
  };

  const handleOnClose = (reset: boolean) => {
    setOpen(false);

    // timeout, so that dialog hides properly after the animation is done
    setTimeout(() => {
      setScheduleDialog(false);
    }, 500);

    if (reset) {
      resetForm();
    }
  };

  const resetForm = () => {
    const { permit } = props;
    setScheduleForm({
      constructionStartDate: permit.constructionStartDate || null,
      constructionEndDate: permit.constructionEndDate || null,
      cmpDueDate: permit.cmpDueDate || null,
      timeOfWork: permit.timeOfWorkProposed || null,
      workStartTime: permit.workStartTime || null,
      workEndTime: permit.workEndTime || null
    });
  };

  const reformatTime = (time: string) => {
    if (!time) {
      return null;
    }
    return time.substring(0, 10) + ' ' + time.substring(11, 19);
  };

  const handleOnUpdate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let extOption = '';

    if (!scheduleDialog) {
      setOpen(true);
      setIsRequestingPermitExtension(true);

      const buttonID = reqExtRef.current.id;

      if (buttonID == 'nightOpt') {
        extOption = 'NIGHT WORK';
      }

      spinoffNewPermit(String(id), extOption)
        .then((permitId) => {
          setIsRequestingPermitExtension(false);
          history.push(`/permit/${permitId}`);
        })
        .catch(() => setIsRequestingPermitExtension(false));

      return;
    }

    setIsLoading(true);
    const payload = {};
    for (const [key, value] of Object.entries(scheduleForm)) {
      if (key == 'timeOfWork') {
        if (value == null) {
          payload['timeOfWorkProposed'] = null;
        } else {
          payload['timeOfWorkProposed'] = value;
        }
      }

      if (value == null) {
        payload[key] = null;
      } else {
        payload[key] = value;
      }
    }

    const reformattedWorkStartTime = reformatTime(payload['workStartTime']);
    if (payload['workStartTime'] !== reformattedWorkStartTime) {
      payload['workStartTime'] = reformattedWorkStartTime;
    }

    const reformattedWorkEndTime = reformatTime(payload['workEndTime']);
    if (payload['workEndTime'] !== reformattedWorkEndTime) {
      payload['workEndTime'] = reformattedWorkEndTime;
    }

    delete payload['timeOfWork'];
    updatePermit(String(id), payload).then(
      () => {
        setIsLoading(false);
        handleOnClose(false);
      },
      () => setIsLoading(false)
    );
  };

  // Handle Open Schedule Dialog
  const handleOpenScheduleDialog = (event: unknown): void => {
    const action = permissionsList.MODIFY_SCHEDULE;
    const isAllowed = checkPermissions(action, user);
    if (!isAllowed) {
      notifyNotAllowed(action, user);
      return;
    }
    setScheduleDialog(true);
    setOpen(true);
  };

  const onStartTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { valueAsDate } = event.target;
    if (!valueAsDate) {
      handleScheduleChange('workStartTime', null);
      return;
    }
    const time = moment(valueAsDate);
    const reformattedTime = time.utc().format('YYYY-MM-DD HH:mm:ss');
    handleScheduleChange(
      'workStartTime',
      time ? reformattedTime : '2000-01-01 00:00:00'
    );
  };

  const onEndTimeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { valueAsDate } = event.target;
    if (!valueAsDate) {
      handleScheduleChange('workEndTime', null);
      return;
    }
    const time = moment(valueAsDate);
    const reformattedTime = time.utc().format('YYYY-MM-DD HH:mm:ss');
    handleScheduleChange(
      'workEndTime',
      time ? reformattedTime : '2000-01-01 00:00:00'
    );
  };

  const handleScheduleChange = (elementKey: string, changedValue: unknown) => {
    setScheduleForm((prevState) => ({
      ...prevState,
      [elementKey]: changedValue
    }));
  };

  const checkExtendPermitNotification = (
    cmp: Date | string | null,
    endDate: Date | string | null
  ): boolean => {
    if (cmp && endDate) {
      if (
        new Date(cmp).getTime() <
        new Date(endDate).getTime() - 86400000 * daysForExpireNotification
      ) {
        return true;
      }
    }
    return false;
  };

  const checkCloserNotification = (
    cmp: Date | string | null,
    startDate: Date | string | null
  ): boolean => {
    if (cmp && startDate) {
      if (
        new Date(cmp).getTime() <
        new Date(startDate).getTime() - 86400000 * daysForCloserNotification
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <section
        className="permit-schedule"
        // onClick={handleOpenScheduleDialog}
        tabIndex={0}
        ref={scheduleTileRef}
        onKeyDown={(event) => handleEnterKeyDown(event, scheduleTileRef)}
      >
        <header>
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            Estimated Schedule
          </Typography>
        </header>
        {props.permit.requestStatusId != 21 && (
          <Grid item md={12} className="my-25px">
            <Button
              id="night-option"
              variant="contained"
              color="primary"
              onClick={handleExtensionsDialog}
            >
              Night Option
            </Button>
          </Grid>
        )}
        <article>
          <div className="cell">
            <span>Construction Start & End</span>
            <span>
              <DateRangeIcon fontSize="small" />
              {formatDateTime(constructionStartDate)} -{' '}
              {formatDateTime(constructionEndDate)}
            </span>
          </div>
          <div className="break"></div>
          <div className="cell">
            <span>Time Of Day</span>
            <span>{timeOfWorkProposed || Text.NA}</span>
          </div>
          <div className="cell">
            <span>CMP Compliance</span>
            <span>{formatDateTime(cmpDueDateFromProps) || Text.NA}</span>
          </div>
          <div className="break"></div>
          <div className="cell">
            <span>Work Performed Daily</span>
            <span>
              {formatTime(workStartTime, true) || Text.NA} -{' '}
              {formatTime(workEndTime, true) || Text.NA}
            </span>
            {props.permit.requestStatusId != 21 && (
              <Button
                id="edit-schedule"
                variant="contained"
                color="primary"
                onClick={handleOpenScheduleDialog}
                sx={{ mt: 2 }}
              >
                Edit Schedule
              </Button>
            )}
          </div>
        </article>
        <footer>
          {/* TODO: missing implementation -> currently editing person */}
          {/* <span hidden>
            <LockOutlinedIcon sx={{ width: '11px' }} />
            <mark>MGibson</mark> is currently editing
          </span> */}
        </footer>
      </section>

      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onBackdropClick={() => handleOnClose(true)}
        onClose={() => handleOnClose(true)}
      >
        <LoadingOverlay isVisible={isLoading} />

        <BootstrapDialogTitle onClose={() => handleOnClose(true)}>
          <strong>
            {(scheduleDialog && 'Schedule') ||
              (requestExtDialog && 'Request Extension') ||
              (nightExtDialog && 'Night Option')}
          </strong>
        </BootstrapDialogTitle>
        <DialogContent>
          <form id="schedule-form" onSubmit={handleOnUpdate}>
            {/* Scheduler Dialog Content */}
            {scheduleDialog ? (
              <div className="schedule-dialog">
                <div className="description">
                  When is construction <strong>scheduled</strong>? An estimate
                  is ok.
                </div>
                <div className="dates-inline-container">
                  <div className="dates-container">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateRangePicker
                        startText="Construction Starts *"
                        endText="Construction Ends *"
                        shouldDisableYear={undefined}
                        value={[
                          scheduleForm.constructionStartDate,
                          scheduleForm.constructionEndDate
                        ]}
                        onChange={(newValue) => {
                          handleScheduleChange(
                            'constructionStartDate',
                            newValue[0]
                          );
                          handleScheduleChange(
                            'constructionEndDate',
                            newValue[1]
                          );
                        }}
                        renderInput={(
                          startProps: unknown,
                          endProps: unknown
                        ) => (
                          <>
                            <TextField
                              {...startProps}
                              inputRef={startInputRef}
                              {...startProps}
                              size="small"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => {
                                        startInputRef.current?.focus();
                                      }}
                                    >
                                      <EventIcon style={{ color: '#0098da' }} />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                            <Box sx={{ mx: 2 }}> - </Box>
                            <TextField
                              {...endProps}
                              size="small"
                              inputRef={endInputRef}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => {
                                        endInputRef.current?.focus();
                                      }}
                                    >
                                      <EventIcon style={{ color: '#0098da' }} />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </>
                        )}
                      />
                    </LocalizationProvider>
                    <div className="cmp-due-date">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="CMP Compliance Due Date"
                          value={scheduleForm.cmpDueDate}
                          onChange={(newValue: DateRange<Date> | null) => {
                            handleScheduleChange('cmpDueDate', newValue);
                          }}
                          renderInput={(params: unknown) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{ svg: { color: '#0098da' } }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="card-notification-container">
                    <Card
                      className="card-notification"
                      hidden={
                        !checkCloserNotification(
                          scheduleForm.cmpDueDate,
                          scheduleForm.constructionStartDate
                        )
                      }
                      sx={{ minWidth: 275, minHeight: 16 }}
                    >
                      <CardContent>
                        <Typography variant="body2">
                          Please submit your permit closer to your contruction
                          date.
                        </Typography>
                      </CardContent>
                    </Card>
                    <Card
                      className="card-notification"
                      hidden={
                        !checkExtendPermitNotification(
                          scheduleForm.cmpDueDate,
                          scheduleForm.constructionEndDate
                        )
                      }
                      sx={{ minWidth: 275 }}
                    >
                      <CardContent>
                        <Typography variant="body2">
                          Your permit may expire during construction, you may
                          want to file for an extension early.
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                </div>
                <div className="description">
                  What <strong>time</strong> of the day will work be done?
                </div>
                <div className="times-container">
                  <TextField
                    label="Daily Start Time"
                    type="time"
                    size="small"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={onStartTimeChange}
                    defaultValue={
                      scheduleForm.workStartTime
                        ? moment(scheduleForm.workStartTime)
                            .parseZone()
                            .format('HH:mm')
                        : null
                    }
                    sx={{ width: 180 }}
                  />
                  <Box sx={{ mx: 2 }}> - </Box>
                  <TextField
                    label="Daily End Time"
                    type="time"
                    size="small"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={onEndTimeChange}
                    defaultValue={
                      scheduleForm.workEndTime
                        ? moment(scheduleForm.workEndTime)
                            .parseZone()
                            .format('HH:mm')
                        : null
                    }
                    sx={{ width: 180 }}
                  />
                </div>

                <div className="description">
                  For budget code <strong>CMP</strong>, please provide the due
                  date.
                </div>
              </div>
            ) : requestExtDialog ? (
              <p>
                Requesting a permit extension will spin off a new permit
                request. You will need to review the information and submit the
                request to permitting.
                <br /> <br />
                Are you sure you want to create an extension for this permit?
              </p>
            ) : (
              <p>
                Requesting a night option will spin off a new permit request.
                You will need to review the information and submit the request
                to permitting.
                <br /> <br />
                Are you sure you want to create a night option for this permit?
              </p>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOnClose(true)}>Cancel</Button>
          {isRequestingPermitExtension ? (
            <Button disabled variant="contained">
              Creating Night Extension{' '}
              <CircularProgress size="15px" sx={{ marginLeft: '10px' }} />
            </Button>
          ) : (
            <Button
              ref={reqExtRef}
              variant="contained"
              form="schedule-form"
              type="submit"
              id={requestExtDialog ? 'reqExt' : 'nightOpt'}
            >
              {(scheduleDialog && 'Update') ||
                (requestExtDialog && 'Request Extension') ||
                (nightExtDialog && 'Request Night Option')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapDispatchToProps = {
  updatePermit: permitActions.updateSubmittedPermitScheduleAction,
  spinoffNewPermit: permitActions.spinoffNewPermitAction
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Schedule);
