import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { LoadingOverlay } from 'src/components/utils/LoadingOverlay/LoadingOverlay';
import { TrenchSpec } from 'src/models/trench.model';
import { RootState } from 'src/store/store';
import './TrenchSpecification.scss';

interface Props extends PropsFromRedux {
  onRemove?: () => void;
  onChange?: (update: Partial<TrenchSpec>) => void;
  isLoading?: boolean;
  trenchSpec?: TrenchSpec;
  styles?: { [key: string]: string };
}

const TrenchSpecification = (props: Props) => {
  const {
    onRemove,
    onChange,
    isLoading,
    trenchSpec,
    trenchRepairTypes,
    styles
  } = props;

  const [trenchSpecForm, setTrenchSpecForm] = useState<Partial<TrenchSpec>>({
    trenchRepairTypeId: '',
    intersectingStreet: '',
    length: '',
    width: '',
    depth: ''
  });
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setShowLoading(isLoading || false);
  }, [isLoading]);

  useEffect(() => {
    setTrenchSpecForm({
      trenchRepairTypeId: trenchSpec?.trenchRepairTypeId || '',
      intersectingStreet: trenchSpec?.intersectingStreet || '',
      length: trenchSpec?.length || '',
      width: trenchSpec?.width || '',
      depth: trenchSpec?.depth || ''
    });
  }, [trenchSpec]);

  const handleFormChange = (name: string, value: any) => {
    onChange && onChange({ [name]: value });
    setTrenchSpecForm({ ...trenchSpecForm, [name]: value });
  };

  return (
    <div className="trench-specification" style={{ ...styles }}>
      <LoadingOverlay isVisible={showLoading} />

      {onRemove && (
        <IconButton
          className="remove-button"
          title="Remove Trench Specification"
          onClick={() => {
            setShowLoading(true);
            onRemove();
          }}
        >
          <RemoveCircleIcon />
        </IconButton>
      )}

      <div className="first-col">
        <FormControl size="small" required>
          <InputLabel id="trench-repair-type-select">
            Trench Repair Type
          </InputLabel>
          <Select
            labelId="trench-repair-type-select"
            label="Trench Repair Type"
            name="trenchRepairTypeId"
            fullWidth
            value={trenchSpecForm.trenchRepairTypeId}
            onChange={(event) =>
              handleFormChange(event.target.name, event.target.value)
            }
          >
            {trenchRepairTypes.map((repairType) => (
              <MenuItem key={repairType.id} value={repairType.id}>
                {repairType.type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Intersecting Street or Additional Facility Info (optional)"
          size="small"
          name="intersectingStreet"
          fullWidth
          value={trenchSpecForm.intersectingStreet}
          onChange={(event) =>
            handleFormChange(event.target.name, event.target.value)
          }
        />
      </div>

      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ margin: 0 }}
      />

      <div className="dimensions">
        <div className="title">TRENCH DIMENSIONS</div>
        <div className="inputs">
          <TextField
            label="Length"
            size="small"
            name="length"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">ft</InputAdornment>
            }}
            value={trenchSpecForm.length}
            onChange={(event) =>
              handleFormChange(event.target.name, event.target.value)
            }
          />
          <TextField
            label="Width"
            size="small"
            name="width"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">ft</InputAdornment>
            }}
            value={trenchSpecForm.width}
            onChange={(event) =>
              handleFormChange(event.target.name, event.target.value)
            }
          />
          <TextField
            label="Depth"
            size="small"
            name="depth"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">ft</InputAdornment>
            }}
            value={trenchSpecForm.depth}
            onChange={(event) =>
              handleFormChange(event.target.name, event.target.value)
            }
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  trenchRepairTypes: state.filter.dropdownOptions?.trenchRepairTypes || []
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TrenchSpecification);
