import { Avatar, Badge, Box, IconButton, Popper } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './PermitHeader.scss';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { NeedsAction } from 'src/models/needs-action.model';
import { UnreadComment, UnreadComments } from 'src/models/comment.model';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getNeedsActionNotificationsAction } from '../../store/action-creators/needs-action-actions';

type Props = {
  className?: string;
  children?: JSX.Element;
  commentCount?: number;
  notificationsCount?: number;
  needsActionNotifications: NeedsAction[];
  unreadComments: UnreadComments;
};

const PermitHeader = (props: Props) => {
  const {
    className,
    children,
    notificationsCount,
    needsActionNotifications,
    unreadComments
  } = props;

  const history = useHistory();
  const [anchorElUnreadComments, setAnchorElUnreadComments] =
    React.useState<null | HTMLElement>(null);
  const [anchorElNeedsAction, setAnchorElNeedsAction] =
    React.useState<null | HTMLElement>(null);

  const dispatch = useDispatch();
  // get notifications
  useEffect(() => {
    dispatch(getNeedsActionNotificationsAction());
  }, []);

  //Unread Comments specific Popper variables/functions
  const openUnreadComments = Boolean(anchorElUnreadComments);
  const idUnreadComments = openUnreadComments
    ? 'unread-comments-popper'
    : undefined;

  const handleClickUnreadComments = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUnreadComments(
      anchorElUnreadComments ? null : event.currentTarget
    );
  };

  //Needs Action specific Popper variables/functions
  const openNeedsAction = Boolean(anchorElNeedsAction);
  const idNeedsAction = openNeedsAction ? 'needs-action-popper' : undefined;

  const handleClickNeedsAction = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNeedsAction(anchorElNeedsAction ? null : event.currentTarget);
  };

  const handlePopperClick = (
    event: React.MouseEvent<HTMLElement>,
    permitId: number | undefined
  ) => {
    setAnchorElNeedsAction(anchorElNeedsAction ? null : event.currentTarget);
    history.push(`/permit/${permitId}`);
  };

  return (
    <div className={classNames('permit-header', className)}>
      {children}

      <div className="badges">
        {unreadComments.unreadCommentTotal > 0 && (
          <IconButton onClick={handleClickUnreadComments}>
            <Badge
              badgeContent={unreadComments.unreadCommentTotal}
              color="primary"
            >
              <ChatBubbleOutlineIcon />
              <div>
                <Popper
                  id={idUnreadComments}
                  open={openUnreadComments}
                  anchorEl={anchorElUnreadComments}
                  placement="bottom-end"
                >
                  <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                    <IconButton
                      aria-label="close"
                      onClick={handleClickUnreadComments}
                      sx={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        color: (theme) => theme.palette.grey[500]
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <div className="action-card-content">
                      <div className="unread-comments-title">
                        <strong>COMMENTS</strong>
                      </div>
                      {unreadComments.unreadComments?.map(
                        (comment: UnreadComment) => (
                          <div
                            key={comment.permitId}
                            className="new-comment-item"
                            onClick={(e) =>
                              handlePopperClick(e, comment.permitId)
                            }
                          >
                            <Avatar className="author-avatar">
                              {comment.lastComment.author.initials}
                            </Avatar>
                            <div>
                              <div className="unread-comment-details">
                                Job# {comment.jobNumber}
                              </div>
                              <Box
                                className="unread-comment-text"
                                sx={{
                                  color: (theme) => theme.palette.primary.main
                                }}
                              >
                                <strong>
                                  {comment.newCommentCount} New Comments
                                </strong>{' '}
                                {comment.lastComment.text}
                              </Box>
                              <div className="author-details">
                                {comment.lastComment.author.firstName}{' '}
                                {comment.lastComment.author.lastName} (
                                {moment(comment.lastComment.createdAt).format(
                                  'M/D/YYYY - HH:mm'
                                )}
                                )
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </Box>
                </Popper>
              </div>
            </Badge>
          </IconButton>
        )}
        {needsActionNotifications.length > 0 ? (
          <IconButton onClick={handleClickNeedsAction}>
            <Badge badgeContent={notificationsCount} color="error">
              <ErrorOutlineIcon />
              <div>
                <Popper
                  id={idNeedsAction}
                  open={openNeedsAction}
                  anchorEl={anchorElNeedsAction}
                  placement="bottom-end"
                >
                  <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                    <IconButton
                      aria-label="close"
                      onClick={handleClickNeedsAction}
                      sx={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        color: (theme) => theme.palette.grey[500]
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <div className="action-card-content">
                      <div className="needs-action-title">NEED ACTION</div>
                      {needsActionNotifications?.map(
                        (actionItem: NeedsAction, index: number) => (
                          <div key={index}>
                            <div
                              className='needs-action-job-details'
                              onClick={(e) =>
                                handlePopperClick(e, actionItem.permitRequestId)
                              }
                            >
                              Job No {actionItem.jobNumber}
                            </div>
                            <div
                              className="needs-action-text"
                              onClick={(e) =>
                                handlePopperClick(e, actionItem.permitRequestId)
                              }
                            >
                              {actionItem?.text}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </Box>
                </Popper>
              </div>
            </Badge>
          </IconButton>
        ) : (
          <IconButton onClick={handleClickNeedsAction}>
            <Badge color="error">
              <ErrorOutlineIcon />
              <div>
                <Popper
                  id={idNeedsAction}
                  open={openNeedsAction}
                  anchorEl={anchorElNeedsAction}
                  placement="bottom-end"
                >
                  <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                    <div>No new action notifications</div>
                    <IconButton
                      aria-label="close"
                      onClick={handleClickNeedsAction}
                      sx={{
                        position: 'absolute',
                        right: 12,
                        top: 0,
                        color: (theme) => theme.palette.grey[500]
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Popper>
              </div>
            </Badge>
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default PermitHeader;
