import { Divider } from '@mui/material';
import './Comments.scss';
import * as commentActions from 'src/store/action-creators/comment-actions';
import * as permitActions from 'src/store/action-creators/permit-actions';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/store/store';
import CommentItem from './CommentItem/CommentItem';
import CommentTextbox from './CommentTextbox/CommentTextbox';
import { useEffect, useState } from 'react';
import { LoadingOverlay } from 'src/components/utils/LoadingOverlay/LoadingOverlay';

interface Props extends PropsFromRedux {
  permitId: string;
}

const Comments = (props: Props) => {
  const {
    comments,
    currentUser,
    permitId,
    tagUser,
    addComment,
    getComments,
    setCommentTagUser,
    updateCmntTimest
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [newCommentText, setNewCommentText] = useState('');

  useEffect(() => {
    let mounted = true;
    if (permitId) {
      setIsLoading(true);
      getComments(permitId).then(
        () => {
          if (mounted) setIsLoading(false);
        },
        () => {
          if (mounted) setIsLoading(false);
        }
      );
      updateCmntTimest(permitId);
    }
    return () => {
      mounted = false;
    };
  }, [permitId, getComments]);

  useEffect(() => {
    if (tagUser) {
      setNewCommentText(
        `${newCommentText}@[name:${tagUser.name}](email:${tagUser.email})`
      );
      setCommentTagUser(null);
    }
  }, [tagUser]);

  const handleCommentCreate = (text: string) => {
    setNewCommentText(text);

    if (currentUser) {
      setIsLoadingCreate(true);
      addComment(permitId, { text }).then(
        () => {
          setNewCommentText('');
          setIsLoadingCreate(false);
        },
        () => setIsLoadingCreate(false)
      );
    }
  };

  return (
    <div className="comments-section">
      <div className="title">
        <strong>Comments</strong>
      </div>

      <div className="comment-list">
        <LoadingOverlay isVisible={isLoading} />

        {comments.map((item, index) => (
          <CommentItem
            key={item.id}
            comment={item}
            isReply={Boolean(item?.replyTo)}
            permitId={permitId}
            position={index}
          />
        ))}
      </div>
      <Divider sx={{ margin: '12px -12px', borderBottomWidth: '2px' }} />

      <CommentTextbox
        className="new-comment"
        isLoading={isLoadingCreate}
        value={newCommentText}
        onChange={(event) => setNewCommentText(event.target.value)}
        onSubmit={(text) => handleCommentCreate(text)}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  comments: state.comment.comments,
  currentUser: state.auth.currentUser,
  tagUser: state.comment.tagUser
});

const mapDispatchToProps = {
  addComment: commentActions.addCommentAction,
  getComments: commentActions.getCommentsAction,
  setCommentTagUser: commentActions.setCommentTagUserAction,
  updateCmntTimest: permitActions.updateCmntTimestAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Comments);
