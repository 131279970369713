import ActionType from '../action-types';
import { PermitState, PermitActions } from '../../models/store-permit.model';

const initialState: PermitState = {
  pageNumber: 0,
  totalCount: 0,
  permits: [],
  permit: null,
  allStatuses: []
};

const permitReducer = (
  state: PermitState = initialState,
  action: PermitActions
): PermitState => {
  const today = new Date();

  switch (action.type) {
    case ActionType.SET_PERMITS:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        totalCount: action.payload.totalCount,
        permits: action.payload.permits
      };
    case ActionType.LOAD_ADDITIONAL_PERMITS:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        totalCount: action.payload.totalCount,
        permits: [...state.permits, ...action.payload.permits]
      };
    case ActionType.SET_PERMIT:
      return {
        ...state,
        permit: action.payload
      };
    case ActionType.SET_PERMIT_PROPERTIES:
      return {
        ...state,
        ...(state.permit && {
          permit: {
            ...state.permit,
            ...action.payload
          }
        })
      };
    case ActionType.ADD_TRENCH_SPEC:
      return {
        ...state,
        ...(state.permit && {
          permit: {
            ...state.permit,
            trenchSpecifications: [
              ...state.permit.trenchSpecifications,
              action.payload
            ]
          }
        })
      };
    case ActionType.REMOVE_TRENCH_SPEC:
      return {
        ...state,
        ...(state.permit && {
          permit: {
            ...state.permit,
            trenchSpecifications: [
              // get all trench specification except the removed one
              ...state.permit.trenchSpecifications.filter(
                (item) => item.id !== action.payload
              )
            ]
          }
        })
      };
    case ActionType.SET_TRENCH_SPEC_PROPERTY:
      return {
        ...state,
        ...(state.permit && {
          permit: {
            ...state.permit,
            trenchSpecifications: [
              ...state.permit.trenchSpecifications.map((item) =>
                item.id === action.payload.id
                  ? { ...item, ...action.payload }
                  : item
              )
            ]
          }
        })
      };
    case ActionType.ADD_SUPPORTING_ADDRESS:
      return {
        ...state,
        ...(state.permit && {
          permit: {
            ...state.permit,
            supportingAddress: [
              ...state.permit.supportingAddress,
              action.payload
            ]
          }
        })
      };
    case ActionType.SET_PERMIT_ASSIGNED_USERS:
      return {
        ...state,
        ...(state.permit && {
          permit: {
            ...state.permit,
            assignedUsers: action.payload
          }
        })
      };
    case ActionType.REMOVE_SUPPORTING_ADDRESS:
      return {
        ...state,
        ...(state.permit && {
          permit: {
            ...state.permit,
            supportingAddress: [
              ...state.permit.supportingAddress.filter(
                (item) => item.time !== action.payload
              )
            ]
          }
        })
      };
    case ActionType.SET_SUPPORTING_ADDRESS:
      return {
        ...state,
        ...(state.permit && {
          permit: {
            ...state.permit,
            supportingAddress: [
              ...state.permit.supportingAddress.map((item) =>
                item.time === action.payload.time
                  ? { ...item, ...action.payload }
                  : item
              )
            ]
          }
        })
      };
    case ActionType.RESET_PERMIT_UNREAD_COMMENTS:
      return {
        ...state,
        permits: [
          ...state.permits.map((permit) =>
            String(permit.id) === action.payload
              ? {
                  ...permit,
                  unreadCommentsCount: 0
                }
              : permit
          )
        ]
      };
    case ActionType.SET_PRIMARY_ADDRESS:
      return {
        ...state,
        ...(state.permit && {
          permit: {
            ...state.permit,
            primaryAddress: { ...action.payload }
          }
        })
      };
    case ActionType.SET_ASSIGNED_USERS:
      return {
        ...state,
        ...(state.permit && {
          permit: {
            ...state.permit,
            assignedUsers: [...action.payload]
          }
        })
      };
    case ActionType.ADD_PERMIT_STATUS_SPEC:
      return {
        ...state,
        ...(state.permit && {
          permit: {
            ...state.permit,
            requestStatusId: action.payload.statusId,
            statuses: [
              ...state.permit.statuses,
              {
                statusId: action.payload.statusId,
                email: action.payload.email,
                createdAt: today.toISOString()
              }
            ]
          }
        })
      };
    case ActionType.REMOVE_PERMIT_STATUS:
      return {
        ...state,
        ...(state.permit && {
          permit: {
            ...state.permit,
            statuses: state.permit.statuses.filter(
              (status) => status.id !== parseInt(action.payload.id)
            )
          }
        })
      };
    case ActionType.SET_ALL_STATUSES:
      return {
        ...state,
        allStatuses: [...action.payload]
      };
    case ActionType.REMOVE_PERMIT_REQUEST:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        totalCount: action.payload.totalCount,
        permits: state.permits.filter(
          (permit) => permit.id !== action.payload.permitId
        )
      };
    case ActionType.SET_PERMIT_NOTES:
      return {
        ...state,
        ...(state.permit && {
          permit: {
            ...state.permit,
            k2Notes: action.payload
          }
        })
      };
    default:
      return state;
  }
};

export default permitReducer;
