import { Box, Divider, Grid, Paper } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import envConfig from 'src/env-config';
import { Agency } from 'src/models/agency.model';
import { RootState } from 'src/store/store';
import axiosClient from 'src/utils/axios-client';
import { isAssertEntry } from 'typescript';
import { getAssignmentByProjectType } from './assignment-utils';

/**
 *  Utility funciton to format the api endpoint response
 */
const parseResponse = ({ body }: { body: any }) => {
  const coordinators = body || [];
  const format = (o: any) => {
    return `${o.name} (${o.role})`;
  };
  return coordinators.map(format).join('; ');
};

/**
 * A row in the list
 */
const AgencyItem = ({ agency }: { agency: Agency }) => {
  const [coordinators, setCoordinators] = useState('');
  const [coordinatorsByPT, setCoordinatorsByPT] = useState<any>();

  const fetchData = useCallback(async () => {
    const permitId = 0; // some non existent permit
    const response = await axiosClient.post(
      `${envConfig.api.permits}/${permitId}/assignUsers`,
      {
        primarAgencyId: agency.id,
        tcpRequired: true
      }
    );
    return parseResponse(response.data.afpermitsResponse);
  }, []);

  const enhanceCoordinators = (c: string) => {
    return c;
  };

  useEffect(() => {
    fetchData()
      .then((result) => setCoordinators(enhanceCoordinators(result)))
      .catch(console.error);
  }, [agency.id]);

  useEffect(() => {
    const projectTypes = ['Tier 2', 'Tier 3', 'HFDT', 'GTS'];
    const coordinatesByProjectType = projectTypes.map((projectType) => {
      const result = getAssignmentByProjectType({
        projectType,
        agency: agency.name
      });

      // utility function for displaying
      const formatAssignmentByPTresult = (r: object) => {
        // object key is e.g. ROLE_PSS
        return Object.keys(r)
          .filter((k) => r[k]) // remove empty results 
          .map((k) => `${r[k]} (${k})`) // format as as: Name (ROLE_XYZ)
          .join('; ');
      };
      const coordinatorsForPT = formatAssignmentByPTresult(result);

      return {
        projectType,
        coordinators: coordinatorsForPT
      };
    });

    const removedEmptyAssignments = coordinatesByProjectType.filter(
      (i) => i.coordinators
    );

    setCoordinatorsByPT(removedEmptyAssignments);
  }, [agency.name]);

  const renderCoordinatorsByPT = (c: any[]) => {
    if (!c || !c.length) return null;
    return (
      <div>
        {c.map((entry) => (
          <div key={entry.projectType}>
            {entry.projectType}: {entry.coordinators}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Grid item container xs={12} >

        <Grid item xs={3}>
          {agency.name}
        </Grid>
        <Grid item xs={9}>
          <div>
            <i>By jurisdiction (current):</i>
          </div>
          <Box sx={{ ml: 2 }}>{coordinators}</Box>
        </Grid>
      </Grid>

      <Grid item container xs={12} pb={1} style={{ borderBottom: '1px solid silver' }}>
        <Grid item xs={3}></Grid>
        <Grid item xs={9}>
          <i>By project type (not in production yet):</i>
          <Box sx={{ ml: 2 }}>{renderCoordinatorsByPT(coordinatorsByPT)}</Box>
        </Grid>
      </Grid>
    </>
  );
};

/**
 * Main Component
 * @returns
 */
const Assignment = () => {
  const agencies = useSelector<RootState, Agency[]>(
    (state) => state.filter.dropdownOptions.agencies
  );

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <h2>Coordinators auto-assignment per Agency</h2>
      <Box sx={{ pb: 2, px: 1 }}>
        <p>
          List of agencies and the result of the auto-assigning logic.
          <br />
          Only TCP,PSS,QAQC roles are auto-assigned.
          <br />
          Other assigments tracked in DB (Muni Advisor, Tech Specialist,
          Supervisor) are not displayed here.
        </p>
      </Box>

      <Paper sx={{ p: 3 }}>
        <Grid container spacing={1}>
          {agencies
            .filter((a) => true || a.id == 93)
            .map((a) => (
              <AgencyItem key={a.id} agency={a} />
            ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default Assignment;
