import { Link } from 'react-router-dom';
import './NotFound.scss';

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="message">
        <div className="code-404">404</div>
        <div className="info">Page not found!</div>
        <div className="link">
          You can continue by navigating back to{' '}
          <Link to="/dashboard">Dashboard</Link>.
        </div>
      </div>
    </div>
  );
};

export default NotFound;
