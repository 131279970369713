import { Dispatch, SetStateAction } from 'react';
import './StatusTimeline.scss';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, IconButton, Chip } from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import { Status, PermitRequest } from 'src/models/permit.model';
import {
  ROLE_QA_QC,
  ROLE_TS,
  ROLE_PSS,
  ROLE_TCP
} from 'src/constants/coordinator-roles';
import { formatUsDate } from 'src/utils/formatting-utils';

type TimelineItemWrapProps = {
  text: string;
  chipText?: string;
};

const TimelineItemWrap = (props: TimelineItemWrapProps) => {
  const { text, chipText } = props;
  return (
    <Grid container spacing={0}>
      <Grid item xs={chipText ? 8 : 12} className="statusName">
        {text}
      </Grid>
      {chipText && (
        <Grid item xs={4} justifyContent="flex-end">
          <Chip label={chipText} />
        </Grid>
      )}
    </Grid>
  );
};

type TimelineStepProps = {
  text: string;
  chipText?: string;
  active?: boolean;
  warning?: boolean;
  children?: any;
};

const TimelineStep = (props: TimelineStepProps) => {
  const { text, chipText, active, warning, children } = props;

  const dotStyles: any = {};
  if (active) {
    dotStyles.bgcolor = 'black';
  }
  if (warning) {
    dotStyles.bgcolor = 'red';
  }

  const getIcon = () => {
    if (warning) {
      return <WarningIcon sx={{ fontSize: 10 }} />;
    } else {
      return <CheckIcon sx={{ fontSize: 10 }} />;
    }
  };

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot sx={dotStyles}>{getIcon()}</TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <TimelineItemWrap text={text} chipText={chipText} />
        {children}
      </TimelineContent>
    </TimelineItem>
  );
};

type Props = {
  statuses: Status[];
  allStatuses: any[];
  isStatusBoxOpen?: boolean;
  setIsStatusBoxOpen?: Dispatch<SetStateAction<boolean>>;
  permit: PermitRequest;
};

type Categories =
  | 'Pending Review'
  | 'In-progress with Permitting'
  | 'In-progress with Agency'
  | 'Permit Issued';

const StatusTimeline = (props: Props) => {
  const {
    statuses = [],
    allStatuses,
    isStatusBoxOpen,
    setIsStatusBoxOpen,
    permit
  } = props;
  let activeCategory: Categories = 'Pending Review';

  const allPssStatuses = allStatuses
    .filter(
      (status) =>
        status.usedBy &&
        status.usedBy.includes(ROLE_PSS) &&
        status.category != 'Complete'
    )
    .map((status) => status.id);
  const allQaQcStatuses = allStatuses
    .filter((status) => status.usedBy && status.usedBy.includes(ROLE_QA_QC))
    .map((status) => status.id);
  const allTcpStatuses = allStatuses
    .filter((status) => status.usedBy && status.usedBy.includes(ROLE_TCP))
    .map((status) => status.id);
  const allTsStatuses = allStatuses
    .filter((status) => status.usedBy && status.usedBy.includes(ROLE_TS))
    .map((status) => status.id);
  const allAgencyStatuses = allStatuses
    .filter((status) => status.category === 'In-progress with Agency')
    .map((status) => status.id);
  const allIssuedStatuses = allStatuses
    .filter((status) => status.category === 'Complete')
    .map((status) => status.id);

  // containing when the permit was created
  const permitCreated = statuses.find((item) => item.statusId === 0);
  // submitted to permitting (Permit Inbox) status
  const permitRequested = statuses.find((item) => item.statusId === 4);

  const pssStatuses = statuses.filter(
    (item) =>
      allPssStatuses.includes(item.statusId) &&
      !allAgencyStatuses.includes(item.statusId)
  );
  const qaStatuses = statuses.filter(
    (item) =>
      allQaQcStatuses.includes(item.statusId) &&
      !allAgencyStatuses.includes(item.statusId)
  );
  const tcpStatuses = statuses.filter(
    (item) =>
      allTcpStatuses.includes(item.statusId) &&
      !allAgencyStatuses.includes(item.statusId)
  );
  const tsStatuses = statuses.filter(
    (item) =>
      allTsStatuses.includes(item.statusId) &&
      !allAgencyStatuses.includes(item.statusId)
  );

  const inProgressStatuses = pssStatuses.concat(
    qaStatuses,
    tcpStatuses,
    tsStatuses
  );
  if (inProgressStatuses.length > 0) {
    activeCategory = 'In-progress with Permitting';
  }
  const agencyStatuses = statuses.filter((item) =>
    allAgencyStatuses.includes(item.statusId)
  );
  if (agencyStatuses.length > 0) {
    activeCategory = 'In-progress with Agency';
  }

  const issuedStatuses = statuses.filter((item) =>
    allIssuedStatuses.includes(item.statusId)
  );
  if (issuedStatuses.length > 0) {
    activeCategory = 'Permit Issued';
  }

  return (
    <>
      {isStatusBoxOpen && setIsStatusBoxOpen && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 3,
            width: 350,
            height: 'calc(100% - 64px)',
            backgroundColor: 'white',
            overflow: 'scroll'
          }}
        >
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="right"
              alignItems="right"
            >
              <IconButton
                onClick={() => {
                  setIsStatusBoxOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Timeline position="right" className="root-status-timeline">
                {props.permit.statuses.map(
                  (item, index) =>
                    item.statusName && (
                      <TimelineStep text={item.statusName} key={index}>
                        {item.updatedAt != undefined && (
                          <li>{formatUsDate(item.updatedAt)}</li>
                        )}
                        <li>{item.email}</li>
                      </TimelineStep>
                    )
                )}
                {/* {(permitRequested || permitCreated) && (
                  <TimelineStep
                    text="Permit Requested"
                    chipText={
                      formatDateTime(
                        permitRequested?.createdAt || permitCreated?.createdAt
                      ) || ''
                    }
                  />
                )}
                {permit.lastK2ModifiedDate && permit.lastK2Status && (
                  <TimelineStep
                    text={lastK2Status.name}
                    chipText={
                      formatDateTime(
                        permit.lastK2ModifiedDate || permit.lastK2ModifiedDate
                      ) || ''
                    }
                  />
                )}
                {permitRequested && (
                  <TimelineStep
                    text="Pending Review"
                    active={activeCategory === 'Pending Review'}
                  />
                )}

                {inProgressStatuses.length > 0 && (
                  <TimelineStep
                    text="In-progress with Permitting"
                    chipText={
                      formatDateTime(inProgressStatuses[0].createdAt) || ''
                    }
                    active={activeCategory === 'In-progress with Permitting'}
                  >
                    <Timeline
                      position="right"
                      className="child-status-timeline"
                    >
                      {inProgressStatuses.length > 0 &&
                        inProgressStatuses.map((status, index) => {
                          const name: string = allStatuses.find(
                            (s) => s.id === status.statusId
                          )?.name as string;
                          const category: string = allStatuses.find(
                            (s) => s.id === status.statusId
                          )?.category as string;
                          const isWarning: boolean =
                            category === 'Needs Action';
                          const foundAnotherStatusBySameUser =
                            inProgressStatuses
                              .slice(index + 1)
                              .find((s) => s.email === status.email);
                          const foundAnotherAgencyStatusBySameUser =
                            agencyStatuses.find(
                              (s) => s.email === status.email
                            );

                          if (
                            (foundAnotherStatusBySameUser ||
                              foundAnotherAgencyStatusBySameUser) &&
                            isWarning
                          )
                            return null;

                          return (
                            <TimelineStep
                              key={name + status.createdAt}
                              text={name}
                              warning={isWarning}
                            />
                          );
                        })}
                    </Timeline>
                  </TimelineStep>
                )}

                {agencyStatuses.length > 0 && (
                  <TimelineStep
                    text="In-progress with Agency"
                    chipText={formatDateTime(agencyStatuses[0].createdAt) || ''}
                  >
                    <Timeline
                      position="right"
                      className="child-status-timeline"
                    >
                      {agencyStatuses.map((status, index) => {
                        const name: string = allStatuses.find(
                          (s) => s.id === status.statusId
                        )?.name as string;
                        const category: string = allStatuses.find(
                          (s) => s.id === status.statusId
                        )?.category as string;
                        const isWarning: boolean = category === 'Needs Action';
                        const foundAnotherStatusBySameUser = agencyStatuses
                          .slice(index + 1)
                          .find((s) => s.email === status.email);

                        if (foundAnotherStatusBySameUser && isWarning)
                          return null;

                        return (
                          <TimelineStep
                            key={name + status.createdAt}
                            text={name}
                            warning={isWarning}
                          />
                        );
                      })}
                    </Timeline>
                  </TimelineStep>
                )}
                {issuedStatuses.length > 0 && (
                  <TimelineStep
                    text="Complete"
                    chipText={formatDateTime(issuedStatuses[0].createdAt) || ''}
                  >
                    <Timeline
                      position="right"
                      className="child-status-timeline"
                    >
                      {issuedStatuses.map((status, index) => {
                        const name: string = allStatuses.find(
                          (s) => s.id === status.statusId
                        )?.name as string;
                        const category: string = allStatuses.find(
                          (s) => s.id === status.statusId
                        )?.category as string;
                        const isWarning: boolean = category === 'Needs Action';
                        const foundAnotherStatusBySameUser = issuedStatuses
                          .slice(index + 1)
                          .find((s) => s.email === status.email);

                        if (foundAnotherStatusBySameUser && isWarning)
                          return null;

                        return (
                          <TimelineStep
                            key={name + status.createdAt}
                            text={name}
                            warning={isWarning}
                          />
                        );
                      })}
                    </Timeline>
                  </TimelineStep>
                )} */}
              </Timeline>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default StatusTimeline;
