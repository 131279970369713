import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ChatIcon from '@mui/icons-material/Chat';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import FilterListIcon from '@mui/icons-material/FilterList';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import {
  Autocomplete,
  Badge,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  debounce,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import classNames from 'classnames';
import './Dashboard.scss';
import * as commentActions from 'src/store/action-creators/comment-actions';
import * as filterActions from 'src/store/action-creators/filter-actions';
import * as permitActions from 'src/store/action-creators/permit-actions';
import { useHistory } from 'react-router';
import PermitHeader from '../PermitHeader/PermitHeader';
import { RootState } from 'src/store/store';
import { connect, ConnectedProps } from 'react-redux';
import {
  getGroupedPermits,
  getIsStatusEnabled
} from '../../utils/permit-utils';
import { RequestStatus } from 'src/models/request-status.model';
import { DashboardFilter } from 'src/models/filter.model';
import { PermitDashboard } from 'src/models/permit.model';
import { needsActionBadgeNotificationsSelector } from 'src/store/selectors/needs-action-selectors';
import {
  formatDateTime,
  getUserRoleInitials
} from 'src/utils/formatting-utils';
import { match } from 'assert';

const NOT_AVAILABLE = 'N/A';

const Dashboard = (props: PropsFromRedux) => {
  const {
    currentUser,
    pageNumber,
    totalCount,
    requestStatuses,
    agencies,
    users,
    allUsers,
    isLoadingOptions,
    filter,
    needsActionNotifications,
    unreadComments,
    clearPermits,
    getPermits,
    getPermitsExport,
    setDashboardFilter,
    getUnreadComments
  } = props;
  // don't display component if user is not logged in
  if (!currentUser) return null;

  let notificationsCount = 0;
  const history = useHistory();

  const [, setPage] = useState(pageNumber);
  const [, setPositionY] = useState(0);
  const [isLoadingPermits, setIsLoadingPermits] = useState(false);
  const [isDownloadingPermitsExport, setIsDownloadingPermitsExport] =
    useState(false);
  const [permits, setPermits] = useState<PermitDashboard[]>([]);
  const [search, setSearch] = useState('');

  const loadingElementRef = useRef<HTMLDivElement>(null);
  const filterPropRef = useRef<any>(null);
  filterPropRef.current = props.filter;
  const isLoadingStateRef = useRef<any>(null);
  isLoadingStateRef.current = isLoadingPermits;

  //Custom HTML for Tooltip Hover Feature
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      position: 'relative',
      width: '300px',
      height: 'auto',
      background: '#FFFFFF',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '3px',
      color: '#000000',
      paddingBottom: '20px',
      fontSize: '10px'
    }
  }));

  //Tooltip Color Determination - Conditional Color Rendering
  const determineToolTipColor = (
    higherLevelCategory: string | null | undefined
  ) => {
    if (higherLevelCategory === 'In-progress with Agency') {
      return 'primary.main'; //blue
    } else if (higherLevelCategory === 'Needs Action') {
      return 'error.main'; //red
    } else if (higherLevelCategory === 'Complete') {
      return 'success.main'; //green
    } else if (higherLevelCategory === 'Not submitted to permitting') {
      return '#ffc107'; //yellow
    } else {
      return '#9D9D9D'; //gray
    }
  };

  // trigger whenever filter changes
  // trigger on component mount
  useEffect(() => {
    getUnreadComments();
    // initialize observer - used for inifinite scroll
    const options = {
      root: null,
      rootMargin: '64px',
      threshold: [0, 0.5, 1]
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loadingElementRef.current) {
      observer.observe(loadingElementRef.current);
    }
    return () => {
      // stop observing on component dismount - to prevent memory leaks
      observer.disconnect();
    };
  }, []);

  // trigger whenever props.filter changes
  useEffect(() => {
    if (currentUser && users.length > 0) {
      // reset permits - so that loading is displayed correctly
      setPermits([]);
      // show loading
      setIsLoadingPermits(true);
      // get permits data
      const currPage = 1;
      // load permit data from backend
      getPermits(filter, currPage).then((res) => {
        // console.log(res.payload.permits);
        setPermits(res.payload.permits);
        // hide loading
        setIsLoadingPermits(false);
      });
      setPage(currPage);
    }
    setSearch(filter.search);
  }, [filter]);

  // callback function for infinite scroll
  const handleObserver = ([entry]: IntersectionObserverEntry[]) => {
    // lot of prevState logic below, because callback funcion is not getting updated state right away
    setPositionY((prevPositionY) => {
      const currPositionY = entry.boundingClientRect.y;
      // if scrolled at the bottom of the page and data are not being loaded already, try to load additional data
      if (
        prevPositionY > currPositionY &&
        entry.intersectionRatio === 1 &&
        !isLoadingStateRef.current
      ) {
        // show loading
        setIsLoadingPermits(true);
        // update state + load data
        setPage((prevPage) => {
          const currPage = prevPage + 1;
          getPermits(filterPropRef.current, currPage).then((res) => {
            setPermits((prevState) => [...prevState, ...res.payload.permits]);
            // hide loading
            setIsLoadingPermits(false);
          });
          return currPage;
        });
      }
      return currPositionY;
    });
  };

  // Set notificationsCount
  notificationsCount = needsActionNotifications.length;

  const handleFilterChange = (
    name:
      | 'search'
      | 'requestStatuses'
      | 'subStatuses'
      | 'team'
      | 'requestors'
      | 'agencies',
    value: any
  ) => {
    // clear requestStatus filter, if subStatuses are selected and vice-versa
    let modifyOther = {};
    if (name == 'subStatuses') {
      modifyOther = { requestStatuses: [] };
    }
    if (name == 'requestStatuses') {
      modifyOther = { subStatuses: [] };
    }

    clearPermits();
    setDashboardFilter({ ...filter, [name]: value, ...modifyOther });
  };

  const debouncedHandlerSearch = useCallback(
    debounce((filterValues) => {
      clearPermits();
      setDashboardFilter({ ...filterValues });
    }, 1000),
    []
  );

  const handleTextFilterChange = (
    name: 'jobNumber' | 'jobTitle' | 'search',
    value: string,
    debouncedHandler: (obj: Partial<DashboardFilter>) => void
  ) => {
    debouncedHandler({ ...filter, [name]: value });
  };

  //Filters through Permits to find unique higherLevelCategories available
  const uniqueHighLevelCategoryRequestStatuses = Array.from(
    new Set(requestStatuses.map((a) => a.higherLevelCategory))
  ).map((higherLevelCategory) => {
    return requestStatuses.find(
      (a) => a.higherLevelCategory === higherLevelCategory
    );
  });
  uniqueHighLevelCategoryRequestStatuses.push({
    id: 9999999,
    status: 'Permit Status Unknown',
    higherLevelCategory: 'Permit Status Unknown',
    color: 'default'
  });

  //Filters through uniqueHighLevelCategoryRequestStatuses to ensure that there are no "null" values
  const uniqueTruthyHighLevelCategoryRequestStatuses =
    uniqueHighLevelCategoryRequestStatuses.filter(
      (obj) => obj && obj.higherLevelCategory
    ) as RequestStatus[];

  const requestStatusesSortedByRole = requestStatuses.sort((a, b) =>
    (a.usedBy || '').localeCompare(b.usedBy || '')
  );

  //Downloads CSV files of all permit information with the current filters
  const exportResults = () => {
    setIsDownloadingPermitsExport(true);
    getPermitsExport(filter)
      .then((response) => {
        setIsDownloadingPermitsExport(false);
        const link = document.createElement('a');
        link.href = response;
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setIsDownloadingPermitsExport(false);
      });
  };

  //Open application to the Permit Request page
  const openPermitRequest = (permit: PermitDashboard) => {
    window.open(`permit/${permit.id}`);
  };

  const getRequestStatusById = (requestStatusId: number | string) => {
    return requestStatuses.find(
      (status) => status.id === Number(requestStatusId)
    );
  };

  const filteredOutRequestor = allUsers.filter(
    (allUser) => allUser.role?.toUpperCase() != 'REQUESTOR'
  );
  const filteredUsers = allUsers.filter(
    (allUsers) => allUsers.role?.toUpperCase() == 'REQUESTOR'
  );

  const groupedPermits = getGroupedPermits(permits);

  return (
    <div className="dashboard">
      <div className="sticky-content">
        <PermitHeader
          className="search-header"
          notificationsCount={notificationsCount}
          needsActionNotifications={needsActionNotifications}
          unreadComments={unreadComments}
        >
          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flex: 1
            }}
          >
            <IconButton sx={{ p: '8px' }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              name="search"
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
                handleTextFilterChange(
                  'search',
                  event.target.value,
                  debouncedHandlerSearch
                );
              }}
            />
            {filter.search && (
              <IconButton
                className="clear-button"
                sx={{ p: '8px', mr: 3 }}
                title="Clear search"
                onClick={() => {
                  setSearch('');
                  handleTextFilterChange('search', '', debouncedHandlerSearch);
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </Paper>
        </PermitHeader>

        <div className="actions">
          <div>
            <div className="title">
              <ListAltIcon className="icon" />
              <span className="all-requests">All Requests</span>
            </div>

            <div className="filter">
              <FilterListIcon className="icon" />
              {/*Request Status*/}
              <Autocomplete
                multiple
                size="small"
                options={uniqueTruthyHighLevelCategoryRequestStatuses}
                disableCloseOnSelect
                value={filter.requestStatuses}
                // value={filter.uniqueTruthyHighLevelCategoryRequestStatuses}
                getOptionLabel={(option) => option.higherLevelCategory}
                onChange={(_event, value) =>
                  handleFilterChange('requestStatuses', value)
                }
                isOptionEqualToValue={(option, value) =>
                  option.higherLevelCategory === value.higherLevelCategory
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.higherLevelCategory}
                  </li>
                )}
                renderTags={(value) => `${value.length} selected`}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="Status" />
                )}
              />
              {/*Sub Status*/}
              <Autocomplete
                multiple
                size="small"
                options={requestStatusesSortedByRole.filter(getIsStatusEnabled)}
                disableCloseOnSelect
                groupBy={(option) =>
                  option.usedBy === null
                    ? NOT_AVAILABLE
                    : getUserRoleInitials(option.usedBy || '')
                }
                value={filter.subStatuses}
                getOptionLabel={(option) => String(option.id)}
                onChange={(_event, value) =>
                  handleFilterChange('subStatuses', value)
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.status}
                  </li>
                )}
                renderTags={(value) => `${value.length} selected`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Sub Status"
                  />
                )}
              />
              {/*Team*/}
              <Autocomplete
                multiple
                size="small"
                options={filteredOutRequestor}
                disableCloseOnSelect
                value={filter.team}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.email === value.email
                }
                onChange={(event, value) => handleFilterChange('team', value)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderTags={(value) => `${value.length} selected`}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="Team" />
                )}
              />
              {/*Requestors*/}
              <Autocomplete
                multiple
                size="small"
                options={filteredUsers}
                disableCloseOnSelect
                value={filter.requestors}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) =>
                  handleFilterChange('requestors', value)
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderTags={(value) => `${value.length} selected`}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="Requestor" />
                )}
              />
              {/*Agencies*/}
              <Autocomplete
                multiple
                size="small"
                options={agencies}
                disableCloseOnSelect
                value={filter.agencies}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) =>
                  handleFilterChange('agencies', value)
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderTags={(value) => `${value.length} selected`}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="Agency" />
                )}
              />
              <div className="results-export">
                <span className="result-count">
                  ({`${permits.length}/${totalCount}`} Results)
                </span>
                {isDownloadingPermitsExport ? (
                  <HtmlTooltip title="Exporting the permit request data can take several minutes. Once processed, the export will automatically download to your computer.">
                    <Chip
                      icon={<CircularProgress size="15px" />}
                      label="Processing Export"
                      variant="outlined"
                      size="small"
                    />
                  </HtmlTooltip>
                ) : (
                  <IconButton
                    className="export-button"
                    color="primary"
                    title="Export results"
                    onClick={exportResults}
                  >
                    <DownloadIcon />
                  </IconButton>
                )}
              </div>
            </div>
          </div>

          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => {
              history.push('/new-permit');
            }}
          >
            New Permit
          </Button>
        </div>

        <div className="table">
          {/* This is a weird workaround for sticky table header -> 2 tables (header + body) */}
          <TableContainer className="table-sticky-header" component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="first-col"></TableCell>
                  <TableCell className="request-col">Request</TableCell>
                  <TableCell className="request-date-col">
                    Request Date
                  </TableCell>
                  <TableCell className="requestor-col">Requestor</TableCell>
                  <TableCell className="team-col">Team</TableCell>
                  <TableCell className="agency-col">Agency</TableCell>
                  <TableCell className="job-number-col">Job Notif#</TableCell>
                  <TableCell className="job-title-col">Job title</TableCell>
                  <TableCell className="last-updated-col">
                    Last Updated
                  </TableCell>
                  <TableCell className="status-col">Status</TableCell>
                  <TableCell className="sub-status-col">Sub-Status</TableCell>
                  <TableCell className="comment-col">
                    <div className="comment-cell">
                      <ChatIcon />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="table">
        {permits.length === 0 && !isLoadingPermits && !isLoadingOptions && (
          <div className="no-permits-message">
            No Permits found for selected filters
          </div>
        )}
        {permits.length > 0 && (
          <TableContainer sx={{ mb: '16px' }} component={Paper}>
            <Table>
              <TableBody>
                {Object.keys(groupedPermits).map((group, dataIndex) => (
                  <Fragment key={group}>
                    <TableRow
                      className={classNames('row-group', {
                        'row-odd': dataIndex % 2 === 0
                      })}
                    >
                      <TableCell
                        className="first-col"
                        rowSpan={groupedPermits[group].length + 1}
                      >
                        {groupedPermits[group].length > 1 && (
                          <div className="group-line">
                            <Divider orientation="vertical" />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>

                    {groupedPermits[group].map((request, reqIndex) => (
                      <TableRow
                        key={request.id}
                        className={classNames('request-row', {
                          'row-odd': dataIndex % 2 === 0,
                          'row-border-none':
                            groupedPermits[group].length - 1 !== reqIndex
                        })}
                        onClick={() => openPermitRequest(request)}
                      >
                        <TableCell className="request-col">
                          {request.id}
                        </TableCell>
                        <TableCell className="request-date-col">
                          {formatDateTime(request.requestDate) || '--'}
                        </TableCell>
                        <TableCell className="requestor-col">
                          {request.createdByName}
                        </TableCell>
                        <TableCell className="team-col">
                          {request.team.map((item) => (
                            <div key={item}>
                              <span>{item}</span>
                            </div>
                          ))}
                          {/* {request.team
                            ?.filter(
                              (value, index, self) =>
                                self.map((x) => x.id).indexOf(value.id) == index
                            )
                            .map((member) => (
                              <div key={`${member.id}_${request.id}`}>
                                <span>{member.name}</span>{' '}
                                {member.role && <span>({member.role})</span>}
                              </div>
                            ))} */}
                        </TableCell>
                        <TableCell className="agency-col">
                          {
                            // TODO: agnecies filtering below should be loaded in different way - directly from BE inside request obj
                            agencies.find(
                              (agency) =>
                                agency.id === Number(request.primarAgencyId)
                            )?.name
                          }
                        </TableCell>
                        <TableCell className="job-number-col">
                          {request.jobNumber || NOT_AVAILABLE}
                        </TableCell>
                        <TableCell className="job-title-col">
                          {request.jobTitle}
                        </TableCell>
                        <TableCell className="last-updated-col">
                          {formatDateTime(
                            request.lastUpdated || request.lastK2ModifiedDate
                          ) || '--'}
                        </TableCell>
                        <TableCell className="status-col">
                          {getRequestStatusById(request.requestStatusId) ? (
                            <HtmlTooltip
                              title={
                                <>
                                  <Chip
                                    sx={{
                                      color: '#FFFFFF',
                                      backgroundColor: determineToolTipColor(
                                        getRequestStatusById(
                                          request.requestStatusId
                                        )?.higherLevelCategory
                                      )
                                    }}
                                    label={
                                      getRequestStatusById(
                                        request.requestStatusId
                                      )?.higherLevelCategory
                                    }
                                  />
                                  {
                                    <div className="status-detail">
                                      <ArrowRightAltIcon />
                                      {
                                        getRequestStatusById(
                                          request.requestStatusId
                                        )?.status
                                      }
                                    </div>
                                  }
                                  {request.requestStatus2 && (
                                    <div className="status-detail">
                                      <ArrowRightAltIcon />
                                      {request.requestStatus2}
                                    </div>
                                  )}
                                  {request.requestStatus3 && (
                                    <div className="status-detail">
                                      <ArrowRightAltIcon />
                                      {request.requestStatus3}
                                    </div>
                                  )}
                                  {request.requestStatus4 && (
                                    <div className="status-detail">
                                      <ArrowRightAltIcon />
                                      {request.requestStatus4}
                                    </div>
                                  )}
                                </>
                              }
                            >
                              <Chip
                                sx={{
                                  color: '#FFFFFF',
                                  backgroundColor: determineToolTipColor(
                                    getRequestStatusById(
                                      request.requestStatusId
                                    )?.higherLevelCategory
                                  )
                                }}
                                label={
                                  getRequestStatusById(request.requestStatusId)
                                    ?.higherLevelCategory
                                }
                              />
                            </HtmlTooltip>
                          ) : (
                            <strong>Permit status is unknown</strong>
                          )}
                        </TableCell>
                        <TableCell className="sub-status-col">
                          {
                            getRequestStatusById(request.requestStatusId)
                              ?.status
                          }
                        </TableCell>
                        <TableCell className="comment-col">
                          <div className="comment-cell">
                            <Badge
                              sx={{
                                width: '26px'
                              }}
                              badgeContent={request.unreadCommentsCount}
                              color="primary"
                            >
                              <ChatBubbleOutlineIcon />
                            </Badge>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <div
          ref={loadingElementRef}
          className={classNames('loading', {
            hidden: !isLoadingPermits && !isLoadingOptions
          })}
        >
          <CircularProgress />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUser: state.auth.currentUser,
  pageNumber: state.permit.pageNumber,
  totalCount: state.permit.totalCount,
  requestStatuses: state.filter.dropdownOptions.requestStatuses,
  agencies: state.filter.dropdownOptions.agencies,
  users: state.filter.dropdownOptions.users, // only users from AD
  allUsers: state.filter.dropdownOptions.allUsers, // all users from AD + tblPermittingTeam + tblAgencies
  isLoadingOptions: state.filter.isLoadingOptions,
  filter: state.filter.dashboardFilter,
  needsActionNotifications: needsActionBadgeNotificationsSelector(state),
  unreadComments: state.comment.unreadComments
});

const mapDispatchToProps = {
  clearPermits: permitActions.clearPermitsAction,
  getPermits: permitActions.getPermitsAction,
  getPermitsExport: permitActions.getPermitsExportAction,
  setDashboardFilter: filterActions.setDashboardFilterAction,
  getUnreadComments: commentActions.getUnreadCommentsAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Dashboard);
