import './DragDropInput.scss';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { ChangeEvent, useEffect, useRef } from 'react';

interface Props {
  onFilesAdd: (files: File[]) => void;
}

const DragDropInput = (props: Props): JSX.Element => {
  const { onFilesAdd } = props;
  const dropRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const dropElement = dropRef.current;
    if (dropElement) {
      dropElement.addEventListener('dragover', handleDrag);
      dropElement.addEventListener('drop', handleDrop);
    }

    return () => {
      if (dropElement) {
        dropElement.removeEventListener('dragover', handleDrag);
        dropElement.removeEventListener('drop', handleDrop);
      }
    };
  }, []);

  const handleDrag = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (
      event.dataTransfer &&
      event.dataTransfer.items &&
      event.dataTransfer.items.length > 0
    ) {
      const addedFiles = Array.from(event.dataTransfer.files);
      onFilesAdd(addedFiles);
      event.dataTransfer.clearData();
    }
  };

  const openFilePicker = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = ''; // needed so onChange triggers also when same fileName selected
      fileInputRef.current.click();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const addedFiles = Array.from(event.target.files || []);
    onFilesAdd([...addedFiles]);
  };

  return (
    <div className="drag-drop-input" ref={dropRef} onClick={openFilePicker}>
      <div className="title">Drag & Drop</div>
      <div className="desc">
        <div>Upload a File</div>
        <ImageSearchIcon color="primary" />
      </div>
      <input
        ref={fileInputRef}
        onChange={handleChange}
        type="file"
        multiple
        hidden
      />
    </div>
  );
};

export default DragDropInput;
