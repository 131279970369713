import ActionType from '../action-types';
import { Dispatch } from 'redux';
import {
  DropdownOptions,
  SetDashboardFilterAction,
  SetDropdownOptionsAction,
  SetIsOptionsAction
} from '../../models/store-filter.model';
import axiosClient from 'src/utils/axios-client';
import envConfig from 'src/env-config';
import { UserRole } from 'src/models/user-role.model';
import { DashboardFilter } from 'src/models/filter.model';
import { User } from '../../models/user.model';
import { processError } from './alert-actions';
import { sortByName } from 'src/utils/formatting-utils';

const ROLES: UserRole[] = [
  UserRole.PSS1,
  UserRole.QAQC,
  UserRole.TCP,
  UserRole.TS,
  UserRole.TA
];

const getUsersFromPermittingTeam = (o: any): User[] => {
  if (!o || Object.keys(o).length === 0) return [];
  const entries = Object.values(o).flat();
  return entries.map((uo: any) => {
    const name = uo.name.replace(/\(([^)]+)\)/i, '');
    return {
      id: String(uo.id),
      initials: name
        .split(' ')
        .map((s: string) => s[0])
        .join(''),
      firstName: name.split(' ')[0],
      lastName: name.split(' ')[1],
      name: uo.name,
      role: uo.role,
      email: uo.email
    };
  });
};

const formatCoordinators = (entries: any[]): User[] => {
  return entries.map((uo: any) => {
    const name = uo.name.replace(/\(([^)]+)\)/i, '');
    return {
      id: uo.email + '_rnd_' + String(Math.floor(Math.random() * 10000)), // no id in DB for this type of users
      initials: name
        .split(' ')
        .map((s: string) => s[0])
        .join(''),
      firstName: name.split(' ')[0],
      lastName: name.split(' ')[1],
      name: uo.name,
      role: undefined,
      email: uo.email
    };
  });
};

const removeDuplicatesByProperty = <T>(ar: T[], propName: string): T[] => {
  const asObject = {};
  ar.forEach((item: T) => {
    asObject[item[propName]] = item;
  });
  return Object.values(asObject);
};

const combineUsers = (
  permittingTeam: any = [],
  users: any[] = [],
  coordinators: any[] = []
) => {
  const permittingTeamUsers = getUsersFromPermittingTeam(permittingTeam);
  const coordinatorsFormatted = formatCoordinators(coordinators);
  const combinedRaw = [
    ...users,
    ...permittingTeamUsers,
    ...coordinatorsFormatted
  ].filter((user) => user.email.match(/^\S+@\S+\.\S+$/i));
  const byUniqueName = removeDuplicatesByProperty<User>(combinedRaw, 'name');
  const byUniqueEmail = removeDuplicatesByProperty<User>(byUniqueName, 'email');
  return byUniqueEmail;
};

export const getDropdownOptionsAction =
  () => async (dispatch: Dispatch<SetDropdownOptionsAction>) => {
    // try to fetch data from backend
    try {
      const [filtersResult, usersResult] = await Promise.all([
        axiosClient.get(envConfig.api.getAllFilters),
        axiosClient.get(envConfig.api.getUsers)
      ]);

      const filters = filtersResult.data.afpermitsResponse.body.filters;

      const result: DropdownOptions = filters;
      const adUsers = usersResult.data.afpermitsResponse.body.users;
      const allUsers = combineUsers(
        filters.permittingTeam,
        usersResult.data.afpermitsResponse.body.users,
        filters.agenciesCoordinators || []
      ).sort(sortByName);

      console.log('allusers', allUsers, filters.agenciesCoordinators);

      result.users = adUsers;
      result.allUsers = allUsers;
      result.userRoles = ROLES;

      dispatch({
        type: ActionType.SET_DROPDOWN_OPTIONS,
        payload: result
      });
      return Promise.resolve(result);
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const setIsLoadingOptionsAction =
  (isLoading: boolean) => (dispatch: Dispatch<SetIsOptionsAction>) => {
    dispatch({
      type: ActionType.SET_IS_LOADING_OPTIONS,
      payload: isLoading
    });
  };

export const setDashboardFilterAction =
  (filterData: DashboardFilter) =>
  (dispatch: Dispatch<SetDashboardFilterAction>) => {
    dispatch({
      type: ActionType.SET_DASHBOARD_FILTER,
      payload: filterData
    });
  };
