import { Dispatch } from 'redux';
import ActionType from '../action-types';
import { SetCurrentUserAction } from '../../models/store-auth.model';
import {
  CognitoRefreshToken,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { MyCognitoUser, User } from 'src/models/user.model';
import envConfig from 'src/env-config';
import history from 'src/utils/history';
import { parseRoleString } from '../../utils/parse-utils';

Amplify.configure({
  Auth: envConfig.auth
});

Amplify.register(Auth);

Hub.listen('auth', (data) => {
  const { payload } = data;
  console.log('A new auth event has happened: ');
  if (payload.event === 'signIn') {
    console.log('a user has signed in!');
  }
  if (payload.event === 'signOut') {
    console.log('a user has signed out!');
  }
});

/**
 * Parse UI user object from Cognito user object
 * @param cognitoUser object fetched from cognito
 * @returns user object used in across the UI
 */
const parseUserData = (cognitoUser: MyCognitoUser): User | null => {
  if (!cognitoUser.attributes) return null;
  // TODO: some properties below will be fetched from coginto, we will have to update this later
  const result: User = {
    id: cognitoUser.attributes.sub,
    email: cognitoUser.attributes.email,
    initials: `${cognitoUser.attributes.given_name[0]}${cognitoUser.attributes.family_name[0]}`,
    firstName: cognitoUser.attributes.given_name,
    lastName: cognitoUser.attributes.family_name,
    name: `${cognitoUser.attributes.given_name} ${cognitoUser.attributes.family_name}`,
    role: parseRoleString(cognitoUser.attributes['custom:role'])[0]
  };
  return result;
};

const clearDataRedirectLogin = (dispatch: Dispatch<SetCurrentUserAction>) => {
  dispatch({
    type: ActionType.SET_CURRENT_USER,
    payload: null
  });

  history.push('/login');
};

export const authenticateAction =
  () => async (dispatch: Dispatch<SetCurrentUserAction>) => {
    try {
      const cognitoUser: MyCognitoUser = await Auth.currentAuthenticatedUser();

      const currentUser = parseUserData(cognitoUser);

      dispatch({
        type: ActionType.SET_CURRENT_USER,
        payload: currentUser
      });

      const redirectTo = localStorage.getItem('redirectTo');
      if (redirectTo) {
        history.push(redirectTo);
      } else {
        history.push('/');
      }

      return Promise.resolve('Successfully authenticated!');
    } catch (error) {
      history.push('/login');
      return Promise.reject(error);
    }
  };

export const ssoAction = () => async (dispatch: Dispatch) => {
  Auth.federatedSignIn({ customProvider: 'ActiveDirectory' });
};

export const submitLoginAction =
  (username: string, password: string) =>
  async (dispatch: Dispatch<SetCurrentUserAction>) => {
    try {
      const cognitoUser: MyCognitoUser = await Auth.signIn(username, password);

      const currentUser = parseUserData(cognitoUser);
      if (!currentUser) {
        throw new Error('User has no attributes!');
      }
      const redirectTo = localStorage.getItem('redirectTo');
      if (redirectTo) {
        history.push(redirectTo);
      } else {
        history.push('/');
      }

      dispatch({
        type: ActionType.SET_CURRENT_USER,
        payload: currentUser
      });
      return Promise.resolve('Successfully logged in!');
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const refreshSessionAction =
  (refreshToken: string) =>
  async (dispatch: Dispatch<SetCurrentUserAction>) => {
    try {
      return new Promise<any>(async (resolve: any, reject: any) => {
        const cognitoUser: MyCognitoUser =
          await Auth.currentAuthenticatedUser();
        const cognitoRefreshToken = new CognitoRefreshToken({
          RefreshToken: refreshToken
        });
        // try to refresh the session (token)
        cognitoUser.refreshSession(
          cognitoRefreshToken,
          (error: any, session: CognitoUserSession) => {
            if (error) {
              // Error refreshing the token
              cognitoUser.signOut();
              clearDataRedirectLogin(dispatch);
              console.error('Error refreshing the token. Logged out.', error);
              reject('Error refreshing the token!');
            } else {
              // Successfully refreshed the token
              // update jwt

              resolve('Token successfully refreshed!');
            }
          }
        );
      });
    } catch (error) {
      // was unable to get the user session -> lets logout
      clearDataRedirectLogin(dispatch);
      return Promise.reject('Unable to get the user session!');
    }
  };

export const logoutAction =
  () => async (dispatch: Dispatch<SetCurrentUserAction>) => {
    try {
      const cognitoUser: MyCognitoUser = await Auth.currentAuthenticatedUser();
      cognitoUser.signOut();
      clearDataRedirectLogin(dispatch);

      return Promise.resolve('Successfully logged in!');
    } catch (error) {
      return Promise.reject(error);
    }
  };
