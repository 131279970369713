import ActionType from '../action-types';
import { Dispatch } from 'redux';
import {
  AddPermitStatusSpecAction,
  AddSupportingAddressAction,
  AddTrenchSpecAction,
  LoadAdditionalPermitsAction,
  RemovePermitRequest,
  RemovePermitStatusAction,
  RemoveSupportingAddressAction,
  RemoveTrenchSpecAction,
  ResetPermitUnreadCommentsAction,
  SetAssignedUsersAction,
  SetPermitAction,
  SetPermitAssignedusersAction,
  SetPermitPropertiesAction,
  SetPermitsAction,
  SetPrimaryAddressAction,
  SetStatusesAction,
  SetSupportingAddressAction,
  SetTrenchSpecPropertyAction,
  SetPermitNotesAction
} from '../../models/store-permit.model';
import envConfig from 'src/env-config';
import {
  PermitCardInfo,
  PermitDashboard,
  PermitRequest
} from 'src/models/permit.model';
import { DashboardFilter } from 'src/models/filter.model';
import axiosClient from 'src/utils/axios-client';
import {
  AssignedUsersSpec,
  Coordinator
} from 'src/models/assigned-users.model';
import { TrenchSpec } from 'src/models/trench.model';
import { MarkerMap } from 'src/models/marker-map.model';
import { User } from 'src/models/user.model';
import { K2Notes } from 'src/models/k2notes.model';
import { RootState } from '../store';
import {
  deleteAllNotifEvents,
  schActionNeededNotif,
  schAssignmentNotif,
  schInProgressWithPermittingNotif,
  schNotSubmittedNotif,
  schPermitCreatedNotif,
  schPermitExpiringNotif,
  schPermitIssuedNotif
} from './notification-actions';
import { StatusType } from 'src/models/status.model';
import {
  DATEFORMAT_DBDATE,
  formatDateTime,
  makeASCISafe
} from 'src/utils/formatting-utils';
import { PRIMARY_ADDRESS } from 'src/constants/addresses';
import {
  PERMIT_ISSUED_STATUS_ID,
  PERMIT_UNDER_REVIEW_IDS
} from 'src/constants/statuses';
import { processError } from './alert-actions';
import { ShowAlertAction } from '../../models/store-alert.model';
import { formatPermitData } from '../../utils/permit-utils';

export const getPermitsAction =
  (filter: DashboardFilter, pageNumber = 1) =>
  async (
    dispatch: Dispatch<SetPermitsAction | LoadAdditionalPermitsAction>,
    getState: () => RootState
  ) => {
    // try to fetch data from backend
    const getTeamUser = (name: string, email: string, index: number) => {
      let selUser: User = {
        id: String(index),
        initials: '',
        firstName: '',
        lastName: '',
        name: name,
        email: email
      };
      const users = getState().filter.dropdownOptions.users;
      users
        .filter((user) => user.email === email)
        .map((filteredUser) => {
          selUser = filteredUser;
        });
      return selUser;
    };

    try {
      const reqBody = {
        pagenumber: pageNumber,
        ...(filter.requestStatuses.length > 0 && {
          status: filter.requestStatuses.map((status) =>
            String(status.higherLevelCategory)
          )
        }),
        ...(filter.subStatuses.length > 0 && {
          subStatus: filter.subStatuses.map((item) => item.id)
        }),
        ...(filter.jobNumber.length > 0 && {
          jobNumber: filter.jobNumber
        }),
        ...(filter.jobTitle.length > 0 && {
          jobTitle: filter.jobTitle
        }),
        ...(filter.search.length > 0 && {
          search: filter.search
        }),
        ...(filter.team.length > 0 && {
          team: filter.team.map((user) => String(user.email))
        }),
        ...(filter.requestors.length > 0 && {
          requestor: filter.requestors.map((requestor) => requestor.email)
        }),
        ...(filter.agencies.length > 0 && {
          agency: filter.agencies.map((agency) => String(agency.id))
        })
      };

      const response = await axiosClient.post(
        envConfig.api.getPermits,
        reqBody
      );

      const totalCount =
        response.data.afpermitsResponse.body.totalcount[0].totalcount;

      const permitDashboardResults =
        response.data.afpermitsResponse.body.permitdata;

      // permitDashboardResults.forEach((permit: any, index: number) => {
      //   const team: User[] = [];
      //   if (permit.agencyCoordinatorEmail) {
      //     team.push(
      //       permit.agencyCoordinatorEmail &&
      //         getTeamUser(
      //           permit.agencyCoordinatorName,
      //           permit.agencyCoordinatorEmail,
      //           index++
      //         )
      //     );
      //   }
      //   if (permit.agencyTCPCoordinatorEmail) {
      //     team.push(
      //       permit.agencyTCPCoordinatorEmail &&
      //         getTeamUser(
      //           permit.agencyTCPCoordinatorName,
      //           permit.agencyTCPCoordinatorEmail,
      //           index++
      //         )
      //     );
      //   }

      //   if (permit.agencyPermitCoordinatorEmail) {
      //     team.push(
      //       permit.agencyPermitCoordinatorEmail &&
      //         getTeamUser(
      //           permit.agencyPermitCoordinatorName,
      //           permit.agencyPermitCoordinatorEmail,
      //           index++
      //         )
      //     );
      //   }
      //   permit.team = team;
      // });

      const permits: PermitDashboard[] = permitDashboardResults;

      if (pageNumber === 1) {
        return dispatch({
          type: ActionType.SET_PERMITS,
          payload: {
            pageNumber: pageNumber,
            totalCount: totalCount,
            permits: permits
          }
        });
      } else {
        return dispatch({
          type: ActionType.LOAD_ADDITIONAL_PERMITS,
          payload: {
            pageNumber: pageNumber,
            totalCount: totalCount,
            permits: permits
          }
        });
      }
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const getPermitsExportAction =
  (filter: DashboardFilter) => async (dispatch: Dispatch<any>) => {
    // try to fetch data from backend
    try {
      const filterFormatted: any = {
        ...filter,
        ...{
          subStatus: (filter.subStatuses || []).map((item) => item.id)
        }
      };
      delete filterFormatted.subStatuses;
      const response = await axiosClient.post(envConfig.api.getPermitsExport, {
        ...filterFormatted
      });
      const permitsExport = response.data.afpermitsResponse.body;
      return Promise.resolve(permitsExport);
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const clearPermitsAction =
  () => async (dispatch: Dispatch<SetPermitsAction>) => {
    dispatch({
      type: ActionType.SET_PERMITS,
      payload: {
        pageNumber: 0,
        totalCount: 0,
        permits: []
      }
    });
  };

export const getPermitAction =
  (id: string) =>
  async (dispatch: Dispatch<SetPermitAction | ShowAlertAction>) => {
    // try to fetch data from backend
    try {
      const response = await axiosClient.get(
        `${envConfig.api.getPermit}/${id}`
      );
      const permit: PermitRequest = formatPermitData(
        response.data.afpermitsResponse.body.permit
      );
      return dispatch({
        type: ActionType.SET_PERMIT,
        payload: permit
      });
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(`Error loading permit with id ${id}`);
    }
  };

export const clearPermitAction =
  () => (dispatch: Dispatch<SetPermitAction>) => {
    return dispatch({
      type: ActionType.SET_PERMIT,
      payload: null
    });
  };

export const createPermitAction =
  (jobNumber: string | null = null, jobType: string) =>
  async (dispatch: Dispatch<SetPermitAction>) => {
    // try to fetch data from backend
    try {
      const response = await axiosClient.post(envConfig.api.createPermit, {
        jobNumber,
        jobType
      });

      const created: PermitRequest =
        response.data.afpermitsResponse.body.permit;

      created.primaryAddress = {
        permitId: created.id,
        time: new Date().getTime(),
        address: created.jobAddress,
        thomsBrosTB: created.thomsBrosTB
      };
      created.supportingAddress = [];
      created.trenchSpecifications = [];
      // schedule the NOT_SUBMITTED notification
      schNotSubmittedNotif(created.id);

      dispatch({
        type: ActionType.SET_PERMIT,
        payload: created
      });
      return Promise.resolve(created);
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const updatePermitAction =
  (id: string, updatedProperty: Partial<PermitRequest>) =>
  async (dispatch: Dispatch<SetPermitPropertiesAction>) => {
    for (const key in updatedProperty) {
      if (typeof updatedProperty[key] === 'string') {
        updatedProperty[key] = makeASCISafe(updatedProperty[key]);
      }
    }
    try {
      await axiosClient.patch(`${envConfig.api.updatePermit}/${id}`, {
        ...updatedProperty
      });

      dispatch({
        type: ActionType.SET_PERMIT_PROPERTIES,
        payload: updatedProperty
      });
      return Promise.resolve('Sucessfully updated!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const updateCoordinatorsAction =
  (permitId: string, updatedProperty: Partial<PermitRequest>) =>
  async (dispatch: Dispatch<SetPermitAssignedusersAction>) => {
    // try to update data on BE
    try {
      const response = await axiosClient.post(
        `${envConfig.api.permits}/${permitId}/assignUsers`,
        {
          ...updatedProperty
        }
      );

      const assignedUsers: AssignedUsersSpec =
        response.data.afpermitsResponse.body;

      dispatch({
        type: ActionType.SET_PERMIT_ASSIGNED_USERS,
        payload: assignedUsers
      });
      return Promise.resolve('Sucessfully updated!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };
export const updateSubmittedPermitTCPAction =
  (permitId: string, updatedProperty: Partial<PermitRequest>) =>
  async (dispatch: Dispatch<SetPermitPropertiesAction>) => {
    try {
      await axiosClient.patch(`${envConfig.api.permits}/${permitId}/TCP`, {
        ...updatedProperty
      });
      dispatch({
        type: ActionType.SET_PERMIT_PROPERTIES,
        payload: updatedProperty
      });
    } catch (error) {
      processError(error, dispatch);
    }
  };

export const updateSubmittedPermitScheduleAction =
  (permitId: string, updatedProperty: Partial<PermitRequest>) =>
  async (dispatch: Dispatch<SetPermitPropertiesAction>) => {
    try {
      await axiosClient.patch(`${envConfig.api.permits}/${permitId}/schedule`, {
        ...updatedProperty
      });
      dispatch({
        type: ActionType.SET_PERMIT_PROPERTIES,
        payload: updatedProperty
      });
    } catch (error) {
      processError(error, dispatch);
    }
  };

export const updatePermitTrenchSpecAction =
  (id: string, updatedProperty: Partial<PermitRequest>) =>
  async (dispatch: Dispatch<SetPermitPropertiesAction>) => {
    try {
      // TODO: call endpoint
      // await axiosClient.patch()
      dispatch({
        type: ActionType.SET_PERMIT_PROPERTIES,
        payload: updatedProperty
      });
    } catch (error) {
      console.error(error);
    }
  };

export const updatePermitContactsAction =
  (id: string, updatedProperty: Partial<PermitRequest>) =>
  async (dispatch: Dispatch<SetPermitPropertiesAction>) => {
    try {
      await axiosClient.patch(`${envConfig.api.permits}/${id}/contacts`, {
        ...updatedProperty
      });
      dispatch({
        type: ActionType.SET_PERMIT_PROPERTIES,
        payload: updatedProperty
      });
    } catch (error) {
      processError(error, dispatch);
    }
  };

export const updatePermitDetailsAction =
  (permitId: string, updatedProperty: Partial<PermitRequest>) =>
  async (dispatch: Dispatch<SetPermitPropertiesAction>) => {
    for (const key in updatedProperty) {
      if (typeof updatedProperty[key] === 'string') {
        updatedProperty[key] = makeASCISafe(updatedProperty[key]);
      }
    }
    try {
      const payloadForAPI = {
        ...updatedProperty,
        // api expects date values in YYYY-MM-DD format
        expirationDate: formatDateTime(
          updatedProperty.expirationDate,
          DATEFORMAT_DBDATE
        ),
        approvalDate: formatDateTime(
          updatedProperty.approvalDate,
          DATEFORMAT_DBDATE
        )
      };

      const response = await axiosClient.patch(
        `${envConfig.api.permits}/${permitId}/details`,
        payloadForAPI
      );
      const { createdPermitIds }: { createdPermitIds: string[] } =
        response.data.afpermitsResponse.body.data;

      // if expiration date was updated, then schedule the PERMIT_EXPIRING notification
      if (updatedProperty.expirationDate) {
        schPermitExpiringNotif(permitId);
      }

      // schedule the NOT_SUBMITTED notification for each newly created permit
      createdPermitIds.forEach((id) => {
        schNotSubmittedNotif(id);
      });

      return dispatch({
        type: ActionType.SET_PERMIT_PROPERTIES,
        payload: updatedProperty
      });
    } catch (error: any) {
      processError(error, dispatch);
      return Promise.reject(error?.message || 'Error updating permit detail.');
    }
  };

export const updatePermitLocationAction =
  (
    permitId: string,
    primaryAddress: MarkerMap,
    thomsBrosTB: string,
    supportingAddresses: MarkerMap[]
  ) =>
  async (dispatch: Dispatch<SetPermitPropertiesAction>) => {
    try {
      const reqBody = {
        primaryAddress: { ...primaryAddress, thomsBrosTB },
        supportingAddresses
      };

      const response = await axiosClient.patch(
        `${envConfig.api.permits}/${permitId}/updateLocation`,
        reqBody
      );

      const resBody = response.data.afpermitsResponse.body;

      const updatePayload: Partial<PermitRequest> = {
        primaryAddress: resBody.primaryAddress,
        jobAddress: resBody.primaryAddress.address,
        latitude: resBody.primaryAddress.latitude,
        longitude: resBody.primaryAddress.longitude,
        thomsBrosTB: resBody.primaryAddress.thomsBrosTB,
        supportingAddress: resBody.supportingAddresses
      };

      return dispatch({
        type: ActionType.SET_PERMIT_PROPERTIES,
        payload: updatePayload
      });
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject('Error updating permit location');
    }
  };

export const updatePermitScopeAction =
  (permitId: string, update: Partial<PermitRequest>) =>
  async (dispatch: Dispatch<SetPermitPropertiesAction>) => {
    for (const key in update) {
      if (typeof update[key] === 'string') {
        update[key] = makeASCISafe(update[key]);
      }
    }

    try {
      const response = await axiosClient.patch(
        `${envConfig.api.permits}/${permitId}/scope`,
        update
      );
      const result: Partial<PermitRequest> =
        response.data.afpermitsResponse.body;

      dispatch({
        type: ActionType.SET_PERMIT_PROPERTIES,
        payload: result
      });
      return Promise.resolve('Sucessfully updated!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const addTrenchSpecAction =
  (permitId: string) => async (dispatch: Dispatch<AddTrenchSpecAction>) => {
    // try to fetch data from backend
    try {
      const response = await axiosClient.post(
        `${envConfig.api.permits}/${permitId}/addTrenchSpec`
      );

      const added: TrenchSpec =
        response.data.afpermitsResponse.body.trenchSpecification;

      dispatch({
        type: ActionType.ADD_TRENCH_SPEC,
        payload: added
      });
      return Promise.resolve('Sucessfully added!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const removeTrenchSpecAction =
  (permitId: string, trenchSpecId: string) =>
  async (dispatch: Dispatch<RemoveTrenchSpecAction>) => {
    // try to execute backend request
    try {
      const response = await axiosClient.delete(
        `${envConfig.api.permits}/${permitId}/removeTrenchSpec/${trenchSpecId}`
      );

      const removedId: number = response.data.afpermitsResponse.body.removedId;

      dispatch({
        type: ActionType.REMOVE_TRENCH_SPEC,
        payload: removedId
      });
      return Promise.resolve('Sucessfully removed!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const updateTrenchSpecAction =
  (permitId: string, trenchSpecId: string, update: Partial<TrenchSpec>) =>
  async (dispatch: Dispatch<SetTrenchSpecPropertyAction>) => {
    // try to fetch data from backend
    for (const key in update) {
      if (typeof update[key] === 'string') {
        update[key] = makeASCISafe(update[key]);
      }
    }
    try {
      await axiosClient.patch(
        `${envConfig.api.permits}/${permitId}/updateTrenchSpec/${trenchSpecId}`,
        update
      );

      dispatch({
        type: ActionType.SET_TRENCH_SPEC_PROPERTY,
        payload: { ...update, id: Number(trenchSpecId) }
      });
      return Promise.resolve('Successfully updated!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const getPermitsByJobIdAction =
  (jobNumber: string, jobType: string) => async (dispatch: Dispatch<any>) => {
    try {
      const reqBody = {
        jobnumber: jobNumber,
        jobtype: jobType
      };
      const response = await axiosClient.post(
        envConfig.api.getPermitsByJobId,
        reqBody
      );

      const permitsbyjobid: PermitCardInfo[] =
        response.data.afpermitsResponse.body.permitdata;
      const permitJobSapValid =
        response.data.afpermitsResponse.permitJobSapValid;

      return Promise.resolve({ permitsbyjobid, permitJobSapValid });
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

/**
 * Adds marker object into DB and into state
 * @param markerMap
 * @returns
 */
export const addSupportingAddress =
  (markerMap: MarkerMap) =>
  async (dispatch: Dispatch<AddSupportingAddressAction>) => {
    try {
      const response = await axiosClient.post(
        `${envConfig.api.permits}/${markerMap.permitId}/addSuppAddr`,
        markerMap
      );
      const added: MarkerMap =
        response.data.afpermitsResponse.body.createdAddress;

      dispatch({
        type: ActionType.ADD_SUPPORTING_ADDRESS,
        payload: added
      });
      //return added;
      return Promise.resolve('Successfully added!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

/**
 * removes marker object into DB and into state
 * @param permitId
 * @param supprtingAddressId
 * @returns
 */
export const removeSupportingAddress =
  (
    permitId: string,
    supprtingAddressId: string,
    supprtingAddressTime: string
  ) =>
  async (dispatch: Dispatch<RemoveSupportingAddressAction>) => {
    // try to execute backend request
    try {
      if (
        permitId &&
        supprtingAddressId &&
        permitId != 'undefined' &&
        supprtingAddressId != 'undefined'
      ) {
        const response = await axiosClient.delete(
          `${envConfig.api.permits}/${permitId}/removeSuppAddr/${supprtingAddressId}`
        );

        const removedId: number =
          response.data.afpermitsResponse.body.removedId;
      }

      dispatch({
        type: ActionType.REMOVE_SUPPORTING_ADDRESS,
        payload: Number(supprtingAddressTime)
      });
      return Promise.resolve('Successfully removed!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

/**
 * When + icon was clicked and address was entered...
 * Insert address and update state.
 * @param markerMap
 * @returns
 */
export const addUpdateSupportingAddress =
  (markerMap: MarkerMap) =>
  async (dispatch: Dispatch<SetSupportingAddressAction>) => {
    try {
      const response = await axiosClient.post(
        `${envConfig.api.permits}/${markerMap.permitId}/addSuppAddr`,
        markerMap
      );
      const updated: MarkerMap =
        response.data.afpermitsResponse.body.createdAddress;

      dispatch({
        type: ActionType.SET_SUPPORTING_ADDRESS,
        payload: updated
      });
      //return added;
      return Promise.resolve('Successfully added!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };
/**
 *
 * @returns only creates new marker object into state
 */
export const addEmptySupportingAddress =
  (markerMap: MarkerMap) =>
  async (dispatch: Dispatch<AddSupportingAddressAction>) => {
    // try to execute backend request
    try {
      dispatch({
        type: ActionType.ADD_SUPPORTING_ADDRESS,
        payload: markerMap
      });
      return Promise.resolve('Successfully added!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const updatePrimaryAddress =
  (marker: MarkerMap) =>
  async (dispatch: Dispatch<SetPrimaryAddressAction>) => {
    marker.addressType = PRIMARY_ADDRESS;

    // try to execute backend request
    try {
      await axiosClient.patch(
        `${envConfig.api.permits}/${marker.permitId}/updateSuppAddr/${marker.id}`,
        marker
      );

      dispatch({
        type: ActionType.SET_PRIMARY_ADDRESS,
        payload: marker
      });

      return Promise.resolve('Successfully updated!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const updateSupportingAddress =
  (marker: MarkerMap) =>
  async (dispatch: Dispatch<SetSupportingAddressAction>) => {
    // try to execute backend request
    try {
      await axiosClient.patch(
        `${envConfig.api.permits}/${marker.permitId}/updateSuppAddr/${marker.id}`,
        marker
      );

      dispatch({
        type: ActionType.SET_SUPPORTING_ADDRESS,
        payload: marker
      });

      return Promise.resolve('Successfully updated!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const updateCmntTimestAction =
  (permitId: string) =>
  async (dispatch: Dispatch<ResetPermitUnreadCommentsAction>) => {
    try {
      await axiosClient.patch(
        `${envConfig.api.permits}/${permitId}/updateCmntTimest`
      );
      dispatch({
        type: ActionType.RESET_PERMIT_UNREAD_COMMENTS,
        payload: permitId
      });
      return Promise.resolve('Sucessfully updated!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const addPermitStatusAction =
  (permitId: number, statusId: number, email: string, text?: string) =>
  async (dispatch: Dispatch<AddPermitStatusSpecAction>) => {
    // try to fetch data from backend
    try {
      const response = await axiosClient.post(
        `${envConfig.api.permits}/${permitId}/statuses`,
        {
          statusId,
          email,
          text: makeASCISafe(text) // note: used for creating needsActionNotification
        }
      );

      const addedId: number =
        response.data.afpermitsResponse.body.newStatus.insertId;
      const createdNeedsAction: { insertId?: string } =
        response.data.afpermitsResponse.body.createdNeedsAction;
      const closePrereqsResult =
        response.data.afpermitsResponse.body.closePrereqsResult;

      // if there is any Needs Action created, then schedule ACTION_NEEDED notification
      if (createdNeedsAction && createdNeedsAction.insertId) {
        schActionNeededNotif(permitId, createdNeedsAction.insertId);
      }
      // if Under Review status, then scheduled IN_PROGRESS_WITH_PERMITTING notification
      if (PERMIT_UNDER_REVIEW_IDS.includes(statusId)) {
        schInProgressWithPermittingNotif(permitId);
      }
      // if Permit Issued status, then delete all scheduled notifications for this permit
      // additionally send PERMIT_ISSUED email notification
      if (statusId === PERMIT_ISSUED_STATUS_ID) {
        deleteAllNotifEvents(permitId);
        schPermitIssuedNotif(permitId);
      }

      dispatch({
        type: ActionType.ADD_PERMIT_STATUS_SPEC,
        payload: { id: addedId, statusId, email, permitId }
      });
      return Promise.resolve(closePrereqsResult);
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const removePermitStatusAction =
  (id: string | null) =>
  async (dispatch: Dispatch<RemovePermitStatusAction>) => {
    if (id) {
      dispatch({
        type: ActionType.REMOVE_PERMIT_STATUS,
        payload: { id }
      });
    }
  };

export const createPermitsWithMultipleAgencies =
  (id: string) => async (dispatch: Dispatch<SetPermitAction>) => {
    try {
      const response = await axiosClient.post(
        `${envConfig.api.multipleAgencies}/${id}`
      );

      const spunPermitIds: number[] = response.data.afpermitsResponse.body;

      // schedule ASSIGNMENT and PERMIT_CREATED notifications for submitted permit
      schAssignmentNotif(id);
      schPermitCreatedNotif(id);
      // schedule the NOT_SUBMITTED notification for each newly created (spun off) permit
      spunPermitIds.forEach((spunId) => {
        schNotSubmittedNotif(spunId);
      });

      dispatch({
        type: ActionType.SET_PERMIT,
        payload: null
      });

      return Promise.resolve(
        response.data.afpermitsResponse.createPrereqsResult
      );
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const getStatusesAction =
  (id: string) => async (dispatch: Dispatch<SetStatusesAction>) => {
    // try to fetch data from backend
    try {
      const response = await axiosClient.get(
        `${envConfig.api.permits}/${id}/statuses`
      );
      const statuses: StatusType[] =
        response.data.afpermitsResponse.body.statuses;

      dispatch({
        type: ActionType.SET_ALL_STATUSES,
        payload: statuses
      });
    } catch (error) {
      processError(error, dispatch);
    }
  };

export const updateAssignedUsersAction =
  (permitId: string, coordinators: Coordinator[]) =>
  async (dispatch: Dispatch<SetAssignedUsersAction>) => {
    try {
      const response = await axiosClient.post(
        `${envConfig.api.permits}/${permitId}/reassign`,
        { assignedUsers: coordinators }
      );

      const assignedUsers: AssignedUsersSpec[] =
        response.data.afpermitsResponse.body.assignedUsers;

      // schedule ASSIGNMENT notification
      schAssignmentNotif(permitId);

      dispatch({
        type: ActionType.SET_ASSIGNED_USERS,
        payload: assignedUsers
      });
      return Promise.resolve('Sucessfully updated!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const removePermitRequestAction =
  (id: string) =>
  async (
    dispatch: Dispatch<RemovePermitRequest>,
    getState: () => RootState
  ) => {
    const pageNumberState = getState().permit.pageNumber;
    const totalCountState = getState().permit.totalCount;
    const isRemovedObj = { isRemoved: 1 };
    try {
      await axiosClient.patch(`${envConfig.api.updatePermit}/${id}`, {
        ...isRemovedObj
      });

      // delete all scheduled notifications for this permit
      deleteAllNotifEvents(id);

      const result = {
        pageNumber: pageNumberState,
        totalCount: totalCountState - 1,
        permitId: Number(id)
      };

      dispatch({
        type: ActionType.REMOVE_PERMIT_REQUEST,
        payload: result
      });
      return Promise.resolve('Sucessfully updated!');
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const spinoffNewPermitAction =
  (id: string, extOption: string) => async (dispatch: Dispatch<any>) => {
    try {
      const response = await axiosClient.post(
        `${envConfig.api.spinoffNewPermit}`,
        { permitId: id, extOption: extOption }
      );

      const permitId = response.data.afpermitsResponse.body.id;
      // const permit = response.data.afpermitsResponse.body.data;

      // schedule the NOT_SUBMITTED notification for newly created permit
      schNotSubmittedNotif(permitId);

      return Promise.resolve(permitId);
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const exportPermitPDFAction =
  (permitId: string, htmlString: string, prefixTime: string) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const response = await axiosClient.post(
        `${envConfig.api.permits}/${permitId}/exportPermitPDF`,
        {
          htmlString: htmlString,
          prefixTime: prefixTime
        }
      );
      const permitExportPDF = response.data.afpermitsResponse.body;
      return Promise.resolve(permitExportPDF);
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const exportPermitZIPAction =
  (permitId: string, fileNameArr: (string | undefined)[], prefixTime: string) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const response = await axiosClient.post(
        `${envConfig.api.permits}/${permitId}/exportPermitZIP`,
        {
          sourceDatePrefix: prefixTime,
          attachments: fileNameArr
        }
      );
      const permitExportZIP = response.data.afpermitsResponse.body;
      return Promise.resolve(permitExportZIP);
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject(error);
    }
  };

export const updateInsertNotes =
  (permitId: string, k2Notes: K2Notes[]) =>
  async (dispatch: Dispatch<SetPermitNotesAction>) => {
    try {
      const response = await axiosClient.patch(
        `${envConfig.api.permits}/${permitId}/projecthistory`,
        { notes: k2Notes }
      );

      const resBody = response.data.afpermitsResponse.body.notes;

      return dispatch({
        type: ActionType.SET_PERMIT_NOTES,
        payload: resBody
      });
    } catch (error) {
      processError(error, dispatch);
      return Promise.reject('Error updating permit location');
    }
  };
