import ActionType from '../action-types';
import {
  PermitSidebarActions,
  PermitSidebarState
} from '../../models/store-permit-sidebar.model';

const initialState: PermitSidebarState = {
  activeStep: 'details'
};

const permitSidebarReducer = (
  state: PermitSidebarState = initialState,
  action: PermitSidebarActions
): PermitSidebarState => {
  switch (action.type) {
    case ActionType.SET_PERMIT_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    default:
      return state;
  }
};

export default permitSidebarReducer;
