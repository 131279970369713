import { Card, CardContent } from '@mui/material';
import { TrenchSpec as TrenchSpecInterface } from 'src/models/trench.model';
import { Text } from 'src/models/form.model';

import './TrenchSpecView.scss';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/store/store';

interface Props extends PropsFromRedux {
  trenchSpec: TrenchSpecInterface;
}

const TrenchSpecView = (props: Props) => {
  const {
    trenchSpec: {
      intersectingStreet,
      trenchRepairTypeId,
      width,
      length,
      depth
    },
    trenchRepairTypes
  } = props;
  return (
    <>
      <Card sx={{ boxShadow: 'none' }}>
        <CardContent>
          <div className="cell">
            <span>Repair Type</span>
            <strong>
              {trenchRepairTypes.find(
                (repairType) => repairType.id == trenchRepairTypeId
              )?.type || Text.NA}
            </strong>
          </div>
          <div className="cell">
            <span>Location (Street)</span>
            <strong>{Text.NA}</strong>
          </div>
          <div className="cell">
            <span>Intersecting Street</span>
            <strong>{intersectingStreet || Text.NA}</strong>
          </div>
          <div className="dimensions">
            <div className="cell">
              <span>Length x</span>
              <strong>{length || Text.NA}</strong>
            </div>
            <div className="cell">
              <span>Width x</span>
              <strong>{width || Text.NA}</strong>
            </div>
            <div className="cell">
              <span>Depth x</span>
              <strong>{depth || Text.NA}</strong>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  trenchRepairTypes: state.filter.dropdownOptions?.trenchRepairTypes || []
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TrenchSpecView);
