import envConfig from 'src/env-config';
import { NotificationType } from 'src/models/email-notification.model';
import axiosClient from 'src/utils/axios-client';

export const schNotSubmittedNotif = (permitId: string | number) => {
  const reqBody = {
    permitId: String(permitId),
    notifType: NotificationType.NOT_SUBMITTED
  };
  try {
    axiosClient.post(envConfig.api.scheduleEmail, reqBody);
  } catch (error) {
    console.error(error);
  }
};

export const schAssignmentNotif = (permitId: string | number) => {
  const reqBody = {
    permitId: String(permitId),
    notifType: NotificationType.ASSIGNMENT
  };
  try {
    axiosClient.post(envConfig.api.scheduleEmail, reqBody);
  } catch (error) {
    console.error(error);
  }
};

export const schPermitExpiringNotif = (permitId: string | number) => {
  const reqBody = {
    permitId: String(permitId),
    notifType: NotificationType.PERMIT_EXPIRING
  };
  try {
    axiosClient.post(envConfig.api.scheduleEmail, reqBody);
  } catch (error) {
    console.error(error);
  }
};

export const schRequestorResolvedActionsNotif = (
  permitId: string | number,
  needsActionId: string | number
) => {
  const reqBody = {
    permitId: String(permitId),
    needsActionId: String(needsActionId),
    notifType: NotificationType.REQUESTOR_RESOLVED_ACTIONS
  };
  try {
    axiosClient.post(envConfig.api.scheduleEmail, reqBody);
  } catch (error) {
    console.error(error);
  }
};

export const schActionNeededNotif = (
  permitId: string | number,
  needsActionId: string | number
) => {
  const reqBody = {
    permitId: String(permitId),
    needsActionId: String(needsActionId),
    notifType: NotificationType.ACTION_NEEDED
  };
  try {
    axiosClient.post(envConfig.api.scheduleEmail, reqBody);
  } catch (error) {
    console.error(error);
  }
};

export const schInProgressWithPermittingNotif = (permitId: string | number) => {
  const reqBody = {
    permitId: String(permitId),
    notifType: NotificationType.IN_PROGRESS_WITH_PERMITTING
  };
  try {
    axiosClient.post(envConfig.api.scheduleEmail, reqBody);
  } catch (error) {
    console.error(error);
  }
};

export const schNewCommentsNotif = (permitId: string | number) => {
  const reqBody = {
    permitId: String(permitId),
    notifType: NotificationType.NEW_COMMENTS
  };
  try {
    axiosClient.post(envConfig.api.scheduleEmail, reqBody);
  } catch (error) {
    console.error(error);
  }
};

export const schTaggedCommentNotif = (
  permitId: string | number,
  commentId: string | number
) => {
  const reqBody = {
    permitId: String(permitId),
    commentId: String(commentId),
    notifType: NotificationType.TAGGED_COMMENT
  };
  try {
    axiosClient.post(envConfig.api.scheduleEmail, reqBody);
  } catch (error) {
    console.error(error);
  }
};

export const schPermitCreatedNotif = (permitId: string | number) => {
  const reqBody = {
    permitId: String(permitId),
    notifType: NotificationType.PERMIT_CREATED
  };
  try {
    axiosClient.post(envConfig.api.scheduleEmail, reqBody);
  } catch (error) {
    console.error(error);
  }
};

export const schPermitIssuedNotif = (permitId: string | number) => {
  const reqBody = {
    permitId: String(permitId),
    notifType: NotificationType.PERMIT_ISSUED
  };
  try {
    axiosClient.post(envConfig.api.scheduleEmail, reqBody);
  } catch (error) {
    console.error(error);
  }
};

export const deleteAllNotifEvents = (permitId: string | number) => {
  const reqBody = {
    permitId: String(permitId),
    notifType: NotificationType.DELETE_ALL
  };
  try {
    axiosClient.post(envConfig.api.scheduleEmail, reqBody);
  } catch (error) {
    console.error(error);
  }
};
