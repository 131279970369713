import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0193D5'
    },
    // secondary: {main: ''},
    error: { main: '#CD3729' },
    warning: {
      main: '#DF962F',
      contrastText: '#FFFFFF'
    },
    info: {
      main: '#123456'
    },
    success: {
      main: '#61AD55',
      contrastText: '#FFFFFF'
    }
    // mode?: PaletteMode;
    // tonalOffset?: PaletteTonalOffset;
    // common?: Partial<CommonColors>;
    // grey?: ColorPartial;
    // text?: Partial<TypeText>;
    // divider?: string;
    // action?: Partial<TypeAction>;
    // background?: Partial<TypeBackground>;
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '6px 24px',
          textTransform: 'none',
          width: 'fit-content'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          width: 'fit-content'
        }
      }
    }
  }
});

export default theme;
