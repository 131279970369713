import { RootState } from '../store';
import { NeedsAction } from '../../models/needs-action.model';
import { User } from '../../models/user.model';
import { createSelector, Selector } from 'reselect';
import { PermitRequest } from '../../models/permit.model';
import { UserRole } from '../../models/user-role.model';

const USER_ROLES_SEE_ALL_NOTIFICATIONS = [UserRole.ADMIN];

/**
 * Returns notifications that should display in the notification Badge
 *
 */
export const needsActionBadgeNotificationsSelector = createSelector<
  [
    Selector<RootState, NeedsAction[]>,
    Selector<RootState, PermitRequest | null>,
    Selector<RootState, User | null>
  ],
  NeedsAction[]
>(
  [
    (state) => state.needsAction.needsActNotifications.filter(notResolved),
    (state) => state.permit.permit,
    (state) => state.auth.currentUser
  ],
  (needsActionNotifications, permit, user) => {
    if (!user || !user.email || needsActionNotifications.length === 0) {
      return [];
    }

    // admin can see all needs action items across all permits
    if (USER_ROLES_SEE_ALL_NOTIFICATIONS.indexOf(<UserRole>user.role) >= 0) {
      return needsActionNotifications;
    }

    // logged user can see all needs action items from permits, the logged-in user has created
    const userItems = needsActionNotifications
      .filter((n) => (n && n.permitCreatedByEmailId))
      .filter((n) => {
        return n.permitCreatedByEmailId.toLowerCase() == user.email.toLowerCase();
    });

    // all users can see needs action items of a permit, if the permit is open
    let permitItems: NeedsAction[] = [];
    if (permit?.id) {
      permitItems = needsActionNotifications.filter(
        (n) => n.permitRequestId === permit.id
      );
    }

    // remove duplicates
    const uniqueItems = new Set([...userItems, ...permitItems]);
    return Array.from(uniqueItems);
  }
);

/**
 * Utility function for selecting only NOT resolved needsAction notifications
 *
 * @param n
 */
const notResolved = (n: NeedsAction) => n.resolved === false;
