import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Paper } from '@mui/material';
import './SapInfoMessage.scss';

interface Props {
  className?: string;
}

export const SapInfoMessage = (props: Props) => {
  const { className = '' } = props;

  return (
    <div className={className}>
      <Paper className="sap-info-message" sx={{ backgroundColor: 'moccasin' }}>
        <LinkOffIcon />
        <div>
          <span className="title-message">
            SAP is currently unable to process the request
          </span>
          <br />
          You may still enter all data and submit your request, however, some
          information you provide may be overwritten when SAP is back online.
        </div>
      </Paper>
    </div>
  );
};
