import {
  Button,
  debounce,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as permitActions from 'src/store/action-creators/permit-actions';
import * as actions from 'src/store/action-creators/permit-sidebar-actions';
import { ScheduleForm } from 'src/models/form.model';
import { RootState } from 'src/store/store';
import './Schedule.scss';
import * as React from 'react';
import { useParams } from 'react-router';
import DatePicker from '@mui/lab/DatePicker';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import EventIcon from '@mui/icons-material/Event';
import { PermitRequest } from 'src/models/permit.model';
import { TimeOfWork } from 'src/models/time-of-work-options.model';
import TitleAndSaveStatus from '../TitleAndSaveStatus/TitleAndSaveStatus';

interface Props extends PropsFromRedux {
  permit: PermitRequest;
}

const Schedule = (props: Props) => {
  const { permit, timeOfWorkProposedOptions, setActiveStep, updatePermit } =
    props;
  const { id } = useParams<{ id: string }>();

  // 2 following constants to be changed
  const daysForCloserNotification = 365;
  const daysForExpireNotification = 365;

  const [scheduleForm, setScheduleForm] = useState<ScheduleForm>({
    constructionStartDate: null,
    constructionEndDate: null,
    cmpDueDate: null,
    timeOfWork: null,
    workStartTime: null,
    workEndTime: null
  });
  const [isSavingPermitData, setIsSavingPermitData] = useState(false);
  const [isPermitUploadSuccessful, setIsPermitUploadSuccessful] =
    useState(true);
  const [CMPRequired, setCMPRequired] = useState(false);

  useEffect(() => {
    setScheduleForm({
      constructionStartDate: permit.constructionStartDate || null,
      constructionEndDate: permit.constructionEndDate || null,
      cmpDueDate: permit.cmpDueDate || null,
      timeOfWork: permit.timeOfWorkProposed || null,
      workStartTime: permit.workStartTime || null,
      workEndTime: permit.workEndTime || null
    });
    if (permit.budgetCode === 'CMP') {
      setCMPRequired(true);
    }
  }, [permit]);

  const invokeUpdatePermit = (id: string, result: any) => {
    setIsPermitUploadSuccessful(true);
    setIsSavingPermitData(true);
    updatePermit(id, result)
      .then(() => setIsSavingPermitData(false))
      .catch(() => {
        setIsPermitUploadSuccessful(false);
      });
  };

  //Debounce handlers for each input in Permit Schedule
  const debounceHandlerConstructionStart = useCallback(
    debounce(invokeUpdatePermit, 1000),
    []
  );

  const debounceHandlerConstructionEnd = useCallback(
    debounce(invokeUpdatePermit, 1000),
    []
  );

  const debounceHandlerCMPCompliance = useCallback(
    debounce(invokeUpdatePermit, 1000),
    []
  );

  const debounceHandlerStartTime = useCallback(
    debounce(invokeUpdatePermit, 1000),
    []
  );

  const debounceHandlerEndTime = useCallback(
    debounce(invokeUpdatePermit, 1000),
    []
  );

  const debounceHandlerTimeOfWorkProposed = useCallback(
    debounce(invokeUpdatePermit, 1000),
    []
  );

  const handleScheduleChange = (
    elementKey: string,
    changedValue: any,
    debounceHandler: (id: string, obj: Partial<PermitRequest>) => void
  ) => {
    if (
      (elementKey === 'constructionStartDate' ||
        elementKey === 'constructionEndDate') &&
      !changedValue
    ) {
      return;
    }
    setScheduleForm((prevState) => ({
      ...prevState,
      [elementKey]: changedValue
    }));
    const result = {};
    result[elementKey] = changedValue;
    debounceHandler(id, result);
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setActiveStep('scope');
  };

  const [cmpDueDate, setCmpDueDate] = React.useState<Date | null>(null);
  const [constructionRangeDate, setConstructionRangeDate] = React.useState<
    DateRange<Date>
  >([null, null]);

  const onStartTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { valueAsDate } = event.target;
    const time = moment(valueAsDate);
    handleScheduleChange(
      'workStartTime',
      time ? time.utc().format('YYYY-MM-DD HH:mm:ss') : '2000-01-01 00:00:00',
      debounceHandlerStartTime
    );
  };

  const onEndTimeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { valueAsDate } = event.target;
    const time = moment(valueAsDate);
    handleScheduleChange(
      'workEndTime',
      time ? time.utc().format('YYYY-MM-DD HH:mm:ss') : '2000-01-01 00:00:00',
      debounceHandlerEndTime
    );
  };

  const checkCloserNotification = (
    cmpDueDate: Date | null,
    startDate: Date | null
  ) => {
    if (cmpDueDate && startDate) {
      if (
        cmpDueDate.getTime() <
        startDate.getTime() - 86400000 * daysForCloserNotification
      ) {
        return true;
      }
    }
    return false;
  };

  const checkExtendPermitNotification = (
    cmpDueDate: Date | null,
    endDate: Date | null
  ) => {
    if (cmpDueDate && endDate) {
      if (
        cmpDueDate.getTime() <
        endDate.getTime() - 86400000 * daysForExpireNotification
      ) {
        return true;
      }
    }
    return false;
  };

  const startInputRef: any = React.useRef();
  const endInputRef: any = React.useRef();

  return (
    <>
      <TitleAndSaveStatus
        titleName={'Schedule'}
        isSavingPermitData={isSavingPermitData}
        isPermitUploadSuccessful={isPermitUploadSuccessful}
      />
      <form className="schedule-form" onSubmit={submitForm}>
        <div className="description">
          When is construction <strong>scheduled</strong>? An estimate is ok.
        </div>
        <div className="dates-inline-container">
          <div className="dates-container">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                startText="Construction Starts"
                endText="Construction Ends"
                shouldDisableYear={undefined}
                value={[
                  scheduleForm.constructionStartDate,
                  scheduleForm.constructionEndDate
                ]}
                onChange={(newValue: any) => {
                  setConstructionRangeDate(newValue);
                  handleScheduleChange(
                    'constructionStartDate',
                    newValue[0],
                    debounceHandlerConstructionStart
                  );
                  handleScheduleChange(
                    'constructionEndDate',
                    newValue[1],
                    debounceHandlerConstructionEnd
                  );
                }}
                disableCloseOnSelect={true}
                renderInput={(startProps: any, endProps: any) => (
                  <React.Fragment>
                    <TextField
                      required={true}
                      {...startProps}
                      inputRef={startInputRef}
                      {...startProps}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                startInputRef.current.focus();
                              }}
                            >
                              <EventIcon style={{ color: '#0098da' }} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <Box sx={{ mx: 2 }}> - </Box>
                    <TextField
                      required={true}
                      {...endProps}
                      inputRef={endInputRef}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                endInputRef.current.focus();
                              }}
                            >
                              <EventIcon style={{ color: '#0098da' }} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
            <div className="cmp-due-date">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={'CMP Compliance Due Date'}
                  value={scheduleForm.cmpDueDate}
                  onChange={(newValue: any) => {
                    setCmpDueDate(newValue);
                    handleScheduleChange(
                      'cmpDueDate',
                      newValue,
                      debounceHandlerCMPCompliance
                    );
                  }}
                  renderInput={(params: any) =>
                    CMPRequired ? (
                      <TextField
                        required={true}
                        {...params}
                        sx={{ svg: { color: '#0098da' } }}
                      />
                    ) : (
                      <TextField
                        {...params}
                        sx={{ svg: { color: '#0098da' } }}
                      />
                    )
                  }
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="card-notification-container">
            <Card
              className="card-notification"
              hidden={
                !checkCloserNotification(cmpDueDate, constructionRangeDate[0])
              }
              sx={{ minWidth: 275, minHeight: 16 }}
            >
              <CardContent>
                <Typography variant="body2">
                  Please submit your permit closer to your contruction date.
                </Typography>
              </CardContent>
            </Card>
            <Card
              className="card-notification"
              hidden={
                !checkExtendPermitNotification(
                  cmpDueDate,
                  constructionRangeDate[1]
                )
              }
              sx={{ minWidth: 275 }}
            >
              <CardContent>
                <Typography variant="body2">
                  Your permit may expire during construction, you may want to
                  file for an extension early.
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="description">
          What <strong>time</strong> of the day will work be done?
        </div>
        <div className="time-range">
          <TextField
            label="Daily Start Time"
            type="time"
            size="small"
            InputLabelProps={{
              shrink: true
            }}
            onChange={onStartTimeChange}
            defaultValue={
              permit.workStartTime
                ? moment(permit.workStartTime).parseZone().format('HH:mm')
                : null
            }
            sx={{ width: 180 }}
          />
          <Box sx={{ mx: 2 }}> - </Box>
          <TextField
            label="Daily End Time"
            type="time"
            size="small"
            InputLabelProps={{
              shrink: true
            }}
            onChange={onEndTimeChange}
            defaultValue={
              permit.workEndTime
                ? moment(permit.workEndTime).parseZone().format('HH:mm')
                : null
            }
            sx={{ width: 180 }}
          />
        </div>
        <FormControl variant="outlined" size="small" required>
          <InputLabel id="work-time-select" required={true}>
            Time of work proposed
          </InputLabel>
          <Select
            labelId="work-time-select"
            label="Time of work proposed"
            name="timeOfWork"
            value={scheduleForm.timeOfWork ? scheduleForm.timeOfWork : ''}
            onChange={(event) => {
              handleScheduleChange(
                'timeOfWorkProposed',
                event.target.value,
                debounceHandlerTimeOfWorkProposed
              );
            }}
          >
            {timeOfWorkProposedOptions.map((option: TimeOfWork) => (
              <MenuItem key={option.id} value={option.timeOfWorkProposed}>
                {option.timeOfWorkProposed}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          className="submit-button"
          type="submit"
          variant="contained"
          disableElevation
          disabled={isSavingPermitData}
        >
          Next
        </Button>
      </form>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  timeOfWorkProposedOptions: state.filter.dropdownOptions
    ? state.filter.dropdownOptions.timeOfWorkProposed
    : []
});

const mapDispatchToProps = {
  setActiveStep: actions.setPermitActiveStepAction,
  updatePermit: permitActions.updatePermitAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Schedule);
