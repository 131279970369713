import axios from 'axios';
import envConfig from '../env-config';
import { store } from '../store/store';
import * as authActions from '../store/action-creators/auth-actions';

const CUSTOM_ERROR = 'CUSTOM_ERROR';
export const DBTIMEOUT_ERROR = 'DBTIMEOUT_ERROR';
export const GWTIMEOUT_ERROR = 'GWTIMEOUT_ERROR';
export const API404_ERROR = 'API404_ERROR';

const axiosClient = axios.create({
  baseURL: envConfig.baseUrl,
  headers: {
    'Content-Type': envConfig.headers.contentType,
    'X-Api-Key': envConfig.headers.apiKey
  }
});

axiosClient.interceptors.request.use((config) => {
  const token = getCognitoIdentityServiceProviderValue('idToken');
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`
  };

  // Get rid of Authorization header if skipAtuhorization is present
  if (config.headers.skipAuthorization) {
    delete config.headers.Authorization;
    delete config.headers.skipAuthorization;
  }

  return config;
});

// Response interceptor for API calls
axiosClient.interceptors.response.use(
  async (response) => {
    const apiResponse = response.data?.afpermitsResponse;
    const responseStatusCode = apiResponse?.statusCode;
    const responseMessage = apiResponse?.body?.message;

    // successful response
    if (
      responseStatusCode === 200 ||
      (isS3Url(response.config.url) && response.status === 200)
    ) {
      return response;
    }
    // 401 response, user not authorized - try to refresh the token
    else if (responseStatusCode === 401) {
      const refreshed = await handle401Error();
      if (refreshed) {
        return axiosClient.request(response.config);
      } else {
        alert(
          'There was an Error refreshing user session. Try to refresh the page or log in again.'
        );
        throw new axios.Cancel('Session refresh failed!');
      }
    } else {
      const genericErrorText = 'There was an Error executing Back-End request.';

      // process custom error
      const isCustomError =
        response.data?.afpermitsResponse?.body?.type === CUSTOM_ERROR;
      if (isCustomError) {
        const message = responseMessage || genericErrorText;
        throw new axios.Cancel(message);
      }

      // process  db timeout error
      const isDatabaseTimeoutError = hasResponseText(
        response,
        'The driver has not received any packets from the server'
      );
      if (isDatabaseTimeoutError) {
        throw new axios.Cancel(DBTIMEOUT_ERROR);
      }

      // not found API response error
      const is404Error = responseStatusCode === 404 && !!responseMessage;
      if (is404Error) {
        throw new axios.Cancel(
          [API404_ERROR, responseMessage.replace('|', '')].join('|')
        );
      }

      // process gateway timeout error
      const isGatewayTimeoutError = response.status === 504;
      if (isGatewayTimeoutError) {
        throw new axios.Cancel(GWTIMEOUT_ERROR);
      }

      // forbidden error
      const isForbiddenError =
        response.data?.afpermitsResponse?.statusCode === 403;
      if (isForbiddenError) {
        alert('You are not permitted to do this action!');
        throw new axios.Cancel(genericErrorText);
      }
      // we dont want to display this alert if there is an error on notification scheduling
      // because notification schedule response is not something we want to display on the UI - even if it fails
      if (response.config.url !== envConfig.api.scheduleEmail) {
        alert(
          `There was an Error from BE api: (${response.config.url}). Try to refresh the page.`
        );
      }
      console.error('Error:', response.data?.afpermitsResponse);
      throw new axios.Cancel(genericErrorText);
    }
  },
  (error) => {
    console.error('Error processing the BE request: ', error);
    alert('There was an error processing this request.');
    return Promise.reject(error);
  }
);

const handle401Error = (): Promise<boolean> => {
  return new Promise((resolve) => {
    const refreshToken = getCognitoIdentityServiceProviderValue('refreshToken');
    if (refreshToken) {
      return store
        .dispatch<any>(authActions.refreshSessionAction(refreshToken))
        .then(
          () => {
            // token refreshed
            resolve(true);
          },
          () => {
            // token not refreshed
            resolve(false);
          }
        );
    } else {
      // token not refreshed
      resolve(false);
    }
  });
};

const isS3Url = (url: string | undefined) => {
  return url ? url.includes('.s3.') : false;
};

const getCognitoIdentityServiceProviderValue = (key: string) => {
  const userName = localStorage.getItem(
    'CognitoIdentityServiceProvider.' +
      envConfig.auth.userPoolWebClientId +
      '.LastAuthUser'
  );
  const storagKey =
    'CognitoIdentityServiceProvider.' +
    envConfig.auth.userPoolWebClientId +
    '.' +
    userName +
    '.' +
    key;
  return localStorage.getItem(storagKey);
};

/**
 * Returns true, if the (current) response object includes provided text
 * in its body=>message | body => errorMessage
 *
 * @param response
 * @param text
 */
const hasResponseText = (response: any, text = ''): boolean => {
  const responseBody =
    response?.data?.afpermitsResponse?.body ||
    response?.data?.afpermitsResponse;
  const responseMessage = responseBody?.message || responseBody?.errorMessage;
  if (!responseMessage) return false;
  return responseMessage.indexOf(text) >= 0;
};

export default axiosClient;
