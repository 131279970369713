import { UserRole } from '../models/user-role.model';

/**
 * Database of permissions for actions by userRoles
 */
export const permissionsList = {
  EXPORT_PERMIT: {
    name: 'export permit pdf or zip',
    byRole: {
      [UserRole.ADMIN]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.PSS1]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.TS]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.TA]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.QAQC]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.TCP]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.REQUESTOR]: {
        allowed: true,
        onlyOwnItem: false
      }
    }
  },

  MODIFY_SCHEDULE: {
    name: 'modify construction schedule',
    byRole: {
      [UserRole.ADMIN]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.PSS1]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.TS]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.TA]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.QAQC]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.TCP]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.REQUESTOR]: {
        allowed: false
      }
    }
  },
  MODIFY_DETAILS: {
    name: 'modify permit details ',
    byRole: {
      [UserRole.ADMIN]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.PSS1]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.TS]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.TA]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.QAQC]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.TCP]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.REQUESTOR]: {
        allowed: false
      }
    }
  },
  MODIFY_TCP: {
    name: 'modify TCP data',
    byRole: {
      [UserRole.ADMIN]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.PSS1]: {
        allowed: false
      },
      [UserRole.TS]: {
        allowed: false
      },
      [UserRole.TA]: {
        allowed: false
      },
      [UserRole.QAQC]: {
        allowed: false
      },
      [UserRole.TCP]: {
        allowed: true
      },
      [UserRole.REQUESTOR]: {
        allowed: false
      }
    }
  },
  CHANGE_STATUS: {
    name: 'change/update submitted permit status',
    byRole: {
      [UserRole.ADMIN]: {
        allowed: true,
        onlyOwnItem: false
      },
      [UserRole.PSS1]: {
        allowed: true
      },
      [UserRole.TS]: {
        allowed: true
      },
      [UserRole.TA]: {
        allowed: true
      },
      [UserRole.QAQC]: {
        allowed: true
      },
      [UserRole.TCP]: {
        allowed: true
      },
      [UserRole.REQUESTOR]: {
        allowed: false
      }
    }
  }
};
