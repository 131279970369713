import { User } from './user.model';

export const USER_TAG_REGEXP = /@\[name:(.*?)\]\(email:.*?\)/g; // match the pattern "@[name:First Last](email:user@example.com)"

export interface Comment {
  id: number;
  replyTo: number | null;
  author: User;
  text: string;
  createdAt: Date;
  editedAt: Date;
  isRemoved: boolean;
}

export interface UnreadComments {
  unreadCommentTotal: number;
  unreadComments: UnreadComment[];
}

export interface UnreadComment {
  permitId: number;
  jobNumber: string | null;
  newCommentCount: number | null;
  lastComment: Comment;
}
