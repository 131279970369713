export enum NotificationType {
  NOT_SUBMITTED = 'ns',
  ASSIGNMENT = 'asg',
  PERMIT_EXPIRING = 'pe',
  REQUESTOR_RESOLVED_ACTIONS = 'rra',
  ACTION_NEEDED = 'an',
  IN_PROGRESS_WITH_PERMITTING = 'ipwp',
  NEW_COMMENTS = 'nc',
  TAGGED_COMMENT = 'tc',
  PERMIT_CREATED = 'pc',
  PERMIT_ISSUED = 'pi',
  DELETE_ALL = 'del'
}
